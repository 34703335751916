import { useEffect, useState } from "react";
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import { Input,Dropdown,Popover,message} from 'antd';
import { httpGet, httpPost, host_url } from '../../utils/httpUtils';
import Iframe from 'react-iframe';
import { Select,Drawer,InputNumber , Button, Tabs,Modal} from 'antd';
import {PushDonate} from "./PushDonate";
import { Formatter } from './Formatter';
import "./Live.css";
import { Common } from "../../app/Common";
import {UserHeader} from "./UserHeader";
import { Share } from './Share';
import { url } from 'inspector';

interface CommentVo{
	liveId:any,
	content:any
}



export function Live() {
    const { TextArea } = Input;
    const [commentVo,setCommentVo] =  useState({}as CommentVo);
    const [commentList,setCommentList] =  useState([]as any);
    const [live,setLive] =  useState({} as any);
	const navigate = useNavigate();
    let query = new URLSearchParams(useLocation().search);
	let  liveId =query.get("liveId");
    useEffect(()=>{
        getDetail();
        if(liveId){
            getLiveCommentList();
			autoSeconds();

			setTimeout( () =>{
					watch()
			}, 10000);
        }
    },[]) 


	function autoSeconds(){
		let id = localStorage.getItem("live_id");
		if(id){
		   clearInterval(id);
	   } 
	   let intervalID =setInterval(()=>{
			getLiveCommentList()
	   },8000)
	   localStorage.setItem("live_id",intervalID+"");
	}




    function getLiveCommentList(){
		httpGet("/live/getCommentList/"+liveId).then((e:any)=>{
			if(e.data.code == 0 ){
				setCommentList(e.data.data);
			}
		})
	}

    function getDetail(){
		httpGet("/live/getDetail/"+liveId).then((e:any)=>{
			if(e.data.code == 0 ){
                setLive(e.data.data);
			}
		});
	}

    function commentSubmit(){
		httpPost("/live/comment",{liveId:liveId,content:commentVo.content,}).then((e:any)=>{
			if(e.data.code == 0 ){
                getLiveCommentList();
				message.success("comment is  success");	
			}else{
				localStorage.setItem("pageFrom","/#/ChLive?liveId="+liveId);
				navigate("/SignIn");
			}
		});
	}

	



	function watch(){
		httpGet("/live/watch/"+liveId).then((e:any)=>{
			if(e.code == 0 ){
				live.watchNum = live.watchNum+1;
				setLive(live);
			}
		});
	}

	function queryURLparams(url:any) {
		let obj = {} as any
		if (url.indexOf('?') < 0) return obj
		let arr = url.split('?')
		url = arr[1]
		let array = url.split('&')
		for (let i = 0; i < array.length; i++) {
			let arr2 = array[i]
			let arr3 = arr2.split('=')
			obj[arr3[0]] = arr3[1]
		}
		return obj
	
	}
	

    function getUrl(url:any){
		if(url && url.indexOf('live') > -1){
			try {
				let code =url.replace("https://www.youtube.com/live/","").replace("https://youtube.com/live/","").split("?")[0];
				return `https://www.youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}

		if( url  && url.indexOf('shorts') > -1){
			try {
				let code =url.replace("https://youtube.com/shorts/","").split("?")[0];
				return `https://youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('watch') > -1){
			try {
				 let ags =  queryURLparams(url);
				return `https://www.youtube.com/embed/${ags.v}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('youtu.be') > -1){
			try {
				 let ags =url.replace("https://youtu.be/","").split("?")[0];
				return `https://www.youtube.com/embed/${ags}`;
			} catch (error) {
				
			}
		}
        return "";
    }

    function commentSubmitChild(id:any){
		httpPost("/live/comment",{liveId:liveId,content:commentVo.content,superiorId:id}).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("comment is  success");	
				commentVo.content=null;
				setCommentVo({...commentVo});
				getLiveCommentList();
				getDetail();
			}else{
				localStorage.setItem("pageFrom","/#/ChLive?liveId="+liveId);
				navigate("/SignIn");
			}
		});
	}

    function showSendContent(indx:any,item:any){
		for (let index = 0; index < commentList.length; index++) {
			if(indx !=index ){
				commentList[index].showSendContent=false;
			}
		}
		commentList[indx].showSendContent=!commentList[indx].showSendContent;
		setCommentList([...commentList]);
	}


	function commentAttention(item:any,index:any){
		httpGet("/attention/commentAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				commentList[index].isLike=1;
				setCommentList([...commentList]);
			}else{
				localStorage.setItem("pageFrom","/#/ChLive?liveId="+liveId);
				navigate("/SignIn");
			}
		})
	}

	function liveAttention(){
		httpGet("/attention/liveAttention/"+liveId).then((e:any)=>{
			if(e.data.code == 0 ){
				getDetail();
			}else{
				localStorage.setItem("pageFrom","/#/ChLive?liveId="+liveId);
				navigate("/SignIn");
			}
		})

	}
	function cancelLiveAttention(){
		httpGet("/attention/cancelAttention/"+liveId+"/6").then((e:any)=>{
			if(e.data.code == 0 ){
			
				getDetail();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/ChLive?liveId="+liveId);
				navigate("/SignIn");
			}
		})
	}
	function cancelLiveCollect(){
		httpGet("/collect/cancelCollect/"+liveId+"/5").then((e:any)=>{
			if(e.data.code == 0 ){
				getDetail();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/ChLive?liveId="+liveId);
				navigate("/SignIn");
			}
		})

	}
	function liveCollect(){
		httpGet("/collect/liveCollect/"+liveId).then((e:any)=>{
			if(e.data.code==0){
				getDetail();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/ChLive?liveId="+liveId);
				navigate("/SignIn");
			}
		})
	}

	function cancelAttention(item:any,index:any){
		httpGet("/collect/cancelCollect/"+item.id+"/5").then((e:any)=>{
			if(e.data.code==0){
				commentList[index].isLike=0;
				setCommentList([...commentList]);
			}else{
				localStorage.setItem("pageFrom","/#/ChLive?liveId="+liveId);
				navigate("/SignIn");
			}
		})
	}



	let Web=  <div  className="live_centent" >
		<div className="live_iframe">
            <Iframe url={getUrl(live.url)}
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
            position="relative"/>
		</div>

      <div  className="live_flex" >
        <div  className="live_title" >
            <div  style={{width:"80%"}} >
              {live.name}
            </div>
			<div className="forumflex"   style={{width:"20%",justifyContent:"flex-end"}}  >
					<div   className="forumflex" style={{width:"100%",alignItems:"center"}}  >
					<div  className="forum_sc" >
							<div>
							{live.isAttentin==0?
							<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{liveAttention()}} className="dis_list_img"  alt="" />:
							<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelLiveAttention()}} className="dis_list_img"  alt="" />}
							<span  className="dis_sc_1" >{live.attentionNum}</span>
							</div>
							<div  >
								   <img src={require("../../assets/v2/feed@2x.png")}  className="dis_list_img"   alt="" />
								   <span  className="dis_sc_1" >{live.commentNum}</span>
							 </div>
							<div> {live.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{liveCollect()}}  className="dis_list_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelLiveCollect()}}  className="dis_list_img"   alt="" />}
							</div>
							{live.image?	<Share sharedata={{type:"live",title:live.name,url:host_url+"/#/ChLive?liveId="+live.id}} ></Share>:<div></div>}
						
					</div>
				</div>
			</div>

        </div>

        <div  className="live_user" >
            <div className="live_user_1"  >
				{live.channelEntity ? <div  className="live_user_1" >
					<div>
						<img src={live.channelEntity.headerImgArr[0]}  className="live_user_img"  alt="" />
					</div>
					<div className="live_user_1_0" >
						<div  className="live_user_name" >
						{live.channelEntity.name}
						</div>  
						<div className="live_user_1_1" >
							<div className="live_user_1_tag"  >{live.channelEntity.plateName}</div>
							<div  className="live_user_1_num"  >{live.watchNum}</div>
						</div>
					</div>
				</div>:""}
				{live.projectEntity?<div>
                     <PushDonate  pages={"/ChLive?liveId="+liveId} handleClick={getDetail} projct={live.projectEntity} fontSize="1.8vw"  ></PushDonate>
                 </div>:""}
            </div>
        </div>
        <div  className="live_detail" >
               {live.description}
        </div>
      </div>

      <div className="live_comment_box" >
				<div className="tiding_feedback" >Feedback</div>
				<div className="tiding_comment_input"  >
					<TextArea placeholder="Your Mint Details" 
					autoSize={{ minRows: 2, maxRows: 6 }}
					onChange={(e)=>{
						commentVo.content=e.target.value;
						setCommentVo({...commentVo});
					}}
					bordered={false} style={{width:"40.1042vw",height:"9.8438vw"}} />

					<div className="tiding_send_but_box" >
							<div className="tiding_send_but but-shadow"  onClick={commentSubmit} >
								SEND
							</div>
					</div>
				</div>
				<div  className="tiding_comment_msg" >
					{commentList.map((item:any,index:any)=>{
						return 	<div key={index} style={{marginTop:30}} >
						<div className="tiding_comment_item" >
							<div className="tiding_comment_item_1"  >
								<img src={item.memberHeadImg?item.memberHeadImg:require("../../assets/v2/logo.png")} alt=""   className="member_logo" /> 
								<div className="tiding_comment_item_1_1" >{item.memberName}</div>
							</div>
								<div className="tiding_comment_item_2"  >{item.content}</div>
								<div  className="tiding_content_item_sx" >
									<div className="tiding_comment_item_3"  >
									<Formatter dateTime={item.createDateTime} ></Formatter>
									</div>
									{item.isLike==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{commentAttention(item,index)}} className="dis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,index)}} className="dis_list_img"  alt="" />}
									<div className="tiding_comment_item_3" style={{marginLeft:"20px"}}  onClick={()=>showSendContent(index,item)} >Reply</div>
								</div>
								{item.showSendContent? <div className="tiding_comment_input"  >
									<TextArea placeholder="Your Mint Details" 
									autoSize={{ minRows: 2, maxRows: 6 }}
									onChange={(e)=>{
										commentVo.content=e.target.value;
										setCommentVo({...commentVo});
									}}
									bordered={false} style={{width:"40.1042vw",height:"9.8438vw"}} />

									<div className="tiding_send_but_box" >
											<div className="tiding_send_but"  onClick={()=>commentSubmitChild(item.id)} >
												SEND
											</div>
									</div>
								</div>:""}
								


						</div>
					</div>
					})}
				</div>
			</div>
	</div>

	let  Mobile=<div  className="mlive_centent" >
	<div className="mlive_iframe">
		<Iframe url={getUrl(live.url)}
		width="100%"
		height="100%"
		id=""
		className=""
		display="block"
		position="relative"/>
	</div>

	<div  className="mlive_title" >
		  {live.name}
	</div>

	<div className="mlive_row"  >
		<div  className="mlive_tr" >
		Traditional media.
		</div>
		<div  className="mlive_but" >
			TEAM
		</div>
	</div>

	<div  className="mlive_detail" >
		   {live.description}
	</div>

	<div className="mproject_donate"  style={{justifyContent:"space-between",paddingLeft:"3.3vw",paddingRight:"3.3vw"}} >
	{		live.projectEntity?<div>
			<PushDonate  pages={"/ChLive?liveId="+liveId} handleClick={getDetail} projct={live.projectEntity} fontSize="1.8vw"  ></PushDonate>
			</div>:""}
			<div  className="moptions_box" >
				<div  className="moptions_box_flex"  >
					{live.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{liveAttention()}} className="moptions_box_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelLiveAttention()}} className="moptions_box_img"  alt="" />}
					<div className="moptions_box_num" >{live.attentionNum}</div>
				</div>
				<div  className="moptions_box_flex "    >
					<img src={require("../../assets/v2/feed@2x.png")}  className="moptions_box_img"   alt="" />
					<div className="moptions_box_num" >{live.commentNum}</div>
				</div>
				
				<div  className="moptions_box_flex "  >
					{live.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{liveCollect()}}  className="moptions_box_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelLiveCollect()}}  className="moptions_box_img"   alt="" />}
				</div>

				{live.image?	<Share sharedata={{type:"live",title:live.name,url:host_url+"/#/ChLive?liveId="+live.id}} ></Share>:<div></div>}

			</div>
	</div>

  <div style={{width:"100%",paddingLeft:"3.3vw",paddingRight:"3.3vw"}} >
			<div className="tiding_feedback" >Feedback</div>
			<div className="mproject_comment_input"  >
					<TextArea placeholder="Your Mint Details"
					autoSize={{ minRows: 2, maxRows: 6 }}
					onChange={(e)=>{
						commentVo.content=e.target.value;
						setCommentVo({...commentVo});
					}}
					bordered={false}  className="mproject_comment_input_text" />
					<div className="msend_but_box" >
							<div className="msend_but"  onClick={commentSubmit} >
								SEND
							</div>
					</div>
			</div>
			


	<div  className="mproject_comment_msg" >
			{commentList.map((item:any,index:any)=>{
				return 	<div  key={index} style={{marginTop:30}} >
				<div className="mcomment_item" >
					<div className="mcomment_item_1"  >
						<UserHeader member={item.memberEntity}  ></UserHeader>
						<div className="mcomment_item_1_name" >{item.memberName}</div>
					</div>
					<div className="mcomment_item_content"  >{item.content}</div>
					<div className="mcomment_item_3"  >
							<Formatter dateTime={item.createDateTime} ></Formatter>
							{item.isLike==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{commentAttention(item,index)}} className="mlive_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,index)}} className="mlive_img"  alt="" />}
							<div className="tiding_comment_item_3" style={{marginLeft:"20px"}}  onClick={()=>showSendContent(index,item)} >Reply</div>
						</div>
				</div>
					{item.showSendContent?<div className="mproject_comment_input"  >
							<TextArea placeholder="Your Mint Details"
							autoSize={{ minRows: 2, maxRows: 6 }}
							onChange={(e)=>{
								commentVo.content=e.target.value;
								setCommentVo({...commentVo});
							}}
							bordered={false}  className="mproject_comment_input_text" />
							<div className="msend_but_box" >
									<div className="msend_but" onClick={()=>commentSubmitChild(item.id)} >
										SEND
									</div>
							</div>
					</div>:""}
			</div>
			})}
		</div>
		</div>
</div>

return Common.isMobile ? Mobile : Web

}
