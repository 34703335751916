import {BasePage, page} from "./BasePage";
import "./SignIn.css";
import { Input} from 'antd';
import { CSignIn} from '../components/CSignIn';

@page("SignIn")
export class SignIn extends BasePage {
	protected webContent() {
		return <CSignIn ></CSignIn>
	}
	protected mobileContent(): JSX.Element {
		return <CSignIn></CSignIn>
	}
}
