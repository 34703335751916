import { useEffect, useState } from "react";
import { Link, useNavigate  } from 'react-router-dom'
import { Input,message,Select,Drawer,InputNumber,Image } from 'antd';
import "./Image.css";
import { url } from 'inspector';


type IProps = {
	item:any
}

export function ImageList(param:IProps) {

	//"width\":400,\"height\":400
  let imageArray=JSON.parse(param.item);
  for (let index = 0; index < imageArray.length; index++) {
		let  item = imageArray[index];
		if(item.width>360){
			item.height_n=360/item.width*item.height/19.2;
			item.width_n=360/19.2;
		}
  }



return   imageArray.map((ite:any,inx:any)=>{
	return <div  key={inx} style={{marginRight:20}} >
			<Image
			   style={{objectFit:"cover"}}
							width={"14.4792vw"}
							height={"9.3229vw"}
							src={ite.url}
						/>
			
	</div>
})
}
