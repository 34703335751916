import {BasePage, page} from "./BasePage";
import "./Project.css";
import { CProject} from '../components/CProject';
import { Navigation } from "../components/Navigation";

@page("Project")
export class Project extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
			<CProject></CProject>
		</div>
	}
	protected mobileContent(): JSX.Element {
		return <div>
			<CProject></CProject>
		</div>
	}
}
