import { useEffect, useState } from "react";
import {httpGet,httpPost} from '../../utils/httpUtils';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export function HomeImg() {

    useEffect(()=>{
        getHomeImg();
	},[])


    const [homeUrl,setHomeUrl] =  useState("");
    const [link,setLink] =  useState("");

    function getHomeImg(){
        httpGet("/common/getBannerUrl/1").then((e:any)=>{
         setHomeUrl(e.data.url);
         setLink(e.data.link);
        })
    }
	let Web=  <div  className="live_centent" >
                    {homeUrl ?  <img src={homeUrl} onClick={()=>{
                    window.open(link);
                }}  className="top_box_banner" alt="" /> : <Skeleton className="top_box_banner" ></Skeleton>}
               
            </div>

return  Web

}
