/* eslint-disable @typescript-eslint/no-unused-vars */
import "./ChannelPage.css";
import { Common } from "../../app/Common";
import { useEffect, useState } from 'react'
import { MMChannel } from '../components/MMChannel';
import { request } from "http";
export function ChannelPage() {
  // @ts-ignore
  const Web = <div id="ChannelPage" mode="web"></div>
  // @ts-ignore
  const Mobile = <div id="ChannelPage" mode="mobile">

            <div  className="ChannelPageTitle">Channel </div>
            <MMChannel hiddenbanner={true} limit={100}></MMChannel>
  </div>
  return Common.isMobile ? Mobile : Web
}