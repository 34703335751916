import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {httpGet,httpPost,host_url} from '../utils/httpUtils';
import { State } from '../app/Store'

const initialState= {
  tagsList: []as Array<any>,
  tagsListALL:[]as Array<any>
}
export const CountSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    tagsList: (state, member) => {
      state.tagsList = member.payload?member.payload:state.tagsList
    },
    tagsListALL: (state, member) => {
      state.tagsListALL = member.payload?member.payload:state.tagsListALL
    }
  },
})


export const tagAsync = () => {
  return async (dispatch: any, getState: any) => {
    httpGet("/common/getTagAll").then((e:any)=>{
      if(e.data.code==0){
        dispatch(tagsList(e.data.data));
      }
    })
  }
}

export const tagAllAsync = () => {
  return async (dispatch: any, getState: any) => {
    httpGet("/common/getTagAll").then((e:any)=>{
      if(e.data.code==0){
        let data  = e.data.data;
				data.unshift({label:"All",value:"All"});
        dispatch(tagsListALL(data));
      }
    })
  }
}

export const { tagsList,tagsListALL } = CountSlice.actions
export default CountSlice.reducer

