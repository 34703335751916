import { State, useAppDispatch, useAppSelector } from "../../app/Store";
import { useEffect, useState } from "react";
import { ChainId, ethereum, web3 } from "../../app/Config";
import { getContract } from "../../app/Contract";
import { Progress, message, Modal, Image, Spin, Divider } from 'antd';
import { Common } from "../../app/Common";
import { NavBar, Popup } from 'antd-mobile'
import {
	TwitterShareButton,
	TwitterIcon,
} from "react-share";
import {
	ConnectSelectors,
	ConnectTask,
	disconnect,
	SwitchTask,
	updateAddress,
	updateBalance,
	updateChainId
} from "../../slices/ConnectSlice";
import "./NftList.css";

export function NftList() {
	const dispatch = useAppDispatch();
	const isConnected = useAppSelector(ConnectSelectors.isConnected);
	const displayAddress = useAppSelector(ConnectSelectors.displayAddress);
	const { address, chainId } = useAppSelector(ConnectSelectors.userData);
	async function init() {
		if (address) {
			const contract = await getContract();
			let startMintTime8848 = await contract.methods.getStartMintTime(8848).call();
			let startMintTime8849 = await contract.methods.getStartMintTime(8849).call();
			let startMintTime8850 = await contract.methods.getStartMintTime(8850).call();
		}
	}

	async function mint(id: any, img: any,gif:any) {
		//showModal(gif);
		if (!isConnected) {
			message.success("PLEASE CONNECT Wallet");
			return;
		}
		const contract = await getContract();
		setLoading(true);
		 let  balanceOf = await contract.methods.balanceOf(address, id).call();
		if(balanceOf > 0){
			setLoading(false);
			message.success(
				{
					content: 'You have already minted, unable to mint ',
					className: 'custom-class',
					style: {
						marginTop: '20vh',
					},
				}

			);
			return;
		}



		let hasDonatAddress = await contract.methods.hasDonatAddress(address, id).call();
		if (hasDonatAddress && balanceOf == 0) {
			contract.methods.mint(1, id).send({
				from: address
			}).on('error', (error: any) => {
				setLoading(false);
				message.error(error);
			}).on('transactionHash', (txHash: any) => {
				setLoading(false);
				console.warn("transactionHash", txHash)
			}).on('receipt', (receipt: any) => {
				setLoading(false);
				showModal(gif);
				// setTranslate(true);
				// setTimeout(() => {
				// 	setTranslate(false);
				// }, 3000)

			})
		} else {
			setLoading(false);
			message.success(
				{
					content: 'Mint address for donation of appropriate amount, unable to Mint ',
					className: 'custom-class',
					style: {
						marginTop: '20vh',
					},
				}

			);
		}
	}


	function share(imgName: any) {
		let metaArr = [
			'twitter:title', 'Build your profile with impact badge',
			'twitter:description', 'Connect with like-minded people ',
			'twitter:card', 'summary_large_image',
			'twitter:image', 'https://www.impactopia.net/impact-share/img/' + imgName + '.png'
		]
		let metaParams = metaArr.toString()
		metaParams = encodeURIComponent(encodeURIComponent(metaParams))
		window.open(`https://twitter.com/share?text= Here is my impact badge minted from Impactopia. claim yours and meet some great people!&url=https://www.impactopia.net/impact/api/share?meta=${metaParams}`)
	}


	useEffect(() => {
		init();
	}, [address, dispatch]);


	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showModalImge, setShowModalImge] = useState("gold");
	const [translate, setTranslate] = useState(false);
	const [loading, setLoading] = useState(false);

	const showModal = (imgName: any) => {
		setShowModalImge(imgName);
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};


	function open(address: any) {
		window.open("https://etherscan.io/address/" + address);
	}

	let [openRequirement, setOpenRequirement] = useState(false);

	let [openData, setOpenData] = useState({id:"",title:"",img:"",gif:"",moeny:''});

	function openRequirementHandle(id: any,title:any,img:any,moeny:any,gif:any) {
		openData.id=id;
		openData.title=title;
		openData.img=img;
		openData.moeny=moeny;
		openData.gif=gif;
		setOpenData({...openData});
		setOpenRequirement(true);
	}

	


	return <div className="nft-list"  >
		<div className="nft-mbg">
			<div className="nft-mbg-dis" onClick={() => openRequirementHandle('8848','Ukraine Donor Badge(Gold):','cat1','Donated at least 1000USD(USDT,USDC,DAI) or 1 ETH to','gold')}>
				<div className="nft-mbg-img-gold"></div>
			</div>
		</div>
		<div className="nft-mbg3">
			<div className="nft-mint-bt" onClick={() => mint('8848', 'cat1','gold')}>
				<div className="nft-mint-bt-img"></div>
				<div className="nft-mint-bt-text">Mint on Polygon→</div>
			</div>
		</div>

		<div className="nft-mbg3">
			<div className="nft-mint-tw" onClick={() => share('cat1')}>
				<div className="nft-mint-tw-img"></div>
				<div className="nft-mint-tw-text">Share on Twitter</div>
			</div>
		</div>
		


		<div className="nft-mbg">
			<div className="nft-mbg-dis" onClick={() => openRequirementHandle('8849','Ukraine Donor Badge(Silver):','cat2','Donated at least 100USD(USDT,USDC,DAI) or 0.1 ETH to','silver')} >
				<div className="nft-mbg-img-silver"></div>
			</div>
		</div>
		<div className="nft-mbg3">
			<div className="nft-mint-bt" onClick={() => mint('8849', 'cat2','silver')} >
				<div className="nft-mint-bt-img"></div>
				<div className="nft-mint-bt-text">Mint on Polygon→</div>
			</div>
		</div>
		<div className="nft-mbg3">
			<div className="nft-mint-tw" onClick={() => share('cat2')}>
				<div className="nft-mint-tw-img"></div>
				<div className="nft-mint-tw-text">Share on Twitter</div>
			</div>
		</div>


		<div className="nft-mbg">
			<div className="nft-mbg-dis"  onClick={() => openRequirementHandle('8850','Ukraine Donor Badge(Bronze):','cat3','Donated at least 1USD(USDT,USDC,DAI) or 0.001 ETH to','bronze')} >
				<div className="nft-mbg-img-bronze"></div>
			</div>
		</div>
		<div className="nft-mbg3">
			<div className="nft-mint-bt" onClick={() => mint('8850', 'cat3','bronze')}   >
				<div className="nft-mint-bt-img"></div>
				<div className="nft-mint-bt-text">Mint on Polygon→</div>
			</div>
		</div>
		<div className="nft-mbg3">
			<div className="nft-mint-tw" onClick={() => share('cat3')}>
				<div className="nft-mint-tw-img"></div>
				<div className="nft-mint-tw-text">Share on Twitter</div>
			</div>
		</div>

		<div className="nft-mbg">
			<div className="nft-mbg-dis">
				<div className="nft-mbg-img-other"></div>
			</div>
		</div>

		<div className="nft-mbg3">
			<div className="nft-bot">
				<div className="nft-bot-imp">Impactopia</div>
				<div className="nft-bot-line"></div>
				<div className="nft-bot-img-tg" onClick={Common.toTwitter}></div>
				<div className="nft-bot-img-discode" onClick={Common.toDiscord}></div>
				<div className="nft-bot-img-mail"></div>
			</div>
		</div>

		<Modal closable={false} width={"100%"} style={{ backgroundColor: "transparent" }} bodyStyle={{ backgroundColor: "transparent", width: "100%" }} footer={null} onOk={handleOk} onCancel={handleCancel} open={isModalOpen}>
			{/* {!translate ? <img style={{ width: "300px", height: "350px" }} src={require("../../assets/impactopia/" + showModalImge + ".png")}   ></img>
				:
				<img style={{ width: "300px", height: "350px" }} src={require("../../assets/impactopia/yh1.gif")}   ></img>
			} */}
			<div className="flex-center"  >
			<img style={{ width: "350px", height: "300px" }} src={require("../../assets/impactopia/" + showModalImge + ".gif")}   ></img>
			</div>
			
			<div className="flex-center"  >
				<img style={{ width: "50px", height: "50px" }} onClick={handleCancel} src={require("../../assets/impactopia/cut.png")} alt="" />
			</div>
		</Modal>

		<Popup className="nav-Popup" visible={openRequirement} onClick={() => { setOpenRequirement(false) }} onMaskClick={() => { setOpenRequirement(false) }} position='left' bodyStyle={{ width: '82vw' }} >
				<div  className="Popup_content" >
					<div className="Popup_nft-text-legend" >
						<span className="Popup_nft-text-legend-one">{openData.title}</span>
						<span className="Popup_nft-text-legend-four">{openData.moeny}</span>
						<span className="Popup_nft-text-legend-three" onClick={() => {
							open('0x633b7218644b83d57d90e7299039ebab19698e9c');
						}}  >Ukraine Dao</span>

						<span className="Popup_nft-text-legend-two">  or  </span>
						<span className="Popup_nft-text-legend-three" onClick={() => {
							open('0x165cd37b4c644c2921454429e7f9358d18a45e14');
						}} >Ukraine Crypto Donation </span>
						<span className="Popup_nft-text-legend-four">on Ethereum network</span>

						<div className="nft-mbg3">
							<div className="Popup_nft-mint-bt" onClick={() => mint(openData.id, openData.img,openData.gif)}   >
								<div className="Popup_nft-mint-bt-img"></div>
								<div className="nft-mint-bt-text">Mint on Polygon→</div>
							</div>
						</div>
					</div>
				</div>
				

		</Popup>

	</div>

}


