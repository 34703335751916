import { useEffect, useState } from "react";
import { Input,Dropdown,message} from 'antd';

import "./My_post.css";
import { Link,useNavigate } from 'react-router-dom'
import type { MenuProps } from 'antd';
import {httpGet,httpPost} from '../../utils/httpUtils';
import { Formatter } from './Formatter';
import {Common} from "../../app/Common";


export function My_post() {
	useEffect(()=>{
		getMyProjectList();
	},[]);
	const navigate = useNavigate();
	const [myProjectList,setMyProjectList] =  useState([]as any);
	function getMyProjectList(){
		httpGet("/project/getMyProjectList").then((e:any)=>{
			if(e.data.code==0){
				setMyProjectList(e.data.data);
			}
		})
	}
	function projectDown(project:any){
		httpGet("/project/offline/"+project.id).then((e:any)=>{
			if(e.data.code==0){
				message.success("project offline sucess ! ");
				getMyProjectList();
			}
		})
	}


	function getMinStep(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=6.25;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="donate_step_bule" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}

	
	function getMinStepM(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=42.6667;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="mdonate_step_bule" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}

	function getBut(item:any){
		let frist="my_post_status_";
		let but_name="";
		let className="";
		if(item.status == -1 ){
			but_name="Rejected"
			className=frist+"Rejected";
		}

		if(item.status == 0 ||  item.status ==1 ){
			className=frist+"Reviewing";
			but_name="Reviewing"
		}

		if(item.status ==2){
			className=frist+"Passed";
			but_name="Passed"
		}

		if(item.status ==3){
			className=frist+"Finished";
			but_name="Finished"
		}
		
		if(item.status ==4){
			className=frist+"Removed";
			but_name="Removed"
		}

		return <div  className={className}  >
			{but_name}
		</div>
	}


	function getButM(item:any){
		let frist="mmy_post_status_";
		let but_name="";
		let className="";
		if(item.status == -1 ){
			but_name="Rejected"
			className=frist+"Rejected";
		}

		if(item.status == 0 ||  item.status ==1 ){
			className=frist+"Reviewing";
			but_name="Reviewing"
		}

		if(item.status ==2){
			className=frist+"Passed";
			but_name="Passed"
		}

		if(item.status ==3){
			className=frist+"Finished";
			but_name="Finished"
		}
		
		if(item.status ==4){
			className=frist+"Removed";
			but_name="Removed"
		}

		return <div  className={className}  >
			{but_name}
		</div>
	}

	let Web= <div className="my_post_body person_my_post" >
		  <div className="person_nft_badge_name">My Posts</div>
		  {myProjectList.length > 0 ?
			myProjectList.map((item:any,idx:any)=>{
				return 	<div key={idx} className="my_post_item" >
					<Link to={"/Project?projectId="+item.id}  >
						<div className="my_post_item_1">
							<div className="my_post_item_1_name" >{item.name}</div>
							<div className="my_post_item_1_date" >
							<Formatter dateTime={item.createDateTime} ></Formatter>
							</div>
							{getBut(item)}
						</div>
					</Link>
					<div  className="my_post_item_end"  >
						{item.status == -1 ?<div className="my_post_item_end_1">REASON: <span className="my_post_item_end_text" >{item.reasonRejection}</span></div>:""}
						{item.status != -1 ?
						<div  style={{display:"flex",alignItems:"center"}} >
							 <div  className="flex_width100 donate_list_item_cld_num " style={{width:"35.4688vw",borderRight:"1px solid rgba(245, 245, 245, 1)"}}  >
								<div  className="donate_num"  >{item.donateNum?item.donateNum:0}{item.receivingToken} / {item.fundRaisingObjectives}{item.receivingToken}</div>
								<div className="donate_step" >{getMinStep(item.fundRaisingObjectives,item.donateNum?item.donateNum:0)}</div>
						    </div>
							<div  className="project_remove" >
								remove
							</div>
						</div>
							
					:""}
				</div>
			</div>
			}):
			<div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="no_list" >You don’t have any post yet!</div></div>
		  }
	</div>
	let Mobile=<div className="mmy_post_body" >
		{myProjectList.length > 0 ?
	  myProjectList.map((item:any,idx:any)=>{
		  return 	<div key={idx} className="mmy_post_item" >
				 <div className="mmy_post_item_1_name"  onClick={()=>{
					navigate("/Project?projectId="+item.id)
				 }} >{item.name}</div>
				<div style={{marginTop:"2.6667vw"}} >
					{getButM(item)}
				</div>
				  {item.status == -1 ?<div className="mmy_post_item_REASON">REASON: <span className="mmy_post_item_end_text" >{item.reasonRejection}</span></div>:""}
				  {item.status != -1 ?
					<div  className="mdonate_num_detail "  >
						<div  className="mdonate_num"  >{item.donateNum?item.donateNum:0}{item.receivingToken} / {item.fundRaisingObjectives}{item.receivingToken}</div>
						<div className="mdonate_step" >{getMinStepM(item.fundRaisingObjectives,item.donateNum?item.donateNum:0)}</div>
					</div>
			  	:""}
				<div className="mmy_post_but" >
					<Formatter dateTime={item.createDateTime} ></Formatter>
					<img src={require("../../assets/mobile/Delist.png")} alt=""   className="mmy_post_delist" /> 
					<img src={require("../../assets/mobile/left.png")} alt=""   className="mmy_post_left_but" /> 
				</div>
	  
	  </div>
	  }):
	  <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You don’t have any post yet!</div></div>
	}
</div>



return Common.isMobile ? Mobile : Web 

}
