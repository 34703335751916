import {BasePage, page} from "./BasePage";
import { Input, } from 'antd';
import { CPerson_show} from '../components/CPerson_show';
import { Navigation } from "../components/Navigation";


import "./Person.css"
@page("Person_show")
export class Person_show extends BasePage {
	
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
			<CPerson_show></CPerson_show>
		</div>
	}

	protected mobileContent(): JSX.Element {
		return <div style={{backgroundColor:'#f6f9f8'}}>
			<CPerson_show></CPerson_show>
		</div>
	}
}
