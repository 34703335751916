import { BasePage, page } from "./BasePage";

@page("RoadMap")
export class RoadMap extends BasePage {

 
  protected webContent() {
    return <div className="road-map-content" >

    <div className="flex-width" >
      <div  className="title-bg"  >
        <div  className="title-text" >Roadmap</div>   
      </div>
      </div>

      <div className="road-flex" >
          <div className="road-step" >
              <div className="step_chidren" >
                   <div className="step-line-bg" ></div>

              </div>
          </div>
      </div>

    </div>
  }

  protected mobileContent(): JSX.Element {
    return <div>
      
    </div>
  }
}
