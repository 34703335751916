
import { Discover } from "./Discover";
import { Weare } from "./Weare";
import { RoadMap } from "./RoadMap";
import { BasePage, page, playVideo } from "./BasePage";
import { Footer } from "../components/Footer";
import { Cookie } from "../components/cookie";
import { Common } from '../../app/Common'
import { Navigation } from "../components/Navigation";
import {
  Outlet 
} from "react-router-dom"
@page('FirstPage')
export  class FirstPage extends BasePage {
  protected webContent() {
    return <div  className="first_page" onClick={Common.closeNavigationTab}>
      <Navigation></Navigation>
      <div id="pageId"  >
        <Outlet></Outlet>
      </div>
      <Footer />
      <Cookie></Cookie>
    </div>
  }

  protected mobileContent(): JSX.Element {
    return  <div onClick={Common.closeNavigationTab}>
        <Navigation></Navigation>
        <div style={{height:"93vh",overflow:"auto",paddingTop:"0vh"}} >
           <Outlet></Outlet>
       </div>
       <Cookie></Cookie>
      {/* <Footer /> */}
    </div>
  }
}