import { useEffect, useState } from "react";
import { Input,message,Select,Carousel,SelectProps } from 'antd';
import { Link, useNavigate  } from 'react-router-dom'
import {httpGet,httpPost} from '../../utils/httpUtils';

export function CDonate_view() {

	  function getMinStep(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=6.25;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="donate_step_bule" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
		}
	const options = [
		{name: 'Swedish', value: 'sv'},
		{name: 'English', value: 'en'},
		{
			type: 'group',
			name: 'Group name',
			items: [
				{name: 'Spanish', value: 'es'},
			]
		},
	];
	const [searchData,setSearchData] =  useState({} as any);
	const [plateList,setPlateList] =  useState([] as Array<any>);

	useEffect(()=>{
	
	},[]) 

	return  <div className="donateViewContent">

		<div  >
			<div>

			</div>
			<div>

			</div>
			<div>
				
			</div>
		</div>

		

		<div className="donate_view_list_box"  >
			<div  className="donate_view_list" >
				<div className="donate_view_list_item"  style={{marginLeft:0}}  >
					<div className="donate_view_list_item_cld" >
							<img src="" alt=""  className="donate_view_list_item_cld_img" />
							<div className="donate_view_list_item_cld_name" >Reading time that the modern people spend .</div>
							<div  className="flex_width100 donate_view_list_item_cld_num " >
								<div  >20Eth / 100Eth</div>
								<div className="donate_view_step" >{getMinStep(100,20)}</div>
							</div>
						</div>
				</div>
			</div>
		</div>

	</div>
}
