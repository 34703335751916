import { useEffect, useState } from "react";
import "./SendForum.css";
import { Input,message,Select,Upload ,SelectProps} from 'antd';
import { Popup,NavBar } from 'antd-mobile'
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { Link, useNavigate,useLocation  } from 'react-router-dom'
import { Quill_Input } from './Quill_Input';
import { Common } from "../../app/Common";
import { LeftOutline } from 'antd-mobile-icons'
import { url } from 'inspector';
import { State, useAppDispatch, useAppSelector } from "../../app/Store";


type IProps = {
    handleClick: () => void,
	superiorId?:any,
	forumInfo?:any,
	showOpen?:any
}

export function SendForum(props:IProps) {
	const { tagsList} = useAppSelector((state: State) => state.tags)
	const navigate = useNavigate();
	interface ForumInfo{
		title:any,
		content:any,
		imgUrl:any,
		tages:any,
		superiorId:any
		url:any,
		projectId:any
	   }
	const [forumInfo,setForumInfo] =  useState({} as ForumInfo);

	const { TextArea } = Input;
	const [fileList, setFileList] = useState([] as Array<any>);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [show, setShow] = useState(false);
	const handleChange = (value: string | string[]) => {
		forumInfo.tages=value;
	  };
	  const [projectList,setProjctList] =  useState([]as Array<any>);
	  function getProjctList(){
		httpPost("/project/getPageList",{}).then((e:any)=>{
			let list=[];
			if(e.data.code==0){
				for (let index = 0; index < e.data.data.list.length; index++) {
					const item = e.data.data.list[index];
					list.push({value:item.id,label:item.name});
				}
				setProjctList([...list]);
			}
		})
	}


	useEffect(()=>{
			if(props.showOpen){
				setIsModalOpen(true)
				memberCheck();
			
			}
			if(props.forumInfo){
				let cpforumInfo={...props.forumInfo};
				if(cpforumInfo.tages){
					cpforumInfo.tages=cpforumInfo.tages.split(",");
				}
				if(cpforumInfo.imgArr){
					let fileList=[];
					for (let index = 0; index < cpforumInfo.imgArr.length; index++) {
						const url = cpforumInfo.imgArr[index];
						fileList.push({
							uid: index,
							name: 'image.png',
							status: 'done',
							url: url
						  })
					}
					setFileList([...fileList])
					cpforumInfo.imgUrl=JSON.parse(cpforumInfo.imgUrl);
				}
				setIsModalOpen(true)
				setForumInfo({...cpforumInfo})
			}
			getProjctList();
	},[props]);


	function onChange(info:any){
		setFileList([...info.fileList]);
		 if (info.file.status === 'done' || info.file.status === 'removed' ) {
			forumInfo.imgUrl=[]
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				forumInfo.imgUrl.push({name:item.name,url:item.response?item.response.url:item.url});
			}
			console.log(forumInfo.imgUrl)
			setForumInfo({...forumInfo})
		  }
	}



	function onRemove(info:any){
	// 	if(info.status == '"removed"' ||info.status === 'done'){
	// 		forumInfo.imgUrl=forumInfo.imgUrl.filter((element:any)=>{
	// 			return element.url != info.url;
	// 		})
	// 		console.log(forumInfo.imgUrl);
	// 	}
	//   setForumInfo({...forumInfo})
	}



	function postSend(){
		
	  let 	cyforumInfo = {...forumInfo};
	  if(cyforumInfo.tages&& cyforumInfo.tages.length>0){
		cyforumInfo.tages=cyforumInfo.tages.toString();
	  }
	   cyforumInfo.imgUrl=JSON.stringify(cyforumInfo.imgUrl);
	   cyforumInfo.superiorId=props.superiorId;
		httpPost("/forum/addForum",{...cyforumInfo}).then((e:any)=>{
			if(e.data.code == 0){
				message.success("publish post successful");
				setForumInfo({}as ForumInfo);
				setFileList([]);

				setIsModalOpen(false);
				props.handleClick();
			}
		})
	}
	  async function onPreview  (file: UploadFile) {
		let src = file.url as string;
		if (!src) {
		  src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj as RcFile);
			reader.onload = () => resolve(reader.result as string);
		  });
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	  }

	  function  memberCheck(){
		setShow(true);
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 401 ){
				if(props.superiorId){
					localStorage.setItem("pageFrom","/#/Forumlist?forumId="+props.superiorId);
				}else{
					localStorage.setItem("pageFrom","/#/Discover_list");
				}
				navigate("/SignIn");
			}
		})
	}


	let Web= <div className="SendForum_body" >
			<div  className="SendForum_input" >
			<Input placeholder={show?'Put a title for your post':'Click here to make a post!'} 			
			     value={forumInfo.title}
				 onClick={memberCheck} 
				 onChange={(e)=>{
					forumInfo.title=e.target.value;
					setForumInfo({...forumInfo});
					
				}}
				bordered={false} style={{width:"40.1042vw",height: "2.3438vw"}} />
		</div>
		{show?<div   className="SendForum_content"  style={{width:"47.7917vw",height:"9.7292vw"}}  >
		   <Quill_Input  w={"46.7917vw"} value={forumInfo.content} handleClick={(e=>{
				forumInfo.content=e;
				setForumInfo ({...forumInfo});
		   })} ></Quill_Input>
		</div>:""}

		{show?<div   className="SendForum_img"   >
			<Upload
				action={host_url+"/api/app/oss/uploadImg"}
				listType="picture-card"
				fileList={fileList}
				onChange={onChange}
				onRemove={onRemove}
				onPreview={onPreview}
			>
				{fileList.length < 3 && '+ Upload'}
			</Upload>
			</div>
		:""}

		{show?	<div   className="SendForum_tags"  style={{marginTop:10}}  >
			<div>YouTube URL</div>
			<Input
			placeholder="Please YouTube URL"
			onChange={(e) => {
				forumInfo.url = e.target.value;
				setForumInfo({ ...forumInfo });
			}}
			value={forumInfo.url}
			style={{ width: '300px',marginLeft:"20px" }}
		/>
		</div>
		:""}
		
		{show?	<div   className="SendForum_tags" style={{marginTop:10}}   >
			<div>Tags</div>
			<Select
			mode="tags"
			placeholder="Please select tags"
			onChange={handleChange}
			bordered={false}
			value={forumInfo.tages}
			style={{ width: '300px',marginLeft:"20px" }}
			options={tagsList}
		/>
		</div>
		:""}


		{show?	<div   className="SendForum_tags" style={{marginTop:10}}   >
					<div>Project</div>
					<Select
					placeholder="Please select  donate  project"
					onChange={(e)=>{
						forumInfo.projectId=e;
						setForumInfo({...forumInfo});
					}}
					value={forumInfo.projectId}
					style={{ width: '300px',marginLeft:"20px" }}
					allowClear={true}
					options={projectList}
				/>
				</div>
		:""}

		   <div style={{display:"flex"}} >

		   {show?	<div   className="SendForum_submit but-shadow"   >
			<div className="SendForum_post "  onClick={postSend} >Post</div>
		</div>
		:""}
		

		{show?	<div   className="SendForum_submit but-shadow" style={{marginLeft:"10px",backgroundColor:"#ffffff",color:"#000000",border:"1px solid #000000"}}   >
			<div className="SendForum_post "  onClick={()=>{setShow(false)}} >Close</div>
		</div>
		:""}

		   </div>
	</div>


let Mobile=<div>
			<Popup  bodyClassName="mproject_pendForum_popup"  style={{height:""}}  visible={isModalOpen} position='bottom' onMaskClick={() => {setIsModalOpen(false)}} >
				<NavBar className="nav-bar" right={<div></div>}  backArrow={<LeftOutline />} onBack={() => {
					setIsModalOpen(false)
					props.handleClick()
				}}>
					Forum
			 	</NavBar>	
				
		<div className="mpmint_box" onClick={memberCheck} style={{paddingLeft:"3.3vw",paddingRight:"3.3vw"}} >
			<div  className="mpmint_input" >
				<Input placeholder="Put a title for your post" 			
			     value={forumInfo.title}
				 onChange={(e)=>{
					forumInfo.title=e.target.value;
					setForumInfo({...forumInfo});
					setShow(true);
				}}
				bordered={false}
				style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }} 
				/>
			</div>

		<div   className="SendForum_content"  style={{width:"100%",height:"78.5333vw"}}  >
		   <Quill_Input  w={"100%"} h="66.5333vw" value={forumInfo.content} handleClick={(e=>{
				forumInfo.content=e;
				setForumInfo ({...forumInfo});
		   })} ></Quill_Input>
		</div>

		<div   className="SendForum_img"   >
			<Upload
				action={host_url+"/api/app/oss/uploadImg"}
				listType="picture-card"
				fileList={fileList}
				onChange={onChange}
				onPreview={onPreview}
			>
				{fileList.length < 3 && '+ Upload'}
			</Upload>
		</div>


		<div   className="mpmint_flex"   >
			<div className="mpmint_lable" >YouTube URL</div>
			<Input
			placeholder="Please YouTube URL"
			onChange={(e) => {
				forumInfo.url = e.target.value;
				setForumInfo({ ...forumInfo });
			}}
			value={forumInfo.url}
			style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
		/>
		</div>

		<div   className="mpmint_flex"   >
			<div className="mpmint_lable"  >Tages</div>
			<Select
			mode="tags"
			placeholder="Please select tags"
			onChange={handleChange}
			bordered={false}
			value={forumInfo.tages}
			style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
			options={tagsList}
		/>
		</div>
		<div   className="mpmint_flex"   >
			<div className="mpmint_lable"  >Project</div>
			<Select
			placeholder="Please select  donate  project"
			onChange={(e)=>{
				forumInfo.projectId=e;
				setForumInfo({...forumInfo});
			}}
			bordered={false}
			value={forumInfo.projectId}
			style={{ width: '300px',marginLeft:"20px" }}
			allowClear={true}
			options={projectList}
		/>
		</div>
		 	<div   className="mpmint_pint"  style={{ marginTop: 20 ,marginBottom:40}}   >
				<div className="mpmint_pint"  onClick={postSend} >Post</div>
			</div>
		
	</div>

			</Popup>
		<div  className="mpost_forum_but"  >
			<img src={require("../../assets/mobile/forum_post.png")}  onClick={()=>{
				setIsModalOpen(true)
				memberCheck();
			}}  className="mpost_forum_but_img" alt="" />
		</div>

</div>


return Common.isMobile ? Mobile : Web 




}
