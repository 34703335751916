import {BasePage, page} from "./BasePage";
import "../css/common.css"
import "./SignUp.css"
import { CForgotPassword} from '../components/CForgotPassword';
@page("SignUp")
export class ForgotPassword extends BasePage {
	protected webContent() {
		return <CForgotPassword></CForgotPassword>
	}
	protected mobileContent(): JSX.Element {
		return <CForgotPassword></CForgotPassword>
	}
}
