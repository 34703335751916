import {BasePage, page} from "./BasePage";
import "./BadgeList.css";
import { CBadgeList} from '../components/CBadgeList';
import { Navigation } from "../components/Navigation";
@page("BadgeList")
export class BadgeList extends BasePage {
	protected webContent() {
		return <div   style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
		<CBadgeList></CBadgeList>
	</div>
	}

	protected mobileContent(): JSX.Element {
		return <div>
			<CBadgeList></CBadgeList>
		</div>
	}
}
