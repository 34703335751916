import {BasePage, page} from "./BasePage";
import "./Publishmint.css";
import { CPublishLive} from '../components/CPublishLive';
import { Navigation } from "../components/Navigation";

@page("PublishLive")
export class PublishLive extends BasePage {
	protected webContent() {

		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
		<CPublishLive></CPublishLive>
	</div>
	}

	protected mobileContent(): JSX.Element {
		return <CPublishLive></CPublishLive>
	}
}
