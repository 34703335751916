import { BasePage, page, playVideo } from "./BasePage";
import { Common } from "../../app/Common";
import { Link } from 'react-router-dom'
import {Create_project_but} from '../components/Create_project_but';
import { Discover } from "./Discover";
import { Weare } from "./Weare";
import { RoadMap } from "./RoadMap";
import { Carousel } from 'antd';
import { Action } from '@reduxjs/toolkit';
import { CHome } from "../components/CHome";
import { HomeImg } from "../components/HomeImg";



@page('HomePage')
export class HomePage extends BasePage {


  protected webContent() {

    function ScrollToData(id:string){
      let getEle = document.getElementById('pageView');
      if(getEle){
        getEle.scrollTo({
          top: document.getElementById(id)?.offsetTop,
          behavior: "smooth"
        });
      }
   }

    return <div  id='pageView'  style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}} >
        <CHome></CHome>
        <div  className="ctop_box" >
          <div style={{width:"100%",display:"flex"}} >
          <div  className="ctop_box_left" >
              <div  className="ctop_box_left_platform" >
                 The Global Impact  Platform 
              </div>
              <div className="ctop_box_left_make" >
                  Make a greater impact with Impactopia by sharing insights, connect with others and take actions together.
              </div>
          </div>

          <div className="ctop_box_right" >
             <HomeImg></HomeImg>
          </div>

          </div>
          


          <div className="ctop_box_bottom" >
              <div  className="ctop_box_item" onClick={()=>ScrollToData('diSHomeBox')}>
                  <img src={require("../../assets/mobile/top1.png")}  className="ctop_box_img" alt="" />
                  <div className="ctop_box_item_name"  >
                    For Impact advocates
                  </div>
                  <img src={require("../../assets/mobile/rt.png")} alt=""  className="ctop_box_img_rt"  />
              </div>
              <div  className="ctop_box_item ctop_box_item_x"   onClick={()=>ScrollToData('MProject')}>
                  <img src={require("../../assets/mobile/top2.png")}  className="ctop_box_img" alt="" />
                  <div className="ctop_box_item_name"  >
                  For Donors
                  </div>
                  <img src={require("../../assets/mobile/rt.png")} alt=""  className="ctop_box_img_rt"  />
              </div>
              <div  className="ctop_box_item" onClick={()=>ScrollToData('MMChannel')}>
                  <img src={require("../../assets/mobile/top3.png")}  className="ctop_box_img" alt="" />
                  <div className="ctop_box_item_name"  >
                  For Organizations
                  </div>
                  <img src={require("../../assets/mobile/rt.png")} alt=""  className="ctop_box_img_rt"  />
              </div>
          </div>
        </div>


        
        <div  className="dis_home_box" id='diSHomeBox' >
          <Link  to={"/Discover_list"} >
          <img src={require("../../assets/v2/dis_bg.png")} alt=""  className="dis_home_box_img"  />
          </Link>
        </div>
        <div id='MProject'>
        <Link  to={"/Donate"} >
         <div  className="project_home_box"  style={{display:"flex",justifyContent:"flex-start"}} >
            <img src={require("../../assets/v2/donate.png")} alt=""  className="project_home_box_img"  />
        </div>
        </Link>
        </div>
        <div id="MMChannel">
        <Link  to={"/Community"} >
          <div  className="channel_home_box"   >
            <img src={require("../../assets/v2/channel_bg.png")} alt=""  className="channel_home_box_img"  />
          </div>

        </Link>
        </div>
      </div>
  }

  protected mobileContent(): JSX.Element {

  
    function ScrollToData(id:string){
      let getEle = document.getElementById('pageView');
      if(getEle){
        getEle.scrollTo({
          top: document.getElementById(id)?.offsetTop,
          behavior: "smooth"
        });
      }
   }

    return <div  id='pageView' style={{height:"86vh",overflowY:"scroll"}}   >
         <CHome></CHome>
        <div  className="top_box" >
          <div   className="top_box_1" >
          Make a greater impact with Impactopia by sharing insights, connect with others and take actions together. 
          </div>
          <div style={{marginTop:"8.1333vw",width:"100%",flexWrap:"wrap",justifyContent:"center",display:"flex"}} >
            <div  className="top_box_item"  onClick={()=>ScrollToData('diSHomeBox')}>
                <img src={require("../../assets/mobile/top1.png")}  className="top_box_img" alt="" />
                <div className="top_box_item_name"  >
                  For Impact advocates
                </div>
                <img src={require("../../assets/mobile/rt.png")} alt=""  className="top_box_img_rt"  />
            </div>

            <div  className="top_box_item"  onClick={()=>ScrollToData('MProject')}>
                <img src={require("../../assets/mobile/top2.png")}  className="top_box_img" alt="" />
                <div className="top_box_item_name"  >
                For Donors
                </div>
                <img src={require("../../assets/mobile/rt.png")} alt=""  className="top_box_img_rt"  />
            </div>

            <div  className="top_box_item"  onClick={()=>ScrollToData('MMChannel')}>
                <img src={require("../../assets/mobile/top3.png")}  className="top_box_img" alt="" />
                <div className="top_box_item_name"  >
                For Organizations
                </div>
                <img src={require("../../assets/mobile/rt.png")} alt=""  className="top_box_img_rt"  />
            </div>
          </div>
        </div>
        <div id='diSHomeBox'  >
            <div  style={{display:"flex",width:"100%",marginTop:30,justifyContent:"center"}} >
              <img src={require("../../assets/mobile/m_dis_top.png")} alt=""  className="m_dis_top"  />
            </div>
            <div  style={{display:"flex",width:"100%",marginTop:40,justifyContent:"center"}}  >
            <Link  to={"/Discover_list"} >
            <img src={require("../../assets/mobile/m_dis_content.png")} alt=""  className="m_dis_content"  />
            </Link>
            </div>
        </div>
        <div id='MProject'>
            <Link  to={"/Donate"} >
            <div  style={{display:"flex",width:"100%",marginTop:10,justifyContent:"center"}}  >
             <img src={require("../../assets/mobile/project_content.png")} alt=""  className="project_content"  />
            </div>
            </Link>
        </div>
        <div id="MMChannel">
           <Link  to={"/Community"} >
            <div  style={{display:"flex",width:"100%",marginTop:10,justifyContent:"center"}}  >
             <img src={require("../../assets/mobile/channel_content.png")} alt=""  className="channel_content"  />
            </div>
            </Link>
        </div>
    </div>
  }
}