import { useEffect, useState } from "react";
import { Input,Dropdown,message} from 'antd';
import {host_url} from '../../utils/httpUtils';
import { Common } from "../../app/Common";
import "./Share.css";
import { type } from 'os';
import { url } from 'inspector';
import copy from 'copy-to-clipboard';
import { web3 } from '../../app/Config';

export function Share(param:any) {
	interface shareinfo{
		title:any,
		img:any,
		url:any,
		type?:any
	}

	const [shareinfo,setShareinfo] =  useState({}as shareinfo);
	if(param.type=='project'){
		shareinfo.title=param.sharedata.name;
		shareinfo.url=host_url+"/#/Project?projectId="+param.sharedata.id;
	}else if(param.sharedata.type =='live'){
		shareinfo.title=param.sharedata.title;;
		shareinfo.url=param.sharedata.url;
	}else {
		shareinfo.title=param.sharedata.title;
		shareinfo.url=host_url+"/#/Forumlist?forumId="+param.sharedata.id;
	}


	function tw() {
		let metaArr = [
			'twitter:title', shareinfo.title,
			'twitter:card', 'summary_large_image',
			'twitter:url', shareinfo.url,
		]
		let metaParams = metaArr.toString()
		metaParams = encodeURIComponent(encodeURIComponent(metaParams))
		window.open(`https://twitter.com/share?text=check out this post on Impactopia : ${shareinfo.title}&url=${host_url}/api/app/share/new?meta=${metaParams}`)
	}

	function cp(){
		copy("check out this organization on Impactopia: "+ shareinfo.title+" "+shareinfo.url);
		message.success("copy is success")
	}
	const items = [
		{
		  key: '1',
		  label: (
				<div className="share_menu"  onClick={tw} >
					<img src={require("../../assets/v2/tw.png")}  className="share_menu_img" alt="" />
					<div className="share_menu_name">Share on Twitter</div>
				</div>
		  ),
		},
		{
			key: '3',
			label: (
				  <div  onClick={cp} className="user_menu" >
					  <img src={require("../../assets/v2/link.png")}  className="share_menu_img" alt="" />
					  <div className="share_menu_name">Copy Network link</div>
				  </div>
			),
		  },
	
	  ];


	  const itemsMobile = [
		{
		  key: '1',
		  label: (
				<div className="mshare_menu"  onClick={tw} >
					<img src={require("../../assets/v2/tw.png")}  className="mshare_menu_img" alt="" />
					<div className="mshare_menu_name">Share on Twitter</div>
				</div>
		  ),
		},
		{
			key: '3',
			label: (
				  <div  onClick={cp} className="mshare_menu" >
					  <img src={require("../../assets/v2/link.png")}  className="mshare_menu_img" alt="" />
					  <div className="mshare_menu_name">Copy Network link</div>
				  </div>
			),
		  },
	
	  ];


	let  Web=	<Dropdown
	
	menu={{
	items,
	}}
	placement="bottomLeft"
	arrow
	>
	<img src={require("../../assets/v2/sh_one.png")}  className="share_img"  alt="" />	
	</Dropdown>



	let Mobile=<Dropdown
	className="mshare_dropdown"
	menu={{
		items:itemsMobile,
	}}
	placement="bottomRight"
	arrow
	>
	<img src={require("../../assets/v2/sh_one.png")}  className="mshare_menu_img"  alt="" />	
	</Dropdown>


return Common.isMobile ? Mobile : Web 
	
	
	

}
