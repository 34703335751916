import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import './ui/css/index.css';
import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css"; 
import { Navigation } from "./ui/components/Navigation";
import { Provider } from "react-redux";
import { Store } from "./app/Store";
import { Tow } from "./ui/pages/Tow";
import { FirstPage } from './ui/pages/First';
import { SignIn } from './ui/pages/SignIn';
import { SignUp } from './ui/pages/SignUp';
import { Person } from './ui/pages/Person';
import { Discover_list } from './ui/pages/Discover_list';
import { Publishmint } from './ui/pages/Publishmint';
import { Project } from './ui/pages/Project';
import { Forum_list } from './ui/pages/Forum_list';
import { News } from './ui/pages/News';
import { ProjectList } from './ui/pages/ProjectList';
import { Donate } from './ui/pages/Donate';
import { Donate_view } from './ui/pages/Donate_view';
import { Person_show } from './ui/pages/Person_show';
import { HomePage } from './ui/pages/Home';
import { ForgotPassword } from './ui/pages/ForgotPassword';
import { ChLive } from './ui/pages/ChLive';
import { Community } from './ui/pages/Community';
import { Channel } from './ui/pages/Channel';
import { PublishChannel } from './ui/pages/PublishChannel';
import { PublishLive } from './ui/pages/PublishLive';
import { BadgeList } from './ui/pages/BadgeList';
import { BadgeDetail } from './ui/pages/BadgeDetail';
import { Chat } from './ui/pages/Chat';
import { SendForum } from './ui/components/SendForum';
import { DonateUs } from './ui/pages/DonateUs';
import { ChannelPage } from './ui/pages/ChannelPage';
import { LivePage } from './ui/pages/LivePage';

import 'antd/dist/antd.css';
// Import Swiper styles
import 'swiper/css';

import {
  HashRouter as Router,    //HashRouter哈希路由 as起别名 router路由
  Route,
  Routes,
} from "react-router-dom"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Provider store={Store}>
        <Routes>
          <Route path='/' element={<FirstPage />  } >
            <Route path='/' element={<Discover_list />}></Route>
            <Route path='/Discover_list' element={<Discover_list />}></Route>
            <Route path='/AboutUS' element={<HomePage />}></Route>
            <Route path='/Nft' element={<Tow />}></Route>
            <Route path='/BadgeList' element={<BadgeList />}></Route>
            <Route path='/BadgeDetail' element={<BadgeDetail />}></Route>
            <Route path='/Person' element={<Person />}></Route>
            <Route path='/Publishmint' element={<Publishmint />}></Route>
            <Route path='/Project' element={<Project />}></Route>
            <Route path='/Forumlist' element={<Forum_list />}></Route>
            <Route path='/News' element={<News />}></Route>
            <Route path='/ProjectList' element={<ProjectList />}></Route>
            <Route path='/Donate' element={<Donate />}></Route>
            <Route path='/DonateUs' element={<DonateUs />}></Route>
            <Route path='/Donate_view' element={<Donate_view />}></Route>
            <Route path='/Person_show' element={<Person_show />}></Route>
            <Route path='/ChLive' element={<ChLive />}></Route>
            <Route path='/Community' element={<Community />}></Route>
            <Route path='/Channel' element={<Channel />}></Route>
            <Route path='/PublishChannel' element={<PublishChannel />}></Route>
            <Route path='/PublishLive' element={<PublishLive />}></Route>
            <Route path='/PublishLive' element={<PublishLive />}></Route>
            <Route path='/Chat' element={<Chat />}></Route>
            <Route path='/ChannelPage' element={<ChannelPage />}></Route>
            <Route path='/LivePage' element={<LivePage />}></Route>
          </Route>
          <Route path='/SignIn' element={<SignIn />}></Route>
          <Route path='/SignUp' element={<SignUp />}></Route>
          <Route path='/ForgotPassword' element={<ForgotPassword/>}></Route>
        </Routes>
      </Provider>
    </Router>
  </React.StrictMode>
)
