import { useEffect, useState } from "react";
import { Input,message} from 'antd';
import { Link, useNavigate  } from 'react-router-dom'
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';

type IProps = {
    handleClick: () => void,
	projectId?:any
}


export function CSignIn_min(props:IProps) {
	  const navigate = useNavigate();
     interface MemberInfo{
		userName:any,
		password:any,
		email:any
	 }
	 const [member,setMamber] =  useState({}as MemberInfo );
	 async function signIn(){
		localStorage.removeItem("token");
		await httpPost('/member/login',{...member}).then(
			(e:any) => {
			  if(e.data.code == 0){
				localStorage.setItem("token",e.data.token);
				localStorage.setItem("member",JSON.stringify(e.data.member));
				props.handleClick();
			  }else{
				message.error("login failed");
			  }
			}
		)
	 }

	 function  render (){
		httpPost('/twitter/render',{}).then(
			( e:any) => {
			   window.open(e.data.data);
			 }
		 )
	}

	function  renderGoogle(){
		httpPost('/oauth/render',{}).then(
			( e:any) => {
			   window.open(e.data.data);
			 }
		 )
	}
	
	return 	<div className="min_sign_right" >
	<div className="min_sign_or" >
		<img  onClick={renderGoogle}  src={require("../../assets/v2/g@2x.png")} style={{width:'3.5417vw',height:'3.5417vw',marginLeft:"0.825vw"}}  alt="" />
		{/* <div className="min_sign_ds">
			<img  onClick={render} src={require("../../assets/v2/t@2.png")} style={{width:'3.5417vw',height:'3.5417vw',marginLeft:"3.125vw"}}  alt="" />
		</div> */}
		{/* <div className="min_sign_ds">
			<img src={require("../../assets/v2/l@2x.png")} style={{width:'68px',height:'68px',marginLeft:"3.125vw"}}  alt="" />
		</div> */}
	</div>

	 <div className="min_sign_or">
		-OR-
	</div>

	<div className="min_sign_full_name"   style={{marginBottom:"3.125vw"}}>
		<Input  value={member.userName} onChange={(e)=>{
			 member.email=e.target.value;
			 setMamber({...member});
		}}
		 placeholder="Full Name or Email Adress"  bordered={false}  className="min_sign_input" style={{width:"100%"}} />
	</div>

	<div className="min_sign_full_name">
		<Input value={member.password} onChange={(e)=>{
			 member.password=e.target.value;
			 setMamber({...member});
		}}  placeholder="Password"  type="password"  bordered={false} style={{width:"100%"}} />
	</div>


	<Link  to={"/ForgotPassword"} >
	<div className="min_sign_to_login">
		 <span  className="min_sign_to_login_2" >Forgot password?</span>
	</div>
	</Link>
	<div className="min_sign_but"  onClick={signIn} >
		Sign IN
	</div>
	<div className="min_sign_to_login">
		Not have an account yet？ <Link  to={"/SignUp"} > <span  className="min_sign_to_login_1" >Sign Up</span></Link>
	</div>
</div>
}
