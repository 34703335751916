import { useEffect, useState } from "react";
import "./cookie.css";
import { Common } from "../../app/Common";
import { ExclamationCircleOutline } from 'antd-mobile-icons'
import { Modal,Switch,Table} from 'antd';
import { page } from '../pages/BasePage';

export function Cookie() {
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [cookieOpen, setCookieOpen] = useState(false); 
    const [cookieConf, setCookieConf] = useState({isSeting:false,isPush:true}); 
    const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};


    useEffect(()=>{
        let cookieConf=localStorage.getItem("cookie");
        if(cookieConf){
            setCookieConf(JSON.parse(cookieConf));
        }
    },[]) 


   
      
      const columns = [
        {
            title: 'Description',
            dataIndex: 'name',
            key: 'name',
          },
        {
          title: 'Category',
          dataIndex: 'address',
          key: 'address',
        },
       
      ];

      const data = [
        {
          key: '1',
          name: 'Necessary cookies (Security)',
          address: `These cookies enable us toprovide cloud cybersccurity and DDoS (attack) mitigation.These include Cloudflare cookies `,
        },
        {
          key: '2',
          name: 'Necessary cookies (Authentication)',
          address: `These cookies enable us to authenticate our users and keep them logged into their accounts. These include auth-refresh and auth-user.`,
        },
        {
            key: '3',
            name: 'Performance cookie (Analytics)',
            address: `These cookies allow us to monitor and improve our platform performance.
            These include Google Analytics cookies.`,
          },
      ];

	let Web=<div>
            {cookieConf.isSeting?  "":<div className="cookie">
                    <ExclamationCircleOutline style={{fontSize:22,marginRight:12}} />
                    <div className="cookie_text" >
                        We use cookies to improve your experience on our site. By using this website you agree to our <span className="policy" onClick={()=>{setCookieOpen(true)}}  >Cookie Policy.</span>
                    </div>
                    <div className="Button_button__MJ5pb buttonThemes_theme-tertiary__v7VoN"  onClick={()=>{
                        setIsModalOpen(true)
                    }} >
                        Manage Settings
                    </div>
                    <div  onClick={()=>{
                             cookieConf.isSeting=true;
                             cookieConf.isPush=true;
                             setCookieConf({...cookieConf});
                            localStorage.setItem("cookie",JSON.stringify(cookieConf))
                    }} className=" Button_button__MJ5pb buttonThemes_theme-primary__C0pSI" >
                        Accept
                    </div>
                 </div>}
        <Modal closable={false} width={"520px"} open={isModalOpen}  bodyStyle={{  borderRadius:"20px",backgroundColor: "transparent", }} footer={null} onOk={handleOk} onCancel={handleCancel}  >
            <div  style={{padding:"2.2rem",width:520}} >
                 <div className="ModalContent_titleRow__8rPYU" >
                    This site uses cookies.
                </div>

                <div >
                    Some of these cookies are essential, while other help us to improve your experience by providing insights into how the site is being used. By using this website you agree to our Cookie Policy.
                </div>

                <div style={{marginTop:20}} className="section" >
                    <div className="ModalContent_titleRow__8rPYU" >
                    Necessary Cookies
                    </div>
                    <div   > 
                          Necessary cookies enable core functionality. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.
                    </div>
                </div>

                <div className="section" style={{marginTop:20}} >
                    <div className="ModalContent_titleRow__8rPYU" >
                    Performance
                    </div>
                    <div >
                          <div>
                             We use these cookies to monitor and improve website performance.
                          </div>
                          <div style={{display:"flex",justifyContent:"flex-end"}} >
                            <Switch
                                defaultChecked
                                style={{
                                    'color': '#00b578',
                                }}
                                checked={cookieConf.isPush}
                                onChange={(e)=>{
                                    setCookieConf({isSeting:false,isPush:e})
                                }}
                                />
                          </div>
                         
                    </div>
                </div>

                <div  style={{display:"flex",justifyContent:"flex-end",marginTop:20}} >
                     <span  onClick={()=>{
                         setIsModalOpen(false);
                         cookieConf.isSeting=true;
                         localStorage.setItem("cookie",JSON.stringify(cookieConf))
                     }}  className="Button_button__MJ5pb buttonThemes_theme-primary__C0pSI" >
                           Save Preferences
                    </span>
                </div>
            </div>
		</Modal> 


        <Modal closable={false} width={"50%"} open={cookieOpen}  bodyStyle={{padding:"20px",backgroundColor: "transparent", }} footer={null}    onCancel={()=>{setCookieOpen(false)}}  >
        <div  style={{padding:"2.2rem"}} >
                 <div className="ModalContent_titleRow__8rPYU" >
                     Cookies are text files containing small amounts of information which are downloaded to your computer or mobile device when you visit Impactopia’s website and allow us to recognize your device.
                </div>
                <div className="ModalContent_titleRow__8rPYU" >
                    Impactopia uses cookies that are strictly necessary for the performance of our services (Necessary Cookies) and cookies that provides us analytics about the performance of our website (Performance Cookies).  You can decide whether you accept or not our use of Performance Cookies by changing the cookies settings. If you do not agree with Necessary Cookies being used, you should leave the website and stop using our services.
                </div>
                <div style={{marginTop:20}} className="section" >
                    <div className="ModalContent_titleRow__8rPYU" >
                      We use Cookies for the following purposes:
                    </div>
                    <div   > 
                        <ul>
                            <li>
                                Security (attack prevention)
                            </li>
                            <li>
                                Authenticated session liveness (for authenticated users)
                            </li>
                            <li>
                                Performance analytics review
                            </li>
                        </ul>
                    </div>
                </div>


                <div style={{marginTop:20}} className="section" >
                    <div className="ModalContent_titleRow__8rPYU" >
                        We also use cookies from third parties
                    </div>
                    <div   > 
                        <ul>
                            <li>
                                Cloudfare cookies provide us with cloud cybersecurity and DDoS mitigation services (security as defined above). Cloudfare uses programming code to collect information about your interaction with our websites. The cookie contains information related to the calculation of Cloudflare’s proprietary bot score and, when anomaly detection is enabled on bot management, a session identifier. The information in the cookie (other than time-related information) is encrypted and can only be decrypted by Cloudflare. This code is only active while you are on our websites and expires after 30 minutes of continuous inactivity by the end user.
                            </li>
                            <li>
                                Google Analytics cookies provide us analytics to enhance the experience of our visitors to our website and to better understand how the website is used. The storage period for Google Analytics is 14 months.
                            </li>
                        </ul>
                    </div>
                </div>
                

                <div style={{marginTop:20}} className="section" >
                    <div className="ModalContent_titleRow__8rPYU" >
                        We collect the following cookies:
                    </div>
                    <div   > 
                    <Table pagination={false} dataSource={data} columns={columns} />
                    </div>
                </div>
            </div>
        </Modal>          

	</div>

    let  Mobile=<div  >
                {cookieConf.isSeting?  "":<div className="cookie" style={{display:"block"}} >
                    <div className="cookie_text" >
                         <ExclamationCircleOutline style={{fontSize:22,marginRight:12}} />
                        We use cookies to improve your experience on our site. By using this website you agree to our <span className="policy"  onClick={()=>{setCookieOpen(true)}}   >Cookie Policy.</span>
                    </div>
                   <div style={{marginTop:20}} >
                    <div className="Button_button__MJ5pb buttonThemes_theme-tertiary__v7VoN"  onClick={()=>{
                            setIsModalOpen(true)
                        }} >
                            Manage Settings
                        </div>
                        <div  onClick={()=>{
                             cookieConf.isSeting=true;
                             cookieConf.isPush=true;
                             setCookieConf({...cookieConf});
                            localStorage.setItem("cookie",JSON.stringify(cookieConf))
                    }} className=" Button_button__MJ5pb buttonThemes_theme-primary__C0pSI" >
                            Accept
                        </div>
                   </div>
                 </div>}
        <Modal closable={false}  open={isModalOpen}  bodyStyle={{  borderRadius:"20px",backgroundColor: "transparent", }} footer={null} onOk={handleOk} onCancel={handleCancel}  >
            <div  style={{padding:"2.2rem",width:"100%"}} >
                 <div className="ModalContent_titleRow__8rPYU" >
                    This site uses cookies.
                </div>

                <div >
                    Some of these cookies are essential, while other help us to improve your experience by providing insights into how the site is being used. By using this website you agree to our Cookie Policy.
                </div>

                <div style={{marginTop:20}} className="section" >
                    <div className="ModalContent_titleRow__8rPYU" >
                    Necessary Cookies
                    </div>
                    <div   > 
                          Necessary cookies enable core functionality. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.
                    </div>
                </div>

                <div className="section" style={{marginTop:20}} >
                    <div className="ModalContent_titleRow__8rPYU" >
                    Performance
                    </div>
                    <div >
                          <div>
                             We use these cookies to monitor and improve website performance.
                          </div>
                          <div style={{display:"flex",justifyContent:"flex-end"}} >
                            <Switch
                                defaultChecked
                                style={{
                                    'color': '#00b578',
                                }}
                                checked={cookieConf.isPush}
                                onChange={(e)=>{
                                    setCookieConf({isSeting:false,isPush:e})
                                }}
                                />
                          </div>
                         
                    </div>
                </div>

                <div  style={{display:"flex",justifyContent:"flex-end",marginTop:20}} >
                     <span  onClick={()=>{
                         setIsModalOpen(false);
                         cookieConf.isSeting=true;
                         localStorage.setItem("cookie",JSON.stringify(cookieConf))
                     }}  className="Button_button__MJ5pb buttonThemes_theme-primary__C0pSI" >
                           Save Preferences
                    </span>
                </div>
            </div>
		</Modal> 
        
        <Modal closable={true} width={"96%"} open={cookieOpen}  bodyStyle={{padding:"20px",backgroundColor: "transparent", }} footer={null}    onCancel={()=>{setCookieOpen(false)}}  >
        <div  style={{padding:"1.2rem"}} >
                 <div  >
                     Cookies are text files containing small amounts of information which are downloaded to your computer or mobile device when you visit Impactopia’s website and allow us to recognize your device.
                </div>
                <div  >
                    Impactopia uses cookies that are strictly necessary for the performance of our services (Necessary Cookies) and cookies that provides us analytics about the performance of our website (Performance Cookies).  You can decide whether you accept or not our use of Performance Cookies by changing the cookies settings. If you do not agree with Necessary Cookies being used, you should leave the website and stop using our services.
                </div>
                <div style={{marginTop:20}} className="section" >
                    <div className="ModalContent_titleRow__8rPYU" >
                      We use Cookies for the following purposes:
                    </div>
                    <div   > 
                        <ul>
                            <li>
                                Security (attack prevention)
                            </li>
                            <li>
                                Authenticated session liveness (for authenticated users)
                            </li>
                            <li>
                                Performance analytics review
                            </li>
                        </ul>
                    </div>
                </div>


                <div style={{marginTop:20}} className="section" >
                    <div className="ModalContent_titleRow__8rPYU" >
                        We also use cookies from third parties
                    </div>
                    <div   > 
                        <ul>
                            <li>
                                Cloudfare cookies provide us with cloud cybersecurity and DDoS mitigation services (security as defined above). Cloudfare uses programming code to collect information about your interaction with our websites. The cookie contains information related to the calculation of Cloudflare’s proprietary bot score and, when anomaly detection is enabled on bot management, a session identifier. The information in the cookie (other than time-related information) is encrypted and can only be decrypted by Cloudflare. This code is only active while you are on our websites and expires after 30 minutes of continuous inactivity by the end user.
                            </li>
                            <li>
                                Google Analytics cookies provide us analytics to enhance the experience of our visitors to our website and to better understand how the website is used. The storage period for Google Analytics is 14 months.
                            </li>
                        </ul>
                    </div>
                </div>
                

                <div style={{marginTop:20}} className="section" >
                    <div className="ModalContent_titleRow__8rPYU" >
                        We collect the following cookies:
                    </div>
                    <div   > 
                    <Table pagination={false} dataSource={data} columns={columns} />
                    </div>
                </div>
            </div>
        </Modal>   
    </div>

return Common.isMobile ? Mobile : Web 

	
}



