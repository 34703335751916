import { useEffect, useState } from "react";
import { Link, useNavigate  } from 'react-router-dom'
import {httpGet,httpPost} from '../../utils/httpUtils';
import "./MMChannel.css"
import { Common } from "../../app/Common";

import { Input, message, Select, Carousel, SelectProps, Divider,Image } from 'antd';
type IProps = {
	limit?:any,
	hiddenbanner?:boolean
}
export function MMChannel(props:IProps) {
	const navigate = useNavigate();
	const [seachData,setSeachData]= useState({page:1,limit:props.limit?props.limit:4} as  any );
	const [projectList,setProjectList] =  useState([] as Array<any>);
	useEffect(()=>{
		getPageList();
	},[]) 
	const  getPageList=()=>{
		httpPost("/channel/getPageList",{...seachData}).then((e:any)=>{
			if(e.data.code==0){
				setProjectList(e.data.data);
			}
		})
	}
  let  Mobile=<div   style={{display:"flex",width:"100%",justifyContent:"center",flexWrap:"wrap"}} >
		{
			props.hiddenbanner? "":<div>
			<img src={require("../../assets/mobile/channel.png")}  className="mchannel_bg"  alt="" />
		</div>
		}
		<div  className="mproject_box" >
					{projectList.map((ite:any,inx:any)=>{
							return  <div key={inx} className="mchannel_box_item" onClick={()=>{
								navigate("/Channel?channelId="+ite.id);
							}}    >
							<Image src={ite.headerImgArr[0]} alt=""  className="mchannel_box_item_img"  />
							<div className="mchannel_box_item_1"  >
								<div className="mmchannel_name" >{ite.name}</div>
								<img src={require("../../assets/v2/member@2x.png")}  className="mchannel_member_img"   alt="" />						</div>
							</div>
							
				})}
		</div>
  </div>

let Web=<div>
	<div  className="channel_home_box"  onClick={()=>{navigate("/Community")}} >
		<img src={require("../../assets/v2/channel_bg.png")} alt=""  className="channel_home_box_img"  />
	</div>
</div>


return Common.isMobile ? Mobile : Web


}
