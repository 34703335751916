import { useEffect, useState } from "react";
import { Input, message, Carousel, Select, Divider, Image, Spin } from 'antd';
import { Link, useNavigate  } from 'react-router-dom'
import { httpGet, httpGetA, httpPost, host_url, web_url } from '../../utils/httpUtils';
import qs from 'qs';
import { url } from 'inspector';
import { Common } from "../../app/Common";

export function CDonateUs() {
	const [memberData,setMemberData] =  useState({}as any);
	  const [options,setOptions] =  useState([] as Array<any>);
	  const [everyList,setEveryList] =  useState([] as Array<any>);
	  const [searchItem,setSearchItem] =  useState("");
	  useEffect(()=>{
		getMember();
		getEveryList();
	},[]);

	 
	function getEveryList(){
		httpGetA("https://partners.every.org/v0.2/search/pets?apiKey=pk_live_40ff5153b8abe3da05145fdfc9d88e0c").then((e:any)=>{
			setEveryList([...e.data.nonprofits])
		})
	}



	  function getMember(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 0 ){
				setMemberData(e.data.data);
			}else{
				
			}
		})
	 }
	 
	function search(keyWorld:any){
		httpGetA("https://partners.every.org/v0.2/search/"+keyWorld+"?apiKey=pk_live_40ff5153b8abe3da05145fdfc9d88e0c").then((e:any)=>{
			// setOptions([...e.data.nonprofits])
			setEveryList([...e.data.nonprofits])
		})
	}


	function searchM(keyWorld:any){
		httpGetA("https://partners.every.org/v0.2/search/"+keyWorld+"?apiKey=pk_live_40ff5153b8abe3da05145fdfc9d88e0c").then((e:any)=>{
			setEveryList([...e.data.nonprofits])
		})
	}


	function donate(item:any){
		let url=`https://www.every.org/${item.slug}?email=${memberData.email}&webhook_token=YgR5EDAYXwdezDYP9oUVeAmv&success_url=https%3A%2F%2Fwww.impactopia.net%2F%23%2FDonateUs&exit_url=https%3A%2F%2Fwww.impactopia.net%2F%23%2FDonateUs#donate/card`;
		window.open(url);
	}

	let Web=   <div className="donateUsContent">
				<div className="donateUsContent"  style={{background: "#F5F5F5",paddingTop:"100px"}} >
					<img src={require("../../assets/v2/donationUs.png")} className="donationUs" alt="" />
				</div>
				<div   className="donateUsSelect" >
					<Input 
						bordered={false}
						onChange={(e)=>{
							debugger
							if(e.target.value){
								search(e.target.value);
							}else{
								search('pets');
							}
						}}
					></Input>

					{/* <Select
					dropdownStyle={{top:"130px"}}
					showSearch={true}
					value={searchItem}
					allowClear={true}
					//open={true}
					placeholder="Simply search an NGO by name"
					onSearch={(e)=>{
						if(e){
							search(e);
						}
					}}
					onChange={(e)=>{
						setSearchItem("");
					}}
					  bordered={false}
						style={{ width: "98%"}}
					>
						{options.map((item:any,inx)=>{
							return <Select.Option  key={inx}  value={item.slug}  >
										<div  onClick={()=>{donate(item)}} className="donateOption"  >
											<img src={item.logoUrl} className="donateUsprofileUrl"  alt="" />
											<div  style={{marginLeft:"10px"}} >
												<div className="donateUsName" >
													{item.name}
												</div>
												<div className="donateUsDescription" >
													{item.description}
												</div>
											</div>
										</div>
							</Select.Option>
						})}
					</Select> */}
				</div>
				 
				 {/* <div  className="donateUsContent donateUsContentReading"    >
					 Reading time that the modern people spend .
				 </div> */}

				<div  className="donateus_list" >
				{everyList.length > 0? everyList.map((item:any,inx:any)=>{
				return <div key={inx}  className="donateus_item">
							<div className="donateus_flex_no" >
								<img src={item.logoUrl} className="donateUsprofileUrl"  alt="" />
								<div className="donateUsName" >
									{item.name}
								</div>
							</div>
							<div className="donateUsDescriptionlist" >
								{item.description}
							</div>
							<div   className="donateus_flex_no" style={{height:"40px",alignItems:"center",justifyContent:"flex-end"}}  >
								<div  className="donateus_but"  onClick={()=>{donate(item)}}  >Donate</div>
							</div>
						</div>
				}):""}
					
</div>
			

	</div>

 let  Mobile=<div className="mdonateus_box" >
	<div className="mdonateus_flex" >
		<div className="mdonateus_text" >
			<div>
				You can donate to over  <span  style={{color:"rgba(0, 163, 127, 1)"}} >1.2 million </span> 			
			</div>
			<div  style={{textAlign:"center"}} >
				US registered NGOs, thanks to our 
			</div>
			<div  style={{textAlign:"center"}} >
				collaboration with every.org!
			</div>
			
		</div>
	</div>

	<div className="mdonateus_flex"  >
		<img src={require("../../assets/mobile/donationUs.png")} className="mdonateus_img" alt="" />
	</div>

	<div className="mdonateus_flex" >
		<div className="mdonateus_input_box" >
			<img src={require("../../assets/mobile/search.png")} className="mdonateus_search_img" />
			<Input className="pmint_input"
					bordered={false}
					placeholder="Simply search an organization by name"
					onChange={(e) => {
						if(e.target.value){
							searchM(e.target.value);
						}else{
							getEveryList();
						}
					}}
					style={{ width: "80%" }} />
		</div>	
	</div>

	<div  className="mdonateus_list" >

	{everyList.length > 0? everyList.map((item:any,inx:any)=>{
	  return <div key={inx}  className="mdonateus_item">
				<div className="mdonateus_flex_no" >
					<img src={item.logoUrl} className="mdonateUsprofileUrl"  alt="" />
					<div className="mdonateUsName" >
						{item.name}
					</div>
				</div>
				<div className="mdonateUsDescription" >
					{item.description}
				</div>
				<div   className="mdonateus_flex_no" style={{height:"40px",alignItems:"center",justifyContent:"flex-end"}}  >
					<div  className="mdonateus_but"  onClick={()=>{donate(item)}}  >Donate</div>
				</div>
			</div>
	}):""}
		
	</div>
	

		
 </div>

return Common.isMobile ? Mobile : Web 

	
}



