import {State, useAppDispatch, useAppSelector} from "../../app/Store";
import {useEffect,useState} from "react";

import { NftList } from "./NftList_m";
import { MyNftList } from "./MyNftList_m";
export function NftMain_m() {
	let [nftTab,setNftTab]=useState("Badge");
	function changeTab(type:any){
		setNftTab(type);
	}
	return <div  style={{width:"100%"}} >
		 <div className="nftTab_div" >
			<div  className="nftTab" >
				<div className={nftTab == 'Badge'?"title-bg":"title-bg-no"}  onClick={() => changeTab('Badge')} >
						Impact Badge
				</div>

				<div className="nftTab_line" >

				</div>
				<div className={nftTab == 'my'?"title-bg":"title-bg-no"} onClick={() => changeTab('my')}  >
						My Collection
				</div>
			</div>
		 </div>

	<div>
		{nftTab=='Badge'?
			<NftList></NftList>
		:
		<div   style={{width:"100%"}}  >
			<MyNftList ></MyNftList>
			<div className="nft-line" ></div>
		</div>}
	</div>

</div>


}
