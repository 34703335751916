import { useEffect, useState } from "react";
import "./MyThoughts.css";
import { Link } from 'react-router-dom'
import {httpGet,httpPost} from '../../utils/httpUtils';
import { Formatter } from './Formatter';
import { Cookie } from './cookie';




import {Common} from "../../app/Common";
import {CMDiscover_list} from "./CMDiscover_list";

import {Share} from "./Share"
export function MyThoughts() {
	useEffect(()=>{
		getmyForum();
	},[]);

	const [forumList,setForumList] =  useState([]as Array<any>);

	function getmyForum(){
		httpGet("/forum/myForum").then((e:any)=>{
			if(e.data.code==0){
				setForumList(e.data.data);
			}
		})
	}




	function getTags(item:any){
		let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}

		let tags=item.tages;
		let tagshtml=<div></div>
		if(tags){
			tags =tags.split(",");
			tagshtml=	<div className="forum_flex_one" >
			{tags.map((ite:any,inde:any)=>{

			let color="";
			if(ite){
				color=color_conf[ite.substring(0,1).toLowerCase()]?color_conf[ite.substring(0,1).toLowerCase()]:"#FFB4A2";
			}

				return <div   style={{backgroundColor:color}}  key={inde} className="forum_but but-shadow" >{ite}</div>
			})}
		</div>
		}
		return  tagshtml;
	}




	function forumAttention(item:any,index:any){
		httpGet("/attention/forumAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=1;
				setForumList([...forumList]);
			}
		})
	}

	function forumCollect(item:any,index:any){
		httpGet("/collect/forumCollect/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=1;
				setForumList([...forumList]);
			}
		})
	}


	function cancelCollect(item:any,index:any){
		httpGet("/collect/cancelCollect/"+item.id+"/3").then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=0;
				setForumList([...forumList]);
			}
		})
	}



	function cancelAttention(item:any,index:any){
		httpGet("/attention/cancelAttention/"+item.id+"/3").then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=0;
				setForumList([...forumList]);
			}
		})
	}



	let Web= <div className="my_post_body person_my_post"  style={{marginTop:20}} >
		  <div className="person_nft_badge_name">My Replies</div>
		  {forumList.length > 0 ?
			forumList.map((item:any,idx:any)=>{
				return 	<div key={idx} className="dis_list_item" >
				<div  className="dis_list_name" >
					{item.title}
				</div>
					<div  className="disflex" style={{width:"50%",marginLeft:"10px",alignItems:"center"}} >
						<img src={ item.memberHeadImg?item.memberHeadImg:require("../../assets/v2/logo.png")} style={{width:'2.1875vw',height:'2.1875vw',borderRadius:"100%",marginRight:"10px"}}   alt="" />
						<div>{item.memberName ? item.memberName:""}</div>
						<div  style={{display:"flex",paddingLeft:15}} >
							 {getTags(item)}
							<div  className="dis_time" >
								<Formatter dateTime={item.createDateTime} ></Formatter>
							</div>
						</div>
					</div>
				<div  className="dis_list_content" dangerouslySetInnerHTML={{__html:item.content}}    >

				</div>

				<div className="disflex"   style={{width:"50.7813vw",marginTop:20}}  >
					<div   className="disflex" style={{width:"50%",alignItems:"center"}}  >
						<div  className="options_box" >
							<div className="options_box_flex options_box_flex_border">
								{item.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{forumAttention(item,idx)}} className="dis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,idx)}} className="dis_list_img"  alt="" />}
								<div className="options_box_num" >{item.attentionNum}</div>
							</div>
							<Link className="options_box_flex options_box_flex_border" to={"/Forumlist?forumId="+item.id}>
								<img src={require("../../assets/v2/feed@2x.png")}  className="dis_list_img"   alt="" />
								<div className="options_box_num" >{item.commentNum}</div>
							</Link>
							<div  className="options_box_flex options_box_flex_border" >
							  {item.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{forumCollect(item,idx)}}  className="dis_list_img"  alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect(item,idx)}}  className="dis_list_img"   alt="" />}
							</div>
							<div  className="options_box_flex" >
								<Share   sharedata={item}  type={'dic'} ></Share>
							</div>
						</div>
					</div>
					<div className="disflex"   style={{width:"50",marginTop:20}}  >
					<div  className="disflex" style={{width:"100%",justifyContent:"flex-end"}} >
						<Link to={"/Forumlist?forumId="+item.id}  >
							<div className="dis_Reading">
								Continue Reading→
							</div>
						</Link>
					</div>
					</div>
				</div>
		</div>
		  }):
		  <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="no_list" >You don't have any post yet!</div></div>
		  
		  }
		  <Cookie></Cookie>
	</div>



let  Mobile=<div>
		{forumList.length > 0 ? <CMDiscover_list forumList={forumList}  handleClick={()=>{}} ></CMDiscover_list>:
			<div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You don't have any post yet!</div></div>
		}
</div>

return Common.isMobile ? Mobile : Web 

}
