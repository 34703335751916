import {BasePage, page} from "./BasePage";
import "./Donate.css"
import "../css/common.css";
import { CDonate} from '../components/CDonate';
import { Navigation } from "../components/Navigation";


@page("Donate")
export class Donate extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
			<CDonate></CDonate>
		</div>
	}


	

	protected mobileContent(): JSX.Element {
		return <div>
			
			<CDonate></CDonate>

		</div>;
	}
}
