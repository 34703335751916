import { useEffect, useState } from 'react'
import { Input, message, Select, Image,Tabs } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { httpGet, httpPost } from '../../utils/httpUtils'
import { Ctrending_star } from './Ctrending_star'
import { SendForum } from './SendForum'
import { Create_project_but } from './Create_project_but'
import { UserHeader } from './UserHeader'
import { Share } from './Share'
import { Formatter } from './Formatter'
import { MMChannel } from './MMChannel'
import { Common } from '../../app/Common'
import { ImageList } from './Image'
import { CMDiscover_list } from './CMDiscover_list'
import Iframe from 'react-iframe';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { State, useAppDispatch, useAppSelector } from "../../app/Store";
export function CDiscover_list() {
  const {  tagsListALL } = useAppSelector((state: State) => state.tags)
  const navigate = useNavigate()
  const [forumList, setForumList] = useState([] as Array<any>)
  const [tidingsList, setTidingsList] = useState([] as Array<any>)
  const [memberData,setMemberData] =  useState(false);

  const [live,setLive] =  useState({} as any );

  function getRecommendDetail(){
		httpGet("/live/getRecommendDetail").then((e:any)=>{
			if(e.data.code == 0 ){
				  setLive(e.data.data);
			}
		})
	 }

  useEffect(() => {
    getPageList()
    getTidings()
   
    getMember();
    getRecommendDetail();
  }, [])

  

  function getSkeleton(){
       return  <div className='dis_list_item' >
      <SkeletonTheme     >
            <Skeleton count={1} style={{height:50}} />
            <Skeleton count={1}  style={{height:50}} />
            <Skeleton count={3} />
            <Skeleton count={1} style={{height:"9.3229vw",width:"14.4792vw"}} />
       </SkeletonTheme> 

       <SkeletonTheme     >
            <Skeleton count={1} style={{height:50}} />
            <Skeleton count={1}  style={{height:50}} />
            <Skeleton count={3} />
            <Skeleton count={1} style={{height:"9.3229vw",width:"14.4792vw"}} />
       </SkeletonTheme> 
    </div> 
  }

  function getMember(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 0 ){
				setMemberData(true);
			}else{
        setMemberData(false);
      }
		})
	 }

  const getPageList = () => {
    httpPost('/forum/getPublicPageList', {
      page: 1,
      limit: 20,
      tags: formData.tags,
      keyWorld: formData.keyWorld,
    }).then((e: any) => {
      if (e.data.code == 0) {
        setForumList(e.data.data.list)
      }
    })
  }

  function getTidings() {
    httpPost('/tidings/getPageList', { page: 1, limit: 20 }).then((e: any) => {
      if (e.data.code == 0) {
        setTidingsList(e.data.data.list)
      }
    })
  }

  function forumAttention(item: any, index: any) {
    httpGet('/attention/forumAttention/' + item.id).then((e: any) => {
      if (e.data.code == 0) {
        forumList[index].isAttentin = 1
        forumList[index].attentionNum += 1
        setForumList([...forumList])
      } else if (e.data.code == 401) {
         
        localStorage.setItem('pageFrom', '/#/Discover_list')
        navigate('/SignIn')
      }
    })
  }

  function forumCollect(item: any, index: any) {
    httpGet('/collect/forumCollect/' + item.id).then((e: any) => {
      if (e.data.code == 0) {
        forumList[index].isCollect = 1
        setForumList([...forumList])
      } else if (e.data.code == 401) {
        localStorage.setItem('pageFrom', '/#/Discover_list')
        navigate('/SignIn')
      }
    })
  }

  function cancelCollect(item: any, index: any, type: any) {
    httpGet('/collect/cancelCollect/' + item.id + '/' + type).then((e: any) => {
      if (e.data.code == 0) {
        forumList[index].isCollect = 0
        setForumList([...forumList])
      } else if (e.data.code == 401) {
        localStorage.setItem('pageFrom', '/#/Discover_list')
        navigate('/SignIn')
      }
    })
  }

  function cancelAttention(item: any, index: any, type: any) {
    httpGet('/attention/cancelAttention/' + item.id + '/' + type).then(
      (e: any) => {
        if (e.data.code == 0) {
          forumList[index].isAttentin = 0
          forumList[index].attentionNum -= 1
          setForumList([...forumList])
        } else if (e.data.code == 401) {
          localStorage.setItem('pageFrom', '/#/Discover_list')
          navigate('/SignIn')
        }
      }
    )
  }


  const [formData, setFormData] = useState({tags:[]} as any);
  
  

  function changTabs(e:any) {
    setDefaultActiveKey(e);
  }
  function getTags(item: any) {
    let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}
    let tags = item.tages
    let tagshtml = <div></div>
    if (tags) {
      tags = tags.split(',')
      tagshtml = (
        <div className="forum_flex_one">
          {tags.map((ite: any, inde: any) => {
            let color = ''
            if (ite) {
              color = color_conf[ite.substring(0, 1).toLowerCase()]
                ? color_conf[ite.substring(0, 1).toLowerCase()]
                : '#FFB4A2'
            }
            return (
              <div
                key={inde}
                className="forum_but but-shadow"
                style={{ backgroundColor: color }}>
                {ite}
              </div>
            )
          })}
        </div>
      )
    }
    return tagshtml
  }


  function getTagsBut() {
    let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}
    
    let  Mobile=  <div  style={{width:"340px",marginTop:"0.5208vw",overflow:"auto",whiteSpace:"nowrap",height:40}} >
        {tagsListALL.map((item,indx)=>{
          let color = ''
          if (item.value) {
            color = color_conf[item.value.substring(0, 1).toLowerCase()]
              ? color_conf[item.value.substring(0, 1).toLowerCase()]
              : '#FFB4A2'
          }
          return <span key={indx}  onClick={()=>{
              if(item.value =='All'){
                formData.tags="";
              }else{
                formData.tags=[item.value]
              }
            setFormData({...formData})
            getPageList();
          }} className='all_tags_but'  style={{background:color,opacity:(formData.tags && formData.tags.indexOf(item.value) >=0 ) ||   formData.tags=="" ? 1:0.5}} >{item.value}</span>
        })
        }
    </div>
    let  Web=  <div  style={{width:"100%",marginTop:"0.5208vw"}} >
      {tagsListALL.length>0 ? <>
              {tagsListALL.map((item,indx)=>{
                let color = ''
                if (item.value) {
                  color = color_conf[item.value.substring(0, 1).toLowerCase()]
                    ? color_conf[item.value.substring(0, 1).toLowerCase()]
                    : '#FFB4A2'
                }
                return <span key={indx}  onClick={()=>{
                  if(item.value =='All'){
                    formData.tags=[];
                  }else{
                    formData.tags=[item.value]
                  }
                  setFormData({...formData})
                  getPageList();
                }} className='call_tags_but but-shadow'  style={{background:color,opacity:(formData.tags && formData.tags.indexOf(item.value) >=0)   ||   formData.tags=="" ? 1:0.5}} >{item.value}</span>
              })
            }
          </>: <Skeleton count={1}  style={{height:40}} ></Skeleton> }
</div>

    return Common.isMobile ? Mobile : Web
  }

 
	// tabs
	const [defaultActiveKey, setDefaultActiveKey] = useState("Forum");
	// const defaultActiveKeyChange = (key: any) => {
	// 		let data = tagsListALL[key].value
	// 	let tags =data=="All"?[]:[ data ]
  //   formData.tags = tags
  //   setFormData({ ...formData })
	// 	getPageList();
	// 	setDefaultActiveKey(key)
	// }

  // function selectAll() {
  //   let tags = []
  //   for (let index = 0; index < tagsListALL.length; index++) {
  //     tags.push(tagsListALL[index].value)
  //   }
  //   formData.tags = tags
  //   setFormData({ ...formData })
  //   getPageList()
  // }
  function queryURLparams(url:any) {
		let obj = {} as any
		if (url.indexOf('?') < 0) return obj
		let arr = url.split('?')
		url = arr[1]
		let array = url.split('&')
		for (let i = 0; i < array.length; i++) {
			let arr2 = array[i]
			let arr3 = arr2.split('=')
			obj[arr3[0]] = arr3[1]
		}
		return obj
	
	}

  

	

	function getUrl(url:any){
		if(url && url.indexOf('live') > -1){
			try {
				let code =url.replace("https://www.youtube.com/live/","").replace("https://youtube.com/live/","").split("?")[0];
				return `https://www.youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}

		if( url  && url.indexOf('shorts') > -1){
			try {
				let code =url.replace("https://youtube.com/shorts/","").split("?")[0];
				return `https://youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('watch') > -1){
			try {
				 let ags =  queryURLparams(url);
				return `https://www.youtube.com/embed/${ags.v}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('youtu.be') > -1){
			try {
				 let ags =url.replace("https://youtu.be/","").split("?")[0];
				return `https://www.youtube.com/embed/${ags}`;
			} catch (error) {
				
			}
		}
        return "";
    }

  let Web = (
    <div className="disContent">
      <Ctrending_star
        paddingLeft_={'5.375vw'}
        isCreate={false}></Ctrending_star>

      <div style={{ paddingTop: 30 }} className="disForum">
        <div className="dis_list_tow">
          <SendForum handleClick={getPageList}></SendForum>
          <div className="badge_options">
            <div className="badge_options_seacher">
              <div className="badge_options_seacher_box">
                <Input
                  style={{ width: '80%' }}
                  bordered={false}
                  onChange={(e) => {
                    formData.keyWorld = e.target.value
                    setFormData({ ...formData })
                    getPageList()
                  }}
                  placeholder="Search by keywords"
                />
              </div>
              <div
                className="badge_options_seacher_filters"
                onClick={getPageList}>
                <img
                  src={require('../../assets/v2/filter.png')}
                  className="badge_box_group_item_filters"
                  alt=""
                />
                Filters
              </div>
            </div>
          </div>
          {getTagsBut()}
          
          {forumList.length > 0 ? <>
            {forumList.map((item, index) => {
            return (
              <div key={index} className="dis_list_item">
                <Link to={'/Forumlist?forumId=' + item.id}>
                  <div className="dis_list_name">{item.title}</div>
                  <div
                    className="disflex"
                    style={{
                      width: '100%',
                      marginLeft: '0.5208vw',
                      alignItems: 'center',
                    }}>
                    <UserHeader member={item.memberEntity}></UserHeader>
                    <div style={{ color: ' #222222' }}>
                      {item.memberName ? item.memberName : ''}
                    </div>
                    <div style={{ display: 'flex', marginLeft: 50 }}>
                      {getTags(item)}
                      <div className="dis_time">
                        <Formatter dateTime={item.createDateTime}></Formatter>
                      </div>
                    </div>
                  </div>

                  <div
                    className="dis_list_content"
                    dangerouslySetInnerHTML={{ __html: item.content?item.content.replace(/<[^>]+>/g,""):"" }}
                    ></div>
                </Link>
                {
                  item.url? <div>
                      <Iframe url={getUrl(item.url)}
                        width="640px"
                        height="320px"
                        id=""
                        className=""
                        display="block"
                        position="relative"/>
                    
                  </div>:  (item.imgUrl && item.imgUrl.length) > 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                      }}>
                      <ImageList item={item.imgUrl}></ImageList>
                    </div>
                  ) : (
                    <div></div>
                  )
                }
              
                <div
                  className="disflex"
                  style={{ width: '50.7813vw', marginTop: 20 }}>
                  <div
                    className="disflex"
                    style={{ width: '50%', alignItems: 'center' }}>
                    <div className="options_box">
                      <div className="options_box_flex options_box_flex_border">
                        {item.isAttentin == 0 ? (
                          <img
                            src={require('../../assets/v2/zan@2x.png')}
                            onClick={() => {
                              forumAttention(item, index)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={require('../../assets/v2/zan_2.png')}
                            onClick={() => {
                              cancelAttention(item, index, 3)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        )}
                        <div className="options_box_num">
                          {item.attentionNum}
                        </div>
                      </div>
                      <div
                        className="options_box_flex options_box_flex_border"
                        onClick={()=>{
                          if(memberData){
                            navigate('/Forumlist?forumId=' + item.id);
                          }else{
                            localStorage.setItem("pageFrom",'/#/Forumlist?forumId=' + item.id);
			                    	navigate("/SignIn");
                          }
                        }}
                        
                        >
                        <img
                          src={require('../../assets/v2/feed@2x.png')}
                          className="dis_list_img"
                          alt=""
                        />
                        <div className="options_box_num">{item.commentNum}</div>
                      </div>


                      <div className="options_box_flex options_box_flex_border">
                        {item.isCollect == 0 ? (
                          <img
                            src={require('../../assets/v2/shoucang@2x.png')}
                            onClick={() => {
                              if(memberData){
                                forumCollect(item, index)
                              }else{
                                localStorage.setItem("pageFrom",'/#/Forumlist?forumId=' + item.id);
                                navigate("/SignIn");
                              }
                             
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={require('../../assets/v2/shoucang_2.png')}
                            onClick={() => {
                              cancelCollect(item, index, 3)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="options_box_flex">
                        <Share sharedata={item} type={'dic'}></Share>
                      </div>
                    </div>
                  </div>

                  <div
                    className="disflex"
                    style={{ width: '50', marginTop: 20 }}>
                    <div
                      className="disflex"
                      style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <Link to={'/Forumlist?forumId=' + item.id}>
                        <div className="dis_Reading">Continue Reading→</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

          </>:getSkeleton()}

          
        </div>
      </div>
    </div>
  )

  let Mobile = (
    <div style={{ width: '100%' }}>
      <div className="mdiscover_list_box"></div>
      <div
        className="disflex mch_tab"
        style={{ width: '100%', marginTop: '5.3333vw' }}>
            {live && live.id ? <img
                    src={require('../../assets/v2/live_new.png')}
                    className="mdiscover_live_new"
                    onClick={()=>{
                        navigate("/ChLive?liveId="+live.id)
                    }}
                    alt=""
                  />:""}
        <Tabs defaultActiveKey={defaultActiveKey} onChange={changTabs} >
          <Tabs.TabPane  tab='Forum' key='Forum'>
            <div className="mdiscover_list_flex">
                <div style={{width:'100%'}}>
                  <div  className="mpmint_box" >
                    <div  className='discover_seach' >
                      <img
                          src={require('../../assets/v2/search.png')}
                          className="mdiscover_seach_img"
                          alt=""
                        />
                      <Input
                          style={{ width: '80%' }}
                          bordered={false}
                          onChange={(e) => {
                            formData.keyWorld = e.target.value
                            setFormData({ ...formData })
                            getPageList()
                          }}
                          placeholder="Search by keywords"
                        />
                    </div>
                  </div>
                  {getTagsBut()}
                  {
                    forumList.length > 0 ?<CMDiscover_list
                    forumList={forumList}
                    handleClick={getPageList}></CMDiscover_list>:""
                  }
                  
                </div>
          </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab='Trending' key='Trending'>
           <div className="mdiscover_list_flex">
              <Ctrending_star
                paddingLeft_={'9.375vw'}
                isCreate={false}></Ctrending_star>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      <SendForum handleClick={getPageList}></SendForum>
    </div>
  )

  return Common.isMobile ? Mobile : Web
}
