import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { Input, Dropdown, Popover, message } from 'antd';
import { httpGet, httpPost } from '../../utils/httpUtils';
import Iframe from 'react-iframe';
import { Select, Drawer, InputNumber, Button, Tabs, Modal } from 'antd';
import { Share } from './Share';
import { UserHeader } from "./UserHeader";
import { Ctrending_star } from './Ctrending_star';
import { json } from 'stream/consumers';
import { Chnannel_jion } from './Chnannel_jion';
import { ImageList } from './Image';
import { Formatter } from './Formatter';
import { Common } from "../../app/Common";
import "./CPerson_myNft.css"

type IProps = {
  memberId?: any,
}

export function CPerson_myNft(props: IProps) {
  console.log(props);
  const [myMemberNft, setMyMemberNft] = useState({} as any)
  useEffect(() => {
    if (props.memberId) {
      listByMemberId(props.memberId);
    }
  }, [props.memberId])


  function listByMemberId(memberId: any) {
    httpGet('/memberNft/listByMemberId/' + memberId).then((e: any) => {
      if (e.data.code == 0) {
        setMyMemberNft(e.data.data)
      }
    })
  }


  const navigate = useNavigate();
  let Web = <div className="person_nft_badge" style={{ marginTop: 20 }}>
    <div className="person_nft_badge_name">MY NFT Badge</div>
    <div className="person_nft_box">
      {myMemberNft.length > 0 ? (
        myMemberNft.map((item: any, inx: any) => {
          return (
            <img
              key={inx}
              src={JSON.parse(item.image)[0].url}
              className="person_nft_badge_img"
              alt=""
            />
          )
        })
      ) : (
        <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div>You don’t have any badge yet!</div></div>
        
      )}
    </div>
  </div>
  let Mobile = <div className="mperson_nft_box" >

    {myMemberNft.length > 0 ? (
      myMemberNft.map((item: any, inx: any) => {
        return (
          <div className="mperson_nft_item" key={inx} >
            <img
              src={JSON.parse(item.image)[0].url}
              className="mperson_nft_item_img"
              alt=""
            />
            <div className="mperson_nft_name" >
              {item.name}
            </div>

            <div className="mperson_nft_description" >
              {item.description}
            </div>

            <div className="mperson_nft_but" >
              <div className="mperson_nft_project"  >
                Mint on Polygon
              </div>
              <div>
                <img className="mperson_nft_tw" src={require('../../assets/mobile/tw_m.png')} alt="" />
              </div>

            </div>

          </div>
        )
      })
    ) : (
       <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You don’t have any badge yet!</div></div>
    )}


  </div>

  return Common.isMobile ? Mobile : Web
}
