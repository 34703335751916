import { MyThoughts } from "./MyThoughts";
import { My_post } from "./My_post";
import { useEffect, useState } from "react";
import { Project } from '../pages/Project';
type IProps = {
	memberId?:any,
}

export function CPerson_post_box(props:IProps) {
  
  const [but,setBut] =  useState("Project");

  let Mobile=<div  style={{width:"100%"}} >
        <div  style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",marginTop:"15px"}}  >
          <div  style={{marginRight:20}}  className={but=='Project'?'mmy_post_project_but_active':'mmy_post_project_but'} onClick={()=>{
            setBut("Project")
          }} >
               My Project
          </div>
          <div  style={{marginLeft:20}}   className={but=='Thoughts'?'mmy_post_project_but_active':'mmy_post_project_but'} onClick={()=>{
            setBut("Thoughts")
          }}  >
               Thoughts
          </div>
        </div>
        {but=='Project'? <My_post></My_post>: <MyThoughts></MyThoughts>}
  </div>

    return Mobile;
}
