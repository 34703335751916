import { useEffect, useState } from "react";
import { Link, useNavigate  } from 'react-router-dom'
import { Input,Dropdown,Popover,message} from 'antd';
import {httpGet,httpPost} from '../../utils/httpUtils';
import {Share} from './Share';
import {UserHeader} from "./UserHeader";
import {Ctrending_star} from './Ctrending_star';
import { json } from 'stream/consumers';
import {Chnannel_jion} from './Chnannel_jion';
import {ImageList} from './Image';
import { Formatter } from './Formatter';
import { Common } from "../../app/Common";
import { MMChannel } from './MMChannel';
import { CMDiscover_list } from './CMDiscover_list';
import {SendForum} from './SendForum';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Iframe from 'react-iframe';


export function MCommunity() {
        const navigate = useNavigate();
        const [forumList,setForumList] =  useState([]as Array<any>);
        const [keyWorld,setKeyWorld] =  useState("");
        const [communityUrl,setCommunityUrl] =  useState("");
        const [link,setLink] =  useState("");
        const [leftUrl,setLeftUrl] =  useState("");
        const [leftLink,setLeftLink] =  useState("");
        const [rightUrl,setRightUrl] =  useState("");
        const [rightLink,setRightLink] =  useState("");


        useEffect(()=>{
		getPageList();
                getLivePageList(keyWorld);
                getMyJoinPageList(keyWorld);
                getNotJoinPageList(keyWorld);
                getcommunityUrl();
	},[])



        function getTags(item:any){

                let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}

		let tags=item.tages;
		let tagshtml=<div></div>
		if(tags){
			tags =tags.split(",");
			tagshtml=	<div className="forum_flex_one" >
			{tags.map((ite:any,inde:any)=>{
                                let color="";
                                if(ite){
                                        color=color_conf[ite.substring(0,1).toLowerCase()]?color_conf[ite.substring(0,1).toLowerCase()]:"#FFB4A2";
                                }
				return <div   style={{backgroundColor:color}}    key={inde} className="forum_but but-shadow" >{ite}</div>
			})}
		</div>
		}
		return  tagshtml;
	}


        function queryURLparams(url:any) {
		let obj = {} as any
		if (url.indexOf('?') < 0) return obj
		let arr = url.split('?')
		url = arr[1]
		let array = url.split('&')
		for (let i = 0; i < array.length; i++) {
			let arr2 = array[i]
			let arr3 = arr2.split('=')
			obj[arr3[0]] = arr3[1]
		}
		return obj
	
	}
	
	

	function getUrl(url:any){
		if(url && url.indexOf('live') > -1){
			try {
				let code =url.replace("https://www.youtube.com/live/","").replace("https://youtube.com/live/","").split("?")[0];
				return `https://www.youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}

		if( url  && url.indexOf('shorts') > -1){
			try {
				let code =url.replace("https://youtube.com/shorts/","").split("?")[0];
				return `https://youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('watch') > -1){
			try {
				 let ags =  queryURLparams(url);
				return `https://www.youtube.com/embed/${ags.v}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('youtu.be') > -1){
			try {
				 let ags =url.replace("https://youtu.be/","").split("?")[0];
				return `https://www.youtube.com/embed/${ags}`;
			} catch (error) {
				
			}
		}
        return "";
    }

        function getcommunityUrl(){
                httpGet("/common/getBannerUrl/2").then((e:any)=>{
                        setCommunityUrl(e.data.url);
                        setLink(e.data.link);
                        setLeftUrl(e.data.leftUrl);
                        setLeftLink(e.data.leftLink);
                        setRightUrl(e.data.rightUrl);
                        setRightLink(e.data.rightLink);

		})

        }


        function forumCollect(item:any,index:any){
		httpGet("/collect/forumCollect/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=1;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Community");
				navigate("/SignIn");
			}
		})
	}


        function forumAttention(item:any,index:any){
		httpGet("/attention/forumAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=1;
				forumList[index].attentionNum+=1;

				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Community");
				navigate("/SignIn");
			}
		})
	}


	function cancelCollect(item:any,index:any,type:any){
		httpGet("/collect/cancelCollect/"+item.id+"/"+type).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=0;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Community");
				navigate("/SignIn");
			}
		})
	}


	function cancelAttention(item:any,index:any,type:any){
		httpGet("/attention/cancelAttention/"+item.id+"/"+type).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=0;
				forumList[index].attentionNum-=1;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Community");
				navigate("/SignIn");
			}
		})
	}


        const [liveList,setLiveList] =  useState([]as Array<any>);
        const [notJoinPageList,setNotJoinPageList] =  useState([]as Array<any>);
        const [myJoinPageList,setMyJoinPageList] =  useState([]as Array<any>);


        const  getMyJoinPageList=(keyWorld:any)=>{
		httpPost("/channel/getMyJoinPageList",{page:1,limit:8,keyWorld:keyWorld}).then((e:any)=>{
			if(e.data.code==0){
				setMyJoinPageList(e.data.data.list);
			}
		})
	}

        const  getNotJoinPageList=(keyWorld:any)=>{
		httpPost("/channel/getNotJoinPageList",{page:1,limit:100,keyWorld:keyWorld}).then((e:any)=>{
			if(e.data.code==0){
				setNotJoinPageList(e.data.data.list);
			}
		})
	}
        const  getLivePageList=(keyWorld:any)=>{
		httpPost("/live/getPageList",{page:1,limit:8,keyWorld:keyWorld}).then((e:any)=>{
			if(e.data.code==0){
				setLiveList(e.data.data.list);
			}
		})
	}

        const  getPageList=()=>{
		httpGet("/channel/getGeneralHome").then((e:any)=>{
			if(e.data.code==0){
				setForumList(e.data.data);
			}
		})
	}



    let Web= <div className="com_content" >
        <div  className="com_banner" >
                <div className="com_banner_left" >
                        {leftUrl? <img onClick={()=>{
                                 window.open(leftLink);
                        }} src={leftUrl}     className="com_banner_left_img"  alt=""    />
                        : <Skeleton className="com_banner_left_img" /> 
                        }
                </div>
                <div className="com_banner_center" >
                        {communityUrl?   <img onClick={()=>{
                                 window.open(link);
                        }} src={communityUrl}  className="com_banner_center_img"  alt=""    /> 
                        : <Skeleton className="com_banner_center_img"></Skeleton>
                        }
                </div>
                <div className="com_banner_right" >
                        <div className="com_banner_center_live"   >
                        {rightUrl?<img onClick={()=>{
                                 window.open(rightLink);
                        }} src={rightUrl}  className="com_banner_center_img" style={{objectFit:"fill"}}  alt=""    /> 
                        : <img src={require("../../assets/v2/live@2x.png")}  className="com_banner_center_live_1"  alt=""    /> }
                        </div>
                </div>
        </div>

        <div className="com_search" >
                <img src={require("../../assets/v2/search.png")}  className="com_search_img"   alt="" />
                <Input placeholder="search your need"   onInput={(e:any)=>{
                        setKeyWorld(e.target.value);
                        getLivePageList(e.target.value);
                        getMyJoinPageList(e.target.value);
                        getNotJoinPageList(e.target.value);
                }} bordered={false} ></Input>
        </div>


        <div className="com_w100_flex"   style={{justifyContent:"space-between",marginTop:" 1.0833vw"}} >
                <div style={{display:"flex",alignItems: "center"}}>
                        <div  className="com_title" >Live</div>
                        <div className="com_searchLive" >
                        <img src={require("../../assets/v2/search.png")}  className="com_live_imgLive"   alt="" />
                        <Input placeholder="search your need"   onInput={(e:any)=>{
                                                                getLivePageList(e.target.value);
                                                        }} bordered={false} ></Input>
                                </div>
                </div>
               
                <div className="com_flex" >
                 <img src={require("../../assets/v2/filter.png")}  className="com_filter"   alt="" />
                  Filters
                </div>
        </div>

        <div  className="com_live" style={{marginTop:" 1.0833vw"}}  >
            <div  className="com_channel_box" >
                {liveList.length > 0 ? liveList.map((item:any,index:any)=>{
                        return <div  key={index} className="com_live_box"   onClick={()=>{ navigate("/ChLive?liveId="+item.id)}}  >
                                  <img src={JSON.parse(item.image)[0]['url']}  className="com_live_img"   alt="" />
                        <div  className="com_live_1">
                                <img src={item && item.channelEntity &&  item.channelEntity.headerImgArr ? item.channelEntity.headerImgArr[0]:require("../../assets/v2/member@2x.png")} className="com_live_header"   alt="" />
                                <div  className="com_live_0" >
                                        <div className="com_live_1_1" >{item.name}</div>
                                        <div  className="com_live_1_2" >
                                                <div  className="com_live_1_2_1" >
                                                        <div style={{marginLeft:"10px"}} >{item && item.channelEntity ? item.channelEntity.plateName:""}</div>
                                                </div>

                                                <div className="com_flex" >
                                                        <img src={require("../../assets/v2/renshu@2x.png")}  className="com_live_renshu"   alt="" />
                                                        {item.watchNum?item.watchNum:0}
                                                </div>
                                        </div>
                                </div>
                        </div>
                     </div>
                }):""}
            </div>
        </div>

        <div className="com_w100_flex"   style={{justifyContent:"space-between",marginTop:" 1.0833vw"}}  >
                <div style={{display:"flex",alignItems: "center"}}>
                        <div  className="com_title" >Channel</div>
                        <div className="com_searchLive" >
                        <img src={require("../../assets/v2/search.png")}  className="com_live_imgLive"   alt="" />
                        <Input placeholder="search your need"   onInput={(e:any)=>{
                                                              getNotJoinPageList(e.target.value);
                                                        }} bordered={false} ></Input>
                                </div>
                </div>
                <div style={{display:"flex",alignItems: "center"}}>
                        <div className="CreateChannel but-shadow" onClick={()=>{ navigate("/PublishChannel")}} >
                        <img src={require("../../assets/v2/ad.png")}   className="com_filterAd"    alt="" />
                                Create channel
                        </div>
                        <div >
                                <img src={require("../../assets/v2/filter.png")}   className="com_filter"    alt="" />
                                Filters
                        </div>
                </div>
               
        </div>

        <div className="com_live" style={{marginTop:" 1.0833vw"}}   >
        <div  className="com_channel_box" >
                {myJoinPageList.length > 0 ? myJoinPageList.map((item:any,index:any)=>{
                        return    <div key={index}  onClick={()=>{ navigate("/Channel?channelId="+item.id)}} className="com_channel_1" >

                         <img src={item.headerImgArr[0]}  className="com_channel_img"   alt="" />
                        <div className="com_channel_0"  >
                               <div  className="com_live_1_1" >
                                       <div className="com_live_1_1_name" >{item.name}</div>
                                       <img src={require("../../assets/v2/member@2x.png")}  className="com_channel_member_img"   alt="" />
                               </div>

                               <div  className="com_live_1_2" >
                                       <div>
                                       <img src={require("../../assets/v2/person@2x.png")}  className="com_channel_member_img"   alt="" />
                                       {item.memberNum ? item.memberNum:0}
                                       </div>
                                       <div style={{marginLeft:"1.0417vw"}} >
                                       <img src={require("../../assets/v2/talk@2x.png")}  className="com_channel_member_img"   alt="" />
                                       {item.commentNum ? item.commentNum:0}
                                       </div>
                                       <div  style={{marginLeft:"1.0417vw"}} className="com_channel_plateName" >
                                           {item.plateName}
                                       </div>
                               </div>
                        </div>
                        {/* </Link>        */}
               </div>
                }):""}

                {notJoinPageList.length > 0 ? notJoinPageList.map((item:any,index:any)=>{
                        return    <div key={index}  className="com_channel_1" >
                                <Link  to={"/Channel?channelId="+item.id}  >
                                    <img src={item.headerImgArr[0]}  className="com_channel_img"   alt="" />
                                </Link>
                                <div className="com_channel_0"  >
                                <div  className="com_live_1_1" >
                                         <Link  to={"/Channel?channelId="+item.id}  >
                                         <div className="com_live_1_1_name" >{item.name}</div>
                                         </Link>
                                         <Chnannel_jion  item={item} handleClick={()=>{
                                                getNotJoinPageList(keyWorld);
                                                getMyJoinPageList(keyWorld);
                                                }} isImage={true}  ></Chnannel_jion>

                                </div>
                                <Link  to={"/Channel?channelId="+item.id}  >
                                        <div  className="com_live_1_2" >
                                                <div>
                                                <img src={require("../../assets/v2/person@2x.png")}  className="com_channel_member_img"   alt="" />
                                                        {item.memberNum ? item.memberNum:0}
                                                </div>
                                                <div style={{marginLeft:"20px"}} >
                                                <img src={require("../../assets/v2/talk@2x.png")}  className="com_channel_member_img"   alt="" />
                                                {item.commentNum ? item.commentNum:0}
                                                </div>
                                                <div style={{marginLeft:"10px"}}  className="com_channel_plateName"  >
                                                {item.plateName}
                                                </div>
                                        </div>
                                </Link>
                                </div>
               </div>
                }):""}

        </div>

        </div>

        <div  className="com_trending" >
                 <div  className="com_trending_box" >
                   <div className="com_w100_flex"  style={{flexWrap:"wrap"}}   >
                        <div className="com_trending_tile" >
                                <div className="com_title" >Community Updates</div>
                                <div>
                                <img src={require("../../assets/v2/filter.png")}  className="com_filter"   alt="" />
                                Filters
                                </div>
                        </div>
                        <div  className="com_trending_list" >
                        {forumList.map((item,index)=>{
			return  <div key={index}  className="dis_list_item" style={{width:"100%"}}  >
						<div  onClick={()=>{
                                                        navigate("/Forumlist?forumId="+item.id);
                                                }}  className="com_list_name"  >
							{item.title}
						</div>
						<div  className="disflex" style={{marginLeft:"0.5208vw",alignItems:"center"}} >
                            <UserHeader member={item.memberEntity}  ></UserHeader>
                            <div style={{float:"left"}}>{item.memberName ? item.memberName:""}</div>
                            <div  style={{display:"flex",paddingLeft:10}} >
                                {getTags(item)}
                                <div  className="dis_time" >
                                    <Formatter dateTime={item.createDateTime} ></Formatter>
                                </div>
                            </div>
						</div>
                        <Link  to={"/Forumlist?forumId="+item.id}  >
                                <div  className="dis_list_content"  dangerouslySetInnerHTML={{__html:item.content}}  style={{width:"100%"}} >

                                </div>
                                {
                                (item.imgUrl &&	 item.imgUrl.length) > 0 ?  <div   className="dis_list_content"  style={{display:"flex",width:"100%"}} >
                                       <ImageList item={item.imgUrl} ></ImageList>
                                </div>: <div></div>
                                }
                       </Link>
                
                       {
			item.url? <div>
				<Iframe url={getUrl(item.url)}
					width="640px"
					height="320px"
					id=""
					className=""
					display="block"
					position="relative"/>
			                        </div>: (item.imgArr &&	 item.imgArr.length) > 0 ?  <div   className="dis_list_content"  style={{display:"flex",width:"100%"}} >
							   <ImageList item={item.imgUrl} ></ImageList>
					</div>: <div></div>
			}



					<div className="disflex"   style={{width:"100%",marginTop:20}}  >
						<div   className="disflex" style={{width:"50%",alignItems:"center"}}  >
							<div  className="options_box" >
									<div  className="options_box_flex options_box_flex_border"  >
										{item.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{forumAttention(item,index)}} className="dis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,index,3)}} className="dis_list_img"  alt="" />}
									<div className="options_box_num" >{item.attentionNum}</div>
									</div>
									<Link  className="options_box_flex options_box_flex_border"  to={"/Forumlist?forumId="+item.id}  >
										<img src={require("../../assets/v2/feed@2x.png")}  className="dis_list_img"   alt="" />
										<div className="options_box_num" >{item.commentNum}</div>
									</Link>
									<div  className="options_box_flex options_box_flex_border"  >
									  {item.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{forumCollect(item,index)}}  className="dis_list_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect(item,index,3)}}  className="dis_list_img"   alt="" />}
									</div>
									<div  className="options_box_flex" >
									<Share   sharedata={item}  type={'dic'} ></Share>
									</div>
							</div>
						</div>
					</div>



			</div>
		        })}
                        </div>
                   </div>
                 </div>

                 <div  className="com_trending_news" >
                     <Ctrending_star  title="Top Community News"    paddingTop_={"0vw"}  paddingLeft_={"0vw"} isCreate={false} ></Ctrending_star>
                </div>
        </div>
    </div>



  let  Mobile=<div>
        <div  className="mcom_top_box" >
        </div>
        <div className="mcom_but_live_box" >
                <div  className="mcom_live" >
                        Live
                </div>
                <div className="mcom_view" onClick={()=>{ navigate("/LivePage")}}>
                        View wall
                </div>
        </div>

        <div>
                {liveList.length > 0 ? liveList.map((item:any,index:any)=>{
                return  <div  key={index} className="mcom_live_box"   onClick={()=>{ navigate("/ChLive?liveId="+item.id)}}  >
                                <img src={JSON.parse(item.image)[0]['url']}  className="mcom_live_img"   alt="" />
                <div  className="mcom_live_box_name">
                        <img src={item && item.channelEntity &&  item.channelEntity.headerImgArr ? item.channelEntity.headerImgArr[0]:require("../../assets/v2/member@2x.png")} className="mcom_live_header"   alt="" />
                        <div  className="mcom_live_0" >
                                <div className="mcom_live_1_1" >{item.name}</div>
                                <div  className="mcom_live_1_2" >
                                        <div  >{item && item.channelEntity ? item.channelEntity.plateName:""}</div>
                                </div>
                        </div>
                </div>
                </div>
        }):""}

        </div>

        <div  className="mcom_but_live_box" style={{marginTop:20}}  >
                <div  className="mcom_but_live_box_but" >
                        view all
                </div>
        </div>

        <div className="mcom_but_live_box"  style={{marginTop:20}} >
                <div  className="mcom_live" >
                Channel
                </div>
                <div className="mcom_view"   onClick={()=>{ navigate("/ChannelPage")}} >
                        View wall
                </div>
                
        </div>
        <MMChannel hiddenbanner={true} ></MMChannel>
        <div className="mcom_but_live_box" style={{marginBottom:20,marginTop:20,justifyContent:"flex-start"}} >
                <div  className="mcom_live" >
                        Native
                </div>
        </div>

        <div className="mdiscover_list_flex" >
       
        {forumList.length > 0 ?
	        <CMDiscover_list  forumList={forumList}  handleClick={getPageList}  ></CMDiscover_list>:<div></div>
	}
                </div>  
        
  </div>

return Common.isMobile ? Mobile : Web
}
