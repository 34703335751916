import { useEffect, useState } from "react";
import "./SendForum.css";
import { Input,message,Select,Upload ,SelectProps,} from 'antd';
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { Link, useNavigate,useLocation  } from 'react-router-dom'
import { Quill_Input } from './Quill_Input';
import { State, useAppDispatch, useAppSelector } from "../../app/Store";

type IProps = {
    handleClick: () => void,
	forumInfo?:any
}

export function UpdateForum(props:IProps) {
	const { tagsList} = useAppSelector((state: State) => state.tags)
	const navigate = useNavigate();
	const { TextArea } = Input;
	const [forumInfo,setForumInfo] =  useState({} as any);
	const [fileList, setFileList] = useState([] as Array<any>);
	const handleChange = (value: string | string[]) => {
		forumInfo.tages=value;
		setForumInfo({...forumInfo})
	  };

	useEffect(()=>{
			let cpforumInfo={...props.forumInfo};
			if(cpforumInfo.tages){
				cpforumInfo.tages=cpforumInfo.tages.split(",");
			}
			if(cpforumInfo.imgArr){
				let fileList=[];
				for (let index = 0; index < cpforumInfo.imgArr.length; index++) {
					const url = cpforumInfo.imgArr[index];
					fileList.push({
						uid: index,
						name: 'image.png',
						status: 'done',
						url: url
					  })
				}
				setFileList([...fileList])
				cpforumInfo.imgUrl=JSON.parse(cpforumInfo.imgUrl);
			}
			setForumInfo({...cpforumInfo})
	},[]);

	function onChange(info:any){
		setFileList([...info.fileList]);
		 if (info.file.status === 'done' || info.file.status === 'removed' ) {
			forumInfo.imgUrl=[]
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				forumInfo.imgUrl.push({name:item.name,url:item.response?item.response.url:item.url});
			}
			console.log(forumInfo.imgUrl)
			setForumInfo({...forumInfo})
		  }
	}

	function onRemove(info:any){
		// if(info.status == '"removed"' ||info.status === 'done'){
		// 	forumInfo.imgUrl=forumInfo.imgUrl.filter((element:any)=>{
		// 		return element.url != info.url;
		// 	})
		// 	setForumInfo({...forumInfo})
		// }
	}

	function postSend(){
	  localStorage.setItem("pageFrom","/#/Forumlist?forumId="+forumInfo.id);
	  let 	cyforumInfo = {...forumInfo};
	  if(cyforumInfo.imgUrl){
		cyforumInfo.imgUrl=JSON.stringify(cyforumInfo.imgUrl);
	  }
	   if(cyforumInfo.tages&& cyforumInfo.tages.length>0){
		cyforumInfo.tages=cyforumInfo.tages.toString();
	   }
		httpPost("/forum/addForum",{...cyforumInfo}).then((e:any)=>{
			if(e.data.code == 0){
				message.success("publish post successful");
				props.handleClick();
			}else if(e.data.code == 401){
				navigate("/SignIn");
				localStorage.setItem("pageFrom","/#/Forumlist?forumId="+forumInfo.id);
			}
		})
	}
	

	  async function onPreview  (file: UploadFile) {
		let src = file.url as string;
		if (!src) {
		  src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj as RcFile);
			reader.onload = () => resolve(reader.result as string);
		  });
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	  };

	  function getMember(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 401 ){
				navigate("/SignIn");
				localStorage.setItem("pageFrom","/#/Forumlist?forumId="+forumInfo.id);
			}
		})
	}

	return <div className="SendForum_body"  onClick={getMember}>
			<div  className="SendForum_input" >
			<Input placeholder="Put a title for your post" 			
			     value={forumInfo.title}
				 onChange={(e)=>{
					forumInfo.title=e.target.value;
					setForumInfo({...forumInfo});
				}}
				bordered={false} style={{width:"40.1042vw",height: "2.3438vw"}} />
		</div>
		 <div   className="SendForum_content" style={{height:"10vw"}}  >
		 <Quill_Input  w={"46.7917vw"} value={forumInfo.content} handleClick={(e=>{
				forumInfo.content=e;
				setForumInfo ({...forumInfo});
		   })} ></Quill_Input>
			{/* <TextArea placeholder="Your forum content " 
			value={forumInfo.content}
			onChange={(e)=>{
			forumInfo.content=e.target.value;
			setForumInfo ({...forumInfo});
			}}
			style={{width:"47.7917vw",height:"5.7292vw"}}
			bordered={false}  /> */}
		</div>

		<div   className="SendForum_img"   >
			<Upload
				action={host_url+"/api/app/oss/uploadImg"}
				listType="picture-card"
				fileList={fileList}
				onRemove={onRemove}
				onChange={onChange}
				onPreview={onPreview}
			>
				{fileList.length < 3 && '+ Upload'}
			</Upload>
			</div>
		

			<div   className="SendForum_tags"  style={{marginTop:10}}  >
			<div>YouTube URL</div>
			<Input
			placeholder="Please YouTube URL"
			onChange={(e) => {
				forumInfo.url = e.target.value;
				setForumInfo({ ...forumInfo });
			}}
			value={forumInfo.url}
			style={{ width: '300px',marginLeft:"20px" }}
		/>
		</div>
		

		
			<div   className="SendForum_tags"  style={{marginTop:10}}  >
			<div>Tags</div>
			<Select
			mode="tags"
			placeholder="Please select tags"
			value={forumInfo.tages}
			onChange={handleChange}
			bordered={false}
			style={{ width: '300px',marginLeft:"20px" }}
			options={tagsList}
		/>
		</div> 
	

		<div   className="SendForum_submit  but-shadow"   >
			<div className="SendForum_post"  onClick={postSend} >Post</div>
		</div>
	</div>

}
