import {BasePage, page} from "./BasePage";
import "./Publishmint.css";
import { CPublishChannel} from '../components/CPublishChannel';
import { Navigation } from "../components/Navigation";

@page("PublishChannel")
export class PublishChannel extends BasePage {
	protected webContent() {

		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
		<CPublishChannel></CPublishChannel>
	</div>
	}

	protected mobileContent(): JSX.Element {
		return <div></div>
	}
}
