import {BasePage, page} from "./BasePage";
import "./News.css"
import "../css/common.css";
import { CTidings} from '../components/CNews';
import { Navigation } from "../components/Navigation";

@page("News")
export class News extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
	
			<CTidings></CTidings>
		</div>
	}

	protected mobileContent(): JSX.Element {
		return <div>
			

		</div>;
	}
}
