import { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { httpGet, httpPost, host_url } from '../../utils/httpUtils'
import { Input, Dropdown, Popover, message, Tabs,Image,Modal } from 'antd'
import { My_post } from './My_post'
import Item from 'antd/lib/list/Item'
import { Share } from './Share'
import { Formatter } from './Formatter'
import { Common } from '../../app/Common'
import { Popup } from 'antd-mobile'
import copy from 'copy-to-clipboard';
import {
  CheckOutline,
  AddOutline,
  MessageOutline,
  EyeFill,
} from 'antd-mobile-icons'
export function CPerson_show() {
  const [tabs, setTabs] = useState([
    'Thoughts',
    'Donation',
    'Channel',
  ] as Array<any>)
  const navigate = useNavigate()
 
  let query = new URLSearchParams(useLocation().search);
	let  memnberId =query.get("ref");

  useEffect(() => {
    getMmber()
    listByMemberId()
    getMyDonateList()
    getmyForum()
    getMyJoinPageList()
  }, [memnberId])
  const [memberVo, setMemberVo] = useState({} as any)
  function getMmber() {
    httpGet('/member/getMemberVo/' + memnberId).then((e: any) => {
      if (e.data.code == 0) {
        setMemberVo(e.data.data)
      }
    })
  }

  const [forumList, setForumList] = useState([] as Array<any>)
  const [myMemberNft, setMyMemberNft] = useState({} as any)
  const [myDonateList, setMyDonateList] = useState({} as any)
  const [myJoinPageList, setMyJoinPageList] = useState([] as any)

  function getMyJoinPageList() {
    httpPost('/channel/getJoinPageList', { memberId: memnberId }).then(
      (e: any) => {
        if (e.data.code == 0) {
          setMyJoinPageList(e.data.data.list)
        }
      }
    )
  }

  function listByMemberId() {
    httpGet('/memberNft/listByMemberId/' + memnberId).then((e: any) => {
      if (e.data.code == 0) {
        setMyMemberNft(e.data.data)
      }
    })
  }

  function getmyForum() {
    httpGet('/forum/memberForum/' + memnberId).then((e: any) => {
      if (e.data.code == 0) {
        setForumList(e.data.data)
      }
    })
  }

  function memberAttention() {
    httpGet('/attention/memberAttention/' + memnberId).then((e: any) => {
      memberVo.isFollow = 1
      setMemberVo({ ...memberVo })
      if (e.data.code == 0) {
        message.success('follow member successful.')
      }else if (e.data.code == 401) {
          let path= window.location.pathname + window.location.hash;
          localStorage.setItem('pageFrom', path)
          navigate('/SignIn')
      }
    })
  }

  function cancelAttention2(item: any) {
    httpGet('/attention/cancelAttention/' + item.id + '/2').then((e: any) => {
      memberVo.isFollow = 0
      setMemberVo({ ...memberVo })
      if (e.data.code == 0) {
        message.success('unfollow member successful.')
      }
    })
  }

  function addMsg() {
    httpGet('/privateletter/create/' + memnberId).then((e: any) => {
      if (e.data.code == 0) {
        navigate('/chat?privateLetterId=' + e.data.data)
      }
    })
  }

  function getMyDonateList() {
    httpGet('/donaterecord/myDonateGroup/' + memnberId).then((e: any) => {
      if (e.data.code == 0) {
        setMyDonateList(e.data.data)
      }
    })
  }

  function getMinStep(donateNum: any, fundRaisingObjectives: any) {
    let max = 9.375
    let width = (max / fundRaisingObjectives) * donateNum
    return (
      <div
        className="person_step_blue"
        style={{ width: (width > max ? max : width) + 'vm' }}></div>
    )
  }

  function forumAttention(item: any, index: any) {
    httpGet('/attention/forumAttention/' + item.id).then((e: any) => {
      if (e.data.code == 0) {
        forumList[index].isAttentin = 1
        setForumList([...forumList])
      }
    })
  }

  function forumCollect(item: any, index: any) {
    httpGet('/collect/forumCollect/' + item.id).then((e: any) => {
      if (e.data.code == 0) {
        forumList[index].isCollect = 1
        setForumList([...forumList])
      }
    })
  }

  function cancelCollect(item: any, index: any) {
    httpGet('/collect/cancelCollect/' + item.id + '/3').then((e: any) => {
      if (e.data.code == 0) {
        forumList[index].isCollect = 0
        setForumList([...forumList])
      }
    })
  }

  function cancelAttention3(item: any, index: any) {
    httpGet('/attention/cancelAttention/' + item.id + '/3').then((e: any) => {
      if (e.data.code == 0) {
        forumList[index].isAttentin = 0
        setForumList([...forumList])
      }
    })
  }

  function getTags(item: any) {
    let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}
    let tags = item.tages
    let tagshtml = <div></div>
    if (tags) {
      tags = tags.split(',')
      tagshtml = (
        <div className="forum_flex_one">
          {tags.map((ite: any, inde: any) => {
            let color = ''
            if (ite) {
              color = color_conf[ite.substring(0, 1).toLowerCase()]
                ? color_conf[ite.substring(0, 1).toLowerCase()]
                : '#FFB4A2'
            }
            return (
              <div
                style={{ backgroundColor: color }}
                key={inde}
                className="forum_but">
                {ite}
              </div>
            )
          })}
        </div>
      )
    }
    return tagshtml
  }

  const [tabIndex, setTabIndex] = useState(1)
  const switchTab = (e: any) => {
    setTabIndex(Number(e.target.dataset.index))
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

  let Web = (
    <div className="person_body">
      <div className="person_menu">
        <div className="person_logo_img_div">
          <Image
            src={
              memberVo.headImg
                ? memberVo.headImg
                : require('../../assets/v2/logo.png')
            }
            style={{ width: '6.5625vw', height: '6.5625vw' }}
            alt=""
          />
        </div>
        <div className="person_show_name">
          <div>{memberVo.userName}</div>
        </div>
        <div className="person_show_name">
          {/* <div className="pserson_follow" onClick={memberAttention}  >+ Follow</div> */}
          {!memberVo.isFollow ? (
            <div
              onClick={() => {
                memberAttention()
              }}
              className="person_people_follow but-shadow">
              √ Follow
            </div>
          ) : (
            ''
          )}
          {memberVo.isFollow == 1 ? (
            <div
              onClick={() => {
                cancelAttention2(memberVo)
              }}
              className="person_people_Unfollow but-shadow">
              Unfollow
            </div>
          ) : (
            ''
          )}
          
          <div  className='persion_share_but  but-shadow'  onClick={()=>{
                  setIsModalOpen(true)
            }} >
                  <img
                  src={require('../../assets/impactopia/share-b@2x.png')}
                  style={{ width: '15px', height: '15px' }}
                />
                  <span  style={{marginLeft:"15px"}} >Share</span> 
            </div>

          <div
            onClick={addMsg}
            style={{ marginLeft: '10px' }}
            className="pserson_send_msg but-shadow">
            <img
              src={require('../../assets/v2/chat.png')}
              alt=""
              className="p_u_add_img"
            />
          </div>
        </div>
        <div className="person_bio">{memberVo.bio}</div>
      </div>

      <Modal closable={false}  width={640} bodyStyle={{height:"320px",borderRadius:"20px",backgroundColor: "transparent"}} footer={null} onOk={handleOk} onCancel={handleCancel} open={isModalOpen} >
            <div  className='share_modal'  >
                  <div  style={{width:"100%",display:"flex",justifyContent:"center"}} >
                      <img
                          src={require('../../assets/v2/share-g@2x.png')}
                          alt=""
                        style={{width:"48px",height:"48px"}}
                        />
                  </div>
                  <div style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"20px"}}  >
                      You are about to share the following link
                  </div>
                    <div style={{color:"#279D8E",width:"100%",display:"flex",justifyContent:"center",marginTop:"10px"}}  > 
                        {host_url+"/#/Person_show?ref="+memberVo.id}
                    </div>

                   <div  className='share_but' onClick={()=>{
                       setIsModalOpen(false)
                        copy(host_url+"/#/Person_show?ref="+memberVo.id);
                        message.success("share link  url copy success")
                     }}  > 
                         Share
                    </div>

            </div>
		  </Modal> 

      <div className="person_content">
        <div className="person_nft_badge" style={{ marginTop: 20 }}>
          <div className="person_nft_badge_name">My NFTbadge</div>
          <div className="person_nft_box">
            {myMemberNft.length > 0 ? (
              myMemberNft.map((item: any, inx: any) => {
                return (
                  <img
                     key={inx}
                    src={JSON.parse(item.image)[0].url}
                    className="person_nft_badge_img"
                    alt=""
                  />
                )
              })
            ) : (
              <div></div>
            )}
          </div>
        </div>

        <div className="person_donation" style={{ marginTop: 30 }}>
          <Tabs>
            <Tabs.TabPane tab="Thoughts" key="1">
              {forumList.length > 0 ? (
                forumList.map((item: any, idx: any) => {
                  return (
                    <div key={idx} className="dis_list_item">
                      <div className="dis_list_name">{item.title}</div>
                      <div
                        className="disflex"
                        style={{
                          width: '100%',
                          marginLeft: '10px',
                          alignItems: 'center',
                          display:"flex"
                        }}>
                        <img
                          src={
                            item.memberHeadImg
                              ? item.memberHeadImg
                              : require('../../assets/v2/logo.png')
                          }
                          style={{
                            width: '2.1875vw',
                            height: '2.1875vw',
                            borderRadius: '100%',
                            marginRight: '10px',
                          }}
                          alt=""
                        />
                        <div>{item.memberName ? item.memberName : ''}</div>
                        <div style={{ display: 'flex', marginLeft: 20 }}>
                          {getTags(item)}
                          <div className="dis_time">
                            <Formatter
                              dateTime={item.createDateTime}></Formatter>
                          </div>
                        </div>
                      </div>
                      <div
                        className="dis_list_content"
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}></div>

                      <div
                        className="disflex"
                        style={{ width: '50.7813vw', marginTop: 20 }}>
                        <div
                          className="disflex"
                          style={{ width: '50%', alignItems: 'center' }}>
                          <div className="dis_sc">
                            <div>
                              {item.isAttentin == 0 ? (
                                <img
                                  src={require('../../assets/v2/zan@2x.png')}
                                  onClick={() => {
                                    forumAttention(item, idx)
                                  }}
                                  className="dis_list_img"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={require('../../assets/v2/zan_2.png')}
                                  onClick={() => {
                                    cancelAttention3(item, idx)
                                  }}
                                  className="dis_list_img"
                                  alt=""
                                />
                              )}
                              {item.attentionNum}
                            </div>
                            <Link to={'/Forumlist?forumId=' + item.id}>
                              <div style={{ color: '#111111' }}>
                                <img
                                  src={require('../../assets/v2/feed@2x.png')}
                                  className="dis_list_img"
                                  alt=""
                                />
                                {item.commentNum}
                              </div>
                            </Link>
                            <div>
                              {item.isCollect == 0 ? (
                                <img
                                  src={require('../../assets/v2/shoucang@2x.png')}
                                  onClick={() => {
                                    forumCollect(item, idx)
                                  }}
                                  className="dis_list_img"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={require('../../assets/v2/shoucang_2.png')}
                                  onClick={() => {
                                    cancelCollect(item, idx)
                                  }}
                                  className="dis_list_img"
                                  alt=""
                                />
                              )}
                            </div>

                            <div>
                              <Share sharedata={item}></Share>
                            </div>
                          </div>
                        </div>
                        <div
                          className="disflex"
                          style={{ width: '50', marginTop: 20 }}>
                          <div
                            className="disflex"
                            style={{
                              width: '100%',
                              justifyContent: 'flex-end',
                            }}>
                            <Link to={'/Forumlist?forumId=' + item.id}>
                              <div className="dis_Reading">
                                Continue Reading→
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div></div>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Donation" key="2">
              {myDonateList.length > 0 ? (
                myDonateList.map((ite: any, idx: any) => {
                  return (
                    <div key={idx} className="person_reading">
                      <div className="person_reading_text">
                        {ite.projectName}
                      </div>
                      <div
                        style={{ display: 'flex', marginBottom: '2.0833vw' }}>
                        <div
                          style={{
                            width: '80%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          className="person_reading_eth">
                          <div>
                            {ite.donateNum}Eth / {ite.fundRaisingObjectives}Eth
                          </div>
                          <div className="person_step">
                            {getMinStep(
                              ite.donateNum,
                              ite.fundRaisingObjectives
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            width: '20%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}>
                          <Link to={'/Project?projectId=' + ite.projectId}>
                            <div className="person_view_but but-shadow">View all</div>
                          </Link>
                        </div>
                      </div>

                      {ite.donateRecordList.length > 0 ? (
                        ite.donateRecordList.map((item: any, index: any) => {
                          return (
                            <div key={index}>
                              <div className="person_reading_item">
                                <div className="person_reading_item_1">
                                  {index + 1}
                                </div>
                                <img
                                  className="person_reading_item_img"
                                  src={require('../../assets/v2/gold.png')}
                                  alt=""
                                />
                                <div className="person_reading_item_2">
                                  {item.memberName}
                                </div>
                                <div className="person_reading_item_3">
                                  Donated {item.amountEth}.
                                </div>
                                <div className="person_reading_item_4">
                                  <Formatter
                                    dateTime={item.createDateTime}></Formatter>
                                </div>
                                <div className="person_reading_item_5">
                                wallet:{item.senderAddress}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <div></div>
                      )}
                    </div>
                  )
                })
              ) : (
                <div></div>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab="Channel" key="3">
              {myJoinPageList.length > 0
                ? myJoinPageList.map((item: any, idx: any) => {
                    return (
                      <div
                      key={idx}
                        className="my_live"
                        style={{ marginTop: ' 1.0833vw' }}>
                        <div className="my_live_1">
                          <img
                            src={item.headerImgArr[0]}
                            className="my_live_header"
                            alt=""
                          />
                          <div className="my_live_0">
                            <div className="my_live_1_1">
                              <div className="my_live_1_1_name">
                                {item.name}
                              </div>
                              <img
                                src={require('../../assets/v2/member@2x.png')}
                                className="my_live_member"
                                alt=""
                              />
                            </div>
                            <div className="my_live_1_2">
                              <div className="my_flex">
                                <img
                                  src={require('../../assets/v2/renshu@2x.png')}
                                  className="my_live_renshu"
                                  alt=""
                                />
                                {item.memberNum}
                              </div>
                              <div className="my_flex">
                                <img
                                  src={require('../../assets/v2/person@2x.png')}
                                  className="my_live_renshu"
                                  alt=""
                                />
                                {item.commentNum}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                : ''}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
  let Mobile = (
    <div>
      <div className="personHead">
        <div className="personHeadTop">
          <img
            className="personHeadImg"
            src={
              memberVo.headImg
                ? memberVo.headImg
                : require('../../assets/v2/logo.png')
            }
          />

          <div className="personHeadName">{memberVo.userName}</div>
          <div>
            <div style={{display:"flex"}} >
            {!memberVo.isFollow ? (
              <div
                className="personHeadISFollowNo"
                onClick={() => {
                  memberAttention()
                }}>
                <AddOutline />
                Follow
              </div>
            ) : (
              ''
            )}
            {memberVo.isFollow == 1 ? (
              <div
                className="personHeadISFollowOK"
                onClick={() => {
                  cancelAttention2(memberVo)
                }}>
                Unfollow
              </div>
            ) : (
              ''
            )}

            
          <div  className='mpersion_share_but'  onClick={()=>{
                  setIsModalOpen(true)
            }} >
                  <img
                  src={require('../../assets/v2/share2@2x.png')}
                  style={{ width: '15px', height: '15px' }}
                />
                  <span  style={{marginLeft:"5px"}} >Share</span> 
            </div>




            </div>
          
            <div className="personHeadMessage" onClick={addMsg}>
              <MessageOutline color="#279D8E" />
            </div>
          </div>
        </div>

        <div className="personHeadContent">
          <div className="personHeadContentItem">{memberVo.bio}</div>
        </div>
      </div>



      <Popup  bodyClassName="mproject_donate_popup2"  style={{height:""}}  visible={isModalOpen} position='bottom' onMaskClick={() => {setIsModalOpen(false)}} bodyStyle={{padding:"20px" }}>
            <div  className='share_modal'  >
                  <div  style={{width:"100%",display:"flex",justifyContent:"center"}} >
                      <img
                          src={require('../../assets/v2/share-g@2x.png')}
                          alt=""
                        style={{width:"48px",height:"48px"}}
                        />
                  </div>
                  <div style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"20px"}}  >
                      You are about to share the following link
                  </div>
                    <div style={{color:"#279D8E",width:"100%",display:"flex",justifyContent:"center",marginTop:"10px"}}  > 
                        {host_url+"/#/Person_show?ref="+memberVo.id}
                    </div>
                   <div  className='share_but' onClick={()=>{
                       setIsModalOpen(false)
                        copy(host_url+"/#/Person_show?ref="+memberVo.id);
                        message.success("share link  url copy success")
                     }}  > 
                         Share
                    </div>
            </div>
			</Popup>







      {/* tabs */}
      <div className="personTabs" onClick={(e) => switchTab(e)}>
        <div
          className={tabIndex === 1 ? 'personTabsItemOK' : 'personTabsItem'}
          data-index="1">
          Thoughts
        </div>
        <div
          className={tabIndex === 2 ? 'personTabsItemOK' : 'personTabsItem'}
          data-index="2">
          Donation
        </div>
        <div
          className={tabIndex === 3 ? 'personTabsItemOK' : 'personTabsItem'}
          data-index="3">
          Channel
        </div>
        <div
          className={tabIndex === 4 ? 'personTabsItemOK' : 'personTabsItem'}
          data-index="4">
          Badges
        </div>
      </div>
      {tabIndex === 1 ? (
        <div className="Thoughts">
          {forumList.length > 0
            ? forumList.map((item: any, idx: any) => {
                return (
                  <div className="ThoughtsItem" key={idx}>
                    <div className="ThoughtsItemTitle">{item.title}</div>
                    <div className="ThoughtsItemH">
                      <div className="ThoughtsItemHLeft">
                        <img
                          src={
                            item.memberHeadImg
                              ? item.memberHeadImg
                              : require('../../assets/v2/logo.png')
                          }
                          className="ThoughtsItemHLeft"
                        />
                      </div>
                      <div className="ThoughtsItemHName">
                        {item.memberName ? item.memberName : ''}
                      </div>
                      <div className="ThoughtsItemHDate">
                        <Formatter dateTime={item.createDateTime}></Formatter>
                      </div>
                      {/* <div className="ThoughtsItemHLook">
                    <EyeFill />
                    2066
                  </div> */}
                    </div>
                    <div
                      className="ThoughtsItemContent"
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}></div>
                    <div className="ThoughtsItemtool">
                      <div>
                        <img
                          className="ThoughtsItemS"
                          src={require('../../assets/mobile/xin.png')}
                        />
                        <span style={{ marginLeft: '4px' }}>
                          {item.attentionNum ? item.attentionNum : 0}
                        </span>
                      </div>
                      <Link to={'/Forumlist?forumId=' + item.id}>
                        <div>
                          <img
                            className="ThoughtsItemS"
                            src={require('../../assets/mobile/xinxi.png')}
                          />
                          <span style={{ marginLeft: '4px', color: '#000' }}>
                            {' '}
                            {item.commentNum ? item.commentNum : 0}
                          </span>
                        </div>{' '}
                      </Link>

                      <img
                        className="ThoughtsItemS"
                        onClick={() => {
                          cancelCollect(item, idx)
                        }}
                        src={require('../../assets/mobile/shouc.png')}
                      />
                      <Share sharedata={item}></Share>
                    </div>
                  </div>
                )
              })
            : ''}
        </div>
      ) : (
        ''
      )}
      {tabIndex === 2 ? (
        <div className="Donation">
          {myDonateList.length > 0
            ? myDonateList.map((ite: any, index: number) => {
                return (
                  <div className="DonationItem" key={index}>
                    <div className="DonationItemViewAll">
                      <div className="DonationItemViewAllName">
                        <div className="DonationItemViewAllNameT">
                          {ite.projectName}
                        </div>
                        <div className="DonationItemViewAllNameE">
                          {ite.donateNum}Eth / {ite.fundRaisingObjectives}Eth
                        </div>
                      </div>
                      <Link to={'/Project?projectId=' + ite.projectId}>
                        <div className="DonationItemViewAllBut">View all</div>
                      </Link>
                    </div>
                    <div className="DonationDontedList">
                      {ite.donateRecordList.length > 0
                        ? ite.donateRecordList.map((item: any, index: any) => {
                            return (
                              <div  key={index} className="DonationDontedListitem">
                                <div className="DonationDontedListitemIndex">
                                  {index + 1}
                                </div>
                                <div className="DonationItemDate">
                                  <div className="DonationDontedListitemIndexN">
                                    Donated{' '}
                                    <span className="DonationDontedListitemIndexNNum">
                                      {item.amountEth}
                                    </span>{' '}
                                    ETH{' '}
                                  </div>
                                  <div className="DonationItemDateT">
                                    <Formatter
                                      dateTime={
                                        item.createDateTime
                                      }></Formatter>
                                    <div className="wallect">
                                      wallect:{item.senderAddress}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        : ''}
                    </div>
                  </div>
                )
              })
            : ''}
        </div>
      ) : (
        ''
      )}
      {tabIndex === 3 ? (
        <div className="Channel">
          {myJoinPageList.length > 0
            ? myJoinPageList.map((item: any, idx: any) => {
                return (
                  <div key={idx} className="ChannelItem">
                    <img
                      src={item.headerImgArr[0]}
                      className="ChannelItemImg"
                      alt=""
                    />
                    <div className="ChannelContent">
                      <div className="ChannelContentname">
                        <div className="ChannelContentnameT">{item.name}</div>
                        <img
                          src={require('../../assets/v2/member@2x.png')}
                          className="ChannelContentnameTM"
                          alt=""
                        />
                      </div>
                      <div className="ChannelContentT">
                        <div className="ChannelContentTMy">
                          <img
                            src={require('../../assets/v2/renshu@2x.png')}
                            className="memberNumImg"
                            alt=""
                          />
                          {item.memberNum}
                        </div>
                        <div className="ChannelContentTM">
                          <img
                            src={require('../../assets/v2/person@2x.png')}
                            className="memberNumImg"
                            alt=""
                          />
                          {item.commentNum}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            : ''}
        </div>
      ) : (
        ''
      )}

      {tabIndex === 4 ? (
        <div className="Badges">
           {myMemberNft.length > 0 ? (
              myMemberNft.map((item: any, inx: any) => {
                return (
                  <div className="mperson_nft_item1" key={inx} >
                  <img
                    src={JSON.parse(item.image)[0].url}
                    className="mperson_nft_item_img"
                    alt=""
                  />
                  <div className="mperson_nft_name" >
                    {item.name}
                  </div>

                  <div className="mperson_nft_description" >
                    {item.description}
                  </div>
                </div>

                )})):("")
            }
        </div>
      ):("")}

    </div>
  )
  return Common.isMobile ? Mobile : Web
}
