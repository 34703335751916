import {BasePage, page} from "./BasePage";
import "../css/common.css"
import { MChannel } from "../components/MChannel";
@page("Channel")
export class Channel extends BasePage {



	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}} >
 					<MChannel></MChannel>
		</div>
	}

	protected mobileContent(): JSX.Element {
		return  <MChannel></MChannel>
	}
}
