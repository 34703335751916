import { useEffect, useState } from "react";
import { Input,Dropdown} from 'antd';
import "./User_but.css";
import { Link ,useLocation,useNavigate} from 'react-router-dom'
import type { MenuProps } from 'antd';
import { httpGet, httpPost } from '../../utils/httpUtils';
import { Project } from '../pages/Project';
import { Common } from "../../app/Common";
import { web3 } from '../../app/Config';


export function User_but() {
	const navigate = useNavigate();
	const [memberData,setMember] =  useState({}as any);
	
	function getMember(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 0 ){
				setMember(e.data.data);
			}
		})
	}
	useEffect(()=>{
		getMember();
	},[]);
	const items = [
		{
		  key: '1',
		  label: (
			<Link  to={'/Person'}   state={{ type: "PersonalCenter"}} >
				<div className="user_menu" >
					<img src={require("../../assets/v2/PersonalCenter.png")}  className="user_menu_img" alt="" />
					<div className="user_menu_name">Personal Center</div>
				</div>
			</Link>
		  ),
		},
		{
		  key: '2',
		  label: (
			<Link  to={'/Person'}   state={{ type: "Orgs"}}   >
				<div className="user_menu" >
					<img src={require("../../assets/v2/shoucang@2x.png")}  className="user_menu_img" alt="" />
					<div className="user_menu_name">My Bookmarks</div>
				</div>
			</Link>
		  ),
		},
		{
		  key: '3',
		  label: (
			<Link  to={'/Person'}  state={{ type: "People"}}   >
				<div className="user_menu" >
					<img src={require("../../assets/v2/People.png")}  className="user_menu_img" alt="" />
					<div className="user_menu_name">People</div>
				</div>
			</Link>
		  ),
		},
		{
			key: '4',
			label: (
			  <Link  to={'/Person'}   state={{ type: "NFTbadge"}}  >
				  <div className="user_menu" >
					  <img src={require("../../assets/v2/myNft.png")}  className="user_menu_img" alt="" />
					  <div className="user_menu_name">My NFT Badge</div>
				  </div>
			  </Link>
			),
		  },
		  {
			key: '5',
			label: (
			  <Link  to={'/Person'}   state={{ type: "Donation"}}  >
				  <div className="user_menu" >
					  <img src={require("../../assets/v2/MyDonation.png")}  className="user_menu_img" alt="" />
					  <div className="user_menu_name">My Donation</div>
				  </div>
			  </Link>
			),
		  },
		  {
			key: '6',
			label: (
			  <Link  to={'/Person'}  state={{ type: "Post"}}   >
				  <div className="user_menu" >
					  <img src={require("../../assets/v2/my_post.png")}  className="user_menu_img" alt="" />
					  <div className="user_menu_name">My Post</div>
				  </div>
			  </Link>
			),
		  },
		  {
			key: '9',
			label: (
			  <Link  to={'/Person'}  state={{ type: "Chnanel"}}   >
				  <div className="user_menu" >
					  <img src={require("../../assets/v2/channel.png")}  className="user_menu_img" alt="" />
					  <div className="user_menu_name">My Channel</div>
				  </div>
			  </Link>
			),
		  },
		  {
			key: '7',
			label: (
			  <Link  to={'/Publishmint'} >
				  <div className="user_menu"    >
					  <img src={require("../../assets/v2/PublishMint.png")}  className="user_menu_img" alt="" />
					  <div className="user_menu_name">Publish  Project</div>
				  </div>
			  </Link>
			),
		  },
		  {
			key: '8',
			label: (
				  <div className="user_menu" onClick={()=>{
						localStorage.removeItem("token");
						localStorage.removeItem("member")
						navigate("/SignIn")
				  }} >
					  <img src={require("../../assets/v2/SignOut.png")}  className="user_menu_img" alt="" />
					  <div className="user_menu_name">Sign Out</div>
				  </div>

			),
		  },
	  ];

	  const items_push = [
		{
		  key: '1',
		  label: (
			<Link  to={'/Publishmint'}   state={{ type: "PersonalCenter"}} >
				<div className="user_menu" >
					<div className="user_menu_name">Publish Project</div>
				</div>
			</Link>
		  ),
		},
		{
		  key: '2',
		  label: (
			<Link  to={'/PublishChannel'}   state={{ type: "Orgs"}}   >
				<div className="user_menu" >
					<div className="user_menu_name">Publish Channel</div>
				</div>
			</Link>
		  ),
		}
	  ];

  let  Web=	<div className="user_body" >
				 {memberData && memberData.userName?
				<div  style={{display:"flex",alignItems:"center"}} >
					<Dropdown
					menu={{
					items,
					}}
					placement="bottomLeft"
					arrow
					>
					{/* <div  className="user_not_sign" >{memberData.userName}</div>  */}
					<img src={memberData.headImg?memberData.headImg:require("../../assets/v2/logo.png")}  style={{marginLeft:"0.5208vw",width:"1.875vw",height:"1.875vw",borderRadius:"100%"}}   alt="" />
					</Dropdown>
					<Dropdown
					menu={{
						items:items_push,
					}}
					placement="bottomLeft"
					arrow
					>
						<div className="pushMint" > Publish </div>
					</Dropdown>
				</div>
		:
		 <Link to={'/SignIn'} >
			<div className="user_not_sign" >
				Sign In
			</div>
		  </Link>
		 }
	</div>
let  Mobile=<div>
		<div>
		{memberData && memberData.userName?
			<div>

			<Link  to={'/Person'}   state={{ type: "PersonalCenter"}} >
			<div className="Popup_nav-item2" >
				<img src={memberData.headImg?memberData.headImg:require("../../assets/v2/logo.png")}   className="Popup_nav-item-img-discover"   alt="" />
				<div >{memberData.userName}</div>
			</div>
			</Link>

			{/* <Link  to={'/Person'}   state={{ type: "PersonalCenter"}} >
				<div className="Popup_nav-item2" >
					<img src={require("../../assets/v2/PersonalCenter.png")}  className="Popup_nav-item-img" alt="" />
					<div >Personal Center</div>
				</div>
			</Link> */}

			<Link  to={'/Person'}   state={{ type: "Orgs"}}   >
				<div className="Popup_nav-item2" >
					<img src={require("../../assets/v2/shoucang@2x.png")}  className="Popup_nav-item-img" alt="" />
					<div >My Bookmarks</div>
				</div>
			</Link>

			<Link  to={'/Person'}  state={{ type: "People"}}   >
				<div className="Popup_nav-item2" >
					<img src={require("../../assets/v2/People.png")}  className="Popup_nav-item-img" alt="" />
					<div >People</div>
				</div>
			</Link>

			<Link  to={'/Person'}   state={{ type: "NFTbadge"}}  >
				  <div className="Popup_nav-item2" >
					  <img src={require("../../assets/v2/myNft.png")}  className="Popup_nav-item-img" alt="" />
					  <div >My NFT Badge</div>
				  </div>
			  </Link>


			<Link  to={'/Person'}   state={{ type: "Donation"}}  >
				  <div className="Popup_nav-item2" >
					  <img src={require("../../assets/v2/MyDonation.png")}  className="Popup_nav-item-img" alt="" />
					  <div >My Donation</div>
				  </div>
			  </Link>

			  <Link  to={'/Person'}  state={{ type: "Post"}}   >
				  <div className="Popup_nav-item2" >
					  <img src={require("../../assets/v2/my_post.png")} className="Popup_nav-item-img" alt="" />
					  <div >My Post</div>
				  </div>
			  </Link>

			  <Link  to={'/Person'}  state={{ type: "Chnanel"}}   >
				  <div className="Popup_nav-item2" >
					  <img src={require("../../assets/v2/channel.png")}  className="Popup_nav-item-img" alt="" />
					  <div >My Channel</div>
				  </div>
			  </Link>

			  <Link  to={'/Person'} state={{ type: "PublishProject"}} >
				  <div className="Popup_nav-item2"    >
					  <img src={require("../../assets/v2/PublishMint.png")}  className="Popup_nav-item-img" alt="" />
					  <div >Publish Project</div>
				  </div>
			  </Link>

			  <Link  to={'/Person'} state={{ type: "PublishChannel"}} >
				  <div className="Popup_nav-item2"    >
					  <img src={require("../../assets/v2/PublishMint.png")}  className="Popup_nav-item-img" alt="" />
					  <div >Publish Channel</div>
				  </div>
			  </Link>


					<div className="Popup_nav-item2" onClick={()=>{
							localStorage.removeItem("token");
							localStorage.removeItem("member")
							navigate("/SignIn")
					}} >
						<img src={require("../../assets/v2/SignOut.png")}  className="Popup_nav-item-img" alt="" />
						<div >Sign Out</div>
					</div>
			</div>: <div className="Popup_nav-item2" onClick={()=>{
							localStorage.removeItem("token");
							localStorage.removeItem("member")
							navigate("/SignIn")
					}} >
						<img src={require("../../assets/v2/SignOut.png")}  className="Popup_nav-item-img" alt="" />
						<div >Sign In</div>
					</div>}
		</div>
</div>



 return Common.isMobile ? Mobile : Web


}
