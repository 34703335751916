import { State, useAppDispatch, useAppSelector } from "../../app/Store";
import { useEffect, useState } from "react";
import { ChainId, ethereum, web3 } from "../../app/Config";
import { getContract } from "../../app/Contract";
import { Progress, message } from 'antd';
import { Common } from "../../app/Common";

import {
	ConnectSelectors,
	ConnectTask,
	disconnect,
	SwitchTask,
	updateAddress,
	updateBalance,
	updateChainId
} from "../../slices/ConnectSlice";
import "./MyNftList.css";

export function MyNftList() {
	const dispatch = useAppDispatch();
	const isConnected = useAppSelector(ConnectSelectors.isConnected);
	const displayAddress = useAppSelector(ConnectSelectors.displayAddress);
	const { address, chainId } = useAppSelector(ConnectSelectors.userData);

	let [balanceOf8848, setBalanceOf8848] = useState(0);
	let [balanceOf8849, setBalanceOf8849] = useState(0);
	let [balanceOf8850, setBalanceOf8850] = useState(0);


	async function init() {
		if (address) {
			const contract = await getContract();
			let balanceOf8848 = await contract.methods.balanceOf(address, 8848).call();
			setBalanceOf8848(balanceOf8848);
			let balanceOf8849 = await contract.methods.balanceOf(address, 8849).call();
			setBalanceOf8849(balanceOf8849);
			let balanceOf8850 = await contract.methods.balanceOf(address, 8850).call();
			setBalanceOf8850(balanceOf8850);
		}
	}

	function share(imgName: any) {
		let metaArr = [
			'twitter:title', 'Build your profile with impact badge',
			'twitter:description', 'Connect with like-minded people ',
			'twitter:card', 'summary_large_image',
			'twitter:image', 'https://www.impactopia.net/impact-share/img/' + imgName + '.png'
		]
		let metaParams = metaArr.toString()
		metaParams = encodeURIComponent(encodeURIComponent(metaParams))
		window.open(`https://twitter.com/share?text=check out this free nft badge from Impactopia. &url=https://www.impactopia.net/impact/api/share?meta=${metaParams}`)
	}


	async function mint(id: any) {
		if (!isConnected) {
			message.success("PLEASE CONNECT Wallet");
		}
		const contract = await getContract();
		let hasDonatAddress = await contract.methods.hasDonatAddress(address, id).call();
		if (hasDonatAddress) {
			contract.methods.mint(1, id).send({
				from: address
			}).on('error', (error: any) => {
				message.error(error);
			}).on('transactionHash', (txHash: any) => {
				console.warn("transactionHash", txHash)
			}).on('receipt', (receipt: any) => {
				message.success("Success");
			})
		} else {
			message.success("Mint address for donation of appropriate amount, unable to Mint ");
		}
	}

	useEffect(() => {
		init();
	}, [address, dispatch]);


	return <div className="mynft-list"  >
		{balanceOf8848 > 0 ?
			<div >
				<div className="mynft-item">
					<div className="mynft-item-img-gold"></div>
					<div className="mynft-item-img-text" >
						<div className="mynft-item-title">
							Ukraine Donor Badge(Gold)
						</div>
						<div className="mynft-item-text">
							Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
						</div>
						<div className="mynft-item-but" onClick={() => share('cat1')}>
							<div className="mynft-item-img-tw"></div>
							<div className="mynft-item-but-text">
								Share on Twitter
							</div>
						</div>
					</div>
				</div>
				<div className="nft-mbg2"></div>
			</div>
			:
			<div >
				<div className="mynft-item">
					<div className="mynft-item-img-other"></div>
					<div className="mynft-item-img-text" >
						<div className="mynft-item-title">
							???
						</div>
						<div className="mynft-item-text">
							Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
						</div>
						<div className="mynft-item-but" onClick={() => share('cat1')}>
							<div className="mynft-item-img-tw"></div>
							<div className="mynft-item-but-text">
								Share on Twitter
							</div>
						</div>
					</div>
				</div>
				<div className="nft-mbg2"></div>
			</div>
		}


		{balanceOf8849 > 0 ?
			<div >
				<div className="mynft-item">
					<div className="mynft-item-img-silver"></div>
					<div className="mynft-item-img-text" >
						<div className="mynft-item-title">
							Ukraine Donor Badge(Silver)
						</div>
						<div className="mynft-item-text">
							Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
						</div>
						<div className="mynft-item-but" onClick={() => share('cat2')}>
							<div className="mynft-item-img-tw"></div>
							<div className="mynft-item-but-text">
								Share on Twitter
							</div>
						</div>
					</div>
				</div>
				<div className="nft-mbg2"></div>
			</div>
			:
			<div >
				<div className="mynft-item">
					<div className="mynft-item-img-other"></div>
					<div className="mynft-item-img-text" >
						<div className="mynft-item-title">
							???
						</div>
						<div className="mynft-item-text">
							Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
						</div>
						<div className="mynft-item-but" onClick={() => share('cat2')}>
							<div className="mynft-item-img-tw"></div>
							<div className="mynft-item-but-text">
								Share on Twitter
							</div>
						</div>
					</div>
				</div>
				<div className="nft-mbg2"></div>
			</div>
		}

		{balanceOf8850 > 0 ?
			<div >
				<div className="mynft-item">
					<div className="mynft-item-img-bronze"></div>
					<div className="mynft-item-img-text" >
						<div className="mynft-item-title">
							Ukraine Donor Badge(Bronze)
						</div>
						<div className="mynft-item-text">
							Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
						</div>
						<div className="mynft-item-but" onClick={() => share('cat3')}>
							<div className="mynft-item-img-tw"></div>
							<div className="mynft-item-but-text">
								Share on Twitter
							</div>
						</div>
					</div>
				</div>
				<div className="nft-mbg2"></div>
			</div>
			:
			<div >
				<div className="mynft-item">
					<div className="mynft-item-img-other"></div>
					<div className="mynft-item-img-text" >
						<div className="mynft-item-title">
							???
						</div>
						<div className="mynft-item-text">
							Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
						</div>
						<div className="mynft-item-but" onClick={() => share('cat3')}>
							<div className="mynft-item-img-tw"></div>
							<div className="mynft-item-but-text">
								Share on Twitter
							</div>
						</div>
					</div>
				</div>
				<div className="nft-mbg2"></div>
			</div>
		}
		<div >
			<div className="mynft-item">
				<div className="mynft-item-img-other"></div>
				<div className="mynft-item-img-text" >
					<div className="mynft-item-title">
						???
					</div>
					<div className="mynft-item-text">
						Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
					</div>
					<div className="mynft-item-but" onClick={() => share('cat1')}>
						<div className="mynft-item-img-tw"></div>
						<div className="mynft-item-but-text">
							Share on Twitter
						</div>
					</div>
				</div>
			</div>
			<div className="nft-mbg2"></div>
		</div>
		<div className="nft-mbg2">
			<div className="nft-bot">
				<div className="nft-bot-imp">Impactopia</div>
				<div className="nft-bot-line"></div>
				<div className="nft-bot-img-tg" onClick={Common.toTwitter}></div>
				<div className="nft-bot-img-discode" onClick={Common.toDiscord}></div>
				<div className="nft-bot-img-mail"></div>
			</div>
		</div>

	</div>

}
