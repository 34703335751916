
import { Discover } from "./Discover";
import { Weare } from "./Weare";
import { RoadMap } from "./RoadMap";
import { BasePage, page, playVideo } from "./BasePage";
import { Footer } from "../components/Footer";
import { Common } from '../../app/Common'
import { Nft } from "./Nft";
import { Navigation } from "../components/Navigation";

@page('Tow')
export  class Tow extends BasePage {
  protected webContent() {
    return <div onClick={Common.closeNavigationTab}>
      <Nft />
    </div>
  }

  protected mobileContent(): JSX.Element {
    return <div onClick={Common.closeNavigationTab}>
    <Nft />
    <Footer />
  </div>
  }
}