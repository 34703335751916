import {BasePage, page} from "./BasePage";
import "../css/common.css"
import "./Nft_m.css"
import { Live } from "../components/Live";

@page("ChLive")
export class ChLive extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
			<Live></Live>

		</div> 
	}

	protected mobileContent(): JSX.Element {
		return <Live></Live>
	}
}
