import {BasePage, page} from "./BasePage";
import { Input, } from 'antd';
import { CPerson} from '../components/CPerson';
import { Navigation } from "../components/Navigation";
import "./Person.css"
@page("Person")
export class Person extends BasePage {
	
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  > 
			<CPerson></CPerson>
		</div>
	}

	protected mobileContent(): JSX.Element {
		return <CPerson></CPerson>
	}
}
