import { State, useAppDispatch, useAppSelector } from "../../app/Store";
import { useEffect, useState } from "react";
import { ChainId, ethereum, web3 } from "../../app/Config";
import { getContract } from "../../app/Contract";
import { Progress, message, Modal, Image, Spin } from 'antd';
import {
	TwitterShareButton,
	TwitterIcon,
} from "react-share";
import {
	ConnectSelectors,
	ConnectTask,
	disconnect,
	SwitchTask,
	updateAddress,
	updateBalance,
	updateChainId
} from "../../slices/ConnectSlice";
import "./NftList.css";

export function NftList() {
	const dispatch = useAppDispatch();
	const isConnected = useAppSelector(ConnectSelectors.isConnected);
	const displayAddress = useAppSelector(ConnectSelectors.displayAddress);
	const { address, chainId } = useAppSelector(ConnectSelectors.userData);
	async function init() {
		if (address) {
			const contract = await getContract();
			let startMintTime8848 = await contract.methods.getStartMintTime(8848).call();
			let startMintTime8849 = await contract.methods.getStartMintTime(8849).call();
			let startMintTime8850 = await contract.methods.getStartMintTime(8850).call();
		}
	}

	async function mint(id: any, img: any,gif:any) {
		//showModal(gif);
		if (!isConnected) {
			message.success("PLEASE CONNECT Wallet");
			return;
		}
		const contract = await getContract();
		setLoading(true);
		let  balanceOf = await contract.methods.balanceOf(address, id).call();
		if(balanceOf > 0){
			setLoading(false);
			message.success(
				{
					content: 'You have already minted, unable to mint ',
					className: 'custom-class',
					style: {
						marginTop: '20vh',
					},
				}

			);
			return;
		}
		let hasDonatAddress = await contract.methods.hasDonatAddress(address, id).call();
		if (hasDonatAddress  && balanceOf == 0) {
			contract.methods.mint(1, id).send({
				from: address
			}).on('error', (error: any) => {
				setLoading(false);
				message.error(error);
			}).on('transactionHash', (txHash: any) => {
				setLoading(false);
				console.warn("transactionHash", txHash)
			}).on('receipt', (receipt: any) => {
				showModal(img);
			})
		} else {
			setLoading(false);
			message.success(
				{
					content: 'Mint address for donation of appropriate amount, unable to Mint ',
					className: 'custom-class',
					style: {
						marginTop: '20vh',
					},
				}

			);
		}
	}


	function share(imgName: any) {
		let metaArr = [
			'twitter:title', 'Build your profile with impact badge',
			'twitter:description', 'Connect with like-minded people ',
			'twitter:card', 'summary_large_image',
			'twitter:image', 'https://www.impactopia.net/impact-share/img/' + imgName + '.png'
		]
		let metaParams = metaArr.toString()
		metaParams = encodeURIComponent(encodeURIComponent(metaParams))
		window.open(`https://twitter.com/share?text= Here is my impact badge minted from Impactopia. claim yours and meet some great people!&url=https://www.impactopia.net/impact/api/share?meta=${metaParams}`)
	}


	useEffect(() => {
		init();
	}, [address, dispatch]);


	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showModalImge, setShowModalImge] = useState("gold");
	const [translate, setTranslate] = useState(false);
	const [loading, setLoading] = useState(false);

	const showModal = (imgName: any) => {
		setShowModalImge(imgName);
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};


	function open(address: any) {
		window.open("https://etherscan.io/address/" + address);
	}



	return <div className="nft-list flex-wap"  >
		<div className="flex-width" >
			<div>
				<Image className="nft-img-big" src={require("../../assets/mobile/card-gold@3x.png")} />
			</div>
			<div style={{ marginLeft: "20px" }} >
				<div className="nft-text-1-big " >
					Ukraine Donor Badge(Gold)
				</div>

				<div className="nft-text-2-big" >
					Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
				</div>

				<div className="nft-text-legend" >
					<span className="nft-text-legend-one">Ukraine Donor Badge(Gold):</span>
					<span className="nft-text-legend-four">Donated at least 1000USD(USDT,USDC,DAI) or 1 ETH to</span>
					<span className="nft-text-legend-three" onClick={() => {
						open('0x633b7218644b83d57d90e7299039ebab19698e9c');
					}}  >Ukraine Dao</span>

					<span className="nft-text-legend-two">  or  </span>
					<span className="nft-text-legend-three" onClick={() => {
						open('0x165cd37b4c644c2921454429e7f9358d18a45e14');
					}} >Ukraine Crypto Donation </span>
					<span className="nft-text-legend-four">on Ethereum network</span>
				</div>

				<div className="flex-width  flex-wap" >
					<Spin spinning={loading} delay={500}>
						<div className="nft-mint-bt" onClick={() => mint('8848', 'cat1','gold')}  >
							<img className="polyogn-icon" src={require("../../assets/impactopia/plo.png")} alt="" />
							<div className="nft-name-mint"  >Mint on Polygon→</div>
							{/* <div className="nft-name-mint"  onClick={() => showModal()} >Mint on Polygon→</div> */}

						</div>
					</Spin>
					<div className="nft-mint-bt-tw2 nft-mint-bt-20" onClick={() => share('cat1')}  >
						<img className="polyogn-icon" src={require("../../assets/impactopia/tw-g.png")} alt="" />
						<div className="nft-name-mint-tw2"  >Share on Twitter</div>
					</div>
				</div>
			</div>
		</div>


		<div className="flex-width nft-top-60" >
			<div>
				<Image className="nft-img-big" src={require("../../assets/mobile/card-silver@3x.png")} />
			</div>
			<div style={{ marginLeft: "20px" }} >
				<div className="nft-text-1-big " >
					Ukraine Donor Badge(Silver)
				</div>
				<div className="nft-text-2-big" >
					Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
				</div>
				<div className="nft-text-legend" >
					<span className="nft-text-legend-one">Ukraine Donor Badge(Silver):</span>
					<span className="nft-text-legend-four">Donated at least 100USD(USDT,USDC,DAI) or 0.1 ETH to</span>
					<span className="nft-text-legend-three" onClick={() => {
						open('0x633b7218644b83d57d90e7299039ebab19698e9c');
					}}  >Ukraine Dao</span>

					<span className="nft-text-legend-two"> or </span>
					<span className="nft-text-legend-three" onClick={() => {
						open('0x165cd37b4c644c2921454429e7f9358d18a45e14');
					}}  >Ukraine Crypto Donation </span>
					<span className="nft-text-legend-four">on Ethereum network</span>
				</div>
				<div className="flex-width flex-wap" >
					<Spin spinning={loading} delay={500}>
						<div className="nft-mint-bt" onClick={() => mint('8849', 'cat2','silver')}  >
							<img className="polyogn-icon" src={require("../../assets/impactopia/plo.png")} alt="" />
							<div className="nft-name-mint"  >Mint on Polygon→</div>
						</div>
					</Spin>
					<div className="nft-mint-bt-tw2 nft-mint-bt-20" onClick={() => share('cat2')}  >
						<img className="polyogn-icon" src={require("../../assets/impactopia/tw-g.png")} alt="" />
						<div className="nft-name-mint-tw2"  >Share on Twitter</div>
					</div>
				</div>
			</div>
		</div>



		<div className="flex-width nft-top-60" >
			<div>
				<Image className="nft-img-big" src={require("../../assets/mobile/card-bionze@3x.png")} />
			</div>
			<div style={{ marginLeft: "20px" }} >
				<div className="nft-text-1-big " >
					Ukraine Donor Badge(Bronze)
				</div>
				<div className="nft-text-2-big" >
					Ukraine Donor Badge is for recognizing your contribution to people of Ukraine in difficult times.
				</div>
				<div className="nft-text-legend" >
					<span className="nft-text-legend-one">Ukraine Donor Badge(Bronze):</span>
					<span className="nft-text-legend-four">Donated at least 1USD(USDT,USDC,DAI) or 0.001 ETH to</span>
					<span className="nft-text-legend-three" onClick={() => {
						open('0x633b7218644b83d57d90e7299039ebab19698e9c');
					}}   >Ukraine Dao</span>

					<span className="nft-text-legend-two"> or </span>
					<span className="nft-text-legend-three" onClick={() => {
						open('0x165cd37b4c644c2921454429e7f9358d18a45e14');
					}}  >Ukraine Crypto Donation </span>
					<span className="nft-text-legend-four">on Ethereum network</span>
				</div>
				<div className="flex-width flex-wap" >
					<Spin spinning={loading} delay={500}>
						<div className="nft-mint-bt" onClick={() => mint('8850', 'cat3','bronze')}  >
							<img className="polyogn-icon" src={require("../../assets/impactopia/plo.png")} alt="" />
							<div className="nft-name-mint"  >Mint on Polygon→</div>
						</div>
					</Spin>
					<div className="nft-mint-bt-tw2 nft-mint-bt-20" onClick={() => share('cat3')}  >
						<img className="polyogn-icon" src={require("../../assets/impactopia/tw-g.png")} alt="" />
						<div className="nft-name-mint-tw2"  >Share on Twitter</div>
					</div>
				</div>
			</div>
		</div>


		<div className="flex-width nft-top-60" >
			<div>
				<img className="nft-img-big" src={require("../../assets/mobile/doubt@2x.png")} />
			</div>
			<div style={{ marginLeft: "20px" }} >
				<div className="nft-text-1-big-other " >
					???
				</div>
				
				{/* <div className="flex-width flex-center flex-wap" >
					<div className="nft-mint-bt" >
						 <img  className="polyogn-icon" src={require("../../assets/impactopia/plo.png")} alt="" />
						 <div className="nft-name-mint"  onClick={() => mint('8850')} >Mint on Polygon→</div>
					</div>
					<div className="nft-mint-bt-tw nft-mint-bt-20"  onClick={() => share('cat3')}  >
						<img  className="polyogn-icon" src={require("../../assets/impactopia/tw-g.png")} alt="" />
						 <div className="nft-name-mint-tw"  >Share on Twitter</div>
					</div>
				</div> */}
			</div>
		</div>

		<Modal closable={false} width={"768px"} style={{ backgroundColor: "transparent" }} bodyStyle={{ backgroundColor: "transparent", width: "100%" }} footer={null} onOk={handleOk} onCancel={handleCancel}  open={isModalOpen}>
			{/* {!translate ? <img style={{ width: "45.65rem", height: " 40rem" }} src={require("../../assets/impactopia/" + showModalImge + ".png")}   ></img>
				:
				<img style={{ width: "45.65rem", height: " 40rem" }} src={require("../../assets/impactopia/yh1.gif")}   ></img>
			} */}
			<div className="flex-center"  >
			<img style={{ width: "60.65rem", height: " 50rem" }} src={require("../../assets/impactopia/" + showModalImge + ".gif")}   ></img>
			</div>
			<div className="flex-center"  >
				<img className="img-cut" onClick={handleCancel} src={require("../../assets/impactopia/cut.png")} alt="" />
			</div>
		</Modal>



	</div>

}


