import { useEffect, useState } from "react";
import { Input, message, Select, Image,Carousel } from 'antd';
import { Tabs } from 'antd-mobile'
import { RightOutline,LeftOutline } from 'antd-mobile-icons'
import { Link, useNavigate,useLocation } from 'react-router-dom'
import { httpGet, httpPost } from '../../utils/httpUtils';
import { Ctrending_star } from './Ctrending_star';
import { Collapse } from 'antd';
import { Formatter } from './Formatter';
import { Common } from "../../app/Common";
import { State, useAppDispatch, useAppSelector } from "../../app/Store";

export function CProject_list() {
	const { tagsList} = useAppSelector((state: State) => state.tags)
	const { Panel } = Collapse;
	const navigate = useNavigate();
	const [unfinished,setUnfinished] =  useState([]as any);
	const [projectList, setProjectList] = useState([] as Array<any>);
	const [plateList, setPlateList] = useState([] as Array<any>);
	const [seachData, setSeachData] = useState({ page: 1, limit: 20 } as any);
	let query = new URLSearchParams(useLocation().search);
	const { state } = useLocation()
	useEffect(() => {
		getPageList();
		getPlateAll();
		getUnfinished();
	}, [])

	useEffect(() => {
			if (state && state.plateName) {
				setDefaultActiveKey(state.plateName)
		  }
	}, [])
	const getPageList = () => {
		httpPost("/project/getPageList", { ...seachData }).then((e: any) => {
			if (e.data.code == 0) {
				setProjectList(e.data.data.list);
			}
		})
	}
	

	function getPlateAll() {
		httpGet("/project/getPlateAll").then((e: any) => {
			if (e.data.code == 0) {
				let list = [];
				for (let index = 0; index < e.data.data.length; index++) {
					const item = e.data.data[index];
					list.push({ value: item.plateName, label: item.plateName });
				}
				Common.isMobile?list.unshift({label:"All",value:"All"}):list.unshift();
				setPlateList([...list]);
			}
		})
	}


	function  getUnfinished(){
		httpGet("/memberNft/unfinished").then((e:any)=>{
			if(e.data.code == 0 ){
				setUnfinished(e.data.data);
			}
		})
	}


	function projectCollect(item: any, index: any) {
		httpGet("/collect/projectCollect/" + item.id).then((e: any) => {
			if (e.data.code == 0) {
				projectList[index].isCollect = 0;
				setProjectList([...projectList]);
				message.success("project sucss");
			}
		})
	}

	function cancelCollect(item: any, index: any) {
		httpGet("/collect/projectCollect/" + item.id + "/1").then((e: any) => {
			if (e.data.code == 0) {
				projectList[index].isCollect = 1;
				setProjectList([...projectList]);
				message.success("project sucss");
			}
		})
	}



	function getTags(item: any) {
		let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}


		let tags = item.tages;
		let tagshtml = <div></div>
		if (tags) {
			tags = tags.split(",");
			tagshtml = <div className="forum_flex_one" >
				{tags.map((ite: any, inde: any) => {
					let color = "";
					if (ite) {
						color = color_conf[ite.substring(0, 1).toLowerCase()] ? color_conf[ite.substring(0, 1).toLowerCase()] : "#FFB4A2";
					}
					return <div key={inde} style={{ backgroundColor: color }} className="forum_but but-shadow" >{ite}</div>
				})}
			</div>
		}
		return tagshtml;
	}

	function getMinStep(fundRaisingObjectives: any, donateNum: any) {
		let maxWidth = 7.375;
		let width = maxWidth / fundRaisingObjectives * donateNum;
		return <div className="projectList_step_blue" style={{ width: width > maxWidth ? `${maxWidth + "vw"}` : `${width + "vw"}` }} >
		</div>
	}

	function getImgOne(item: any) {
		if (item.bannerImgUrl) {
			let imgs = JSON.parse(item.bannerImgUrl);
			return imgs[0].url;
		}
		return require("../../assets/mobile/lesd@2x.png");
	}

	const handleChange = (value: string | string[]) => {
		seachData.plateName = value.toString();
		setSeachData({ ...seachData });
		getPageList();
	};


	const [defaultActiveKey, setDefaultActiveKey] = useState("");
	const defaultActiveKeyChange = (key: any) => {
		seachData.plateName = key=="All"?"":key;
		setSeachData({ ...seachData });
		getPageList();
		setDefaultActiveKey(key)
	}

	function getMinStepM(fundRaisingObjectives: any, donateNum: any) {
		let maxWidth = 42.6667
		let width = (maxWidth / fundRaisingObjectives) * donateNum
		return (
		  <div
			className="mproject_box_item_step_bule"
			style={{
			  width: width > maxWidth ? `${maxWidth + 'vw'}` : `${width + 'vw'}`,
			}}></div>
		)
	  }

	  function goProject(id:any){
		if(id){
			navigate("/Project?projectId="+id);
		}else{
			message.error("Projects without  donations");
		}
	}

	// @ts-ignore
	const Web = <div className="projectListContent" mode="web" >
		<div className="projectListContent">
			<div className="projectListLeft" >
				<div className="projectListQuery">
					<Collapse defaultActiveKey={['1']} bordered={false} >
						<Panel header="This is panel header 1" key="1">
							<Select
								mode="tags"
								placeholder="Please select plate"
								onChange={handleChange}
								style={{ width: '12vw', marginLeft: "20px" }}
								options={plateList}
							/>
						</Panel>
					</Collapse>

				</div>
				<div className="projectList" >
					{projectList.map((item, index) => {
						return <div key={index} className="projectListItem" >
							<div>
								<div className="projectListTile" >
									{item.isCollect == 0 ? <img onClick={() => projectCollect(item, index)} src={require("../../assets/v2/shoucang@2x.png")} className="projectList_img" alt="" /> : <img onClick={() => cancelCollect(item, index)} src={require("../../assets/v2/shoucang@2x.png")} className="projectList_img" alt="" />}
									<div className="projectListTiletext" >{item.name}</div>
								</div>

								<div className="projectListContent_1" >{item.detail}</div>
								<div className="projectList_conf" >

									<div style={{ width: "30%" }} >
										<div className="projectListToken" >{item.donateNum}{item.receivingToken} / {item.fundRaisingObjectives}{item.receivingToken}</div>
									</div>
									<div className="projectListTags" style={{ width: "30%" }} >
										{getTags(item)}
									</div>
									<div style={{ width: "30%", display: "flex" }} >
										<div className="projectListCreate"  ><Formatter dateTime={item.createDateTime} ></Formatter> </div>
										<Link to={"/Project?projectId=" + item.id}  >
											<div className="projectListDonate" >Donate→</div>
										</Link>
									</div>
								</div>
							</div>
							<div className="projectListImgBox" >
								<Image src={getImgOne(item)} className="projectListImg" />
							</div>
						</div>
					})}

				</div>
			</div>
			<div className="projectListRight" >
				<Ctrending_star isCreate={true}  ></Ctrending_star>
			</div>
		</div>
	</div>
	// @ts-ignore
	const Mobile = <div id="projectListContent" mode="mobile">
			<Carousel className="mbadge_your_carousel"  dots={false}  autoplay>
					{unfinished && unfinished.length > 0 ? unfinished.map((item:any,indx:any)=>{
						return <div  key={indx} >
						<div className="mbadge_your_current" >
							<div  className="mbadge_your_current_left"   onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}}  >
								<img src={JSON.parse(item.image)[0].url}  className="mbadge_your_current_left_img" alt="" />
								<div className="mbadge_your_current_left_name"  >
									{item.name}
								</div>
							</div>

							<div  className="mbadge_your_current_right">
								<div className="mbadge_your_current_right_text" >
									You're about to get this badge.
								</div>
								<div className="mbadge_your_current_right_token"  onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}} >
									{item.donationAmount} / {item.handselValue}{item.tokenName}
								</div>
								<div className="mbadge_your_current_step"  >
									<div  className="mdonate_list_item_cld_num "  onClick={()=>{	navigate("/BadgeDetail?detailId="+item.itemId);}}  >
										<div className="mdonate_step" >{getMinStepM(item.handselValue,item.donationAmount)}</div>
									</div>
								</div>
									<div className="mbadge_your_current_but" >
											<div className="mbadge_your_current_right_go" onClick={()=>{goProject(item.projectId)}} >
												GET <img src={require("../../assets/v2/go.png")}  className="mbadge_your_current_right_go_img" alt="" />
											</div>
									</div>
							</div>
						</div>
					</div>
					}):""}
				</Carousel>
				<div style={{width:'100%',marginTop:20}}>
            <div  className="mpmint_box" >
              <div  className='discover_seach' >
                <img
                    src={require('../../assets/v2/search.png')}
                    className="mdiscover_seach_img"
                    alt=""
                  />
				  <Select
					placeholder="Please select tags"
					onChange={(e) => {
						if(e){
							seachData.tages = [e];
							setSeachData({ ...seachData });
						}else{
							seachData.tages = [];
							setSeachData({ ...seachData });
							
						}
						getPageList();
					}}
					bordered={false}
					allowClear={true}
					style={{ width: '90%' }}
					options={tagsList}
				/>
                {/* <Input
                    style={{ width: '80%' }}
                    bordered={false}
                    onChange={(e) => {
                      formData.keyWorld = e.target.value
                      setFormData({ ...formData })
                      getPageList()
                    }}
                    placeholder="Search by keywords"
                  /> */}
              </div>
            </div>
          </div>
		{
			plateList.length > 0 ? <div className="projectListContentTabs">
			<LeftOutline className='moreIcon'  fontSize={40}    />
				<Tabs defaultActiveKey={defaultActiveKey} onChange={defaultActiveKeyChange} style={{'--active-line-color': '#fff','--active-title-color':'#279D8E'}}>
					{
						plateList.map((item:any,index:any)=>{
							return <Tabs.Tab  title={item.label} key={item.value}></Tabs.Tab>
						})
					}
				</Tabs>
				<RightOutline className='moreIcon' fontSize={40}   />
			</div>:<div></div>
		}
		<div className="projectListBox">
			{projectList.map((ite:any,inx:any)=>{
					return  <div key={inx} className="cdonate_list_item"   >
								<Link to={"/Project?projectId="+ite.id}  >
									<div  style={{width:"100%",display:"flex",justifyContent:"center"}}>
											<Image src={JSON.parse(ite.imgUrl)[0].url} alt="" className="cdonate_list_item_cld_img" />
									</div>
								<div className="cdonate_list_item_cld_name" >{ite.name}</div>
								<div  className="donate_list_item_cld_num " >
									<div  className="cdonate_num"  >{ite.donateNum}{ite.receivingToken} / {ite.fundRaisingObjectives}{ite.receivingToken}</div>
									<div className="mproject_box_item_step" >{getMinStepM(ite.fundRaisingObjectives,ite.donateNum)}</div>
								</div>
							</Link>
				</div>
			})}
		</div>
		

	</div>
	return Common.isMobile ? Mobile : Web

}
