import {BasePage, page} from "./BasePage";
import "./DonateUs.css"
import "../css/common.css";
import { CDonateUs} from '../components/CDonateUs';

@page("DonateUs")
export class DonateUs extends BasePage {
	protected webContent() {
		return <div  style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
			<CDonateUs></CDonateUs>
		</div>
	}


	

	protected mobileContent(): JSX.Element {
		return <div>
			<CDonateUs></CDonateUs>
		</div>;
	}
}
