import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {httpGet,httpPost,host_url} from '../utils/httpUtils';
import {State} from "../app/Store";
// 定义接口
interface Icount {
  number: number,
}
 
// 初始化数据
const initialState: Icount = {
  number: 0,
}
// Slice对象
export const CountSlice = createSlice({
  name: 'count',
  initialState,
  reducers: {
    // 初始化
    init: (state,num) => {
      state.number = Number(num.payload)
    },
    // 加
    increment: (state,num) => {
      debugger
      state.number += Number(num.payload)
    },
    // 减
    decrement: (state,num) => {
      if(state.number <=Number(num.payload) ){
        state.number =0;
      }else{
        state.number -= Number(num.payload) 
      }
     
    }
  }
})
// 异步处理
export const incrementAsync = () => {
  return async (dispatch: any, getState: any) => {
    httpGet("/privateletter/getUnreadNum").then((e:any)=>{
      if(e.data.code==0){
        dispatch(init(e.data.data));
      }
    })
  }
}
// 导出处理函数
export const { increment, decrement ,init} = CountSlice.actions
// 导出reducer
export default CountSlice.reducer