

import impact from "../contracts/impact.json";
import impactManger from "../contracts/ImpactManger.json";
import {ChainId, web3} from "./Config";
import {AbiItem} from "web3-utils";
import {Contract} from "web3-eth-contract";
import erc20 from "../contracts/erc20.json";
import swapProxy from "../contracts/swapProxy.json";


let contract: Contract;
export async function getContract() {
	if (!contract) {
		const networkId = ChainId;
		// @ts-ignore
		contract = new web3.eth.Contract(
			impact.abi as AbiItem[],
			impact.address
		);
	}
	return contract;
}

export async function getImpactManger() {
	if (!contract) {
		// @ts-ignore
		contract = new web3.eth.Contract(
			impactManger.abi as AbiItem[],
			impactManger.address
		);
	}
	return contract;
}

export async function getSwapProxy() {
	if (!contract) {
		// @ts-ignore
		contract = new web3.eth.Contract(
			swapProxy.abi as AbiItem[],
			swapProxy.address
		);
	}
	return contract;
}




export async function getErc20Contract(address:any) {
	// @ts-ignore
	return  new web3.eth.Contract(
		erc20.abi as AbiItem[],
		address
	);

}

