import { useEffect, useState } from "react";
import { Input,message,Select,Drawer,InputNumber,Image } from 'antd';
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import {httpGet,httpPost} from '../../utils/httpUtils';
import {Ctrending_star} from './Ctrending_star';
import { url } from "inspector";
import {SendForum} from './SendForum';
import {SendContent} from './SendContent';
import { Share } from './Share';
import { UserHeader } from './UserHeader';
import { UpdateForum } from './UpdateForum';
import { Formatter } from './Formatter';
import { Common } from "../../app/Common";
import { CMDiscover_list } from './CMDiscover_list';
import Iframe from 'react-iframe'
import {PushDonate} from "./PushDonate";
export function CForum_list() {
	const { TextArea } = Input;
	const navigate = useNavigate();
	let query = new URLSearchParams(useLocation().search);
	let  forumId = 	query.get("forumId");
	const [forumList,setForumList] =  useState([]as any);
	useEffect(()=>{
		if(forumId){
			getPageList();
			getMmber();
		}
	},[forumId]);

	const [memberVo,setMemberVo] =  useState({} as any);
	function getMmber(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 0 ){
				setMemberVo(e.data.data);
			}
		})
	}

	const  getPageList=()=>{
		httpPost("/forum/getDetail",{page:1,limit:20,id:forumId}).then((e:any)=>{
			setForumList([...e.data.data.list]);
		})
	}


	
	function forumAttention(item:any,index:any){
		httpGet("/attention/forumAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=1;
				forumList[index].attentionNum+=1;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Forumlist?forumId="+forumId);
				navigate("/SignIn");
			}
		})
	}

	function forumCollect(item:any,index:any){
		httpGet("/collect/forumCollect/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=1;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Forumlist?forumId="+forumId);
				navigate("/SignIn");
			}
		})
	}


	function cancelCollect(item:any,index:any){
		httpGet("/collect/cancelCollect/"+item.id+"/3").then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=0;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Forumlist?forumId="+forumId);
				navigate("/SignIn");
			}
		})
	}



	function cancelAttention(item:any,index:any){
		httpGet("/attention/cancelAttention/"+item.id+"/3").then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=0;
				forumList[index].attentionNum-=1;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Forumlist?forumId="+forumId);
				navigate("/SignIn");
			}
		})
	}

	function queryURLparams(url:any) {
		let obj = {} as any
		if (url.indexOf('?') < 0) return obj
		let arr = url.split('?')
		url = arr[1]
		let array = url.split('&')
		for (let i = 0; i < array.length; i++) {
			let arr2 = array[i]
			let arr3 = arr2.split('=')
			obj[arr3[0]] = arr3[1]
		}
		return obj
	
	}
	
	

	function getUrl(url:any){
		if(url && url.indexOf('live') > -1){
			try {
				let code =url.replace("https://www.youtube.com/live/","").replace("https://youtube.com/live/","").split("?")[0];
				return `https://www.youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}

		if( url  && url.indexOf('shorts') > -1){
			try {
				let code =url.replace("https://youtube.com/shorts/","").split("?")[0];
				return `https://youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('watch') > -1){
			try {
				 let ags =  queryURLparams(url);
				return `https://www.youtube.com/embed/${ags.v}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('youtu.be') > -1){
			try {
				 let ags =url.replace("https://youtu.be/","").split("?")[0];
				return `https://www.youtube.com/embed/${ags}`;
			} catch (error) {
				
			}
		}
        return "";
    }

	function getTags(item:any){
		let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}
		let tags=item.tages;
		let tagshtml=<div></div>
		if(tags){
			tags =tags.split(",");
			tagshtml=	<div className="forum_flex_one" >
			{tags.map((ite:any,inde:any)=>{
				let color="";
				if(ite){
					color=color_conf[ite.substring(0,1).toLowerCase()]?color_conf[ite.substring(0,1).toLowerCase()]:"#FFB4A2";
				}

				return <div  style={{backgroundColor:color}}  key={inde} className="forum_but but-shadow" >{ite}</div> 
			})}
		</div>
		}
		return  tagshtml;
	}


	function calcscale(width:any,height:any){
		let maxWidh=160;
		if(width>160){
		   let scacle:any= Number(maxWidh/width).toFixed(4);
		   height= Number(scacle*height);
		   return {width:maxWidh,height:height};
		}else{
			return {width:maxWidh,height:height};
		}
	}

	function lodingImg(item:any){
		let imgUrl=item.imgUrl;
		let tagshtml=<div></div>
		if(imgUrl){
			imgUrl =JSON.parse(imgUrl);
			tagshtml=	<div className="forum_flex_one" >
					{imgUrl.map((ite:any,inde:any)=>{
						let {width,height}=	calcscale(ite.width,ite.height);
						return <div key={inde} className="forum_img">
									<Image
										width={287}
										height={179}
										src={ite.url}
									/>
								</div> 
					})}
				</div>
		}
		return  tagshtml;
	}

	function  sendContent() {
		
	}

	function showSendContent(indx:any,item:any){
		for (let index = 0; index < forumList.length; index++) {
			forumList[index].showSendContent=false;
		}
		forumList[indx].showSendContent=!forumList[indx].showSendContent;
		setForumList([...forumList]);
	}

	function deletForum(item:any,index:any,){
		httpGet("/forum/remove/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				message.success("delete post successful ");
				getPageList();
				navigate("/Discover_list")
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Forumlist?forumId="+forumId);
				navigate("/SignIn");
			}
		})
	}

	function  showUpdateForum(item:any,index:any){
		forumList[index].edit=!forumList[index].edit;
		setForumList([...forumList]);
	}

	let Web=  <div  className="forum_body" >
	<div  className="forum_left"  >
	{forumList.map((item:any,indx:any)=>{
		return  <div key={indx}  className="forum_list_item" >
			<div  className="forum_list_name" >
				{item.title}
			</div>
				<div  className="forumflex" style={{width:"100%",marginLeft:"10px",alignItems:"center"}} >
						<UserHeader  member={item.memberEntity} ></UserHeader>
						<div>{item.memberName}</div>
					<div  style={{display:"flex",marginLeft:50}} >
						 {getTags(item)}
						<div  className="forum_time" ><Formatter dateTime={item.createDateTime} ></Formatter></div> 
					</div>
				</div>
			{!item.edit ? <div  className="forum_list_content"   dangerouslySetInnerHTML={{__html:item.content}} ></div> :""}
			{ (item.edit &&  item.memberId == memberVo.id )  ?	<UpdateForum   forumInfo={item}  handleClick={getPageList} ></UpdateForum> : ""}
			{!item.edit ? item.url? <div className="live_iframe_dis" >
						<Iframe url={getUrl(item.url)}
								width="100%"
								height="100%"
							id=""
							className=""
							display="block"
							position="relative"/>
					</div>: <div  className="forum_list_img"  >
						{lodingImg(item)}
					</div> :""
								
			}

{
								
								}


			<div className="forumflex"   style={{width:"100%",marginTop:20,marginBottom:10}}  >
				<div   className="forumflex" style={{width:"100%",alignItems:"center"}}  >
					<div  className="forum_sc" >
							<div>
							{item.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{forumAttention(item,indx)}} className="dis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,indx)}} className="dis_list_img"  alt="" />}
							<span  className="dis_sc_1" >{item.attentionNum}</span>
							</div>
							{item.type == 1 ?
								   <div  >
								   <img src={require("../../assets/v2/feed@2x.png")}  className="dis_list_img"   alt="" />
								   <span  className="dis_sc_1" >{item.commentNum}</span>
							   </div>:
							       <div  >
								   <img src={require("../../assets/v2/feed@2x.png")}   onClick={()=>showSendContent(indx,item)} className="dis_list_img"   alt="" />
								   <span  className="dis_sc_1" >{item.commentNum}</span>
							   </div>
							}
							{item.type == 1 ?
								<div> {item.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{forumCollect(item,indx)}}  className="dis_list_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect(item,indx)}}  className="dis_list_img"   alt="" />}
							    </div>:
							    <div></div>
							}
							<Share sharedata={item} ></Share>
				
					</div>
							{item.type == 1  && item.projectEntity ?
								<div> 
									<PushDonate  pages={"/Forumlist?forumId="+forumId} handleClick={getPageList} projct={item.projectEntity} fontSize="1.2vw"  ></PushDonate>
							    </div>:
							    <div></div>
							}
					
				</div>
				{
					(item.memberId == memberVo.id) ? 
					<div   className="forumflex" style={{width:"100%",justifyContent:"flex-end"}}  >
						<div className="forum_tools_but" >
							<img  src={require("../../assets/v2/write@2x.png")}   onClick={()=>{showUpdateForum(item,indx)}}  className="forum_but_img but-shadow"   alt="" /> 
							<div className="forum_but_line" ></div>
							<img  src={require("../../assets/v2/dele@2x.png")}  onClick={()=>{deletForum(item,indx)}}  className="forum_but_img but-shadow"   alt="" /> 
						</div>
				</div>:<div></div>
				}
			</div>
			 {item.showSendContent ?<SendContent  showList={true} forumId={item.id} handleClick={getPageList}></SendContent>:""}
			 {item.type == 1  && !item.edit ?	<SendForum  superiorId={item.id}  handleClick={getPageList}  ></SendForum> : ""}
		</div>})}

	</div>
	<div className="forum_right" >
		<Ctrending_star  paddingLeft_={"50px"}  isCreate={true} ></Ctrending_star>
	</div>
	</div>

let  Mobile=<div>
	{forumList.length > 0 ?
			<CMDiscover_list  forumId={forumId}  forumList={forumList}  show={true}   mmeber={memberVo}  handleClick={getPageList} ></CMDiscover_list>:<div></div>
		}
</div>
return Common.isMobile ? Mobile : Web 

}
