import {BasePage, page} from "./BasePage";
import "../css/common.css"
import "./SignUp.css"
import { CSignUp} from '../components/CSignUp';
@page("SignUp")
export class SignUp extends BasePage {
	protected webContent() {
		return <CSignUp></CSignUp>
	}
	protected mobileContent(): JSX.Element {
		return <CSignUp></CSignUp>
	}
}

