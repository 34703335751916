import {BasePage, page} from "./BasePage";
import "../css/common.css"
import "./Nft_m.css"
import { MCommunity } from "../components/MCommunity";

@page("Community")
export class Community extends BasePage {
	protected webContent() {
		function changTab(tpye:any){
		}
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}   ><MCommunity></MCommunity></div> 
	}

	protected mobileContent(): JSX.Element {
		return  <MCommunity></MCommunity>
	}
}
