
import {Common} from "../../app/Common";
import { httpGet, httpPost, host_url } from '../../utils/httpUtils'
import { useEffect, useState } from 'react'
import { Input, message, Select, Upload, Divider } from 'antd'
import { web3 } from '../../app/Config'
import { State, useAppDispatch, useAppSelector } from '../../app/Store'
import {
	ConnectSelectors,
	ConnectTask,
	disconnect,
	SwitchTask,
	updateAddress,
	updateBalance,
	updateChainId,
  } from '../../slices/ConnectSlice'
  import "./PersonalCenter.css"


export function PersonalCenter() {
	const { address, chainId } = useAppSelector(ConnectSelectors.userData)


	const { TextArea } = Input
	const [memberVo, setMemberVo] = useState({} as any)
	function getMmber() {
		httpGet('/member/getMember').then((e: any) => {
		  if (e.data.code == 0) {
			setMemberVo(e.data.data)
		  }
		})
	  }
	useEffect(() => {
		getMmber()
	}, [])


function uploadChange(info: any) {
	if (info.file.status === 'done') {
	if (info.file.response.code == 0) {
		memberVo.headImg = info.file.response.url
		setMemberVo({ ...memberVo })
	}
	}
}


function saveMember() {
    httpPost('/member/uptData', { ...memberVo }).then((e: any) => {
      if (e.data.code == 0) {
        getMmber()
        message.success('save  success');
      }
    })
  }

async function sign_wallet() {
    const signature = await web3.eth.personal.sign(
      'welcome to impact',
      address,
      'welcome to impact'
    )
    httpPost('/member/bindWallet', {
      address: address,
      signature: signature,
      message: 'welcome to impact',
    }).then((e: any) => {
      if (e.data.code == 0) {
        getMmber()
      }
    })
  }



 let Web=   <div className="person_basic_from" style={{ marginTop: 20 }}>
 <div className="person_nft_badge_name">Basic information</div>
 <div className="person_from_item">
   {memberVo.headImg ? (
	 <img
	   src={memberVo.headImg}
	   style={{
		 width: '3.75vw',
		 height: '3.75vw',
		 borderRadius: '100%',
	   }}
	   alt=""
	 />
   ) : (
	 <img
	   src={require('../../assets/v2/logo.png')}
	   style={{ width: '3.75vw', height: '3.75vw' }}
	   alt=""
	 />
   )}
   <Upload
	 action={host_url + '/api/app/oss/upload'}
	 showUploadList={false}
	 maxCount={1}
	 onChange={uploadChange}>
	 <div className="person_upload_file but-shadow">Upload new picture</div>
   </Upload>
 </div>
 <div className="person_from_item pserson_from_title">Name</div>
 <div className="person_from_item">
   <Input
	 placeholder="Your name"
	 value={memberVo.userName}
	 onChange={(e) => {
	   memberVo.userName = e.target.value
	   setMemberVo({ ...memberVo })
	 }}
	 style={{ width: '31.25vw', height: 48 }}
   />
 </div>
 <div className="person_from_item person_basc_text">
   Choose a nickname, so people know who is who.
 </div>

 <div className="person_from_item pserson_from_title">Bio</div>
 <div className="person_from_item">
   <TextArea
	 maxLength={100}
	 value={memberVo.bio}
	 onChange={(e) => {
	   memberVo.bio = e.target.value
	   setMemberVo({ ...memberVo })
	 }}
	 style={{ height: 120, width: '31.25vw' }}
	 placeholder="Introduce yourself..."
   />
 </div>
 <div className="person_from_item person_basc_text">
   Brief description for your profile. URLs are hyperlinked.
 </div>

 <div className="person_from_item pserson_from_title">
 Wallet
 </div>
 <div className="person_from_item">
   <Input
	 value={memberVo.walletAddress}
	 onChange={(e) => {
	   memberVo.walletAddress = e.target.value
	   setMemberVo({ ...memberVo })
	 }}
	 placeholder="Your Polygon address to receive Impact Badge"
	 style={{ width: '27.25vw', height: 48 }}
   />
   <div className="person_sign_but but-shadow" onClick={sign_wallet}>
	 Sign
   </div>
 </div>
 <div className="person_from_item person_basc_text">
   Link your wallet address
 </div>

 <div className="person_from_item pserson_from_title ">
   Personal website
 </div>
 <div className="person_from_item">
   <Input
	 value={memberVo.personalWebsite}
	 onChange={(e) => {
	   memberVo.personalWebsite = e.target.value
	   setMemberVo({ ...memberVo })
	 }}
	 placeholder="Your personal link"
	 style={{ width: '31.25vw', height: 48 }}
   />
 </div>
 <div className="person_from_item person_basc_text">
   Your home page, blog, or company site.
 </div>

 <div className="person_from_item_but ">
   <div onClick={saveMember} className="person_from_item_but_1 but-shadow">
	 Save
   </div>
 </div>
</div>



let Mobile=<div className="mpmint_body" >
<div className="person_from_item">
  {memberVo.headImg ? (
	<img
	  src={memberVo.headImg}
	  style={{
		width: '17.0667vw',
		height: '17.0667vw',
		borderRadius: '100%',
	  }}
	  alt=""
	/>
  ) : (
	<img
	  src={require('../../assets/v2/logo.png')}
	  style={{ width: '17.0667vw', height: '17.0667vw' }}
	  alt=""
	/>
  )}
  <Upload
	action={host_url + '/api/app/oss/upload'}
	showUploadList={false}
	maxCount={1}
	onChange={uploadChange}>
	<div className="mperson_upload_file">Upload new picture</div>
  </Upload>
</div>
<div className="person_from_item mpserson_from_title">Name</div>
<div className="person_from_item">
  <Input
	placeholder="Your name"
	value={memberVo.userName}
	onChange={(e) => {
	  memberVo.userName = e.target.value
	  setMemberVo({ ...memberVo })
	}}
  />
</div>
<div className="person_from_item mperson_basc_text">
  Choose a nickname, so people know who is who.
</div>

<div className="person_from_item mpserson_from_title">Bio</div>
<div className="person_from_item">
  <TextArea
	maxLength={100}
	rows={4}
	value={memberVo.bio}
	onChange={(e) => {
	  memberVo.bio = e.target.value
	  setMemberVo({ ...memberVo })
	}}
	placeholder="can resize"
  />
</div>
<div className="person_from_item mperson_basc_text">
  Brief description for your profile. URLs are hyperlinked.
</div>

<div className="person_from_item mpserson_from_title">
Wallet Connecting
</div>
<div className="person_from_item">
  <Input
	value={memberVo.walletAddress}
	onChange={(e) => {
	  memberVo.walletAddress = e.target.value
	  setMemberVo({ ...memberVo })
	}}
	placeholder="Your Polygon address to receive Impact Badge"
  />
  <div className="mperson_sign_but" onClick={sign_wallet}>
	Sign
  </div>
</div>
<div className="person_from_item mperson_basc_text">
  Link your wallet address
</div>

<div className="person_from_item mpserson_from_title ">
  Personal website
</div>
<div className="person_from_item">
  <Input
	value={memberVo.personalWebsite}
	onChange={(e) => {
	  memberVo.personalWebsite = e.target.value
	  setMemberVo({ ...memberVo })
	}}
	placeholder="Your personal link"
  />
</div>
<div className="person_from_item mperson_basc_text">
  Your home page, blog, or company site.
</div>

<div className="mpmint_but_box" style={{marginTop:20}}  >
  <div onClick={saveMember} className="mpmint_pint">
	Save
  </div>
</div>
</div>
return Common.isMobile ? Mobile : Web 

}
