import {BasePage, page} from "./BasePage";
import "./Discover.css"
import "../css/common.css"
import { CDiscover} from '../components/CDiscover';
@page("Discover")
export class Discover extends BasePage {
	
	protected webContent() {
		return  <div  style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}} 	>
 <CDiscover></CDiscover>
		</div>
	}

	protected mobileContent(): JSX.Element {
		return  <CDiscover></CDiscover>;
	}
}
