import axios from "axios";

//const host_url="http://127.0.0.1:8090/api/app"
// export const web_url="http://localhost:3002"
// export const host_url="http://127.0.0.1:8090"
//export const host_url="https://www.impactopia.net"
 //export const host_url="https://www.impactopia.net"
//   export const web_url="http://dev.impactopia.net"
//  export const host_url="http://dev.impactopia.net"
 export const web_url="https://www.impactopia.net"
 export const host_url="https://www.impactopia.net"
// 添加响应拦截器bur

axios.interceptors.response.use(function (response) {

// 对响应数据做点什么
  return response;

},function (error) {

// 对响应错误做点什么

return Promise.reject(error);

});

// get请求

export function httpGet(api:any){
  const instance = axios.create({
    timeout:50000,
     headers:{
             'Content-Type': 'application/json',
              'token': localStorage.getItem("token")+""
             },  // 请求头 token 可以放里面
   })

  return new Promise((reslove,reject)=>{
    instance.get(host_url+"/api/app"+api).then(res=>{
    reslove(res)
    }).catch((err)=>{
      reject(err)
    })
  })
};



export function httpGetA(api:any){
  const instance = axios.create({
    timeout:50000,
     headers:{
             'Content-Type': 'application/json',
      },  // 请求头 token 可以放里面
   })


  return new Promise((reslove,reject)=>{
    instance.get(api).then(res=>{
    reslove(res)
    }).catch((err)=>{
      reject(err)
    })
  })
};
// post请求

export function httpPost(api:any,data:any){
  return new Promise((reslove,reject)=>{
    const instance = axios.create({
      timeout:50000,
      headers:{
              'Content-Type': 'application/json',
                'token': localStorage.getItem("token")+""
              },  // 请求头 token 可以放里面
    })
    instance.post(host_url+"/api/app"+api,data).then(res=>{

        reslove(res)

    }).catch((err)=>{

      reject(err)

        })

    })

};

