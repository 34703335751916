import { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { httpGet, httpPost, host_url } from '../../utils/httpUtils'
import { Image,message } from 'antd'
import { My_post } from './My_post'
import { MyThoughts } from './MyThoughts'
import { People } from './People'
import { Common } from '../../app/Common'
import { My_chnannel_post } from './My_chnannel_post'
import { Tabs } from 'antd-mobile'
import { CPerson_myNft } from './CPerson_myNft'
import { CPerson_donation } from './CPerson_donation'
import { CPerson_post_box } from './CPerson_post_box'
import { CPublishmint } from './CPublishmint'
import { CPublishChannel } from './CPublishChannel'
import { PersonalCenter } from './PersonalCenter'
import copy from 'copy-to-clipboard';
import { CPerson_org } from './CPerson_org'
export function CPerson() {
  const [personMenu, setPersonMenu] = useState('PersonalCenter')
  const navigate = useNavigate()
  const { state } = useLocation()
  useEffect(() => {
    getMmber()
    if (state && state.type) {
      setPersonMenu(state.type)
    }
  }, [state])
  function menuChange(e: any) {
    setPersonMenu(e)
  }
  const [memberVo, setMemberVo] = useState({} as any)
  function getMmber() {
    httpGet('/member/getMember').then((e: any) => {
      if (e.data.code == 0) {
        setMemberVo(e.data.data)
      }
    })
  }








  // people切换

  let Web = (
    <div className="person_body">
      <div className="person_menu">
        <div className="person_logo_img_div">
          <Image
            src={
              memberVo.headImg
                ? memberVo.headImg
                : require('../../assets/v2/logo.png')
            }
            style={{
              width: '6.5625vw',
              height: '6.5625vw',
              borderRadius: '100%',
            }}
            alt=""
          />
        </div>
        <div className="person_name">{memberVo.userName}</div>
          
        <div className='person_but'  >
            <div className='persion_edit_profile'   onClick={() => {
            menuChange('PersonalCenter')
          }} >
                Edit Profile
            </div>

            <div  className='persion_share_but'  onClick={()=>{
                
                copy(host_url+"/#/Person_show?ref="+memberVo.id);
                message.success("share link  url copy success")
            }} >
                  <img
                  src={require('../../assets/impactopia/share-b@2x.png')}
                  style={{ width: '15px', height: '15px' }}
                />
                  <span  style={{marginLeft:"15px"}} >Share</span> 
            </div>

        </div>


        <div
          onClick={() => {
            menuChange('PersonalCenter')
          }}
          className="person_menu_item">
          <img
            src={require('../../assets/v2/PersonalCenter.png')}
            style={{ width: '1.1458vw', height: '1.1458vw' }}
            alt=""
          />
          {personMenu == 'PersonalCenter' ? (
            <div className="person_menu_item_name_active">Personal Center</div>
          ) : (
            <div className="person_menu_item_name">Personal Center</div>
          )}
        </div>

        <div
          onClick={() => {
            menuChange('Orgs')
          }}
          className="person_menu_item">
          <img
            src={require('../../assets/v2/Projects.png')}
            style={{ width: '1.1458vw', height: '1.1458vw' }}
            alt=""
          />
          {personMenu == 'Orgs' ? (
            <div className="person_menu_item_name_active">My Bookmarks</div>
          ) : (
            <div className="person_menu_item_name">My Bookmarks</div>
          )}
        </div>

        <div
          onClick={() => {
            menuChange('People')
          }}
          className="person_menu_item">
          <img
            src={require('../../assets/v2/People.png')}
            style={{ width: '1.1458vw', height: '1.1458vw' }}
            alt=""
          />
          {personMenu == 'People' ? (
            <div className="person_menu_item_name_active">People</div>
          ) : (
            <div className="person_menu_item_name">People</div>
          )}
        </div>

        <div
          onClick={() => {
            menuChange('NFTbadge')
          }}
          className="person_menu_item">
          <img
            src={require('../../assets/v2/myNft.png')}
            style={{ width: '1.1458vw', height: '1.1458vw' }}
            alt=""
          />
          {personMenu == 'NFTbadge' ? (
            <div className="person_menu_item_name_active">MY NFT Badge</div>
          ) : (
            <div className="person_menu_item_name">My NFTbadge</div>
          )}
        </div>
        <div
          onClick={() => {
            menuChange('Donation')
          }}
          className="person_menu_item">
          <img
            src={require('../../assets/v2/MyDonation.png')}
            style={{ width: '1.1458vw', height: '1.1458vw' }}
            alt=""
          />
          {personMenu == 'Donation' ? (
            <div className="person_menu_item_name_active">My Donation</div>
          ) : (
            <div className="person_menu_item_name">My Donation</div>
          )}
        </div>
        <div
          onClick={() => {
            menuChange('Post')
          }}
          className="person_menu_item">
          <img
            src={require('../../assets/v2/my_post.png')}
            style={{ width: '1.1458vw', height: '1.1458vw' }}
            alt=""
          />
          {personMenu == 'Post' ? (
            <div className="person_menu_item_name_active">My Post</div>
          ) : (
            <div className="person_menu_item_name">My Post</div>
          )}
        </div>
        <div
          onClick={() => {
            menuChange('Chnanel')
          }}
          className="person_menu_item">
          <img
            src={require('../../assets/v2/channel.png')}
            style={{ width: '1.1458vw', height: '1.1458vw' }}
            alt=""
          />
          {personMenu == 'Chnanel' ? (
            <div className="person_menu_item_name_active">Channel</div>
          ) : (
            <div className="person_menu_item_name">Channel</div>
          )}
        </div>

        <div
          onClick={() => {
            localStorage.removeItem('token')
            navigate('/SignIn')
          }}
          className="person_menu_item">
          <img
            src={require('../../assets/v2/SignOut.png')}
            style={{ width: '1.1458vw', height: '1.1458vw' }}
            alt=""
          />
          {personMenu == 'Sign' ? (
            <div className="person_menu_item_name_active">Sign Out</div>
          ) : (
            <div className="person_menu_item_name">Sign Out</div>
          )}
        </div>
      </div>

      <div className="person_content">
        {personMenu == 'People' ? (
          <div className="person_people">
            <People></People>
          </div>
        ) : (
          ''
        )}
        {personMenu == 'Orgs' && memberVo.id ? (
          <CPerson_org memberId={memberVo.id}  ></CPerson_org>
        ) : (
          ''
        )}

        {personMenu == 'Post' ? (
          <div>
            <My_post></My_post>
            <MyThoughts></MyThoughts>
          </div>

        ) : (
          ''
        )}


        {personMenu == 'Chnanel' ? <My_chnannel_post></My_chnannel_post> : ''}

        {personMenu == 'PersonalCenter' ? (
          <PersonalCenter></PersonalCenter>
        ) : (
          ''
        )}
        {personMenu == 'NFTbadge' && memberVo.id ? (
          <CPerson_myNft memberId={memberVo.id}></CPerson_myNft>
        ) : (
          ''
        )}

        {personMenu == 'Donation' && memberVo.id ? <CPerson_donation memberId={memberVo.id} email={memberVo.email}  ></CPerson_donation> : ''}
      </div>
    </div>
  )

  let Mobile = (
    <div className="cpersion_box">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="person_logo_img_div">
          <img
            className="cperson_logo_img"
            src={
              memberVo.headImg
                ? memberVo.headImg
                : require('../../assets/v2/logo.png')
            }
            alt=""
          />
        </div>

        <Link  to={'/Person'}   state={{ type: "PersonalCenter"}} >
        <div className="cperson_name">
          <div>{memberVo.userName}</div>
          <div className="cperson_edit"  >Edit Profile</div>
        </div>
      </Link>

      </div>

      <div className="cpersion_boi">{memberVo.bio}</div>
      <Tabs
        activeKey={personMenu}
        onChange={(e) => {
          setPersonMenu(e)
        }}>
        <Tabs.Tab title="Personal Center" key="PersonalCenter">
          <PersonalCenter></PersonalCenter>
        </Tabs.Tab>
        <Tabs.Tab title="My Bookmarks" key="Orgs">
          {memberVo.id ? <CPerson_org memberId={memberVo.id}  ></CPerson_org> : <div></div>}
        </Tabs.Tab>
        <Tabs.Tab title="People" key="People">
          {memberVo.id ? <People></People> : <div></div>}

        </Tabs.Tab>
        <Tabs.Tab title="My NFT Badge" key="NFTbadge">
          {memberVo.id ? <CPerson_myNft memberId={memberVo.id} ></CPerson_myNft> : <div></div>}
        </Tabs.Tab>

        <Tabs.Tab title="My Donation" key="Donation">
          {memberVo.id ? <CPerson_donation memberId={memberVo.id}  email={memberVo.email} ></CPerson_donation> : <div></div>}
        </Tabs.Tab>
        <Tabs.Tab title="My Post" key="Post">
          <CPerson_post_box memberId={memberVo.id}  ></CPerson_post_box>
        </Tabs.Tab>
        <Tabs.Tab title="My Channel" key="Chnanel">
          <My_chnannel_post></My_chnannel_post>
        </Tabs.Tab>
        <Tabs.Tab title="Publish Project" key="PublishProject">
          <CPublishmint></CPublishmint>
        </Tabs.Tab>
        <Tabs.Tab title="Publish Channel" key="PublishChannel">
          <CPublishChannel></CPublishChannel>
        </Tabs.Tab>
      </Tabs>
    </div>
  )

  return Common.isMobile ? Mobile : Web
}
