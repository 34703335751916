import { useEffect, useState } from "react";
import "./SendForumChannel.css";
import { Input,message,Select,Upload ,SelectProps} from 'antd';
import { Popup,NavBar } from 'antd-mobile'
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { Quill_Input } from './Quill_Input';
import { Common } from "../../app/Common";
import { LeftOutline } from 'antd-mobile-icons'
import { Link, useNavigate,useLocation  } from 'react-router-dom'
import { State, useAppDispatch, useAppSelector } from "../../app/Store";
type IProps = {
    handleClick: () => void,
	superiorId?:any,
	channelId?:any
}

export function SendForumChannel(props:IProps) {
	const { tagsList} = useAppSelector((state: State) => state.tags)
	interface ForumInfo{
		title:any,
		content:any,
		imgUrl:any,
		tages:any,
		superiorId:any,
		isPublic:any,
		channelId:any,
		url:any
	   }
  const [isModalOpen, setIsModalOpen] = useState(false);
	const [forumInfo,setForumInfo] =  useState({} as ForumInfo);
	const navigate = useNavigate();
	const { TextArea } = Input;

	const [fileList, setFileList] = useState([] as Array<any>);

	const handleChange = (value: string | string[]) => {
		forumInfo.tages=value.toString();
	  };

	  const isPublicChange = (value: string | string[]) => {
		forumInfo.isPublic=value.toString();
	  };

	  


	useEffect(()=>{

	},[props]);


	function onChange(info:any){
		setFileList([...info.fileList]);
		 if (info.file.status === 'done' || info.file.status === 'removed' ) {
			forumInfo.imgUrl=[]
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				forumInfo.imgUrl.push({name:item.name,url:item.response?item.response.url:item.url});
			}
			setForumInfo({...forumInfo})
		  }
	}

	function postSend(){
	  let 	cyforumInfo = {...forumInfo};
	   cyforumInfo.imgUrl=JSON.stringify(cyforumInfo.imgUrl);
	   cyforumInfo.superiorId=props.superiorId;
	   cyforumInfo.channelId=props.channelId;
		httpPost("/channel/addForum",{...cyforumInfo}).then((e:any)=>{
			if(e.data.code == 0){
				message.success("forum send  is success");
				setForumInfo({}as ForumInfo);
				setFileList([]);
				setIsModalOpen(false);
				props.handleClick();
			}else{
				message.error(e.data.msg);
			}
		})
	}
	

	  async function onPreview  (file: UploadFile) {
		let src = file.url as string;
		if (!src) {
		  src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj as RcFile);
			reader.onload = () => resolve(reader.result as string);
		  });
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	  };
	  const [show, setShow] = useState(false);
	  function  memberCheck(){
		setShow(true);
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 401 ){
				if(props.superiorId){
					localStorage.setItem("pageFrom","/#/Forumlist?forumId="+props.superiorId);
				}else{
					localStorage.setItem("pageFrom","/#/Discover_list");
				}
				navigate("/SignIn");
			}
		})
	}
	let Web= <div className="SendForum_ch_body" >
			<div  className="SendForum_ch_input" >
				{!show? <img src={require("../../assets/v2/write_c.png")}  className="send_write_c"   alt="" />	:""}
			 <Input placeholder={show?'Put a title for your post':'Click here to make a post!'} 		
			     value={forumInfo.title}
				 onClick={memberCheck} 
				 onChange={(e)=>{
					forumInfo.title=e.target.value;
					setForumInfo({...forumInfo});
				}}
				bordered={false} style={{width:"40.1042vw",height: "2.3438vw"}} />
		</div>
		{show?<div   className="SendForum_ch_content"  style={{width:"47.7917vw",height:"9.7292vw"}}  >
				<Quill_Input  w={"46.7917vw"} handleClick={(e=>{
				forumInfo.content=e;
				setForumInfo ({...forumInfo});
		   })} ></Quill_Input>

		</div>:""}

		{show?<div   className="SendForum_ch_img"   >
			<Upload
				action={host_url+"/api/app/oss/uploadImg"}
				listType="picture-card"
				fileList={fileList}
				onChange={onChange}
				onPreview={onPreview}
			>
				{fileList.length < 3 && '+ Upload'}
			</Upload>
			</div>
		:""}


		{show?	<div   className="SendForum_tags"  style={{marginTop:10}}  >
					<div>YouTube URL</div>
					<Input
					placeholder="Please YouTube URL"
					onChange={(e) => {
						forumInfo.url = e.target.value;
						setForumInfo({ ...forumInfo });
					}}
					value={forumInfo.url}
					style={{ width: '300px',marginLeft:"20px" }}
				/>
				</div>
		:""}
		
		{show?	<div   className="SendForum_ch_tags"    >
			<div style={{width:"40px"}} >Tags</div>
			<Select
			mode="tags"
			placeholder="Please select tags"
			onChange={handleChange}
			style={{ width: '300px',marginLeft:"20px" }}
			options={tagsList}
		/>
		</div>
		:""}
		
		{show?	<div   className="SendForum_ch_tags"   >
			<div style={{width:"40px"}} >Section</div>
			<Select
			placeholder="Please select tags"
			onChange={isPublicChange}
			style={{ width: '300px',marginLeft:"20px" }}
			options={[{value:"0",label:"General"},{value:"1",label:"Member"}]}
		/>
		</div>
		:""}
			<div style={{display:"flex"}} >
			{show?	<div   className="SendForum_ch_submit but-shadow"   >
			<div className="SendForum_ch_post"  onClick={postSend} >Post</div>
			</div>
			:""}
				{show?	<div   className="SendForum_submit but-shadow" style={{marginLeft:"10px",backgroundColor:"#ffffff",color:"#000000",border:"1px solid #000000"}}   >
					<div className="SendForum_post "  onClick={()=>{setShow(false)}} >Close</div>
				</div>
				:""}
			</div>
	</div>




let Mobile=<div>
		<Popup  bodyClassName="mproject_pendForum_popup"  style={{height:""}}  visible={isModalOpen} position='bottom' onMaskClick={() => {setIsModalOpen(false)}} >
			<NavBar className="nav-bar" right={<div></div>}  backArrow={<LeftOutline />} onBack={() => {
				setIsModalOpen(false)
			}}>
				Forum
			</NavBar>	
			
		<div className="mpmint_box" style={{paddingLeft:"3.3vw",paddingRight:"3.3vw"}} >
		<div  className="mpmint_input" >
			<Input placeholder="Put a title for your post" 			
			value={forumInfo.title}
			onChange={(e)=>{
				forumInfo.title=e.target.value;
				setForumInfo({...forumInfo});
			}}
			bordered={false}
			style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }} 
			/>
		</div>

		<div   className="SendForum_content"  style={{width:"100%",height:"78.5333vw"}}  >
		<Quill_Input  w={"100%"} h="66.5333vw" value={forumInfo.content} handleClick={(e=>{
			forumInfo.content=e;
			setForumInfo ({...forumInfo});
		})} ></Quill_Input>
		</div>

		<div   className="SendForum_img"   >
		<Upload
			action={host_url+"/api/app/oss/uploadImg"}
			listType="picture-card"
			fileList={fileList}
			onChange={onChange}
			onPreview={onPreview}
		>
			{fileList.length < 3 && '+ Upload'}
		</Upload>
		</div>


		<div   className="mpmint_flex"   >
			<div className="mpmint_lable" >YouTube URL</div>
			<Input
			placeholder="Please YouTube URL"
			onChange={(e) => {
				forumInfo.url = e.target.value;
				setForumInfo({ ...forumInfo });
			}}
			value={forumInfo.url}
			style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
		/>
		</div>


		<div   className="mpmint_flex"   >
		<div className="mpmint_lable"  >Tages</div>
		<Select
		mode="tags"
		placeholder="Please select tags"
		onChange={handleChange}
		bordered={false}
		value={forumInfo.tages}
		style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
		options={tagsList}
		/>
		</div>


		<div   className="mpmint_flex"   >
			<div   className="mpmint_lable" >Section</div>
			<Select
			placeholder="Please select tags"
			onChange={isPublicChange}
			bordered={false}
			style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
			options={[{value:"0",label:"General"},{value:"1",label:"Member"}]}
		/>
		</div>


		<div   className="mpmint_pint"  style={{ marginTop: 20 ,marginBottom:40}}   >
			<div className="mpmint_pint"  onClick={postSend} >Post</div>
		</div>
		</div>
		</Popup>
		<div  className="mpost_forum_but"  >
		<img src={require("../../assets/mobile/forum_post.png")}  onClick={()=>{
			setIsModalOpen(true)
		}}  className="mpost_forum_but_img" alt="" />
		</div>
		</div>
return Common.isMobile ? Mobile : Web ;

}
