import {BasePage, page} from "./BasePage";
import "./Chat.css";
import { MChat} from '../components/MChat';
import { Navigation } from "../components/Navigation";

@page("Chat")
export class Chat extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}    >
		<MChat></MChat>
	</div>
	}
	protected mobileContent(): JSX.Element {
		return <div>
		<MChat></MChat>
		</div>
	}
}
