import { useEffect, useState } from "react";
import { Input, message, Select, Carousel, SelectProps, Divider,Image,Tabs } from 'antd';
import { Link, useNavigate  } from 'react-router-dom'
import { httpGet, httpPost } from '../../utils/httpUtils';
import qs from 'qs';
import { url } from 'inspector';
import { Common } from "../../app/Common";

import { CDonateUs } from "./CDonateUs";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'



export function CDonate() {
	const navigate = useNavigate();
	  function getSkeleton(){
		return  fisrt? <div  >
				 <Skeleton count={1} style={{height:"2.9167vw",width:"100%"}} />
				
				<div  style={{display:"flex"}} >
				<div className="donate_list_item_cld" style={{marginRight:20}} >
						<Skeleton count={1} style={{width: "13.5417vw",height:" 9.5313vw"}} />
					<Skeleton count={1} style={{height:30,width: "13.5417vw"}} />
					<Skeleton count={1} style={{height:30,width: "13.5417vw"}} /> 
				 </div>
				 <div className="donate_list_item_cld" >
						<Skeleton count={1} style={{width: "13.5417vw",height:" 9.5313vw"}} />
					<Skeleton count={1} style={{height:30,width: "13.5417vw"}} />
					<Skeleton count={1} style={{height:30,width: "13.5417vw"}} /> 
				 </div>

				</div>
				
			 </div> :""
   }




	  function getMinStepM(fundRaisingObjectives: any, donateNum: any) {
		let maxWidth = 42.6667
		let width = (maxWidth / fundRaisingObjectives) * donateNum
		return (
		  <div
			className="mproject_box_item_step_bule"
			style={{
			  width: width > maxWidth ? `${maxWidth + 'vw'}` : `${width + 'vw'}`,
			}}></div>
		)
	  }


	function getMinStep(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=6.25;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="donate_step_bule" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}

	const [searchData,setSearchData] =  useState({} as any);
	const [plateList,setPlateList] =  useState([] as Array<any>);
	const [bannerList,setBannerList] =  useState([] as Array<any>);
	const [plateListProject,setPlateListProject] =  useState([] as Array<any>);
	const [fisrt,setFisrt] =  useState(true);
	useEffect(()=>{
		getPlateAll();
		getBannerList();
		getPlateListProject();

	},[]) 
	

	function getPlateAll(){
		httpGet("/project/getPlateAll").then((e:any)=>{
			if(e.data.code==0){
				let list=[];
				for (let index = 0; index < e.data.data.length; index++) {
					const item = e.data.data[index];
					list.push({value:item.plateName,label:item.plateName,tokenList:item.tokenList});
				}
				list.unshift({label:"All",value:"All"});
				setPlateList([...list]);
			}
			setFisrt(false);
		})
	}


	function getBannerList(){
		httpGet("/project/getBannerList").then((e:any)=>{
			if(e.data.code==0){
				setBannerList(e.data.data);

			}
		})
	}

	function getPlateListProject(){
		httpPost("/project/getPlateListProject",searchData).then((e:any)=>{
			if(e.data.code==0){
				setPlateListProject(e.data.data);
			}
		})
	}






   function getTagsBut() {
	let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}
	
	let  Mobile=  <div  style={{width:"95vw",marginTop:10,overflow:"auto",whiteSpace:"nowrap",height:40}} >
		{ plateList.length > 0 ? plateList.map((item,indx)=>{
		  let color = ''
		  if (item.value) {
			color = color_conf[item.value.substring(0, 1).toLowerCase()]
			  ? color_conf[item.value.substring(0, 1).toLowerCase()]
			  : '#FFB4A2'
		  }
		  return <span key={indx}  onClick={()=>{
			  if(item.value =='All'){
				 delete searchData.plateName;
			  }else{
				searchData.plateName=item.value;
			  }
			  setSearchData({...searchData})
			  getPlateListProject();
		  }} className='all_tags_but'  style={{background:color,opacity:  searchData.plateName== item.value ||  searchData.plateName ==''   ? 1:0.8}} >{item.value}</span>
		}) : <Skeleton count={1}   style={{height:"80px",width:"100%"}} />
		}
	</div>

	let  Web=  <div  style={{width:"100%",marginTop:10,display:"flex",flexWrap:"wrap"}} >
	  {plateList.length > 0 ? plateList.map((item,indx)=>{
		let color = ''
		if (item.value) {
		  color = color_conf[item.value.substring(0, 1).toLowerCase()]
			? color_conf[item.value.substring(0, 1).toLowerCase()]
			: '#FFB4A2'
		}
		return <div key={indx}  onClick={()=>{
		  if(item.value =='All'){
			delete searchData.plateName;
		  }else{
			searchData.plateName=[item.value];
		  }
		  setSearchData({...searchData})
		  getPlateListProject();
		}} className='call_tags_but but-shadow'  style={{background:color,opacity: searchData.plateName== item.value ||  searchData.plateName =='' ? 1:0.8}} >{item.value}</div>
	  }):  <Skeleton count={1}  style={{height:40}} />
	  }
</div>

	return Common.isMobile ? Mobile : Web
  }



	let Web=   <div className="donateContent">
			{bannerList.length > 0 ? <div className="donate_banner"> <Carousel effect="fade" autoplay>
				{bannerList.map((item:any,index:any)=>{
						return <div  key={index} >
							<Link to={"/Project?projectId="+item.id}  >
								<div className="donate_banner_box"  style={{backgroundImage:`url(${JSON.parse(item.bannerImgUrl)[0].url})`}}  ></div>
							</Link>
						</div>
				})} 
			</Carousel>	</div>:<div></div>}
			

		
				<Tabs style={{paddingLeft:"120px",paddingRight:"120px"}} >
						<Tabs.TabPane tab="International" key="item-1">
								<div  className="flex_width100_center m_top_32 ">
								<div  className="badge_options" >
									<div className="badge_options_seacher" >
										<div className="badge_options_seacher" >
										<div className="badge_options_seacher_box" >
											<Input style={{width:"80%"}}  onChange={(e)=>{
												searchData.keyWord=e.target.value;
												setSearchData({...searchData});
											}} bordered={false} placeholder="Basic usage" />
										</div>
										<div className="badge_options_seacher_filters" onClick={getPlateListProject} >
											<img src={require("../../assets/v2/filter.png")}  className="badge_box_group_item_filters" alt="" />
											Filters
										</div>
										</div>
									</div>
									{getTagsBut()}
								</div>
								</div>

								<div className="donate_list_box"  >
										
												

									{plateListProject.length > 0 ?  plateListProject.map((item,index)=>{
										return  <div key={index}  className="donate_list" >
										<div className="donate_list_title" >
											<div className="donate_list_title_1" >{item.plateName}</div>

											<Link to={"/ProjectList"}  >
												<div className="donate_list_title_2 but-shadow"   >View all</div>
											</Link>
										</div>
										{item.projectList.map((ite:any,inx:any)=>{
											return  <div key={inx} className="donate_list_item"  style={{marginLeft:inx==0? 0:30}}  >
														<Link to={"/Project?projectId="+ite.id}  >
														<div className="donate_list_item_cld" >
														<Image src={JSON.parse(ite.bannerImgUrl)[0].url} alt="" height={"9.5313vw"}  width={"13.5417vw"} className="donate_list_item_cld_img" />
														<div className="donate_list_item_cld_name" >{ite.name}</div>
														<div className="donate_list_item_cld_name_desc" >{ite.description}</div>
														<div  className="flex_width100 donate_list_item_cld_num " style={{width:"100%"}} >
															<div  className="donate_num"  >{ite.donateNum}{ite.receivingToken} / {ite.fundRaisingObjectives}{ite.receivingToken}</div>
															<div className="donate_step" >{getMinStep(ite.fundRaisingObjectives,ite.donateNum)}</div>
														</div>
														</div>
													</Link>
										</div>
										})}
									</div>
									}):getSkeleton()}
								</div>
											
						</Tabs.TabPane>
			<Tabs.TabPane tab="USA" key="item-2">
				<CDonateUs></CDonateUs>
			</Tabs.TabPane>
			</Tabs>

		
	</div>

 let  Mobile=<div className="mdonate_box" >

		{bannerList.length > 0 ? <div className="mdonate_banner"> 
		<Carousel effect="fade" autoplay>
			{bannerList.map((item:any,index:any)=>{
					return <div  key={index} >
						<Link to={"/Project?projectId="+item.id}  >
							<div className="mdonate_banner_box"  style={{backgroundImage:`url(${JSON.parse(item.bannerImgUrl)[0].url})`}}  ></div>
						</Link>
					</div>
			})} 
		</Carousel>	</div>:<div></div>}

		<div  style={{paddingLeft:20}} >
			<Tabs   >
				<Tabs.TabPane tab="International" key={1}>
					<div className="mdonate_list_box"  >
						{plateListProject.length > 0 ?  plateListProject.map((item,index)=>{
							return  <div key={index}  className="mdonate_list" >
							<div className="cdonate_list_title" >
								<div className="cdonate_list_title_1" >{item.plateName}</div>

									<div className="cdonate_list_title_2 but-shadow"   onClick={()=>{
										navigate("/ProjectList",{state:{ plateName: item.plateName }} )
									}} >View all</div>
							</div>
							{item.projectList.map((ite:any,inx:any)=>{
								return  <div key={inx} className="cdonate_list_item"   >
											<Link to={"/Project?projectId="+ite.id}  >
												<div  style={{width:"100%",display:"flex",justifyContent:"center"}}>
														<Image src={JSON.parse(ite.bannerImgUrl)[0].url} alt="" className="cdonate_list_item_cld_img" />
												</div>
											<div className="cdonate_list_item_cld_name" >{ite.name}</div>
											<div className="mdonate_list_item_cld_name_desc" >{ite.description}</div>
											<div  className="donate_list_item_cld_num "  style={{paddingLeft:10}} >
												<div  className="cdonate_num"  >{ite.donateNum}{ite.receivingToken} / {ite.fundRaisingObjectives}{ite.receivingToken}</div>
												<div className="mproject_box_item_step" >{getMinStepM(ite.fundRaisingObjectives,ite.donateNum)}</div>
											</div>
										</Link>
							</div>
							})}
						</div>
						}):<div></div>}
					</div>
				</Tabs.TabPane>
					<Tabs.TabPane tab="USA" key="2">
						<CDonateUs></CDonateUs>
					</Tabs.TabPane>
			</Tabs>
		</div>
		
 </div>

return Common.isMobile ? Mobile : Web 

	
}



