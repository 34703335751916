import { useEffect, useState } from "react";
import { Input,message,Select,Upload} from 'antd';
import { Link, useNavigate,useLocation  } from 'react-router-dom'
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Common } from "../../app/Common";
import { State, useAppDispatch, useAppSelector } from "../../app/Store";



export function CPublishLive() {
	const { tagsList} = useAppSelector((state: State) => state.tags)
	let query = new URLSearchParams(useLocation().search);
	let  channelId =query.get("channelId");
	const { TextArea } = Input;
	const navigate = useNavigate();
	interface ProjectVo{
		name:any,
		tages:any,
		description:any,
		url:any,
		image:any,
		channelId:any,
		projectId:any
	}

	const [projct,setProjct] =  useState({}as ProjectVo );
	const [projectList,setProjctList] =  useState([]as Array<any>);

	

	const [fileListBanner, setFileListBanner] = useState([] as Array<any>);

	function revert(){
		window.history.back();
	}
	function getProjctList(){
		httpPost("/project/getPageList",{}).then((e:any)=>{
			let list=[];
			if(e.data.code==0){
				for (let index = 0; index < e.data.data.list.length; index++) {
					const item = e.data.data.list[index];
					list.push({value:item.id,label:item.name});
				}
				setProjctList([...list]);
			}
		})
	}
	
	function onChangeBanner(info:any){
		setFileListBanner([...info.fileList]);
		 if (info.file.status === 'done' || info.file.status === 'removed' ) {
			projct.image=[];
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				if(item.response){
					projct.image.push({name:item.name,url:item.response.url,width:item.response.width,height:item.response.height});
				}
			}
		  }
		  setProjct({...projct})
	}
	



	async function onPreview  (file: UploadFile) {
		let src = file.url as string;
		if (!src) {
		  src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj as RcFile);
			reader.onload = () => resolve(reader.result as string);
		  });
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	  };

	useEffect(()=>{
		getProjctList();
	},[]) 
	async function release(){
		let  cpprojct={...projct};
		if(!cpprojct.name){
			message.error("channel name is Required");
			return
		}
		if(!channelId){
			message.error("channelId is Required");
			return
		}

		if(!cpprojct.url){
			message.error("YouTube URL is Required");
			return
		}

		if(cpprojct.image){
			cpprojct.image=JSON.stringify(cpprojct.image);
		}
		if(cpprojct.tages){
			cpprojct.tages=cpprojct.tages.toString()
		}
		cpprojct.channelId=channelId;
		httpPost("/live/addLive",cpprojct).then((e:any)=>{
			if(e.data.code == 0){
				message.success("release channelId is success");
				navigate("/Channel?channelId="+channelId)
			}else{
				message.error(e.data.msg);
			}
		})
	}



	
	let  Web= <div  className="pmint_body" >
	<div className="pmint_box" >
	<div  className="pmint_name" >Live release</div>
	<div  className="pmint_input" >
		<span className="lable_red" >*</span>
		<Input placeholder=" Live  name"  
			value={projct.name}
			onChange={(e)=>{
				projct.name=e.target.value;
				setProjct({...projct});
			}}
		bordered={false} style={{width:"40.1042vw",height:"3.125vw",borderBottom:"1px solid #EBEBEB"}} />
	</div>
	
	
	<div  style={{display:"flex",alignItems:"flex-start"}} >
		<span className="lable_red" >*</span>
		<div style={{border:"1px solid #EBEBEB"}} >
			<TextArea placeholder="Description" 
				value={projct.description}
				onChange={(e)=>{
					projct.description=e.target.value;
					setProjct({...projct});
				}}
				bordered={false} style={{width:"40.1042vw",height:"4.8438vw"}} />

			</div>
	</div>
	
	<div className="pmint_flex" style={{marginTop:"1.0417vw"}} >
	<span className="lable_red" >*</span>
		<div className="pmint_lable" > YouTube URL</div>
		<Input   className="pmint_input" 
		value={projct.url}
		onChange={(e)=>{
			projct.url=e.target.value;
			setProjct({...projct});
		}}
		style={{width:"18.8542vw"}} />
	</div>


	<div className="pmint_flex"  >
	<span className="lable_red" >&nbsp;&nbsp;</span>
		<div className="pmint_lable" >Project</div>
		<Select
			placeholder="Please select  donate  project"
			value={projct.projectId}
			onChange={(e)=>{
				projct.projectId=e;
				setProjct({...projct});
			}}
			style={{ width: '18.8542vw' }}
			allowClear={true}
			options={projectList}
		/>
	</div>

	
	<div className="pmint_flex"  >
	<span className="lable_red" >*</span>
		<div className="pmint_lable" >Tags</div>
		<Select
			mode="tags"
			placeholder="Please select tags"
			value={projct.tages}
			onChange={(e)=>{
				projct.tages=e;
				setProjct({...projct});
			}}
			style={{ width: '18.8542vw' }}
			options={tagsList}
		/>
	</div>

	<div className="projectflex" >
		<span className="lable_red" >*</span>
		<div className="pmint_lable" style={{marginRight:50}} >Image</div>
			<Upload
				action={host_url+"/api/app/oss/uploadImg"}
				listType="picture-card"
				fileList={fileListBanner}
				onChange={onChangeBanner}
			>
				{fileListBanner.length < 1 && '+ Upload'}
			</Upload>
	</div>

	<div className="projectflex"  style={{marginTop:20}} >
		<div  className="pmint_preview but-shadow"   onClick={revert} >
			Back
		</div>
		<div  className="pmint_pint but-shadow"  onClick={release} >
			Publish Live
		</div>
	</div>

	</div>
</div>

let Mobile=<div  className="mpmint_body"  style={{paddingLeft:"3.3vw",paddingRight:"3.3vw"}} >
		<div className="mpmint_box" >
		<div  className="mpmint_input" >
			<span className="lable_red" >*</span>
			<Input placeholder=" Live  name"  
				value={projct.name}
				onChange={(e)=>{
					projct.name=e.target.value;
					setProjct({...projct});
				}}
			bordered={false}
			style={{ width: "100%", height: "11.7333vw", borderBottom: "1px solid #EBEBEB" }} 
			/>
		</div>


		<div  className="mpmint_input" style={{ display: "flex", alignItems: "flex-start" }} >
			<span className="lable_red" >*</span>
			<TextArea placeholder="Description" 
			value={projct.description}
			onChange={(e)=>{
				projct.description=e.target.value;
				setProjct({...projct});
			}}
			bordered={false} style={{ width: "100%", height: "40vw", borderBottom: "1px solid #EBEBEB" }}
			/>
		</div>

		<div className="mpmint_flex" style={{marginTop:"1.0417vw"}} >
			<div className="mpmint_lable" > YouTube URL<span className="lable_red" >*</span></div>
			<Input   className="pmint_input" 
			value={projct.url}
			onChange={(e)=>{
				projct.url=e.target.value;
				setProjct({...projct});
			}}
			style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
			/>
		</div>


		<div className="mpmint_flex"  >
			<div className="mpmint_lable" >Project<span className="lable_red" ></span></div>
			<Select
				placeholder="Please select  donate  project"
				value={projct.projectId}
				onChange={(e)=>{
					projct.projectId=e;
					setProjct({...projct});
				}}
				style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
				allowClear={true}
				options={projectList}
			/>
		</div>


		<div className="mpmint_flex"  >
			<div className="mpmint_lable" >Tags<span className="lable_red" >*</span></div>
			<Select
				mode="tags"
				placeholder="Please select tags"
				value={projct.tages}
				onChange={(e)=>{
					projct.tages=e;
					setProjct({...projct});
				}}
				style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
				options={tagsList}
			/>
		</div>

		<div className="mpmint_flex" >
			<div className="pmint_lable"  >Image<span className="lable_red" >*</span></div>
				<Upload
					action={host_url+"/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileListBanner}
					onChange={onChangeBanner}
				>
					{fileListBanner.length < 1 && '+ Upload'}
				</Upload>
		</div>

		<div className="mpmint_but_box"  style={{marginTop:20,marginBottom:40}} >
			<div  className="mpmint_preview"   onClick={revert} >
				Back
			</div>
			<div  className="mpmint_pint"  onClick={release} >
				Publish Live
			</div>
		</div>

		</div>
</div>


return Common.isMobile ? Mobile : Web 
}
