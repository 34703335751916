import { useEffect, useState } from "react";
import { Input,InputNumber, message, Select, Upload } from 'antd';
import { Link, useNavigate } from 'react-router-dom'
import { httpGet, httpPost, host_url } from '../../utils/httpUtils';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Common } from "../../app/Common";
import "./CPublishmint.css";
import { Quill_Input } from './Quill_Input';
import { State, useAppDispatch, useAppSelector } from "../../app/Store";


export function CPublishmint() {
	const { tagsList} = useAppSelector((state: State) => state.tags)
	const { TextArea } = Input;
	const navigate = useNavigate();
	interface ProjectVo {
		plateName: any,
		name: any,
		description: any,
		detail: any,
		fundRaisingObjectives: any,
		receivingToken: any,
		receivingAddress: any,
		officialWebsite: any,
		imgUrl: any,
		discode: any,
		twitter: any,
		tages: any,
		bannerImgUrl: any,
		paypalAccount: any,
		showEth:any,
		showPayPal:any
	}

	const [projct, setProjct] = useState({} as ProjectVo);

	const [tokenList, setTokenList] = useState([] as Array<any>);

	const [plateList, setPlateList] = useState([] as Array<any>);



	const [fileList, setFileList] = useState([] as Array<any>);

	const [fileListBanner, setFileListBanner] = useState([] as Array<any>);


	function revert() {
		setFileListBanner([]);
		setFileList([]);
		setProjct({} as ProjectVo);
	}

	function onChangeBanner(info: any) {
		setFileListBanner([...info.fileList]);
		if (info.file.status === 'done' || info.file.status === 'removed') {
			projct.bannerImgUrl = [];
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				if (item.response) {
					projct.bannerImgUrl.push({ name: item.name, url: item.response.url, width: item.response.width, height: item.response.height });
				}
			}
		}
		setProjct({ ...projct })
	}


	function onChange(info: any) {
		setFileList([...info.fileList]);
		if (info.file.status === 'done' || info.file.status === 'removed') {
			projct.imgUrl = [];
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				if (item.response) {
					projct.imgUrl.push({ name: item.name, url: item.response.url, width: item.response.width, height: item.response.height });
				}
			}
		}
		setProjct({ ...projct })
	}


	async function onPreview(file: UploadFile) {
		let src = file.url as string;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj as RcFile);
				reader.onload = () => resolve(reader.result as string);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	};



	useEffect(() => {
		getPlateAll();
		memberCheck();
	}, [])

	function memberCheck() {
		httpGet("/member/getMember").then((e: any) => {
			if (e.data.code == 401) {
				localStorage.setItem("pageFrom", "/#/Publishmint");
				navigate("/SignIn");
			}
		})
	}


	

	function getTokenAll(tokenList: any) {
		let list = [];
		for (let index = 0; index < tokenList.length; index++) {
			const item = tokenList[index];
			list.push({ value: item.tokenName, label: item.tokenName });
		}
		setTokenList([...list]);
	}


	function getPlateAll() {
		httpGet("/project/getPlateAll").then((e: any) => {
			if (e.data.code == 0) {
				let list = [];
				for (let index = 0; index < e.data.data.length; index++) {
					const item = e.data.data[index];
					list.push({ value: item.plateName, label: item.plateName, tokenList: item.tokenList });
				}
				setPlateList([...list]);
			}
		})
	}





	async function release() {

		let cpprojct = { ...projct };

		if (!cpprojct.name) {
			message.error("projct name is Required");
			return
		}

		if (!cpprojct.description) {
			message.error("projct description is Required");
			return
		}


		if (!cpprojct.detail) {
			message.error("projct detail is Required");
			return
		}


		if (cpprojct.fundRaisingObjectives == 0) {
			message.error("projct fundRaisingObjectives is Required");
			return
		}

		if(!cpprojct.receivingAddress && !cpprojct.paypalAccount){
			message.error("A PayPal account and/or an ETH address required ");
			return
		}


		if (!cpprojct.imgUrl) {
			message.error("projct img is Required");
			return
		}

		if (cpprojct.bannerImgUrl) {
			cpprojct.bannerImgUrl = JSON.stringify(cpprojct.bannerImgUrl);
		}
		if (cpprojct.imgUrl) {
			cpprojct.imgUrl = JSON.stringify(cpprojct.imgUrl);
		}


		if (cpprojct.tages) {
			cpprojct.tages = cpprojct.tages.toString()
		}

		httpPost("/project/release", cpprojct).then((e: any) => {
			if (e.data.code == 0) {
				message.success("Submission Successful! Pending Review");
				navigate("/Person", { state: { type: "Post" } })
			} else {
				message.error(e.data.msg);
			}
		})
	}

	let Web = <div className="pmint_body" >
		<div className="pmint_box" >
			<div className="pmint_name" >Publish Project</div>
			<div className="pmint_input" >
				<span className="lable_red" >*</span>
				<Input placeholder="project name"
					value={projct.name}
					onChange={(e) => {
						projct.name = e.target.value;
						setProjct({ ...projct });
					}}

					bordered={false} style={{ width: "40.1042vw", height: "3.125vw", borderBottom: "1px solid #EBEBEB" }} />

			</div>

			<div className="pmint_input"   >
				<span className="lable_red" >*</span>
				<Input placeholder="Your project description"
					value={projct.description}
					onChange={(e) => {
						projct.description = e.target.value;
						setProjct({ ...projct });
					}}
					bordered={false} style={{ width: "40.1042vw", height: "2.3438vw" }} />
					
			</div>
			<div style={{ display: "flex", alignItems: "flex-start",height:"12.8438vw" }} >
				<span className="lable_red" >*</span>
				<div  >
						<Quill_Input  w={"40.1042vw"} h={"9.8438vw"} 
							placeholder="Your project details"
							handleClick={(e=>{
							projct.detail=e;
							setProjct({ ...projct });
							})} ></Quill_Input>
				</div>


			</div>

			<div className="pmint_flex"  style={{flexWrap:"wrap"}} >
				<div className="pmint_lable"  style={{width:"100%"}} >	<span className="lable_red" >*</span>Tags</div>
				<Select
					mode="tags"
					placeholder="Please select tags"
					value={projct.tages}
					onChange={(e) => {
						projct.tages = e;
						setProjct({ ...projct });
					}}
					style={{ width: '25.8542vw' }}
					options={tagsList}
				/>
			</div>

			<div className="pmint_flex"  style={{marginTop:20,flexWrap:"wrap"}} >
				<div className="pmint_lable"  style={{width:"100%"}} ><span className="lable_red" >*</span>Category</div>
				{plateList.length > 0 ?
					<Select
						style={{ width: "25.8542vw" }}
						value={projct.plateName}
						onChange={(e, op) => {
							getTokenAll(op.tokenList);
							projct.plateName = e;
							setProjct({ ...projct });
						}}
						options={plateList}
					/> : ""
				}
			</div>

		

			<div className="pmint_flex" style={{flexWrap:"wrap"}} >
				<div className="pmint_lable" style={{width:"100%"}}  ><span className="lable_red" >*</span> Receiving currency</div>

				<div className="pmint_lable"  style={{display:"flex",width:"100%",marginTop:10}}  >
							<div  className={projct.showEth?'pint_pay_but_active but-shadow':'pint_pay_but but-shadow'} onClick={()=>{
								  projct.showEth=!projct.showEth;
								  setProjct({...projct});
							}} >
								Receiving address
							</div>
							<div  style={{marginLeft:10}} className={projct.showPayPal?'pint_pay_but_active but-shadow':'pint_pay_but but-shadow'} onClick={()=>{
								  projct.showPayPal=!projct.showPayPal;
								  setProjct({...projct});
							}}  >
								PayPal
							</div>
					</div>
					{tokenList.length > 0 ?
					<div className="pmint_lable"  style={{width:"100%",marginTop:10}}  >
						<Select
							style={{ width: "25.8542vw" }}
							value={projct.receivingToken}
							placeholder="Receiving currency "
							onChange={(e) => {
								projct.receivingToken = e;
								setProjct({ ...projct });
							}}
							options={tokenList}
						/> 
					</div>:""}
					{projct.showEth?
				<div className="pmint_lable"  style={{width:"100%",marginTop:10}}  >
					<Input className="pmint_input"
						value={projct.receivingAddress}
						placeholder="Receiving address"
						onChange={(e) => {
							projct.receivingAddress = e.target.value;
							setProjct({ ...projct });
						}}
						style={{ width: "25.8542vw" }} />
				</div>:""}

				{projct.showPayPal?<div className="pmint_lable"  style={{width:"100%",marginTop:10}}  >
						<Input className="pmint_input"
						value={projct.paypalAccount}
						placeholder="PayPal Account"
						onChange={(e) => {
							projct.paypalAccount = e.target.value;
							setProjct({ ...projct });
						}}
						style={{ width: "25.8542vw" }} />
					</div>:""}		
					
			</div>

			<div className="pmint_flex"  style={{flexWrap:"wrap"}}  >
				<div className="pmint_lable" style={{width:"100%"}}  > 	<span className="lable_red" >*</span>Fundraising amount</div>
				<InputNumber
					type={"number"}
					min={0}
					value={projct.fundRaisingObjectives}
					onChange={(e) => {
						projct.fundRaisingObjectives = e;
						setProjct({ ...projct });
					}}

					className="pmint_input" style={{ width: "25.8542vw" }} />
			</div>


			<div className="pmint_flex"  style={{flexWrap:"wrap"}}  >
				<div className="pmint_lable" style={{width:"100%"}}  >	<span className="lable_red" >*</span>Official Website</div>
				<Input className="pmint_input"
					value={projct.officialWebsite}
					onChange={(e) => {
						projct.officialWebsite = e.target.value;
						setProjct({ ...projct });
					}}
					style={{ width: "25.8542vw" }} />
			</div>

			<div className="pmint_flex"  style={{flexWrap:"wrap"}}  >
			
				<div className="pmint_lable" style={{width:"100%"}}  ><span className="lable_red" >&nbsp;&nbsp;</span>Twitter</div>
				<Input className="pmint_input"
					value={projct.twitter}
					onChange={(e) => {
						projct.twitter = e.target.value;
						setProjct({ ...projct });
					}}
					style={{ width: "25.8542vw" }} />
			</div>

			<div className="projectflex"  style={{flexWrap:"wrap"}}  >
				
				<div className="pmint_lable" style={{ marginRight: 50 }} ><span className="lable_red" >*</span>Icon</div>
				<Upload
					action={host_url + "/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileListBanner}
					onChange={onChangeBanner}
				>
					{fileListBanner.length < 1 && '+ Upload'}
				</Upload>
			</div>



			<div className="projectflex"   style={{flexWrap:"wrap"}} >
			
				<div className="pmint_lable" style={{ marginRight: 50 }} >	<span className="lable_red" >*</span>Pictures</div>
				<Upload
					action={host_url + "/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileList}
					onChange={onChange}
				>
					{fileList.length < 3 && '+ Upload'}
				</Upload>
			</div>

			<div className="projectflex" style={{ marginTop: 20 }} >
				<div className="pmint_preview but-shadow" onClick={revert} >
					Revert
				</div>
				<div className="pmint_pint but-shadow" onClick={release} >
					Publish project
				</div>
			</div>
		</div>



	</div>


	let Mobile = <div className="mpmint_body" >
		<div className="mpmint_box" >
			<div className="mpmint_input" >
				<span className="lable_red" >*</span>
				<Input placeholder="project name"
					value={projct.name}
					onChange={(e) => {
						projct.name = e.target.value;
						setProjct({ ...projct });
					}}
					bordered={false} style={{ width: "100%", height: "11.7333vw", borderBottom: "1px solid #EBEBEB" }} />
			</div>

			<div className="mpmint_input"   >
				<span className="lable_red" >*</span>
				<Input placeholder="Your project description"
					value={projct.description}
					onChange={(e) => {
						projct.description = e.target.value;
						setProjct({ ...projct });
					}}
					bordered={false} style={{ width: "100%", height: "11.7333vw", borderBottom: "1px solid #EBEBEB" }} />
			</div>
			<div className="mpmint_input" style={{ display: "flex", alignItems: "flex-start" }} >
				<span className="lable_red" >*</span>
				<TextArea placeholder="Your project details"
					value={projct.detail}
					onChange={(e) => {
						projct.detail = e.target.value;
						setProjct({ ...projct });
					}}
					bordered={false} style={{ width: "100%", height: "40vw", borderBottom: "1px solid #EBEBEB" }} />
			</div>

			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Tags<span className="lable_red" >*</span></div>
				<Select
					mode="tags"
					placeholder="Please select tags"
					value={projct.tages}
					onChange={(e) => {
						projct.tages = e;
						setProjct({ ...projct });
					}}
					style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
					options={tagsList}
				/>
			</div>


			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Category<span className="lable_red" >*</span></div>
				{plateList.length > 0 ?
					<Select
						style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
						value={projct.plateName}
						onChange={(e, op) => {
							getTokenAll(op.tokenList);
							projct.plateName = e;
							setProjct({ ...projct });
						}}
						options={plateList}
					/> : ""
				}
			</div>

			
			{tokenList.length > 0 ?
				<div className="mpmint_flex">
					<div className="mpmint_lable" >Receiving currency<span className="lable_red" >*</span><span  style={{color:"#ADADAD"}} >(multiple choice)</span></div>

					<div className="mpmint_flex"  style={{display:"flex",width:"100%",justifyContent:"space-between",paddingLeft:10,paddingRight:10}}  >
							<div  className={projct.showEth?'mpint_pay_but_active':'mpint_pay_but'} onClick={()=>{
								  projct.showEth=!projct.showEth;
								  setProjct({...projct});
							}} >
								Receiving address
							</div>
							<div  className={projct.showPayPal?'mpint_pay_but_active':'mpint_pay_but'} onClick={()=>{
								  projct.showPayPal=!projct.showPayPal;
								  setProjct({...projct});
							}}  >
								PayPal
							</div>
					</div>
					
						<Select
							style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
							value={projct.receivingToken}
							placeholder="Receiving currency "
							onChange={(e) => {
								projct.receivingToken = e;
								setProjct({ ...projct });
							}}
							options={tokenList}
						/> 
						{projct.showEth ? <Input className="mpmint_input"
							value={projct.receivingAddress}
							placeholder="Receiving address"
							onChange={(e) => {
								projct.receivingAddress = e.target.value;
								setProjct({ ...projct });
							}}
							style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
						/>:"" }
						
							{
								projct.showPayPal?<Input className="mpmint_input"
								value={projct.paypalAccount}
								onChange={(e) => {
									projct.paypalAccount = e.target.value;
									setProjct({ ...projct });
								}}
								placeholder="PayPal Account"
								style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
							/>:""
							}
				</div>: ""
			}

			<div className="mpmint_flex">
				<div className="mpmint_lable" >Fundraising amount <span className="lable_red" >*</span></div>
				<InputNumber
					type={"number"}
					min={0}
					value={projct.fundRaisingObjectives}
					onChange={(e) => {
						projct.fundRaisingObjectives = e;
						setProjct({ ...projct });
					}}
					style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }} />
			</div>



			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Official Website <span className="lable_red" >*</span> </div>
				<Input className="mpmint_box"
					value={projct.officialWebsite}
					onChange={(e) => {
						projct.officialWebsite = e.target.value;
						setProjct({ ...projct });
					}}
					style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
				/>
			</div>

			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Twitter</div>
				<Input className="mpmint_box"
					value={projct.twitter}
					onChange={(e) => {
						projct.twitter = e.target.value;
						setProjct({ ...projct });
					}}
					style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
				/>
			</div>

			<div className="mpmint_flex" >
				<div className="mpmint_lable" style={{ marginRight: 50, marginBottom: 10 }} >Icon<span className="lable_red" >*</span></div>
				<Upload
					action={host_url + "/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileListBanner}
					onChange={onChangeBanner}
				>
					{fileListBanner.length < 1 && '+ Upload'}
				</Upload>
			</div>

			<div className="mpmint_flex" >
				<div className="mpmint_lable" style={{ marginRight: 50, marginBottom: 10 }} >Pictures<span className="lable_red" >*</span></div>
				<Upload
					action={host_url + "/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileList}
					onChange={onChange}
				>
					{fileList.length < 3 && '+ Upload'}
				</Upload>
			</div>

			<div className="mpmint_but_box" style={{ marginTop: 20 }} >
				<div className="mpmint_preview" onClick={revert} >
					Revert
				</div>

				<div className="mpmint_pint" onClick={release} >
					Publish project
				</div>
			</div>
		</div>
	</div>
	return Common.isMobile ? Mobile : Web

}
