import { useEffect, useState } from "react";
import { Input,message,Select,Drawer,InputNumber } from 'antd';
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import {httpGet,httpPost} from '../../utils/httpUtils';
import {Ctrending_star} from "./Ctrending_star"
import { Formatter } from './Formatter';
interface CommentVo{
	tidingId:any,
	content:any
}


export function CTidings() {
	const { TextArea } = Input;
	const navigate = useNavigate();
	let query = new URLSearchParams(useLocation().search);
	let  tidingId = 	query.get("tidingId");
	const [tiding,setTiding] =  useState([]as any);
	const [commentList,setCommentList] =  useState([]as any);
	const [commentVo,setCommentVo] =  useState({}as CommentVo);
	useEffect(()=>{
		if(tidingId){
			getDetail();
			getComment();
		}
	
	},[]);

	function getDetail(){
		httpGet("/tidings/getDetail/"+tidingId).then((e:any)=>{
			setTiding(e.data.data);
		})
	}

	function commentSubmit(){
		httpPost("/tidings/comment",{forumId:tidingId,content:commentVo.content}).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("comment is  success");	
				getComment();
				getDetail();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/News?tidingId="+tidingId);
				navigate("/SignIn");
			}
		});
	}


	function commentSubmitChild(id:any){
		httpPost("/tidings/comment",{forumId:tidingId,content:commentVo.content,superiorId:id}).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("comment is  success");	
				getComment();
				getDetail();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/News?tidingId="+tidingId);
				navigate("/SignIn");
			}
		});
	}

	function getComment(){
		httpGet("/tidings/getComment/"+tidingId).then((e:any)=>{
			setCommentList(e.data.data);
		})
	}

	function showSendContent(indx:any,item:any){
		for (let index = 0; index < commentList.length; index++) {
			if(indx !=index ){
				commentList[index].showSendContent=false;
			}
		}
		commentList[indx].showSendContent=!commentList[indx].showSendContent;
		setCommentList([...commentList]);
	}


	return  <div  className="tiding_body" >
		
		{tiding.id ? <div  className="tiding_left"  >
		<div className="tiding_title" >
			{tiding.title}
		</div>
	
		<div className="tiding_text_desc"   dangerouslySetInnerHTML={{__html:tiding.content}}>
		</div>


		<div className="tiding_comment_box" >
				<div className="tiding_feedback" >Feedback</div>
				<div className="tiding_comment_input"  >
					<TextArea placeholder="Your Mint Details" 
					autoSize={{ minRows: 2, maxRows: 6 }}
					onChange={(e)=>{
						commentVo.content=e.target.value;
						setCommentVo({...commentVo});
					}}
					bordered={false} style={{width:"40.1042vw",height:"9.8438vw"}} />

					<div className="tiding_send_but_box" >
							<div className="tiding_send_but"  onClick={commentSubmit} >
								SEND
							</div>
					</div>
				</div>
				<div  className="tiding_comment_msg" >
					{commentList.map((item:any,index:any)=>{
						return 	<div key={index} style={{marginTop:30}} >
						<div className="tiding_comment_item" >
							<div className="tiding_comment_item_1"  >
								<img src={item.memberHeadImg?item.memberHeadImg:require("../../assets/v2/logo.png")} alt=""   className="member_logo" /> 
								<div className="tiding_comment_item_1_1" >{item.memberName}</div>
							</div>
								<div className="tiding_comment_item_2"  >{item.content}</div>
								<div  className="tiding_content_item_sx" >
									<div className="tiding_comment_item_3"  ><Formatter dateTime={item.createDateTime} ></Formatter></div>
									<div className="tiding_comment_item_3"  >like</div>
									<div className="tiding_comment_item_3"  onClick={()=>showSendContent(index,item)} >repley</div>
								</div>
								{item.showSendContent? <div className="tiding_comment_input"  >
									<TextArea placeholder="Your Mint Details" 
									autoSize={{ minRows: 2, maxRows: 6 }}
									onChange={(e)=>{
										commentVo.content=e.target.value;
										setCommentVo({...commentVo});
									}}
									bordered={false} style={{width:"40.1042vw",height:"9.8438vw"}} />

									<div className="tiding_send_but_box" >
											<div className="tiding_send_but"  onClick={()=>commentSubmitChild(item.id)} >
												SEND
											</div>
									</div>
								</div>:""}
								


						</div>
					</div>
					})}
				</div>
			</div> 
		</div>:<div  className="tiding_left"  ></div>}

	


	<div className="tiding_right" >
		<Ctrending_star   paddingLeft_={"60px"} isCreate={false} ></Ctrending_star>
	</div>
	</div>
}
