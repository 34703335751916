import { useEffect, useState } from "react";
import { Input,message,Select,Upload,Dropdown,Image,Badge} from 'antd';
import { Input as InputH5,ImageUploader, ImageUploadItem,Popup } from 'antd-mobile'
import { SearchOutline,LeftOutline, MoreOutline  } from 'antd-mobile-icons'
import { Link, useNavigate,useLocation  } from 'react-router-dom'
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import moment from 'moment';
import {Ctrending_star} from './Ctrending_star';
import { UserHeader } from './UserHeader';
import { Quill_Input } from './Quill_Input';
import { Formatter } from './Formatter';
import {Common} from "../../app/Common";

import { State, useAppDispatch, useAppSelector } from "../../app/Store";
import { increment, decrement, incrementAsync } from '../../slices/count'


import { type } from 'os';
export function MChat() {
	const dispatch = useAppDispatch();
	let query = new URLSearchParams(useLocation().search);
	let  privateLetterId =query.get("privateLetterId");
	const { TextArea } = Input;
	const navigate = useNavigate();
	const [memberVo,setMemberVo] =  useState({} as any);
	const [privateLetterList,setPrivateLetterList] =  useState([] as Array<any>);
	const [msgData,setMsgData] =  useState({} as any);
	const [currentLetter,setCurrentLetter] =  useState({} as any);
	const [detailList,setDetailList] =  useState([] as Array<any>);
	const [systemMessage,setSystemMessage] =  useState([] as Array<any>);
	const [meun,setMeun] =  useState('chat');
	const [key,setKey] =  useState('');
	const [visible,setVisible] =useState(false);
	function changeMenun(item:any){
		if(item=='impactopia'){
			getListSystemMsg();
		}
		setMeun(item);

	}
	useEffect(()=>{
		memberCheck();
		getList("");
		getListSystemMsg();
	},[])

		function autoSeconds(privateLetterId:any){
			let id:any = localStorage.getItem("detail_intervalID") as any;
			if(id){
			   clearInterval(id);
		   }
		   let intervalID =setInterval(()=>{
			 console.log(privateLetterId);
			  getDetail(privateLetterId);
		   },4000)
		   localStorage.setItem("detail_intervalID",intervalID+"");
		}

	function  getList(keyWorld:any){
		httpPost("/privateletter/getList",{key:keyWorld?keyWorld:key}).then((e:any)=>{
			if(e.data.code == 0 ){
				setPrivateLetterList(e.data.data);
				for (let index = 0; index < e.data.data.length; index++) {
					const element = e.data.data[index];
					if(element.privateLetterId ==privateLetterId ){
						setCurrentLetter(element);
						getDetail(privateLetterId);
					}
				}
			}
		})
	}


	const items = [
		{
		  key: '1',
		  label: (
				<div className="user_menu"  onClick={sendTop} >
					<img src={require("../../assets/v2/Top@2x.png")}  className="chat_user_menu_img" alt="" />
					Top
				</div>
		  ),
		},
		{
			key: '2',
			label: (
				  <div className="user_menu"  onClick={removeAll} >
					<img src={require("../../assets/v2/Clearhistory@2x.png")}  className="chat_user_menu_img" alt="" />
					  Clear history
				  </div>
			),
		  },
		  {
			key: '3',
			label: (
				  <div className="user_menu" onClick={()=>{navigate("/Person_show", {state:{ memberId: currentLetter.id }} )}}  >
						<img src={require("../../assets/v2/Viewprofile@2x.png")}  className="chat_user_menu_img" alt="" />
					  View profile
				  </div>
			),
		  },
		  {
			key: '4',
			label: (
				  <div className="user_menu" >
						<img src={require("../../assets/v2/del@2x.png")}  className="chat_user_menu_img" alt="" />
					  Delete chat
				  </div>
			),
		  },
		  {
			key: '5',
			label: (
				  <div className="user_menu" onClick={block} >
					<img src={require("../../assets/v2/Block@2x.png")}  className="chat_user_menu_img" alt="" />
					  Block
				  </div>
			),
		  }
	  ];

		const itemsH5 = [
			{
				key: '1',
				label: (
					<div className="user_menu_h5"  onClick={() =>{
						setVisible(false)
						sendTop()
						setStep(0)
					}} >
						<img src={require("../../assets/v2/Top@2x.png")}  className="user_menu_h5_img" alt="" />
						<text className="user_menu_h5_text">Top</text>
					</div>
				),
			},
			{
				key: '2',
				label: (
						<div className="user_menu_h5"   onClick={() =>{
							setVisible(false)
							removeAll()
							setStep(0)
						}}>
						<img src={require("../../assets/v2/Clearhistory@2x.png")}  className="user_menu_h5_img" alt="" />
						<text className="user_menu_h5_text">Clear history</text>
						</div>
				),
				},
				{
				key: '3',
				label: (
						<div className="user_menu_h5" onClick={()=>{
							setVisible(false)
							navigate("/Person_show", {state:{ memberId: currentLetter.id }} )
							setStep(0)
							}}  >
							<img src={require("../../assets/v2/Viewprofile@2x.png")}  className="user_menu_h5_img" alt="" />
							<text className="user_menu_h5_text">View profile</text>
						</div>
				),
				},
				{
				key: '4',
				label: (
						<div className="user_menu_h5"  onClick={() => {
							setVisible(false)
							setStep(0)

						} } >
							<img src={require("../../assets/v2/del@2x.png")}  className="user_menu_h5_img" alt="" />
							<text className="user_menu_h5_text">Delete chat</text>
						</div>
				),
				},
				{
				key: '5',
				label: (
						<div className="user_menu_h5" onClick={() => {
							setVisible(false)
							block()
							setStep(0)
						} } >
						<img src={require("../../assets/v2/Block@2x.png")}  className="user_menu_h5_img" alt="" />
							<text className="user_menu_h5_text">Block</text>
						</div>
				),
				}
			];
	function  changePrivateLetter(item:any,index:any) {
		setCurrentLetter(item);
		getDetail(item.privateLetterId);
		autoSeconds(item.privateLetterId);
		readMsg(item,index);
	}

	function onChangeImg(info:any){
		 if (info.file.status === 'done' ) {
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				if(item.response){
					let url=item.response.url;
					let cpmsgData={type:"img",value:url}
					httpPost("/privateletter/sendMessage",{privateLetterId:currentLetter.privateLetterId,content:JSON.stringify(cpmsgData)}).then((e:any)=>{
						if(e.data.code == 0){
							getDetail(currentLetter.privateLetterId);
						}
					})
				}
			}
		  }
	}




	function  memberCheck(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 401 ){
				localStorage.setItem("pageFrom","/#/chat");
				navigate("/SignIn");
			}if(e.data.code == 0 ){
				setMemberVo(e.data.data);
			}
		})
	}

	function removeAll(){
		httpGet("/privateletter/removeAll/"+currentLetter.privateLetterId).then((e:any)=>{
			if(e.data.code == 0 ){
				getDetail(currentLetter.privateLetterId);
				getList("");
			}
		})
	}


	function getListSystemMsg(){
		httpGet("/systemMessage/getList").then((e:any)=>{
			if(e.data.code == 0 ){
				setSystemMessage(e.data.data);
			}
		})
	}

	function block(){
		httpGet("/privateletter/addBlacklist/"+currentLetter.privateLetterId).then((e:any)=>{
			if(e.data.code == 0 ){
				getDetail(currentLetter.privateLetterId);
				getList("");

			}
		})
	}

	function sendTop(){
		httpGet("/privateletter/sendTop/"+currentLetter.privateLetterId).then((e:any)=>{
			if(e.data.code == 0 ){
				getList("");
				getDetail(currentLetter.privateLetterId);
			}
		})
	}

	function getDetail(privateLetterId:any){
		httpGet("/privateletter/getDetailById/"+privateLetterId).then((e:any)=>{
			if(e.data.code == 0){
				setDetailList(e.data.data);
				setTimeout(()=>{
					var element = document.getElementById("chatbox");
					element?.scrollTo({top:element.scrollHeight});
				},2000)
			}
		})
	}
	function sendMessage(){
		let cpmsgData={...msgData}
		if(!cpmsgData.value){
			message.error("Please enter content");
			return;
		}
		httpPost("/privateletter/sendMessage",{privateLetterId:currentLetter.privateLetterId,content:JSON.stringify(cpmsgData)}).then((e:any)=>{
			if(e.data.code == 0){
				setMsgData({value:"",type:"text"});
				getDetail(currentLetter.privateLetterId);
			}
		})
	}


	function readMsg(item:any,index:any){
		httpGet("/privateletter/readAllByMemberId/"+item.id).then((e:any)=>{
			if(e.data.code == 0){
				privateLetterList[index].unreadNum=0;
				setPrivateLetterList([...privateLetterList]);

			}
		})
	}

	// 计算页面剩余高度
	const [remainingHeight, setRemainingHeight] = useState(0);
  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const headerHeight = document.getElementById('navigation')?.getBoundingClientRect().height || 0;
      const footerHeight = document.getElementById('chat_input')?.getBoundingClientRect().height || 0;
      const remainingHeight = windowHeight - headerHeight - footerHeight;
      setRemainingHeight(remainingHeight);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
	// 是点击还是列表
	const [step,setStep] = useState(0)
	useEffect(() => {
		if(step === 0) setDetailList([])
		// 需要最新data后的操作
	}, [step])



	// @ts-ignore
	const Web = <div  className="chat_body"  mode="web" >
		<div className="chat_left" >
			<div  >
				<div className="chat_left_01"  onClick={()=>{changeMenun('chat')}}  style={{color: meun == 'chat'? "#279D8E":""}} >chat</div>
				<div className="chat_left_01"  onClick={()=>{changeMenun('impactopia')}}   style={{color: meun == 'impactopia'? "#279D8E":""}} >Impactopia</div>
			</div>
		</div>
		{meun=='chat'? <div className="chat_center" >
			<div className="chat_center_member">
				<div  className="chat_center_member_seacher" >
					<Input  width={"13.0208vw"} style={{width:"13.0208vw"}}
						onChange={(e)=>{
							setKey(e.target.value);
							getList(e.target.value);
						}}

					></Input>
				</div>
				<div  className="chat_center_member_list" >
					{privateLetterList && privateLetterList.length >0 ? privateLetterList.map((item:any,indx:any)=>{
						return <div  onClick={()=>{
							changePrivateLetter(item,indx)
							dispatch(decrement(item.unreadNum))
							}}  key={indx} className={item.privateLetterId==currentLetter.privateLetterId?'chat_center_member_list_item chat_center_member_list_item_active':"chat_center_member_list_item"} >
						<div className="chat_center_member_list_item_img_box" >
						<Badge   count={item.unreadNum} >
							<UserHeader member={item}  marginRight={0.1}   ></UserHeader>
						</Badge>
						</div>
						<div className="chat_center_member_list_item_right_box"  >
							<div  className="chat_center_member_list_item_right_box_name"  >
								<div className="chat_center_member_list_item_right_box_name_1" >{item.userName}</div>
								<div className="chat_center_member_list_item_right_box_name_2" >
								{moment(item.uptDate).utc().format('MM-DD HH:mm:ss')}
								</div>
							</div>
							{/* <div  className="chat_center_member_list_item_right_imge"  >
								{item.nftList.map((iex:any,inx:any)=>{
									return 	<img  key={inx} src={JSON.parse(iex.image)[0].url}  className="chat_center_member_list_item_badge" />

								})}
							</div> */}
						</div>
					</div>
					}):""}
				</div>
			</div>
			<div className="chat_center_msg">
				<div className="chat_center_msg_top" >
					{currentLetter.privateLetterId ?<div  className="chat_center_msg_top_name" >
						{currentLetter.userName}
					</div>:"" }
					{currentLetter.privateLetterId ? <div   className="chat_center_msg_top_tools">
						<Dropdown
							menu={{
							items,
							}}
							placement="bottomRight"
							arrow
							>
								<div>
								<img src={require("../../assets/v2/dian.png")}  className="chat_center_msg_top_tools_img" alt="" />
									<img src={require("../../assets/v2/dian.png")}  className="chat_center_msg_top_tools_img" alt="" />
									<img src={require("../../assets/v2/dian.png")}  className="chat_center_msg_top_tools_img" alt="" />`
								</div>
							</Dropdown>
					</div>:""}
				</div>
				<div className="chat_center_msg_centent" id="chatbox" >
					{detailList && detailList.length > 0 ? detailList.map((item:any,indx:any)=>{
						return <div  key={indx}  >
							{item.type == 1  ? <div className="caht_msg_member_left" >
							<div>
								<UserHeader member={currentLetter} ></UserHeader>
							</div>
							<div className="caht_msg_member_content" >
								<div className="caht_msg_member_content_img_left">
								</div>
								<div className="caht_msg_member_content_content" >
									{JSON.parse(item.content).type == 'txt' ? <div   dangerouslySetInnerHTML={{__html:JSON.parse(item.content).value}} className="caht_msg_member_content_text">

									</div>:<div className="caht_msg_member_content_text">
										<Image src={JSON.parse(item.content).value}  width={"8.5833vw"} height={"8.5833vw"} className="caht_msg_member_content_text_img" alt="" />
									</div> }
									<div className="caht_msg_member_content_date" >

										<Formatter dateTime={item.createDateTime} ></Formatter>

									</div>
								</div>
							</div>
					</div>:	<div className="caht_msg_member_right" >
						<div className="caht_msg_member_content" >
							<div className="caht_msg_member_content_content_right" >
								{JSON.parse(item.content).type == 'txt' ? <div  dangerouslySetInnerHTML={{__html:JSON.parse(item.content).value}}  className="caht_msg_member_content_text">
								</div>:<div className="caht_msg_member_content_text">
									<Image src={JSON.parse(item.content).value}  width={"8.5833vw"} height={"8.5833vw"} className="caht_msg_member_content_text_img" alt="" />
								</div> }
								<div className="caht_msg_member_content_date" >
								<Formatter dateTime={item.createDateTime} ></Formatter>
								</div>
							</div>
							<div className="caht_msg_member_content_img_right">
							</div>
						</div>
						<div>
							<UserHeader member={memberVo} ></UserHeader>
						</div>
					</div>
					 }
						</div>
					}):""}
				</div>
				<div className="chat_center_msg_options" >
						<div>
							<Upload
								showUploadList={false}
								action={host_url+"/api/app/oss/uploadImg"}
								onChange={onChangeImg}
								>
								<img src={require("../../assets/v2/image.png")}  className="chat_center_msg_options_img" />
							</Upload>
						</div>


						<div  className="chat_center_msg_options_input"  >
								<Quill_Input w={"27.0417vw"} value={msgData.value}  h={"5.125vw"} handleClick={(e)=>{
									msgData.type="txt";
									msgData.value=e;
									setMsgData({...msgData});
								}} />
						</div>
						<div  onClick={sendMessage} className="chat_center_msg_options_but but-shadow" >
							Send
						</div>
				</div>
			</div>
		</div>:<div className="chat_center" style={{justifyContent:"center"}} >
			<div className="chat_center_box" >
			{systemMessage && systemMessage.map((item:any,ix:any)=>{
				return <div  key={ix} className="chat_sys_msg" >
					<div  className="chat_sys_msg_name"  >
						{item.title}
					</div >
					<div className="chat_sys_msg_centent" >
						{item.content}
					</div>
			</div>
			 })}
			</div>
			</div>}
		<div  className="chat_right" >
			<Ctrending_star  title="Trending"    paddingTop_={"0vw"}  paddingLeft_={"3vw"} isCreate={false} ></Ctrending_star>
		</div>

	</div>
// @ts-ignore
const Mobile = <div id="chat_h5" mode="mobile">
		{
			step === 0? <div>
					<div className="chat_input" id="chat_input">
						<div className="chat_input_search">
							<SearchOutline fontSize={24}></SearchOutline>
							<InputH5 clearable onChange={(value)=>{
									getList(value);
								}}  />
						</div>
					</div>
					<div style={{ height: `${remainingHeight}px`,overflow:'scroll' }} className="msgList">
						{
							privateLetterList && privateLetterList.length >0 ? privateLetterList.map((item:any,indx:any) => {
								return <div className="magList-item"  key={indx} onClick={()=>{
									setStep(1)
									changePrivateLetter(item,indx)
									dispatch(decrement(item.unreadNum))
								}} >
								   <Badge   count={item.unreadNum} >
								   <img className="magList-item-img" src={item.headImg}  />
								   </Badge>

									<div style={{flex:1}}>
										<div className="magList-item-name">
											<div className="magList-item-namel">
												<div className="magList-item-name-name">{item.userName}</div>
												<div className="imgList">
												{/* {item.nftList.map((iex:any,inx:any)=>{
													return 	<img  key={inx} src={JSON.parse(iex.image)[0].url}  className="imgList-item" />
												})} */}
												</div>
											</div>
											<div className="magList-item-namer">{moment(item.uptDate).utc().format('MM-DD HH:mm:ss')}</div>
										</div>
										<div className="magList-item-content">This code can be used to log in tod to log in to</div>
									</div>
								</div>
							}):''
						}
					</div>
			</div>:<div>
						<div className="msgHead">
							<LeftOutline  fontSize={24} onClick= {() => setStep(0)} />
							<div className="msgHead-name">{currentLetter.userName} </div>
							<MoreOutline fontSize={24} onClick= {() => setVisible(true)}/>
						</div>
						{/* 信息列表 */}
						<div style={{ height: `${remainingHeight}px`,overflow:'scroll' }} className="chatRecords">
							{detailList && detailList.length > 0 ? detailList.map((item:any,indx:any)=>{
								return <div  key={indx}  >
									{item.type == 1  ? <div className="caht_msg_member_left" style={{padding:"0 10.1333vw 0 3.2vw"}}>
									<div>
										<UserHeader member={currentLetter} ></UserHeader>
									</div>
									<div className="caht_msg_member_content" >
										<div className="caht_msg_member_content_img_left">
										</div>
										<div className="caht_msg_member_content_content" style={{padding:'2.6667vw'}}>
											{JSON.parse(item.content).type == 'txt' ? <div   dangerouslySetInnerHTML={{__html:JSON.parse(item.content).value}} className="caht_msg_member_content_text caht_msg_member_content_textH5">
											</div>:<div className="caht_msg_member_content_text">
												<Image src={JSON.parse(item.content).value}  width={"16vw"} height={"16vw"} className="caht_msg_member_content_text_img" alt="" />
											</div> }
											<div className="mcaht_msg_member_content_date" >
												<Formatter dateTime={item.createDateTime} ></Formatter>
											</div>
										</div>
									</div>
							</div>:	<div className="caht_msg_member_right"  style={{padding:"0 3.2vw 0 10.1333vw"}}>
								<div className="caht_msg_member_content" >
									<div className="caht_msg_member_content_content_right" style={{padding:'2.6667vw'}}>
										{JSON.parse(item.content).type == 'txt' ? <div  dangerouslySetInnerHTML={{__html:JSON.parse(item.content).value}}  className="caht_msg_member_content_text caht_msg_member_content_textH5 ">
										</div>:<div className="caht_msg_member_content_text">
											<Image src={JSON.parse(item.content).value}  width={"16vw"} height={"16vw"} className="caht_msg_member_content_text_img" alt="" />
										</div> }
										<div className="mcaht_msg_member_content_date" >
										<Formatter dateTime={item.createDateTime} ></Formatter>
										</div>
									</div>
									<div className="caht_msg_member_content_img_right">
									</div>
								</div>
								<div>
									<UserHeader member={memberVo} ></UserHeader>
								</div>
							</div>
							}
								</div>
							}):""}

							<div className="chatTools">
							{/* <ImageUploader value={[]}  upload={onChangeImg}> */}
									{/* <img src={require("../../assets/v2/image.png")}  style={{width:'5.8667vw',height:'5.8667vw'}}/> */}
							{/* </ImageUploader> */}
							<Upload
								showUploadList={false}
								action={host_url+"/api/app/oss/uploadImg"}
								onChange={onChangeImg}
								>
									<img src={require("../../assets/v2/image.png")}  style={{width:'5.8667vw',height:'5.8667vw'}}/>
							</Upload>
									<div className="chatToolsInput">
										<InputH5  onChange={(e)=>{
											msgData.type="txt";
											msgData.value=e;
											setMsgData({...msgData});

										}} />
									</div>

									<div  onClick={sendMessage} className="chatTools_but" >
										Send
									</div>
							</div>
						</div>

						<Popup visible={visible} position='bottom' onMaskClick={() => {setVisible(false)}} bodyStyle={{padding:"20px" }}>

										{
											itemsH5.map(item => {
												return <div  key={item.key} >{item.label}</div>
											})
										}
						</Popup>
			</div>
		}


	</div>
	return Common.isMobile ? Mobile : Web
}
