import { useEffect, useState } from "react";
import { Input, message, Select, Upload } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { httpGet, httpPost, host_url } from '../../utils/httpUtils';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Cascader } from 'antd';
import { Common } from "../../app/Common";
import "./CPublishmint.css";
import { State, useAppDispatch, useAppSelector } from "../../app/Store";

export function CPublishChannel() {
	const { tagsList} = useAppSelector((state: State) => state.tags)
	const navigate = useNavigate();
	let query = new URLSearchParams(useLocation().search);
	let channelId = query.get("channelId");


	interface ProjectVo {
		name: any,
		channelStory: any,
		twitter: any,
		tage: any,
		headerImg: any,
		image: any,
		itemIds: any,
		plateName: any,
		id: any
	}

	const [projct, setProjct] = useState({} as ProjectVo);

	const [tokenList, setTokenList] = useState([] as Array<any>);

	const [plateList, setPlateList] = useState([] as Array<any>);


	const [fileList, setFileList] = useState([] as Array<any>);


	const [fileListBanner, setFileListBanner] = useState([] as Array<any>);


	function memberCheck() {
		httpGet("/member/getMember").then((e: any) => {
			if (e.data.code == 401) {
				localStorage.setItem("pageFrom", "/#/Publishmint");
				navigate("/SignIn");
			}
		})
	}


	function revert() {
		setFileListBanner([]);
		setFileList([]);
		setProjct({} as ProjectVo);
		window.history.back();
	}

	function onChangeBanner(info: any) {
		setFileListBanner([...info.fileList]);
		if (info.file.status === 'done' || info.file.status === 'removed') {
			projct.headerImg = [];
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				if (item.response) {
					projct.headerImg.push({ name: item.name, url: item.response.url, width: item.response.width, height: item.response.height });
				}
			}
		}
		setProjct({ ...projct })
	}


	function onChange(info: any) {
		setFileList([...info.fileList]);
		if (info.file.status === 'done' || info.file.status === 'removed') {
			projct.image = [];
			for (let index = 0; index < info.fileList.length; index++) {
				const item = info.fileList[index];
				if (item.response) {
					projct.image.push({ name: item.name, url: item.response.url, width: item.response.width, height: item.response.height });
				}
			}
		}
		setProjct({ ...projct })
	}


	async function onPreview(file: UploadFile) {
		let src = file.url as string;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj as RcFile);
				reader.onload = () => resolve(reader.result as string);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	};



	useEffect(() => {
		getPlateAll();
		getNfts();
		memberCheck();
		if (channelId) {
			getChannel();
		}
	}, [])

	const getChannel = () => {
		httpGet("/channel/getDetail/" + channelId).then((e: any) => {
			if (e.data.code == 0) {
				if (e.data.data.headerImg) {
					projct.headerImg = JSON.parse(e.data.data.headerImg);
				}
				if (e.data.data.image) {
					projct.image = JSON.parse(e.data.data.image);
				}
				projct.channelStory = e.data.data.channelStory;
				projct.tage = e.data.data.tage ? JSON.parse(e.data.data.tage) : [];
				projct.itemIds = e.data.data.itemIds ? JSON.parse(e.data.data.itemIds) : [];
				projct.name = e.data.data.name;
				projct.plateName = e.data.data.plateName;
				projct.twitter = e.data.data.twitter;

				if (e.data.data.headerImgArr) {
					let fileList = [];
					for (let index = 0; index < e.data.data.headerImgArr.length; index++) {
						const url = e.data.data.headerImgArr[index];
						fileList.push({
							uid: index,
							name: 'image.png',
							status: 'done',
							url: url
						})
					}
					setFileListBanner([...fileList]);
				}

				if (e.data.data.imgArr) {
					let fileList = [];
					for (let index = 0; index < e.data.data.imgArr.length; index++) {
						const url = e.data.data.imgArr[index];
						fileList.push({
							uid: index,
							name: 'image.png',
							status: 'done',
							url: url
						})
					}
					setFileList([...fileList]);
				}
				setProjct(projct);
			}
		})
	}

	function getTokenAll(tokenList: any) {
		let list = [];
		for (let index = 0; index < tokenList.length; index++) {
			const item = tokenList[index];
			list.push({ value: item.tokenName, label: item.tokenName });
		}
		setTokenList([...list]);
	}


	function getPlateAll() {
		httpGet("/project/getPlateAll").then((e: any) => {
			if (e.data.code == 0) {
				let list = [];
				for (let index = 0; index < e.data.data.length; index++) {
					const item = e.data.data[index];
					list.push({ value: item.plateName, label: item.plateName, tokenList: item.tokenList });
				}
				setPlateList([...list]);
			}
		})
	}


	async function release() {
		let cpprojct = { ...projct };
		if (!cpprojct.name) {
			message.error("projct name is Required");
			return
		}
		if (cpprojct.headerImg) {
			cpprojct.headerImg = JSON.stringify(cpprojct.headerImg);
		}
		if (cpprojct.image) {
			cpprojct.image = JSON.stringify(cpprojct.image);
		}
		if (cpprojct.tage) {
			cpprojct.tage = JSON.stringify(cpprojct.tage);
		}

		if (cpprojct.itemIds) {
			cpprojct.itemIds = JSON.stringify(cpprojct.itemIds);
		}
		cpprojct.id = channelId;
		httpPost("/channel/createChannel", cpprojct).then((e: any) => {
			if (e.data.code == 0) {
				message.success("Submission Successful! Pending Review");
				navigate("/Person", { state: { type: "Chnanel" } })
			} else {
				message.error(e.data.msg);
			}
		})
	}
	interface Option {
		value: string | number;
		label: string;
		children?: Option[];
	}

	const [nftOptions, setNftOptions] = useState([] as Array<any>);


	function getNfts() {
		httpGet("/common/getSelNft").then((e: any) => {
			if (e.data.code == 0) {
				setNftOptions(e.data.data);
			}
		})
	}





	let Web = <div className="pmint_body" >
		<div className="pmint_box" >
			<div className="pmint_name" >Create Channel</div>
			<div className="pmint_input" >
				<span className="lable_red" >*</span>
				<Input placeholder="Channel Name"
					value={projct.name}
					onChange={(e) => {
						projct.name = e.target.value;
						setProjct({ ...projct });
					}}
					bordered={false} style={{ width: "40.1042vw", height: "3.125vw", borderBottom: "1px solid #EBEBEB" }} />
			</div>

			<div className="pmint_input"   >
				<span className="lable_red" >*</span>
				<Input placeholder="Channel story"
					value={projct.channelStory}
					onChange={(e) => {
						projct.channelStory = e.target.value;
						setProjct({ ...projct });
					}}
					bordered={false} style={{ width: "40.1042vw", height: "2.3438vw" }} />
			</div>


			<div className="pmint_flex" style={{ marginTop: "1.0417vw" }} >
				<span className="lable_red" >*</span>
				<div className="pmint_lable" >Tags</div>
				<Select
					mode="tags"
					placeholder="Please select tags"
					value={projct.tage}
					onChange={(e) => {
						projct.tage = e;
						setProjct({ ...projct });
					}}
					style={{ width: '18.8542vw' }}
					options={tagsList}
				/>
			</div>

			<div className="pmint_flex" >
				<span className="lable_red" >*</span>
				<div className="pmint_lable" >Category</div>
				{plateList.length > 0 ?
					<Select
						style={{ width: "18.8542vw" }}
						value={projct.plateName}
						onChange={(e, op) => {
							getTokenAll(op.tokenList);
							projct.plateName = e;
							setProjct({ ...projct });
						}}
						options={plateList}
					/> : ""
				}
			</div>


			<div className="pmint_flex" style={{ marginTop: "1.0417vw" }} >
				<div className="pmint_lable" >Member Rule</div>
				{nftOptions.length > 0 ?
					<Select
						mode="multiple"
						style={{ width: "18.8542vw" }}
						value={projct.itemIds}
						onChange={(e) => {
							projct.itemIds = e;
							setProjct({ ...projct });
						}}
						options={nftOptions}
					/> : ""
				}
			</div>

			<div className="pmint_flex" >
				<span className="lable_red" >&nbsp;&nbsp;</span>
				<div className="pmint_lable" >Twitter</div>
				<Input className="pmint_input"
					value={projct.twitter}
					onChange={(e) => {
						projct.twitter = e.target.value;
						setProjct({ ...projct });
					}}
					style={{ width: "18.8542vw" }} />
			</div>

			<div className="projectflex" >
				<span className="lable_red" >*</span>
				<div className="pmint_lable" style={{ marginRight: 50 }} >Channel icon</div>
				<Upload
					action={host_url + "/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileListBanner}
					onChange={onChangeBanner}
				>
					{fileListBanner.length < 1 && '+ Upload'}
				</Upload>
			</div>


			<div className="projectflex" >
				<span className="lable_red" >*</span>
				<div className="pmint_lable" style={{ marginRight: 50 }} >Pictures</div>
				<Upload
					action={host_url + "/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileList}
					onChange={onChange}
				>
					{fileList.length < 3 && '+ Upload'}
				</Upload>
			</div>

			<div className="projectflex" style={{ marginTop: 20 }} >
				<div className="pmint_preview but-shadow" onClick={revert} >
					Back
				</div>
				<div className="pmint_pint  but-shadow" onClick={release} >
					Publish Channel
				</div>
			</div>

		</div>

	</div>



	let Mobile = <div className="mpmint_body" >
		<div className="mpmint_box" >
			<div className="mpmint_input" >
				<span className="lable_red" >*</span>
				<Input placeholder="Channel Name"
					value={projct.name}
					onChange={(e) => {
						projct.name = e.target.value;
						setProjct({ ...projct });
					}}
					bordered={false} style={{ width: "100%", height: "11.7333vw", borderBottom: "1px solid #EBEBEB" }} />
			</div>

			<div className="mpmint_input"   >
				<span className="lable_red" >*</span>
				<Input placeholder="Channel story"
					value={projct.channelStory}
					onChange={(e) => {
						projct.channelStory = e.target.value;
						setProjct({ ...projct });
					}}
					bordered={false} style={{ width: "100%", height: "11.7333vw", borderBottom: "1px solid #EBEBEB" }} />
			</div>


			<div className="mpmint_flex" style={{ marginTop: "1.0417vw" }} >
				<div className="mpmint_lable" >Tags<span className="lable_red" >*</span></div>
				<Select
					mode="tags"
					placeholder="Please select tags"
					value={projct.tage}
					onChange={(e) => {
						projct.tage = e;
						setProjct({ ...projct });
					}}
					style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
					options={tagsList}
				/>
			</div>

			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Category<span className="lable_red" >*</span></div>
				{plateList.length > 0 ?
					<Select
						style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
						value={projct.plateName}
						onChange={(e, op) => {
							getTokenAll(op.tokenList);
							projct.plateName = e;
							setProjct({ ...projct });
						}}
						options={plateList}
					/> : ""
				}
			</div>


			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Member Rule</div>
				{nftOptions.length > 0 ?
					<Select
						mode="multiple"
						style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
						value={projct.itemIds}
						onChange={(e) => {
							projct.itemIds = e;
							setProjct({ ...projct });
						}}
						options={nftOptions}
					/> : ""
				}
			</div>

			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Twitter<span className="lable_red" >*</span></div>
				<Input className="pmint_input"
					value={projct.twitter}
					onChange={(e) => {
						projct.twitter = e.target.value;
						setProjct({ ...projct });
					}}
					style={{ width: "100%", height: "8.5333vw", marginTop: "5px" }}
				/>
			</div>

			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Channel icon<span className="lable_red" >*</span></div>
				<Upload
					action={host_url + "/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileListBanner}
					onChange={onChangeBanner}
				>
					{fileListBanner.length < 1 && '+ Upload'}
				</Upload>
			</div>


			<div className="mpmint_flex" >
				<div className="mpmint_lable" >Pictures<span className="lable_red" >*</span></div>
				<Upload
					action={host_url + "/api/app/oss/uploadImg"}
					listType="picture-card"
					fileList={fileList}
					onChange={onChange}
				>
					{fileList.length < 3 && '+ Upload'}
				</Upload>
			</div>

			<div className="mpmint_but_box" style={{ marginTop: 20 }} >
				<div className="mpmint_preview" onClick={revert} >
					Revert
				</div>
				<div className="mpmint_pint" onClick={release} >
					Publish Channel
				</div>
			</div>

		</div>

	</div>

	return Common.isMobile ? Mobile : Web

}
