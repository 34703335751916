import { useEffect, useState } from "react";
import { Input, message, Select, Image } from 'antd';
import { Link, useNavigate  } from 'react-router-dom'
import {httpGet,httpPost} from '../../utils/httpUtils';
import {UserHeader} from "./UserHeader";
import {Share} from './Share';
import { Formatter } from './Formatter';
import {SendForum} from './SendForum';
import {SendContent} from './SendContent';
import { Popup,NavBar } from 'antd-mobile'
import Iframe from 'react-iframe'
import {PushDonate} from "./PushDonate";

type IProps = {
	handleClick: () => void,
	forumList: any,
	forumId?:any,
	show?: any,
	mmeber?:any,
	fromPage?:any,
	isSysyem?:any
}
import "./CMDiscover_list.css";
export function CMDiscover_list(props:IProps) {
	const navigate = useNavigate();
	const [memberVo,setMemberVo] =  useState({} as any);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [uptforum,setUptforum] =  useState({} as any);
	const [isUptforum,setIsUptforum] =  useState(false);
	const [forumList,setForumList] =  useState([]as Array<any>);


	useEffect(()=>{
		if(props.forumList){
			setForumList([...props.forumList])
		}
		getMmber();

	},[props.forumList,props.mmeber])

	function queryURLparams(url:any) {
		let obj = {} as any
		if (url.indexOf('?') < 0) return obj
		let arr = url.split('?')
		url = arr[1]
		let array = url.split('&')
		for (let i = 0; i < array.length; i++) {
			let arr2 = array[i]
			let arr3 = arr2.split('=')
			obj[arr3[0]] = arr3[1]
		}
		return obj
	
	}
	
	

	function getUrl(url:any){
		if(url && url.indexOf('live') > -1){
			try {
				let code =url.replace("https://www.youtube.com/live/","").replace("https://youtube.com/live/","").split("?")[0];
				return `https://www.youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}

		if( url  && url.indexOf('shorts') > -1){
			try {
				let code =url.replace("https://youtube.com/shorts/","").split("?")[0];
				return `https://youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('watch') > -1){
			try {
				 let ags =  queryURLparams(url);
				return `https://www.youtube.com/embed/${ags.v}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('youtu.be') > -1){
			try {
				 let ags =url.replace("https://youtu.be/","").split("?")[0];
				return `https://www.youtube.com/embed/${ags}`;
			} catch (error) {
				
			}
		}
        return "";
    }

	function getMmber(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 0 ){
				setMemberVo(e.data.data);
			}
		})
	}

	function showSendContent(indx:any,item:any){
		debugger
		for (let index = 0; index < forumList.length; index++) {
			forumList[index].showSendContent=false;
		}
		debugger
		forumList[indx].showSendContent=!forumList[indx].showSendContent;
		setForumList([...forumList]);
	}

	function handleClick(){
		props.handleClick();
	}
	function forumAttention(item:any,index:any){
		httpGet("/attention/forumAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=1;
				forumList[index].attentionNum+=1;
				setForumList([...forumList]);
				props.handleClick();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Discover_list");
				navigate("/SignIn");
				}
		})
	}

	function ImageList(item:any) {
	  let imageArray=JSON.parse(item);
		return   imageArray.map((ite:any,inx:any)=>{
			return <div  key={inx} >
					<Image
					className="mdis_list_img_bander"
									src={ite.url}
								/>
					
			</div>
	})
	}

	function deletForum(){
		httpGet("/forum/remove/"+uptforum.id).then((e:any)=>{
			if(e.data.code==0){
				if(props.fromPage){
					message.success("delete post successful ");
					setIsModalOpen(false)
					props.handleClick();
				}else{
					message.success("delete post successful ");
					setIsModalOpen(false)
					props.handleClick();
					navigate("/Discover_list")
				}
				
			}
		})
	}

	function forumCollect(item:any,index:any){
		httpGet("/collect/forumCollect/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=1;
				setForumList([...forumList]);
				props.handleClick();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Discover_list");
				navigate("/SignIn");
				}
		})
	}

	function cancelCollect(item:any,index:any,type:any){
		httpGet("/collect/cancelCollect/"+item.id+"/"+type).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=0;
				setForumList([...forumList]);
				props.handleClick();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Discover_list");
				navigate("/SignIn");
				}
		})
	}


	

	function cancelAttention(item:any,index:any,type:any){
		httpGet("/attention/cancelAttention/"+item.id+"/"+type).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=0;
				forumList[index].attentionNum-=1;
				setForumList([...forumList]);
				props.handleClick();
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Discover_list");
				navigate("/SignIn");
				}
		})
	}

	function getTags(item:any){
		let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}
		let tags=item.tages;
		let tagshtml=<div  className="mdisflex"  style={{marginTop:6}}  ></div>
		if(tags){
			tags =tags.split(",");
			tagshtml=	<div  className="mdisflex"  style={{marginTop:6}}  >
			{tags.map((ite:any,inde:any)=>{
				if(inde <5){
					let color="";
					if(ite){
						color=color_conf[ite.substring(0,1).toLowerCase()]?color_conf[ite.substring(0,1).toLowerCase()]:"#FFB4A2";
					}
					return <span  key={inde} className="mforum_but"  style={{backgroundColor:color}} >{ite}</span>
				}
			})}
		</div>
		}
		return  tagshtml;
	}

	return  <div className="mdisContent"  style={{padding:"3.3vw"}} >

			<Popup  bodyClassName="mdis_list_popup"    visible={isModalOpen} position='bottom' onMaskClick={() => {setIsModalOpen(false)}} >
					<div className="mdis_list_popup_but">
						<div style={{textAlign:"center"}}  >
							<div className="mdis_list_popup_but_img_box" >
								<img  onClick={deletForum} src={require("../../assets/mobile/del.png")}  className="mdis_list_popup_but_img" alt="" />
							</div>
							<div className="mdis_list_popup_but_text" >
								Delete
							</div>
						</div>
						<div  style={{textAlign:"center"}} >
							<div className="mdis_list_popup_but_img_box" >
								<img  onClick={()=>{
									setIsModalOpen(false)
									setIsUptforum(true);
									}} src={require("../../assets/mobile/edit.png")}  className="mdis_list_popup_but_img" alt="" />
							</div>
							<div  className="mdis_list_popup_but_text" >
								Revise
							</div>
						</div>
					</div>
			</Popup>

			{ uptforum.id &&  isUptforum ?	<SendForum   forumInfo={uptforum} handleClick={()=>{
				 setIsUptforum(false); 
				 setUptforum({});
				 props.handleClick();
			}}  ></SendForum> : ""}
			{props.show?<SendForum superiorId={props.forumId?props.forumId:""}   handleClick={handleClick}  ></SendForum>:""}
			

			{forumList.map((item,index)=>{
					return  <div key={index} className="mdis_list_item" >
								<div className="moptions_name_box_flex"  >
									<div  className="mdis_list_name"  onClick={()=>{
										navigate("/Forumlist?forumId="+item.id)
									}} >
										{item.title}
									</div>
								</div>	
								
								<div   className="mdisflex" style={{marginTop:"10px"}} >
										<UserHeader member={item.memberEntity}  ></UserHeader>
										<div  className="muser_name" >{item.memberName ? item.memberName:""}</div>
										<div  className="mdis_time" style={{marginLeft:60}} ><Formatter dateTime={item.createDateTime} ></Formatter></div>
								</div>

								{getTags(item)}
								<div className={props.show?'mdis_list_content_show':'mdis_list_content'}>
									<div   onClick={()=>{
										navigate("/Forumlist?forumId="+item.id)
									}}    dangerouslySetInnerHTML={{__html:props.show?item.content:(item.content?item.content.replace(/<[^>]+>/g,""):"")}}  >
									</div>
								</div>
								{
								item.url? <div>
									<Iframe url={getUrl(item.url)}
										width="100%"
										height="200px"
										id=""
										className=""
										display="block"
										position="relative"/>
								</div>: (item.imgUrl &&	 item.imgUrl.length) > 0 ?  <div   style={{display:"flex",width:"100%",flexWrap:"wrap"}} >
										{ImageList( item.imgUrl)}
									</div>: <div></div>
								}

							<div className="mdisflex"   style={{marginTop:10}}  >
									<div  className="mcoptions_box" >
											<div  className="moptions_box_flex"  >
												{item.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{forumAttention(item,index)}} className="mdis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,index,3)}} className="mdis_list_img"  alt="" />}
												<div className="options_box_num" >{item.attentionNum}</div>
											</div>
												<div  className="moptions_box_flex " onClick={()=>{
														if(!props.show){
															navigate("/Forumlist?forumId="+item.id)
														}else{
															showSendContent(index,item)
														}
													}}   >
												<img src={require("../../assets/v2/feed@2x.png")}  className="mdis_list_img"   alt="" />
												<div className="options_box_num" >{item.commentNum}</div>
												</div>
										    <div  className="options_box_flex options_box_flex_border"  >
									 		 {item.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{forumCollect(item,index)}}  className="mdis_list_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect(item,index,3)}}  className="mdis_list_img"   alt="" />}
											</div>
											<div  className="options_box_flex" >
												<Share   sharedata={item}  type={'dic'} ></Share>
											</div>
											
											{item.type == 1  && item.projectEntity ?
												<div> 
													<PushDonate  pages={"/Forumlist?forumId="+props.forumId} handleClick={props.handleClick} projct={item.projectEntity} fontSize="1.2vw"  ></PushDonate>
												</div>:
												<div></div>
											}


											{/* {memberVo.id == item.memberId ?	<div  className="options_box_flex" >	
											<img  onClick={()=>{
												setIsModalOpen(true)
												setUptforum(item);
											}} src={require("../../assets/mobile/op.png")}  className="mdis_list_img" alt="" />
											</div>
											:""} */}
											
									</div>
							</div>
							<div className="mdisflex"   style={{marginTop:10}}  >
							{item.showSendContent   && item.type==2 ?<SendContent  showList={true} forumId={item.id} handleClick={props.handleClick}></SendContent>:""}
				 	     	</div>
								{item.showSendContent   && item.type==1 ? <SendForum  showOpen={true} superiorId={props.forumId?props.forumId:""}   handleClick={handleClick}  ></SendForum>:""}
					</div>
				})}
	</div>
}
