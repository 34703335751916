import { useEffect, useState } from "react";
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import { Input,Dropdown,Popover,message,Image} from 'antd';
import {httpGet,httpPost} from '../../utils/httpUtils';
import Iframe from 'react-iframe';
import { Select,Drawer,InputNumber , Button, Tabs,Modal,Carousel} from 'antd';
import {Share} from './Share';
import {UserHeader} from "./UserHeader";
import {SendForumChannel} from './SendForumChannel';
import { Timeline } from 'react-twitter-widgets'
import { Forum_opention } from './Forum_opention';
import {Chnannel_jion} from "./Chnannel_jion";
import {Channel_options} from "./Channel_options";
import {ImageList} from "./Image";
import { Formatter } from './Formatter';
import {Common} from "../../app/Common";
import { CMDiscover_list } from './CMDiscover_list';

export function MChannel() {
	const navigate = useNavigate();
        const [forumList,setForumList] =  useState([]as Array<any>);
		const [channel,setChannel] =  useState({} as any);
		let query = new URLSearchParams(useLocation().search);
		let  channelId =query.get("channelId");
        useEffect(()=>{
			getMmber();
			if(channelId){
				getPageList("",'');
				getChannel();
				getPlateAll();
				getMemberListByChannelId();
				getChannelPageList("");
			}
		},[channelId]) 

    const [tabs,setTabs] =  useState(["General","Member"]as Array<any>);

	const [keyWorld,setKeyWorld] =  useState("");
	const [activeKey,setActiveKey] =  useState("General");

	//  function  changTabs(){
	// 	let cytabs=[tabs[1],tabs[0]];
	// 	setTabs([...cytabs])

	// 	getPageList(tabs[1],'');
	// }


	function changTabs(e:any) {
		setActiveKey(e);
		getPageList(e,'')
	}


        function getTags(item:any){
			let color_conf={
				a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
				n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
			}
			

		let tags=item.tages;
		let tagshtml=<div></div>
		if(tags){
			tags =tags.split(",");
			tagshtml=	<div className="forum_flex_one" >
			{tags.map((ite:any,inde:any)=>{
					let color="";
					if(ite){
						color=color_conf[ite.substring(0,1).toLowerCase()]?color_conf[ite.substring(0,1).toLowerCase()]:"#FFB4A2";
					}
				return <div  key={inde}  style={{backgroundColor:color}}   className="forum_but but-shadow" >{ite}</div> 
			})}
		</div>
		}
		return  tagshtml;
	}

        function forumCollect(item:any,index:any){
		httpGet("/collect/forumCollect/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=1;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Channel?channelId="+channelId);
				navigate("/SignIn");
			}
		})
	}

	




	const [memberVo,setMemberVo] =  useState({} as any);
	function getMmber(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 0 ){
				setMemberVo(e.data.data);
			}
		})
	}


        function forumAttention(item:any,index:any){
		httpGet("/attention/forumAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=1;
				forumList[index].attentionNum+=1;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Channel?channelId="+channelId);
				navigate("/SignIn");
			}
		})
	}


	function cancelCollect(item:any,index:any,type:any){
		httpGet("/collect/cancelCollect/"+item.id+"/"+type).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=0;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Channel?channelId="+channelId);
				navigate("/SignIn");
			}
		})
	}


	function cancelAttention(item:any,index:any,type:any){
		httpGet("/attention/cancelAttention/"+item.id+"/"+type).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=0;
				forumList[index].attentionNum-=1;
				setForumList([...forumList]);
			}else if(e.data.code == 401){
				localStorage.setItem("pageFrom","/#/Channel?channelId="+channelId);
				navigate("/SignIn");
			}
		})
	}

	
	function getTw(tw:any){
		if(tw && tw.indexOf("https") > -1){
			return channel.twitter.replace("https://twitter.com/","");
		}else{
			return "";
		}
	  }

	const  getChannel=()=>{
		httpGet("/channel/getDetail/"+channelId).then((e:any)=>{
			if(e.data.code==0){
				setChannel(e.data.data);
			}
		})
	}

    const  getPageList=(name:any,keyWorld:any)=>{
		if(!name ){
			name=tabs[0];
		}
		setForumList([]);
		if(name == 'General' ){
			httpPost("/channel/getGeneralPage",{page:1,limit:10,channelId:channelId,keyWorld:keyWorld}).then((e:any)=>{
				if(e.data.code==0){
					setForumList(e.data.data.list);
				}
			})
		}else{
			httpPost("/channel/getMemberForumPage",{page:1,limit:10,channelId:channelId,keyWorld:keyWorld}).then((e:any)=>{
				if(e.data.code==0){
					setForumList(e.data.data.list);
				}
			})

		}
	}

	const [bannerBut, setBannerBut] = useState("LIVE");
	const [plateList,setPlateList] =  useState([] as Array<any>);
	function getPlateAll(){
		httpGet("/project/getPlateAll").then((e:any)=>{
			if(e.data.code==0){
				let list=[];
				for (let index = 0; index < e.data.data.length; index++) {
					const item = e.data.data[index];
					list.push({value:item.plateName,label:item.plateName,tokenList:item.tokenList});
				}
				setPlateList([...list]);
			}
		})
	}

	function changeBut(e:any){
		setBannerBut(e);
	}



	const [members,setMembers] =  useState([]as Array<any>);
	const [channelList,setChannelList] =  useState([]as Array<any>);

	function getMemberListByChannelId(){
		httpGet("/channel/getMemberListByChannelId/"+channelId).then((e:any)=>{
			if(e.data.code==0){
				setMembers(e.data.data);	
			}
		})
	}


	function getChannelPageList(e:any){
		httpPost("/channel/getPageList",{plateName:e}).then((e:any)=>{
			if(e.data.code==0){
				setChannelList(e.data.data);	
			}
		})
	}

	
	function formatText(e:any){
		if(e){
			if(e.length > 200){
				e=e.substring(0,150)+"...."
			}
			return e;
		}
		return "";
	
	}

	function Revise(e:any){
		navigate("/PublishChannel?channelId="+e);
	}

	function channelChange(id:any){
		navigate("/Channel?channelId="+id)
	}



	
	function queryURLparams(url:any) {
		let obj = {} as any
		if (url.indexOf('?') < 0) return obj
		let arr = url.split('?')
		url = arr[1]
		let array = url.split('&')
		for (let i = 0; i < array.length; i++) {
			let arr2 = array[i]
			let arr3 = arr2.split('=')
			obj[arr3[0]] = arr3[1]
		}
		return obj
	
	}
	
	

	function getUrl(url:any){
		if(url && url.indexOf('live') > -1){
			try {
				let code =url.replace("https://www.youtube.com/live/","").replace("https://youtube.com/live/","").split("?")[0];
				return `https://www.youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}

		if( url  && url.indexOf('shorts') > -1){
			try {
				let code =url.replace("https://youtube.com/shorts/","").split("?")[0];
				return `https://youtube.com/embed/${code}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('watch') > -1){
			try {
				 let ags =  queryURLparams(url);
				return `https://www.youtube.com/embed/${ags.v}`;
			} catch (error) {
				
			}
		}
		if( url  && url.indexOf('youtu.be') > -1){
			try {
				 let ags =url.replace("https://youtu.be/","").split("?")[0];
				return `https://www.youtube.com/embed/${ags}`;
			} catch (error) {
				
			}
		}
        return "";
    }

	

	const [formData,setFormData] =  useState({} as any);
	function selectAll(){
		let plateName=[];
	   for (let index = 0; index < plateList.length; index++) {
		   plateName.push( plateList[index].value);
	   }
	   formData.plateName=plateName;
	   setFormData({...formData});
	   getChannelPageList(plateName);
   }

   const  forum_div=	<div  className="ch_trending_list" >
				{forumList.map((item,index)=>{
				return  <div key={index} className="dis_list_item" style={{width:"100%"}}  >
				<div  className="ch_list_name" >
					{item.title}
				</div>
				<div  className="disflex" style={{marginLeft:"0.5208vw",alignItems:"center"}} >
						<UserHeader member={item.memberEntity}  ></UserHeader>
						<div>{item.memberName ? item.memberName:""}</div>
					<div  style={{display:"flex",marginLeft:50}} >
							{getTags(item)}
						<div  className="dis_time" >
							<Formatter dateTime={item.createDateTime} ></Formatter>
						</div> 
					</div>
				</div>
				<Link  to={"/Forumlist?forumId="+item.id}  >
						<div  className="dis_list_content"  dangerouslySetInnerHTML={{__html:item.content}}  style={{width:"100%"}} >
						</div>	
					
				</Link>

				{
				item.url? <div>
					<Iframe url={getUrl(item.url)}
						width="640px"
						height="320px"
						id=""
						className=""
						display="block"
						position="relative"/>
				</div>: (item.imgArr &&	 item.imgArr.length) > 0 ?  <div   className="dis_list_content"  style={{display:"flex",width:"100%"}} >
									<ImageList item={item.imgUrl} ></ImageList>
						</div>: <div></div>
				}

				<div className="disflex"   style={{width:"100%",marginTop:20}}  >
				<div   className="disflex" style={{width:"50%",alignItems:"center"}}  >
					<div  className="options_box" >
							<div  className="options_box_flex options_box_flex_border"  >
								{item.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{forumAttention(item,index)}} className="dis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,index,3)}} className="dis_list_img"  alt="" />}
							<div className="options_box_num" >{item.attentionNum}</div>
							</div>
							<Link  className="options_box_flex options_box_flex_border"  to={"/Forumlist?forumId="+item.id}  >
								<img src={require("../../assets/v2/feed@2x.png")}  className="dis_list_img"   alt="" />
								<div className="options_box_num" >{item.commentNum}</div>
							</Link>
							<div  className="options_box_flex options_box_flex_border"  >
								{item.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{forumCollect(item,index)}}  className="dis_list_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect(item,index,3)}}  className="dis_list_img"   alt="" />}
							</div>
							<div  className="options_box_flex" >
								<Share   sharedata={item}  type={'dic'} ></Share>
							</div>
					</div>
				</div>
				<div className="disflex" style={{width:"50%",alignItems:"center",justifyContent:"flex-end"}}>
				{memberVo.id == channel.memberId || memberVo.id==item.memberId ?
					<Forum_opention handleClick={()=>{getPageList("","")}}  item={item}    ></Forum_opention>:""
				}
				</div>
				</div>
				</div>
				})}
   </div>
   
	let Web= channel.id ? <div  id="channel"  className="ch_content" >
	<div  className="ch_banner" >
			<div className="ch_banner_left" >
				<div className="ch_banner_left_box" >
					<img src={channel.headerImgArr ? channel.headerImgArr[0]:""}  className="ch_banner_header" alt="" />
					<div className="ch_banner_name" >
						<div>{channel.name}</div>
						
					</div>
					<img src={require("../../assets/v2/member@2x.png")}  className="ch_banner_member"  alt=""  />
					<div className="ch_channelStory" >
						{formatText(channel.channelStory)}
					</div>
					<div  className="ch_banner_line" ></div>
					   <div className="ch_channelNftList"  >
					   {channel.channelNftList && channel.channelNftList.length> 0 ? channel.channelNftList.map((item:any,inx:any)=>{
							return  inx <=5 ?	<img  key={inx} src={JSON.parse(item.image)[0].url}  onClick={()=>{
								navigate("/BadgeDetail?detailId="+item.nftItemId)
							}} className="ch_media_img_bage"  style={{objectFit:"contain"}}  alt=""  />:"";
						}):""} 
					</div>
					
				</div>
				<div  className="ch_banner_footter" >
					<div  className="ch_banner_mgs" >
						<img src={require("../../assets/v2/talk@2x.png")}  className="ch_media_img"  alt=""  />
						{channel.commentNum ? channel.commentNum:0}
					</div>

					<div className="ch_banner_member_2" >
						<img src={require("../../assets/v2/person@2x.png")}  className="ch_media_img"  alt=""  />
						{channel.memberNum ? channel.memberNum:0}
					</div>
					<div className="ch_banner_media" >
						<img src={require("../../assets/v2/person@2x.png")}  className="ch_media_img"  alt=""  />
						{channel.plateName}
					</div>
				</div>
			</div>
			<div className="ch_banner_right" >
				<div  className="ch_banner_right_1" >
					<div className="ch_banner_right_1_but_box" >
						<div  className={bannerBut=='LIVE'?"ch_banner_right_1_but ch_banner_right_1_but_active but-shadow ":"ch_banner_right_1_but but-shadow"}
							onClick={()=>{
									changeBut('LIVE')

						
							}}
						>
						  LIVE
						</div>
						<div className={bannerBut=='Image'?"ch_banner_right_1_but ch_banner_right_1_but_active but-shadow":"ch_banner_right_1_but but-shadow "}
							onClick={()=>{changeBut('Image')}}
						>
						 Image
						</div>
						<div className={bannerBut=='Story'?"ch_banner_right_1_but ch_banner_right_1_but_active but-shadow ":"ch_banner_right_1_but but-shadow"}  
							onClick={()=>{changeBut('Story')}}
						>
						Story
						</div>
					</div>
					<div>

						{memberVo.id == channel.memberId ?
					<Channel_options channelId={channelId} ></Channel_options>
					:
					<div className={channel.memberIsJob?'ch_banner_right_2_but_0':'ch_banner_right_2_but'}   >
					<Chnannel_jion item={channel} handleClick={()=>{
							getChannel();
							getMemberListByChannelId()
						
					}}  ></Chnannel_jion>
				</div>
					
			
					
							}
					</div>
				</div>
				<Carousel  afterChange={(e)=>{
					console.log(e);
					if(e==0){
						changeBut('LIVE')
					}

					if(e==1){
						changeBut('Image')
					}

					if(e==2){
						changeBut('Story')
					}

				}}  dots={false}   autoplay>
						<div>
							<div className="ch_banner_all_live" >
									{channel && channel.liveList ?
										channel.liveList.map((item:any,inx:any)=>{
											return item.image?
											<Link key={inx}   to={"/ChLive?liveId="+item.id} >
											<div className="ch_banner_all_image_item_box" >
											<Image
												width={"18vw"}
												height={"9.375vw"}
												className="ch_banner_all_image_item"
												src={JSON.parse(item.image)[0]['url']}  
											/>
											</div>
											
											
											</Link>
											:""
										}):""
									}
								</div>
						</div>
							
						
						<div>
						<div className="ch_banner_all_image" >
							{channel && channel.imgArr ?
							channel.imgArr.map((item:any,inx:any)=>{
								return <div className="ch_banner_all_image_item_box" >
									<Image
										width={"18vw"}
										height={"9.375vw"}
										className="ch_banner_all_image_item"
										src={item}  
									/>
								</div>
								
							}):""
						}
						</div>
						</div>
					
						<div>
							<div className="ch_banner_all_story" >
								{channel.channelStory}
							</div>

						</div>
						
				  </Carousel>
			</div>
	</div>
  <div  className="ch_w100_content" style={{marginTop:"2.0833vw",minHeight:"62.5vw",marginBottom:"2.0833vw"}}  >
	<div  className="ch_content_left" >
		<div className="ch_search_1">
			<img src={require("../../assets/v2/search.png")}  className="ch_search_img"   alt="" />
			<Input placeholder="search your need"  bordered={false} onInput={(e:any)=>{
				setKeyWorld(e.target.value);
				getPageList("",e.target.value);
			}} ></Input>
		</div>  
			<div  className="ch_title" style={{marginTop:"1.0833vw"}} >
			Channel  
			</div>
			<div  className="ch_content_left_1" >
				<div  className="ch_content_left_tools" >
					<div  onClick={selectAll} className="ch_content_left_ALL" >ALL</div>
					<Select 
					mode={"multiple"}
					style={{ width: '80%' }}
					value={formData.plateName}
					showArrow
					allowClear={true}
					onChange={(e,op)=>{
						formData.plateName=e;
						setFormData({...formData});

						getChannelPageList(formData.plateName);

					}}
					options={plateList}
					/>
				</div>
				<div style={{marginTop:"0.5208vw"}} >
					{channelList && channelList.length>0 ? channelList.map((item:any,inx:any)=>{
							return <div key={inx} className="ch_content_left_2"  onClick={()=>{channelChange(item.id)}} >
							<img src={item.headerImgArr[0]}  className="ch_midea" alt=""  />
							<div className="ch_content_left_3" >{item.name}</div>
							{item.memberIsJob?<img src={require("../../assets/v2/member@2x.png")}  className="ch_midea" alt="" />
							:<img src={require("../../assets/v2/team1.png")}     className="ch_midea" alt="" />}
							</div>
					}):"" }
				</div>
			</div>
	</div>

	<div  className="ch_content_center"  >
			<div >
				<SendForumChannel  channelId={channelId}  superiorId={''}  handleClick={()=>{getPageList("",'')}}  ></SendForumChannel>
			</div> 


			<Tabs onChange={changTabs}   defaultActiveKey={activeKey} >
				<Tabs.TabPane tab="General" key="General">
					{forum_div}
				</Tabs.TabPane>
				
				<Tabs.TabPane tab="Member" key="Member">
					{forum_div}
				</Tabs.TabPane>

			</Tabs>	
					


			{/* <div  className="disflex ch_tab"   style={{width:"100%"}} >
					<div className="ch_title_tab ch_status_active" onClick={changTabs} >
							{tabs[0]}
					</div>
					<div className="ch_title_tab"   onClick={changTabs} style={{marginLeft:"1.9583vw"}} >
							{tabs[1]}
					</div>
			</div> */}

		
	</div>

	<div  className="ch_content_right"  >
			<div  className="ch_title" style={{marginTop:"3.0833vw"}} >
					Member
			</div>

			<div  className="ch_content_right_member" >
				<div className="ch_content_right_member_box" >
					{members && members.length > 0 ? members.map((item,idx)=>{
					return 	<div  key={idx}  className="ch_content_right_member_1" >
						<UserHeader member={item} h={'3vw'} w={'3vw'} ></UserHeader>
					<div className="ch_content_right_member_1_name" >{item.userName}</div>
					{item.id == channel.memberId ?
					<img src={require("../../assets/v2/team1.png")}  className="ch_member_img" alt=""  />:<img src={require("../../assets/mobile/lesd@2x.png")}  className="ch_member_img" alt=""  />
					}
					</div>
					}):""}
				</div>
			</div>
				
			<div  className="ch_title" style={{marginTop:"1.0833vw"}} >
				News
			</div>
			<div  style={{width:"100%",alignItems:"center",justifyContent:"flex-start"}} >
				{channel.twitter?	<Timeline
				dataSource={{
					sourceType: 'profile',
					screenName: getTw(channel.twitter)
				}}
				options={{
					height: '1200',
					width:'25vw'
				}}
				/>:<div></div>}

			</div>
	</div>
</div>
</div>:<div></div>;

let  Mobile=<div  className="mchannel_box" >
		<div className="mch_banner_left" >
				<div className="mch_banner_left_box" >
					<div className="mch_banner_left_box_left"  >
						<img src={channel.headerImgArr ? channel.headerImgArr[0]:""}  className="mch_banner_header" alt="" />
						<div className="mch_banner_name" >
							<div>{channel.name}</div>
							<div>
							{channel.channelNftList && channel.channelNftList.length> 0 ? channel.channelNftList.map((item:any,inx:any)=>{
								return 	inx <=2 ? <img  src={JSON.parse(item.image)[0].url} 
								onClick={()=>{
									navigate("/BadgeDetail?detailId="+item.id)
								}}
								className="mch_media_img_bage"  style={{objectFit:"contain"}}  alt=""  />:"";
							}):""} 
							</div>
						</div>
					</div>

					<div  className="mch_banner_left_box_right" >
									{memberVo.id == channel.memberId ?
								<Channel_options channelId={channelId} ></Channel_options>
								:
								<div  style={{opacity:channel.memberIsJob? '0.8':'1'}} >
									<Chnannel_jion   isImage={true} item={channel} handleClick={()=>{
											getChannel();
											getMemberListByChannelId()
									}}  ></Chnannel_jion>
								</div>
								}
						

					</div>
				</div>

				<div  className="ch_banner_left_box" style={{paddingLeft:"5.8667vw",marginTop:"5.7333vw",width:"100%"}} >
					<div  className="mch_banner_mgs" >
						<img src={require("../../assets/v2/talk@2x.png")}  className="mch_media_img"  alt=""  />
						{channel.commentNum ? channel.commentNum:0}
					</div>
					<div className="mch_banner_mgs" >
						<img src={require("../../assets/v2/person@2x.png")}  className="mch_media_img"  alt=""  />
						{channel.memberNum ? channel.memberNum:0}
					</div>
					<div className="mch_banner_mgs" >
						<img src={require("../../assets/v2/person@2x.png")}  className="mch_media_img"  alt=""  />
						{channel.plateName}
					</div>
				</div>
				
			</div>

			<div className="ch_banner_right_1_but_box_m" >
				<div  className={bannerBut=='LIVE'?"ch_banner_right_1_but_m ch_banner_right_1_but_active_m":"ch_banner_right_1_but_m"}
					onClick={()=>{
							changeBut('LIVE')
					}}
				>
					LIVE
				</div>
				<div className={bannerBut=='Image'?"ch_banner_right_1_but_m ch_banner_right_1_but_active_m ":"ch_banner_right_1_but_m  "}
					onClick={()=>{changeBut('Image')}}
				>
					Image
				</div>
				<div className={bannerBut=='Story'?"ch_banner_right_1_but_m ch_banner_right_1_but_active_m  ":"ch_banner_right_1_but_m"}  
					onClick={()=>{changeBut('Story')}}
				>
				Story
				</div>
			</div>



			<Carousel  afterChange={(e)=>{
					console.log(e);
					if(e==0){
						changeBut('LIVE')
					}

					if(e==1){
						changeBut('Image')
					}

					if(e==2){
						changeBut('Story')
					}

				}}  dots={false}   autoplay>
						<div>
							<div className="ch_banner_all_live_m" >
									{channel && channel.liveList ?
										channel.liveList.map((item:any,inx:any)=>{
											return item.image?
											<Link key={inx}   to={"/ChLive?liveId="+item.id} >
											<div className="ch_banner_all_image_item_box_m" >
											<Image
												width={"58.3333vw"}
												height={"28.6667vw"}
												className="ch_banner_all_image_item"
												src={JSON.parse(item.image)[0]['url']}  
											/>
											</div>
											</Link>
											:""
										}):""
									}
								</div>
						</div>
							
						
						<div>
						<div className="ch_banner_all_live_m" >
							{channel && channel.imgArr ?
							channel.imgArr.map((item:any,inx:any)=>{
								return <div className="ch_banner_all_image_item_box_m" >
									<Image
										width={"58.3333vw"}
										height={"28.6667vw"}
										className="ch_banner_all_image_item"
										src={item}  
									/>
								</div>
								
							}):""
						}
						</div>
						</div>
						<div>
							<div className="ch_banner_all_story_m" >
								{channel.channelStory}
							</div>

						</div>
				  </Carousel>





			<div  className="disflex mch_tab"   style={{width:"100%",marginTop:"2.3333vw"}} >
						{/* <div className="mch_title_tab mch_status_active" onClick={changTabs} >
								{tabs[0]}
						</div>
						<div className="mch_title_tab"   onClick={changTabs} style={{marginLeft:"1.9583vw"}} >
								{tabs[1]}
						</div> */}

						<Tabs onChange={changTabs}   defaultActiveKey={activeKey} >
						<Tabs.TabPane tab="General" key="General">
							{forumList.length > 0 ?
								<CMDiscover_list  isSysyem={memberVo.id == channel.memberId }  fromPage={"Channel?channelId="+channelId}  mmeber={memberVo} forumList={forumList}  show={false}  handleClick={()=>{getPageList("","")}} ></CMDiscover_list>:<div></div>
							}
						</Tabs.TabPane>
						
						<Tabs.TabPane tab="Member" key="Member">
						{forumList.length > 0 ?
								<CMDiscover_list  isSysyem={memberVo.id == channel.memberId }  fromPage={"Channel?channelId="+channelId}  mmeber={memberVo} forumList={forumList}  show={false}  handleClick={()=>{getPageList("","")}} ></CMDiscover_list>:<div></div>
							}
						</Tabs.TabPane>

					</Tabs>	
					


				</div>
				<SendForumChannel  channelId={channelId}  superiorId={''}  handleClick={()=>{getPageList("",'')}}  ></SendForumChannel>
				




</div>


return Common.isMobile ? Mobile : Web 




}
