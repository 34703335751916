import { useEffect, useState } from "react";
import { Input,message,Select,Upload,Drawer,Modal} from 'antd';
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UserHeader } from './UserHeader';
import { Common } from "../../app/Common";
import { Tabs,Popup} from 'antd-mobile'

export function CBadgeDetail() {
	const navigate = useNavigate();
	let query = new URLSearchParams(useLocation().search);
	let  detailId =query.get("detailId");
	const [detail,setDetail] =  useState({}as any);
	const [myNftList,setMyNftList] =  useState([]as any);
	const [memberList,setMemberList] =  useState([]as any);
	const [badgeMint,setBadgeMint] =  useState(false);
	const [mintSelect, setMintSelect] = useState(0);
	const [walletAddress, setWalletAddress] = useState('')
	const [modalOpenStatus, setModalOpenStatus] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);


	useEffect(()=>{
		getDetail();
		getMyNftList();
		getMemberList();
	},[detailId]);
	function getDetail(){
		httpGet("/memberNft/getDetailByItemId/"+detailId).then((e:any)=>{
			setDetail(e.data.data);
		})
	}
	function  getMemberList(){
		httpGet("/memberNft/ownerByitemId/"+detailId).then((e:any)=>{
			if(e.data.code == 0 ){
				setMemberList(e.data.data);
			}
		})
	}
	function getMyNftList(){
		httpGet("/memberNft/myNftList").then((e:any)=>{
			if(e.data.code == 0 ){
				setMyNftList(e.data.data);
			}
		})
	}

	function memberNft(){
		if(mintSelect == 1){
			message.error("Please input your Polygon Address ");
			return;
		}
		httpPost("/memberNft/mint",{walletAddress:walletAddress}).then((e:any)=>{
			if(e.data.data==0){
				setModalOpenStatus(false)
			}else{
				setModalOpenStatus(true)
			}
			setBadgeMint(false);
			setIsModalOpen(true);
		})
	}

	
	
	function getMinStep(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=6.25;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="donate_step_bule" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}

	function getMinStepM(fundRaisingObjectives:any,donateNum:any){
		let maxWidth=42.6667;
		let width =maxWidth/fundRaisingObjectives*donateNum;
		return <div className="mdonate_list_item_cld_num_step" style={{width:width>maxWidth?`${maxWidth+"vw"}`:`${width+"vw"}`}} >
		</div>
	}


	function windOpen(url:any){
		window.open(url);
	}


	function goProject(id:any){
		if(id){
			navigate("/Project?projectId="+id);
		}else{
			message.error("Projects without  donations");
		}
	}

	function tw(id:any) {
		let shar_url=host_url+"/#/BadgeDetail?detailId="+id;
		let metaArr = [
			'twitter:title','',
			'twitter:card', 'summary_large_image',
			'twitter:url', shar_url,
		]
		let metaParams = metaArr.toString()
		metaParams = encodeURIComponent(encodeURIComponent(metaParams))
		window.open(`https://twitter.com/share?text=Here’s my Impact badge from Impactopia, please check it out&url=${host_url}/api/app/share/new?meta=${metaParams}`)
	}


	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};



let Web=	 <div  className="badge_detail_body" >
		<div   className="badge_detail_current" >
				<div  className="badge_detail_current_img_box">
					{detail.image ? 	<img   src={JSON.parse(detail.image)[0].url}  className="badge_detail_current_img" />:""}
				</div>
				<div  className="badge_detail_current_info_box" >
					<div className="badge_detail_current_info_box_top" >
						<div  className="badge_detail_current_info_box_top_1" >
							<div  className="badge_detail_current_info_box_top_1_name" >{detail.name}</div>		
							<div className="badge_detail_current_info_box_top_1_content" >
								{detail.nftConfigEntity ? detail.nftConfigEntity.description:""}
							</div>
							<div className="badge_detail_current_top2" >
								{  detail && detail.plateNftList && detail.plateNftList.length > 0  ? <div className="badge_detail_current_line" >
									<div className="badge_detail_current_token" >
										{detail.plateNftList[0].donationAmount?detail.plateNftList[0].donationAmount:0} / {detail.plateNftList[0].handselValue}{detail.plateNftList[0].tokenName}
									</div>
									<div  className="flex_width100 donate_list_item_cld_num " >
										<div className="donate_step" >{getMinStep(detail.plateNftList[0].handselValue,detail.plateNftList[0].donationAmount)}</div>
									</div>
								</div>:""
								}
								<div className="badge_detail_current_but" >
									<div className="badge_detail_current_mint but-shadow" onClick={()=>{setBadgeMint(true)}} >
										Claim 
									</div>

									<div className="badge_detail_current_tw but-shadow"  onClick={()=>{tw(detail.itemId)}} >
										Share on Twitter
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="badge_detail_current_info_box_bottom"  >
					{detail.nftConfigEntity? detail.nftConfigEntity.useDirections:""}
						</div>
				</div>

				<div className="badge_detail_current_info_list" >
					<div  className="badge_detail_current_info_list_1" >
						<div className="badge_detail_current_info_list_img" >
							{myNftList ?  myNftList.map((item:any,inx:any)=>{
								return 	<img  onClick={()=>{
									navigate("/BadgeDetail?detailId="+item.nftItemId);
								}}   key={inx} src={JSON.parse(item.image)[0].url}  className="badge_your_list_img" alt="" />
							}):""}
						</div>
						<div className="badge_your_list_view but-shadow" >
						 <Link  to={'/Person'}   state={{ type: "NFTbadge"}}  >
							 <div>
								<div className="badge_your_list_view_1" >View</div> 
								<div className="badge_your_list_view_1" >All</div>
							</div>
							</Link>
							
						</div>
				
				</div>

				<div className="badge_detail_current_info_list_text" >
						You have obtained these badges.
					</div>
				</div>
		</div>

		<div className="badge_detail_list" >
			<div className="badge_detail_list_name" >
				 {detail.nftConfigEntity? detail.nftConfigEntity.description:""}
			</div>
			<div  className="badge_detail_user_list" >
				{memberList ? memberList.map((ite:any,idx:any)=>{
						return <div  key={idx} className="badge_detail_user_list_member" >
						<UserHeader member={ite}  marginRight={0.1} ></UserHeader>
						<div  className="badge_detail_user_list_member_name" >
							{ite.userName}
						</div>
					</div>
				}):"" }
			</div>
		</div>

		<Drawer title="Badge mint" placement="right"    width={"24.8542vw"} onClose={()=>{setBadgeMint(false)}} open={badgeMint}>
			<div style={{paddingTop:"10.4167vw",width:"100%"}} >
			<div className="flex-align-center" >
					<div className={mintSelect == 0? 'badgemint_only badgemint_only_active':'badgemint_only'}  onClick={()=>{
						setMintSelect(0)
					}} >
							On Impactopia Only
					</div>
				</div>
				<div className="flex-align-center" >
					<div className={mintSelect == 1? 'badgemint_only badgemint_only_active':'badgemint_only'} onClick={()=>{
						setMintSelect(1)
					}} >
						On Impactopia and On Polygon
					</div>
				</div>
				{
					mintSelect == 1?<div className="flex-align-center" style={{marginTop:" 1.0417vw"}} >
					<Input placeholder="Your Polygon Address"
					value={walletAddress}
					onChange={(e)=>{
						setWalletAddress(e.target.value);
					}}
					bordered={false} style={{ width: "18.75vw", height: "2.5vw",background:"#FCFCFC" }} />
				</div>:""
				}
				<div className="flex-align-center"  onClick={memberNft} style={{marginTop:" 5.4167vw"}} >
					<div  className="badgemintBut" >
						Continue
					</div>
				</div>
			</div>
		</Drawer>
		<Modal closable={false}   bodyStyle={{ borderRadius:"20px",backgroundColor: "transparent", width: "100%" }} footer={null} onOk={handleOk} onCancel={handleCancel}  open={isModalOpen}>
				{modalOpenStatus?<div className="mint_success-img"  >
					<div className="flex-align-center">
					<div className="donationMoney_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Good</div>
				</div>
			</div>:<div className="mint_failed-img"  >
				<div className="flex-align-center">
					<div className="donationMoney_fail_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Continue</div>
				</div>
			</div>}
		</Modal> 


	</div>
let  Mobile=detail.itemId ?<div>
	{  detail && detail.plateNftList && detail.plateNftList.length > 0 ?
				<div>
					<div className="mbadge_detail_current" >
						<div     onClick={()=>{	navigate("/BadgeDetail?detailId="+detail.itemId);}}  >
							<img src={JSON.parse(detail.image)[0].url}  className="mbadge_detail_img" alt="" />
						</div>
						<div  className="mbadge_your_current_right">
							<div className="mbadge_detail_name"  >
								{detail.name}
							</div>
			
							<div className="mbadge_detail_dec"  >
								{detail.nftConfigEntity ? detail.nftConfigEntity.description:""}
							</div>
							{  detail && detail.plateNftList && detail.plateNftList.length > 0
								?<div className="mbadge_your_current_right_token"  onClick={()=>{	navigate("/BadgeDetail?detailId="+detail.itemId);}} >
								{detail.plateNftList[0].donationAmount} / {detail.plateNftList[0].handselValue}{detail.plateNftList[0].tokenName}
							</div>:""
							}
						
							<div className="mbadge_your_current_step"  >
								<div  className="mdonate_list_item_cld_num "  onClick={()=>{	navigate("/BadgeDetail?detailId="+detail.itemId);}}  >
									<div className="cdonate_step" >{getMinStepM(detail.plateNftList[0].handselValue,detail.plateNftList[0].donationAmount)}</div>
								</div>

							</div>

							{/* <div	 className="mbadge_your_current_but" >
									<div  className="mbadge_your_current_claim" onClick={()=>{setBadgeMint(true)}} >
										Claim
									</div>
							</div> */}

						</div>
								
						
					</div>
					<div style={{paddingLeft:10,paddingRight:10,textAlign:"center",fontWeight:"bold"}} >
						{detail.nftConfigEntity ? detail.nftConfigEntity.useDirections:""}
					</div>
					<div  className="mbadge_deail_but_box" >
						<div className="mbadge_detail_current_mint"  onClick={()=>{setBadgeMint(true)}} >
							Claim 
						</div>
			
						<div className="mbadge_detail_current_tw"  onClick={()=>{tw(detail.itemId)}} >
							Share on Twitter
						</div>
					</div>
				</div>
				
				:""
				}



	
		


			<div  className="mbadge_detail_user_list" >
				{memberList ? memberList.map((ite:any,idx:any)=>{
						return <div  key={idx} className="mbadge_detail_user_list_member" >
						<UserHeader member={ite}  marginRight={0.1} ></UserHeader>
						<div  className="mbadge_detail_user_list_member_name" >
							{ite.userName}
						</div>
					</div>
				}):"" }
			</div>


			<Popup  bodyClassName="mbadgemint_popup" visible={isModalOpen} position='bottom' onMaskClick={() => {setBadgeMint(false)}} bodyStyle={{padding:"20px" }}>
			{modalOpenStatus?<div className="m_mint_success-img"  >
					<div className="flex-align-center">
					<div className="mdonationMoney_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Good</div>
				</div>
			</div>:<div className="m_mint_failed-img"  >
				<div className="flex-align-center">
					<div className="mdonationMoney_fail_but" onClick={()=>{
						setIsModalOpen(false)
					}} >Continue</div>
				</div>
			</div>}
		</Popup>

		<Popup  bodyClassName="mbadgemint_popup" visible={badgeMint} position='bottom' onMaskClick={() => {setBadgeMint(false)}} bodyStyle={{padding:"20px" }}>
			<div style={{paddingTop:"10.4167vw",width:"100%"}} >
			<div className="flex-align-center" >
					<div className={mintSelect == 0? 'mbadgemint_only mbadgemint_only_active':'mbadgemint_only'}  onClick={()=>{
						setMintSelect(0)
					}} >
							On Impactopia Only
					</div>
				</div>
				<div className="flex-align-center" >
					<div className={mintSelect == 1? 'mbadgemint_only mbadgemint_only_active':'mbadgemint_only'} onClick={()=>{
						setMintSelect(1)
					}} >
						On Impactopia and On Polygon
					</div>
				</div>
				{
					mintSelect == 1?<div className="flex-align-center" style={{marginTop:" 1.0417vw"}} >
					<Input placeholder="Your Polygon Address"
					value={walletAddress}
					onChange={(e)=>{
						setWalletAddress(e.target.value);
					}}
					bordered={false} style={{ width: "85.3333vw", height: "11.7333vw",background:"#FCFCFC" }} />
				</div>:""
				}
				<div className="flex-align-center"  onClick={memberNft} style={{marginTop:" 5.4167vw"}} >
					<div  className="mbadgemintBut" >
						Continue
					</div>
				</div>
			</div>
		</Popup>






	</div>:<div></div>
return Common.isMobile ? Mobile : Web
}
