import { useEffect, useState } from "react";
import { Link, useNavigate  } from 'react-router-dom'

export function CHome() {
	const navigate = useNavigate();

	useEffect(() => {
		let  projectForm =localStorage.getItem("pageFrom");
		if(projectForm){
			localStorage.removeItem("pageFrom");
			navigate(projectForm);
		}
	  }, [])

	return  <div ></div>
}
