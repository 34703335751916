import { useEffect, useState } from "react";
import "./SendContent.css";
import { Input,message,Select,Upload ,SelectProps} from 'antd';
import {httpGet,httpPost,host_url} from '../../utils/httpUtils';
import { Formatter } from './Formatter';
import { Common } from '../../app/Common'
type IProps = {
    handleClick: () => void,
	superiorId?:any,
	forumId:any,
	showList?:boolean
}
export function SendContent(props:IProps) {
	const { TextArea } = Input;
	const [forumContentInfo,setForumCommentInfo] =  useState({} as ForumContent);
	const [forumCommentList,setforumCommentList] =  useState([] as Array<any>);
	interface ForumContent{
		content:any,
		forumId:any,
		superiorId:any
	   }

	function postSend(){
		  let  cyforumInfo = {...forumContentInfo};
		  cyforumInfo.superiorId=props.superiorId;
		  cyforumInfo.forumId=props.forumId;
		  httpPost("/forum/comment",{...cyforumInfo}).then((e:any)=>{
			  if(e.data.code == 0){
				  message.success("forum content send  is success");
				  setForumCommentInfo({}as ForumContent);
				  props.handleClick();
				  if(props.showList){
					getComment();
				 }
			  }
		  })
	  }


	  useEffect(()=>{
		if(props.showList){
			getComment();
		}
	},[props]);



	  function getComment(){
		httpGet("/forum/getComment/"+props.forumId).then((e:any)=>{
			if(e.data.code == 0){
				setforumCommentList(e.data.data);
			}
		})
	  }

	  function showSendContent(indx:any,item:any){
		for (let index = 0; index < forumCommentList.length; index++) {
			forumCommentList[index].showSendContent=false;
		}
		forumCommentList[indx].showSendContent=!forumCommentList[indx].showSendContent;
		setforumCommentList([...forumCommentList]);
	}

	function cancelAttention(item:any,index:any){
		httpGet("/attention/cancelAttention/"+item.id+"/5").then((e:any)=>{
			if(e.data.code==0){
				forumCommentList[index].isLike=0;
				setforumCommentList([...forumCommentList]);
			}
		})
	}

	function commentAttention(item:any,index:any){
		httpGet("/attention/commentAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumCommentList[index].isLike=1;
				setforumCommentList([...forumCommentList]);
			}
		})
	}



	let Web= <div>
		<div className="sendContent" >
			<TextArea placeholder="Your forum  content " 
				value={forumContentInfo.content}
				onChange={(e)=>{
					forumContentInfo.content=e.target.value;
					setForumCommentInfo({...forumContentInfo});
				}}
				style={{width:"47.3958vw",height:"4.8vw"}}
				bordered={false}  />
				<div className="sendContent_post"  onClick={postSend} >Post</div>
			</div>

			<div className="contentList" >
				{forumCommentList.map((item,indx)=> {
					return <div className="contentlist_item" key={indx} >
							<div className="content_1"  >
								<img src={item.memberHeadImg?item.memberHeadImg:require("../../assets/v2/logo.png")} alt=""   style={{width:26,height:26,borderRadius:26}} /> 
								<div className="content_1_2" >{item.memberName}</div>
								{item.superiorId ? 	<div className="content_1_type" >reple</div> :"" }
								{item.superiorId ? 	<img src={item.superiorMemberHeadImg?item.superiorMemberHeadImg:require("../../assets/v2/logo.png")} alt=""   style={{width:26,height:26,borderRadius:26}} /> :"" }
								{item.superiorId ? <div className="content_1_2" >{item.superiorMemberName}</div>:"" }
							</div>
							<div className="content_1_3"  >{item.content}</div>
							<div  className="content_item_but" >
								<div className="content_item_but_1"  >
								<Formatter dateTime={item.createDateTime} ></Formatter>
								</div>
								<div className="content_item_but_1"  >Like</div>
								<div className="content_item_but_1"  onClick={()=>showSendContent(indx,item)} >Reply</div>
							</div>
							{item.showSendContent?<SendContent  showList={false} forumId={item.forumId} superiorId={item.id} handleClick={getComment}></SendContent>:""}
						</div>
				})}
			</div>

	</div>
	

	let Mobile= <div className="msendContent_box"  >
		<div className="msendContent" >
			<TextArea placeholder="Your forum  content " 
				value={forumContentInfo.content}
				onChange={(e)=>{
					forumContentInfo.content=e.target.value;
					setForumCommentInfo({...forumContentInfo});
				}}
				style={{width:"100%",height:"44.8vw"}}
				bordered={false}  />
				<div  style={{display:"flex",justifyContent:"flex-end",width:"100%","paddingRight":10}} >
					<div className="msendContent_post"  onClick={postSend} >Post</div>
				</div>
		</div>


		<div className="mcontentList" >
				{forumCommentList.map((item,indx)=> {
					return <div className="mcontentlist_item" key={indx} >
							<div className="mcontent_1"  >
								<img src={item.memberHeadImg?item.memberHeadImg:require("../../assets/v2/logo.png")} alt=""  className="mcontent_img"  /> 
								<div className="mcontent_1_2" >{item.memberName}</div>
							</div>
							<div className="mcontent_1_3"  >{item.superiorId ? <span className="mcontent_1_2_name" >@{item.superiorMemberName}</span>:"" }{item.content}</div>

							<div  className="mcontent_item_but" >
								<div className="mcontent_item_but_1"  >
									<Formatter dateTime={item.createDateTime} ></Formatter>
								</div>
								<div  className="moptions_box_flex"  >
									{item.isLike==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{commentAttention(item,indx)}} className="mlive_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,indx)}} className="mlive_img"  alt="" />}
								</div>
								<div className="moptions_box_flex"  onClick={()=>showSendContent(indx,item)} >
									<img src={require("../../assets/v2/feed@2x.png")}  className="mlive_img"   alt="" />
								</div>
							</div>
							{item.showSendContent  ?<SendContent  showList={false} forumId={item.forumId} superiorId={item.id} handleClick={getComment}></SendContent>:""}
						</div>
				})}
			</div>
	</div>
	return Common.isMobile ? Mobile : Web
	
}
