import { useEffect, useState } from "react";
import { Link, useNavigate  } from 'react-router-dom'
import { Input, Dropdown, Popover, message, Spin } from 'antd';
import {httpGet,httpPost} from '../../utils/httpUtils';
import Iframe from 'react-iframe';
import { Select,Drawer,InputNumber , Button, Tabs,Modal} from 'antd';
import {Share} from './Share';
import {UserHeader} from "./UserHeader";
import {Ctrending_star} from './Ctrending_star';
import { json } from 'stream/consumers';
import {Chnannel_jion} from './Chnannel_jion';
import {ImageList} from './Image';
import { Formatter } from './Formatter';
import { Common } from "../../app/Common";
import "./CPerson_donation.css";
import { CMDiscover_list } from './CMDiscover_list'

type IProps = {
	memberId?:any,
}

export function CPerson_org(props:IProps) {
  const [attentionProjectList, setAttentionProjectList] = useState({} as any)
  const [myMemberNft, setMyMemberNft] = useState({} as any)
  useEffect(() => {
   if(props.memberId){
      getAttentionProjectList();
      getForumList("");
      getMember();
   }
 }, [props.memberId])
 const [forumList, setForumList] = useState([] as Array<any>)
 const [memberData,setMemberData] =  useState(false);

 const [activeKey,setActiveKey] =  useState("Thoughts");

   const getForumList = (active:any) => {
    if(!active){
      active=activeKey;
    }
    httpPost('/forum/myCollectPageList', {
      page: 1,
      limit: 100,
      queryType:(active=='Thoughts'?'2':'1')
    }).then((e: any) => {
      if (e.data.code == 0) {
        setForumList(e.data.data.list)
      }
    })
  }

  function tabsChange(e:any){
    setActiveKey(e);
    setFollowName(e);
    if(e =='Project'){
      getAttentionProjectList();
    }else{
      getForumList(e);
    }
    
  }

  
  function getMember(){
		httpGet("/member/getMember").then((e:any)=>{
			if(e.data.code == 0 ){
				setMemberData(true);
			}else{
        setMemberData(false);
      }
		})
	 }



  function getTags(item: any) {
    let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}
    let tags = item.tages
    let tagshtml = <div></div>
    if (tags) {
      tags = tags.split(',')
      tagshtml = (
        <div className="forum_flex_one">
          {tags.map((ite: any, inde: any) => {
            let color = ''
            if (ite) {
              color = color_conf[ite.substring(0, 1).toLowerCase()]
                ? color_conf[ite.substring(0, 1).toLowerCase()]
                : '#FFB4A2'
            }
            return (
              <div
                key={inde}
                className="forum_but but-shadow"
                style={{ backgroundColor: color }}>
                {ite}
              </div>
            )
          })}
        </div>
      )
    }
    return tagshtml
  }

 
 function getAttentionProjectList() {
  httpGet('/attention/getCollectProjectList').then((e: any) => {
    if (e.data.code == 0) {
      setAttentionProjectList(e.data.data)
    }
  })
}
function getMinStepM(fundRaisingObjectives: any, donateNum: any) {
  let maxWidth = 42.6667
  let width = (maxWidth / fundRaisingObjectives) * donateNum
  return (
    <div
      className="mproject_box_item_step_bule"
      style={{
        width: width > maxWidth ? `${maxWidth + 'vw'}` : `${width + 'vw'}`,
      }}></div>
  )
}
function getMinStep(attentionMember: any) {
  let width =
    (180 / attentionMember.fundRaisingObjectives) * attentionMember.donateNum
  return (
    <div
      className="min_step_1"
      style={{ width: width > 180 ? 180 : width }}></div>
  )
}

  function getUrl(url:any){
    if(url ){
      try {
        let code =url.replace("https://youtu.be/","");
        return `https://www.youtube.com/embed/${code}`;
      } catch (error) {
        
      }
    }
        return "";
    }

    function forumAttention(item: any, index: any) {
      httpGet('/attention/forumAttention/' + item.id).then((e: any) => {
        if (e.data.code == 0) {
          forumList[index].isAttentin = 1
          forumList[index].attentionNum += 1
          setForumList([...forumList])
        } else if (e.data.code == 401) {
          localStorage.setItem('pageFrom', '/#/Discover_list')
          navigate('/SignIn')
        }
      })
    }
  
    function forumCollect(item: any, index: any) {
      httpGet('/collect/forumCollect/' + item.id).then((e: any) => {
        if (e.data.code == 0) {
          forumList[index].isCollect = 1
          setForumList([...forumList])
        } else if (e.data.code == 401) {
          localStorage.setItem('pageFrom', '/#/Discover_list')
          navigate('/SignIn')
        }
      })
    }
  
    function cancelCollect(item: any, index: any, type: any) {
      httpGet('/collect/cancelCollect/' + item.id + '/' + type).then((e: any) => {
        if (e.data.code == 0) {
          forumList[index].isCollect = 0
          setForumList([...forumList])
        } else if (e.data.code == 401) {
          localStorage.setItem('pageFrom', '/#/Discover_list')
          navigate('/SignIn')
        }
      })
    }
  
    function cancelAttention(item: any, index: any, type: any) {
      httpGet('/attention/cancelAttention/' + item.id + '/' + type).then(
        (e: any) => {
          if (e.data.code == 0) {
            forumList[index].isAttentin = 0
            forumList[index].attentionNum -= 1
            setForumList([...forumList])
          } else if (e.data.code == 401) {
            localStorage.setItem('pageFrom', '/#/Discover_list')
            navigate('/SignIn')
          }
        }
      )
    }


    const navigate = useNavigate();
    let Web=  <div className="person_projects" style={{ marginTop: 20 }}>
    <Tabs onChange={tabsChange}   defaultActiveKey={activeKey} >
      <Tabs.TabPane tab="Thoughts" key="Thoughts">
      {forumList.length > 0 ? forumList.map((item:any, index:any) => {
            return (
              <div key={index} className="dis_list_item">
                <Link to={'/Forumlist?forumId=' + item.id}>
                  <div className="dis_list_name">{item.title}</div>
                  <div
                    className="disflex"
                    style={{
                      width: '100%',
                      marginLeft: '0.5208vw',
                      alignItems: 'center',
                    }}>
                    <UserHeader member={item.memberEntity}></UserHeader>
                    <div style={{ color: ' #222222' }}>
                      {item.memberName ? item.memberName : ''}
                    </div>
                    <div style={{ display: 'flex', marginLeft: 50 }}>
                      {getTags(item)}
                      <div className="dis_time">
                        <Formatter dateTime={item.createDateTime}></Formatter>
                      </div>
                    </div>
                  </div>

                  <div
                    className="dis_list_content"
                    dangerouslySetInnerHTML={{ __html: item.content?item.content.replace(/<[^>]+>/g,""):"" }}
                    ></div>
                </Link>
                {
                  item.url? <div>
                      <Iframe url={getUrl(item.url)}
                        width="640px"
                        height="320px"
                        id=""
                        className=""
                        display="block"
                        position="relative"/>
                    
                  </div>:  (item.imgUrl && item.imgUrl.length) > 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                      }}>
                      <ImageList item={item.imgUrl}></ImageList>
                    </div>
                  ) : (
                    <div></div>
                  )
                }
              
                <div
                  className="disflex"
                  style={{ width: '50.7813vw', marginTop: 20 }}>
                  <div
                    className="disflex"
                    style={{ width: '50%', alignItems: 'center' }}>
                    <div className="options_box">
                      <div className="options_box_flex options_box_flex_border">
                        {item.isAttentin == 0 ? (
                          <img
                            src={require('../../assets/v2/zan@2x.png')}
                            onClick={() => {
                              forumAttention(item, index)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={require('../../assets/v2/zan_2.png')}
                            onClick={() => {
                              cancelAttention(item, index, 3)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        )}
                        <div className="options_box_num">
                          {item.attentionNum}
                        </div>
                      </div>
                      <div
                        className="options_box_flex options_box_flex_border"
                        onClick={()=>{
                          if(memberData){
                            navigate('/Forumlist?forumId=' + item.id);
                          }else{
                            localStorage.setItem("pageFrom",'/#/Forumlist?forumId=' + item.id);
			                    	navigate("/SignIn");
                          }
                        }}
                        
                        >
                        <img
                          src={require('../../assets/v2/feed@2x.png')}
                          className="dis_list_img"
                          alt=""
                        />
                        <div className="options_box_num">{item.commentNum}</div>
                      </div>


                      <div className="options_box_flex options_box_flex_border">
                        {item.isCollect == 0 ? (
                          <img
                            src={require('../../assets/v2/shoucang@2x.png')}
                            onClick={() => {
                              if(memberData){
                                forumCollect(item, index)
                              }else{
                                localStorage.setItem("pageFrom",'/#/Forumlist?forumId=' + item.id);
                                navigate("/SignIn");
                              }
                             
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={require('../../assets/v2/shoucang_2.png')}
                            onClick={() => {
                              cancelCollect(item, index, 3)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="options_box_flex">
                        <Share sharedata={item} type={'dic'}></Share>
                      </div>
                    </div>
                  </div>
                  <div
                    className="disflex"
                    style={{ width: '50', marginTop: 20 }}>
                    <div
                      className="disflex"
                      style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <Link to={'/Forumlist?forumId=' + item.id}>
                        <div className="dis_Reading">Continue Reading→</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          }):   <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="no_list" >You haven’t followed any thoughts yet!</div></div>}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Project" key="Project">
      {attentionProjectList.length > 0 ? (
            attentionProjectList.map((item: any, index: any) => {
              return (
                <div className="person_Projects_box" key={index}>
                  <Link to={'/Project?projectId=' + item.id}>
                    <div style={{ width: '100%' }}>
                      <div className="person_people_name">
                        {item.name}
                        <div className="person_people_name_2">→</div>
                      </div>
                      <div className="person_people_text">
                        <div className="person_people_text_1">
                          {item.description}
                        </div>
                        <div className="person_people_text_2">
                          {item.donateNum} {item.receivingToken} /{' '}
                          {item.fundRaisingObjectives} {item.receivingToken}
                        </div>

                        <div className="min_step">{getMinStep(item)}</div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })
          ) : (
            <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="no_list" >You haven’t followed any project yet!</div></div>
          )}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Channel" key="Channel">
      {forumList.length > 0 ? forumList.map((item:any, index:any) => {
            return (
              <div key={index} className="dis_list_item">
                <Link to={'/Forumlist?forumId=' + item.id}>
                  <div className="dis_list_name">{item.title}</div>
                  <div
                    className="disflex"
                    style={{
                      width: '100%',
                      marginLeft: '0.5208vw',
                      alignItems: 'center',
                    }}>
                    <UserHeader member={item.memberEntity}></UserHeader>
                    <div style={{ color: ' #222222' }}>
                      {item.memberName ? item.memberName : ''}
                    </div>
                    <div style={{ display: 'flex', marginLeft: 50 }}>
                      {getTags(item)}
                      <div className="dis_time">
                        <Formatter dateTime={item.createDateTime}></Formatter>
                      </div>
                    </div>
                  </div>

                  <div
                    className="dis_list_content"
                    dangerouslySetInnerHTML={{ __html: item.content?item.content.replace(/<[^>]+>/g,""):"" }}
                    ></div>
                </Link>
                {
                  item.url? <div>
                      <Iframe url={getUrl(item.url)}
                        width="640px"
                        height="320px"
                        id=""
                        className=""
                        display="block"
                        position="relative"/>
                    
                  </div>:  (item.imgUrl && item.imgUrl.length) > 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                      }}>
                      <ImageList item={item.imgUrl}></ImageList>
                    </div>
                  ) : (
                    <div></div>
                  )
                }
              
                <div
                  className="disflex"
                  style={{ width: '50.7813vw', marginTop: 20 }}>
                  <div
                    className="disflex"
                    style={{ width: '50%', alignItems: 'center' }}>
                    <div className="options_box">
                      <div className="options_box_flex options_box_flex_border">
                        {item.isAttentin == 0 ? (
                          <img
                            src={require('../../assets/v2/zan@2x.png')}
                            onClick={() => {
                              forumAttention(item, index)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={require('../../assets/v2/zan_2.png')}
                            onClick={() => {
                              cancelAttention(item, index, 3)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        )}
                        <div className="options_box_num">
                          {item.attentionNum}
                        </div>
                      </div>
                      <div
                        className="options_box_flex options_box_flex_border"
                        onClick={()=>{
                          if(memberData){
                            navigate('/Forumlist?forumId=' + item.id);
                          }else{
                            localStorage.setItem("pageFrom",'/#/Forumlist?forumId=' + item.id);
			                    	navigate("/SignIn");
                          }
                        }}
                        
                        >
                        <img
                          src={require('../../assets/v2/feed@2x.png')}
                          className="dis_list_img"
                          alt=""
                        />
                        <div className="options_box_num">{item.commentNum}</div>
                      </div>


                      <div className="options_box_flex options_box_flex_border">
                        {item.isCollect == 0 ? (
                          <img
                            src={require('../../assets/v2/shoucang@2x.png')}
                            onClick={() => {
                              if(memberData){
                                forumCollect(item, index)
                              }else{
                                localStorage.setItem("pageFrom",'/#/Forumlist?forumId=' + item.id);
                                navigate("/SignIn");
                              }
                             
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={require('../../assets/v2/shoucang_2.png')}
                            onClick={() => {
                              cancelCollect(item, index, 3)
                            }}
                            className="dis_list_img"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="options_box_flex">
                        <Share sharedata={item} type={'dic'}></Share>
                      </div>
                    </div>
                  </div>
                  <div
                    className="disflex"
                    style={{ width: '50', marginTop: 20 }}>
                    <div
                      className="disflex"
                      style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <Link to={'/Forumlist?forumId=' + item.id}>
                        <div className="dis_Reading">Continue Reading→</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          }):  <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="no_list" >You haven’t followed any project yet!</div></div>
          }
      </Tabs.TabPane>
    </Tabs>;

  </div>

const [followName, setFollowName] = useState('Thoughts')

  let  Mobile=<div className="cperson_projects" >
    <div className='followBut'>
			<div className={followName === 'Thoughts' ? 'followsel' : 'followNoSel'} onClick={() => tabsChange('Thoughts')} >Thoughts</div>
      <div className={followName === 'Project' ? 'followsel' : 'followNoSel'} onClick={() => tabsChange('Project')}  >Project</div>
			<div className={followName === 'Channel' ? 'followsel' : 'followNoSel'} onClick={() => tabsChange('Channel')}  >Channel</div>
		</div>


          { followName == 'Thoughts'  ? <div>
          {
            forumList.length > 0 ?<CMDiscover_list
            forumList={forumList}
            handleClick={()=>{getForumList(followName)}}></CMDiscover_list>:
            <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You haven’t followed any thoughts yet!</div></div>
          }
          </div>:""
          }

        { followName == 'Channel'  ? <div>
          {
            forumList.length > 0 ?<CMDiscover_list
            forumList={forumList}
            handleClick={()=>{getForumList(followName)}}></CMDiscover_list>:
            <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You haven’t followed any Channel yet!</div></div>
          }
          </div>:""
          }
        { followName == 'Project'  ? <div>
          {attentionProjectList.length > 0 ? (
              attentionProjectList.map((item: any, index: any) => {
                return (
                  <div  key={index}  className="cperson_Projects_box">
                    <Link to={'/Project?projectId=' + item.id}>
                      <div style={{ width: '100%' }}>
                        <div className="cperson_people_name">{item.name}</div>
                        <div className="cperson_people_text">
                          {item.description}
                        </div>
                        <div className="cperson_people_step">
                          <div className="cperson_people_text_2">
                            {item.donateNum} {item.receivingToken} /{' '}
                            {item.fundRaisingObjectives} {item.receivingToken}
                          </div>
                          <div className="cmin_step">
                            {getMinStepM(
                              item.fundRaisingObjectives,
                              item.donateNum
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })
            ) : (
              <div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You haven’t followed any project yet!</div></div>
            )}
          </div>:""
          }
         
</div>

return Common.isMobile ? Mobile : Web
}
