import {BasePage, page} from "./BasePage";
import "./Forum_list.css"
import "../css/common.css";
import { CForum_list} from '../components/CForum_list';
import { Navigation } from "../components/Navigation";

@page("Forum_list")
export class Forum_list extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
			<CForum_list></CForum_list>
		</div>
	}
	protected mobileContent(): JSX.Element {
		return <div>
			<CForum_list></CForum_list>
		</div>;
	}
}
