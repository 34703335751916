import {BasePage, page} from "./BasePage";
import "./Publishmint.css";
import { CPublishmint} from '../components/CPublishmint';
import { Navigation } from "../components/Navigation";

@page("Publishmint")
export class Publishmint extends BasePage {
	protected webContent() {

		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
		<CPublishmint></CPublishmint>
	</div>
	}

	protected mobileContent(): JSX.Element {
		return <div></div>
	}
}
