import {BasePage, page} from "./BasePage";
import "./Discover_list.css"
import "../css/common.css";
import { CDiscover_list} from '../components/CDiscover_list';
import { Navigation } from "../components/Navigation";

@page("Discover_list")
export class Discover_list extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
	
			<CDiscover_list></CDiscover_list>
		</div>
	}

	protected mobileContent(): JSX.Element {
		return <div>
			
			<CDiscover_list></CDiscover_list>
		</div>;
	}
}
