import { useEffect, useState } from "react";
import { Input,Dropdown,message} from 'antd';
import "./User_but.css";
import { httpGet, httpPost } from '../../utils/httpUtils';
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import { Channel } from '../pages/Channel';
import { Common } from "../../app/Common";

type IProps = {
	channelId:any
}
export function Channel_options(props:IProps) {
	const navigate = useNavigate();
	function Revise(e:any){
		if(Common.isMobile){
			navigate("/Person?channelId="+props.channelId,{ state: { type: "PublishChannel"}});
		}else{
			navigate("/PublishChannel?channelId="+props.channelId);
		}
	
	}


	function PublishLive(e:any){
		navigate("/PublishLive?channelId="+props.channelId);
	}

	const items = [
		{
		  key: '1',
		  label: (
				<div   className={Common.isMobile?"muser_menu":"user_menu"} onClick={Revise} >
					Revise
				</div>
		  ),
		},
		{
			key: '2',
			label: (
				  <div  className={Common.isMobile?"muser_menu":"user_menu"} onClick={PublishLive} >
					  Add Live
				  </div>
			),
		  }
	  ];


	 


	let Web= <Dropdown
	menu={{
	items,
	}}
	placement="bottomRight"
	arrow
	>
	<div className="ch_banner_right_2_but"   >
		Operate
	</div>
	</Dropdown>

  let  Mobile=<Dropdown
  menu={{
  items,
  }}
  placement="bottomRight"
  arrow
  >
  <div className="mch_banner_right_2_but"   >
	  Operate
  </div>
  </Dropdown>



return Common.isMobile ? Mobile : Web 


}
