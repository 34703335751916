import {State, useAppDispatch, useAppSelector} from "../../app/Store";
import {useEffect,useState} from "react";

import "./NftList.css";

import { NftList } from "./NftList";
import { MyNftList } from "./MyNftList";


export function NftMain() {
		
	let [nftTab,setNftTab]=useState("Badge");

	function changeTab(type:any){
		setNftTab(type);
	}

	return <div className="disContent"  style={{paddingBottom: nftTab==='Badge'?"80px":"660px"}} >

	<div style={{width: "412px"}} >
		<div  className={nftTab == 'Badge'?"title-bg":"title-bg-no"} onClick={() => changeTab('Badge')} >
			<div  className="title-text" >Impact badge→</div>   
		</div>

		<div className={nftTab == 'my'?"title-bg":"title-bg-no"}  onClick={() => changeTab('my')}  style={{marginTop:"30px"}} >
			<div  className="title-text" >MY collection</div>   
		</div>
	</div>

	<div  style={{width: "1108px"}} >
		{nftTab=='Badge'?
		 <div  style={{marginLeft:"47px"}} >
			<div className="nft-bg-text" ><span className="badge-text" >BADGE: </span> <span className="badge-text_2" >Claim Impact Badge for your contribution. </span> </div>
			<NftList></NftList>
			{/* <div className="nft-line" ></div>
			<div className="nft-box1 nft-top-60  " >
		    <span  className="text-bule-nft" >Ukraine Donor Badge(Gold):</span>	 Donated at least 100 USD（USDC，USDT or DAI) or 0.1 ETH to  <span  className="text-bule-nft-2" >Ukraine Dao  </span> or  <span  className="text-bule-nft-2" > Ukraine Crypto Donation</span> on Ethereum network. 
			</div>

			<div className="nft-box1 nft-top-2" >
			<span  className="text-bule-nft" > Ukraine Donor Badge(Silver):</span>  Donated at least 10 USDC or USDT DAI or 0.01 ETH to <span  className="text-bule-nft-2" >Ukraine Dao</span> or  <span  className="text-bule-nft-2" > Ukraine Crypto Donation</span> on Ethereum network.
			</div>  */}
		</div>
		:
		<div style={{marginLeft:"47px"}} >
			<MyNftList  ></MyNftList>
			<div className="nft-line" ></div>
		</div>}
	</div>

</div>


}
