import {BasePage, page} from "./BasePage";
import "./Discover_list.css"
import "./ProjectList.css"
import "../css/common.css";
import { CProject_list} from '../components/CProject_list';
import { Navigation } from "../components/Navigation";

@page("ProjectList")
export class ProjectList extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
		
			<CProject_list></CProject_list>
		</div>
	}

	protected mobileContent(): JSX.Element {
		return <div>
			<CProject_list></CProject_list>
		</div>;
	}
}
