import { useEffect, useState } from "react";
import { Input,message,Select,Image} from 'antd';
import { Link, useNavigate  } from 'react-router-dom'
import {httpGet,httpPost} from '../../utils/httpUtils';
import { Common } from "../../app/Common";
import {Share} from './Share';
import {UserHeader} from "./UserHeader";
import { Formatter } from './Formatter';
export function CDiscover() {
	const [forumList,setForumList] =  useState([]as Array<any>);
	const [tidingsList,setTidingsList] =  useState([]as Array<any>);
	useEffect(()=>{
		getPageList();
		getTidings();
	},[]) 

	const  getPageList=()=>{
		httpPost("/forum/getPageList",{page:1,limit:4}).then((e:any)=>{
			if(e.data.code==0){
				setForumList(e.data.data.list);
			}
		})
	}



	function getTidings(){
		httpPost("/tidings/getPageList",{page:1,limit:4}).then((e:any)=>{
			if(e.data.code==0){
				setTidingsList(e.data.data.list);
			}
		})
	}

	const [tabs,setTabs] =  useState(["Forum","Trending"]as Array<any>);

	function changTabs(){
		let cytabs={...tabs};
		setTabs([cytabs[1],cytabs[0]])
	}
	
	function getTags(item:any){

		let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}
		

		let tags=item.tages;
		let tagshtml=<div></div>
		if(tags){
			tags =tags.split(",");
			tagshtml=	<div style={{display:"flex",flexWrap:"wrap"}}  >
			{tags.map((ite:any,inde:any)=>{
				let color="";
				if(ite){
					color=color_conf[ite.substring(0,1).toLowerCase()]?color_conf[ite.substring(0,1).toLowerCase()]:"#FFB4A2";
				}

				return <div  key={inde}  style={{backgroundColor:color}}  className="forum_but but-shadow" >{ite}</div> 
			})}
		</div>
		}
		return  tagshtml;
	}



	function forumCollect(item:any,index:any){
		httpGet("/collect/forumCollect/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=1;
				setForumList([...forumList]);
			}
		})
	}



	function forumAttention(item:any,index:any){
		httpGet("/attention/forumAttention/"+item.id).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=1;
				forumList[index].attentionNum+=1;
				
				setForumList([...forumList]);
			}
		})
	}


	function cancelCollect(item:any,index:any,type:any){
		httpGet("/collect/cancelCollect/"+item.id+"/"+type).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isCollect=0;
				setForumList([...forumList]);
			}
		})
	}


	function cancelAttention(item:any,index:any,type:any){
		httpGet("/attention/cancelAttention/"+item.id+"/"+type).then((e:any)=>{
			if(e.data.code==0){
				forumList[index].isAttentin=0;
				forumList[index].attentionNum-=1;
				setForumList([...forumList]);
			}
		})
	}

	function calcscale(width:any,height:any){
		let maxWidh=160;
		if(width>160){
		   let scacle:any= Number(maxWidh/width).toFixed(4);
		   height= Number(scacle*height);
		   return {width:maxWidh,height:height};
		}else{
			return {width:maxWidh,height:height};
		}
	}

	function lodingImg(item:any){
		let imgUrl=item.imgUrl;
		let tagshtml=<div></div>
		if(imgUrl){
			imgUrl =JSON.parse(imgUrl);
			tagshtml=	<div className="forum_flex_one" >
					{imgUrl.map((ite:any,inde:any)=>{
						let {width,height}=	calcscale(ite.width,ite.height);
						return <div key={inde} className="forum_img">
									<Image
										width={287*0.5}
										height={179*0.5}
										src={ite.url}
									/>
								</div> 
					})}
				</div>
		}
		return  tagshtml;
	}


	let Web=   <div className="disContent">
	<div style={{paddingTop:30,width:"100%"}}   className="disForum" >
		<div  className="disflex" style={{alignItems:"center"}} >
				<div  className="disflex" style={{width:"50%",alignItems:"center"}} >
					<div className="disTitle">
						Discover 
					</div>
					<img src={require("../../assets/mobile/lesd@2x.png")} style={{width:'22px',height:'22px',marginLeft:"10px",marginTop:"20px"}}   alt="" />
				</div>
				<div  className="disflex dis_tab"  style={{width:"50%",justifyContent:"flex-end"}} >
					<div className="dis_status dis_status_active" onClick={changTabs} >
						{tabs[0]}
					</div>
					<div className="dis_status"   onClick={changTabs} style={{marginLeft:"1.9583vw"}} >
						{tabs[1]}
					</div>
				</div>
		</div>


	{tabs[0] == 'Trending'? <div className="dis_list_tow"  style={{display:"flex",flexWrap:"wrap"}} >
		{tidingsList.map((item,index)=>{
			return  <div  key={index} className="dis_list_item" style={{width:"47%"}} >
					<div  className="dis_list_name"  style={{width:"47%"}}  >
						{item.title}
					</div>
					<Link to={"/News?tidingId="+item.id}  >
						<div  className="dis_content"  >
								{item.outline}
						</div>
					</Link>
					<div className="disflex"   style={{width:"100%",marginTop:20}}  >
							<div   className="disflex" style={{width:"50%",alignItems:"center"}}  >
								<div  className="options_box" >
									<div  className="options_box_flex options_box_flex_border"  >
										{item.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{forumAttention(item,index)}} className="dis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,index,3)}} className="dis_list_img"  alt="" />}
									<div className="options_box_num" >{item.attentionNum}</div>
									</div>
									<Link  className="options_box_flex options_box_flex_border" to={"/News?tidingId="+item.id}   >
										<img src={require("../../assets/v2/feed@2x.png")}  className="dis_list_img"   alt="" />
										<div className="options_box_num" >{item.commentNum}</div>
									</Link>
									<div  className="options_box_flex options_box_flex_border"  >
									  {item.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{forumCollect(item,index)}}  className="dis_list_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect(item,index,3)}}  className="dis_list_img"   alt="" />}
									</div>
									<div  className="options_box_flex" >
									<Share   sharedata={item}  type={'dic'} ></Share>
									</div>
								</div>
						</div>
					</div>
			</div>
		})}
	</div>:""}

	{tabs[0] == 'Forum'? <div className="dis_list_tow" style={{display:"flex",flexWrap:"wrap"}}  >
		{forumList.map((item,index)=>{
			return  <div key={index} className="dis_list_item" style={{width:"47%",marginLeft:"2%"}}  >
						<div  className="dis_list_name text_my"  style={{width:"35.1042vw"}}  >
							{item.title}
						</div>
						<div  className="disflex" style={{marginLeft:"0.5208vw",alignItems:"center"}} >
								<UserHeader member={item.memberEntity}  ></UserHeader>
								<div>{item.memberName ? item.memberName:""}</div>
							<div  style={{display:"flex",marginLeft:50}} >
								 {getTags(item)}
								<div  className="dis_time" >
								<Formatter dateTime={item.createDateTime} ></Formatter>
								</div> 
							</div>
						</div>
						<Link  to={"/Forumlist?forumId="+item.id}  >
							<div  className="dis_list_content"  dangerouslySetInnerHTML={{__html:item.content}}  style={{width:"100%"}} >
							</div>	
							<div  className="forum_list_img"  >
								{lodingImg(item)}
							</div>
					</Link>

					<div className="disflex"   style={{width:"48%",marginTop:20}}  >
						<div   className="disflex" style={{width:"50%",alignItems:"center"}}  >
							<div  className="options_box" >
									<div  className="options_box_flex options_box_flex_border"  >
										{item.isAttentin==0?<img src={require("../../assets/v2/zan@2x.png")} onClick={()=>{forumAttention(item,index)}} className="dis_list_img"  alt="" />:<img src={require("../../assets/v2/zan_2.png")}   onClick={()=>{cancelAttention(item,index,3)}} className="dis_list_img"  alt="" />}
									<div className="options_box_num" >{item.attentionNum}</div>
									</div>
									<Link  className="options_box_flex options_box_flex_border"  to={"/Forumlist?forumId="+item.id}  >
										<img src={require("../../assets/v2/feed@2x.png")}  className="dis_list_img"   alt="" />
										<div className="options_box_num" >{item.commentNum}</div>
									</Link>
									<div  className="options_box_flex options_box_flex_border"  >
									  {item.isCollect==0? <img src={require("../../assets/v2/shoucang@2x.png")}  onClick={()=>{forumCollect(item,index)}}  className="dis_list_img"   alt="" />: <img src={require("../../assets/v2/shoucang_2.png")}  onClick={()=>{cancelCollect(item,index,3)}}  className="dis_list_img"   alt="" />}
									</div>
									<div  className="options_box_flex" >
									<Share   sharedata={item}  type={'dic'} ></Share>
									</div>
							</div>
						</div>
					</div>
			</div>
		})}
		</div>:""}
	</div>
	</div>



  let  Mobile=<div className="dis_list_tow" style={{marginTop:"40px",display:"flex",justifyContent:"center",flexWrap:"wrap"}}  >
	<div  style={{marginBottom:"50px"}} >
		<img src={require("../../assets/mobile/discover_bg.png")}  className="discover_bg"   alt="" />
	</div>
  {forumList.map((item,index)=>{
	  return  <div key={index} className="dis_list_item" style={{width:"96%"}}  >
				  <div  className="dis_list_name text_my"  style={{width:"35.1042vw"}}  >
					  {item.title}
				  </div>
				  <div  className="disflex" style={{marginLeft:"0.5208vw",marginBottom:"10px",alignItems:"center"}} >
						  <UserHeader member={item.memberEntity}  ></UserHeader>
						  <div>{item.memberName ? item.memberName:""}</div>
					 	 <div  style={{display:"flex",marginLeft:10}} >
						   {getTags(item)}
						  <div  className="dis_time" >
						  <Formatter dateTime={item.createDateTime} ></Formatter>
						  </div> 
						  
						 
					  </div>
				  </div>
				  <Link  to={"/Forumlist?forumId="+item.id}  >
					  <div  className="dis_list_content"  dangerouslySetInnerHTML={{__html:item.content}}  style={{width:"100%"}} >
					  </div>	
					  <div  className="forum_list_img"  >
						  {lodingImg(item)}
					  </div>
				  </Link>

	  </div>
  })}
  </div>

return Common.isMobile ? Mobile : Web


}
