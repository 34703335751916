import { useEffect, useState } from "react";
import { Input,Dropdown,message,Modal} from 'antd';
import "./Chnannel_jion.css";
import { Link, useNavigate  } from 'react-router-dom'
import { httpGet, httpPost } from '../../utils/httpUtils';
import { Common } from "../../app/Common";
import { Popup } from 'antd-mobile'


type IProps = {
    handleClick: () => void,
	item:any,
	isImage?:boolean
}
export function Chnannel_jion(props:IProps) {
	const navigate = useNavigate();
	function addChannel(){
		if(props.item.memberIsJob){
			return;
		}
		httpGet("/channel/addChannel/"+props.item.id).then((e:any)=>{
			if(e.data.code==0){
				message.success("add channel success");
				setsSccessOpen(true);
				props.handleClick();
			}else if(e.data.code==401){
				localStorage.setItem('pageFrom', '/#/Channel?channelId='+props.item.id)
				navigate('/SignIn')
			}else{
				message.success(e.data.msg); 
				setsFialOpen(true);
			}
		})
	}

	const [rules,setRules] =  useState([]as any);

	function getRules(){
		httpGet("/channel/getChannelNftList/"+props.item.id).then((e:any)=>{
			if(e.data.code==0){
				setRules(e.data.data);
			}
		})
	}

	useEffect(()=>{
		getRules();
	},[props.item]) 
	const [successOpen, setsSccessOpen] = useState(false);
	const [failOpen, setsFialOpen] = useState(false);
	let Web= <div>
		{props.isImage?<img src={require("../../assets/v2/join.png")}   onClick={addChannel}  className="com_channel_join_img"   alt="" />:<div   onClick={addChannel} >
				+ Join us
				</div>}
				<Modal
					closable={false}
					footer={null}
					open={successOpen}
				>
					<div className="modal_success" >
						<div className="modal_success_1" >
							<div className="modal_success_1_1" >You have successfully joined us！</div>
							<div className="modal_success_but" onClick={()=>{setsSccessOpen(false)}} > Continue</div>
						</div>
					</div>
				</Modal>

				<Modal
					closable={false}
					footer={null}
					open={failOpen}
				>
					<div className="modal_fial" >
						<div className="modal_success_1" >
							<div className="modal_success_1_1" >Failed, you need to get this badge</div>
							<div className="modal_success_1_1" >

							{rules.length > 0 ? 
							rules.map((item:any,inx:any)=>{
								return   <img key={inx}  onClick={()=>{
									navigate("/BadgeDetail?detailId="+item.nftItemId)
								}}  src={JSON.parse(item.image)[0].url}  className="u_nft_badge_img shou" alt="" />
							})
							:<div></div>
							}
							</div>
							

							<div className="modal_fial_but" onClick={()=>{setsFialOpen(false)}} > Try again</div>
						</div>
						
					</div>
				</Modal>

		</div>
let Mobile=<div>
			<img src={require("../../assets/v2/join.png")}   onClick={addChannel}  className="mcom_channel_join_img"   alt="" />
			<Popup  bodyClassName="mproject_donate_popup2"  style={{height:""}}  visible={successOpen} position='bottom' onMaskClick={() => {setsSccessOpen(false)}} bodyStyle={{padding:"20px" }}>
						<div className="mproject_donate_popup_box"  >
							<div className="flex-align-center">
								<img  className="mproject_donate_popup_box_success" src={require("../../assets/mobile/success.png")} />
							</div>
							<div className="flex-align-center">
								<div className="mproject_donate_success">
									Success
								</div>
							</div>
							<div className="flex-align-center">
								<div  className="mdonationMoney" >You have successfully joined us!</div>
							</div>
							<div className="flex-align-center" style={{ marginTop: "7.4667vw"}} >
								<div className="mdonationMoney_but" onClick={()=>{
									setsSccessOpen(false)
								}} >Good</div>
							</div>
						</div>

			</Popup>
			<Popup  bodyClassName="mproject_donate_popup2"  style={{height:""}}  visible={failOpen} position='bottom' onMaskClick={() => {setsFialOpen(false)}} bodyStyle={{padding:"20px" }}>
					<div className="mproject_donate_popup_box"  >
						<div className="flex-align-center">
							<img  className="mproject_donate_popup_box_success" src={require("../../assets/mobile/failed.png")} />
						</div>
						<div className="flex-align-center">
							<div className="mproject_donate_failed">
								Failed
							</div>
						</div>
						<div className="flex-align-center">
							<div  className="mdonationMoney" >Failed, you need to get this badge:</div>
						</div>

						<div className="flex-align-center" >
							{rules.length > 0 ? 
							rules.map((item:any,inx:any)=>{
								return   <img key={inx}  onClick={()=>{
									navigate("/BadgeDetail?detailId="+item.nftItemId)
								}}  src={JSON.parse(item.image)[0].url}  className="m_nft_badge_img shou" alt="" />
							})
							:<div></div>
							}
						</div>
						<div className="flex-align-center">
							<div className="mdonationMoney_fail_but" onClick={()=>{
								setsFialOpen(false)
							}} >Continue</div>
						</div>
					</div>
			</Popup>
</div>



return Common.isMobile ? Mobile : Web ;
		
}






