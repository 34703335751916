import { useEffect, useState } from "react";
import { Input, Dropdown, message, Image ,Modal } from 'antd';
import "./My_chnannel_post.css";
import { Link, useNavigate } from 'react-router-dom'
import { httpGet, httpPost } from '../../utils/httpUtils';
import { Formatter } from './Formatter';
import { Common } from "../../app/Common";
import "./My_post.css";
export function My_chnannel_post() {
	useEffect(() => {
		getMyProjectList();
		getMyJoinPageList();
	}, []);
	const [myApprovePage, setMyApprovePage] = useState([] as any);
	const [myJoinPageList, setMyJoinPageList] = useState([] as any);
	const navigate = useNavigate();

	function getMyProjectList() {
		httpPost("/channel/getMyApprovePage", {}).then((e: any) => {
			if (e.data.code == 0) {
				setMyApprovePage(e.data.data.list);
			}
		})
	}


	function getMyJoinPageList() {
		httpPost("/channel/getMyJoinPageList", {}).then((e: any) => {
			if (e.data.code == 0) {
				setMyJoinPageList(e.data.data.list);
			}
		})
	}


	function delChannel(id: any) {
		
		httpGet("/channel/delChannel/" + id).then((e: any) => {
			if (e.data.code == 0) {
				message.success("delete channel success");
				getMyProjectList();
				getMyJoinPageList();
				handleCancel()
			}
		})
	}



	function Revisechannel(id: any) {
		navigate("/PublishChannel?channelId=" + id);
	}



	function getBut(item: any) {
		let frist = "my_post_status_";
		let but_name = "";
		let className = "";
		if (item.status == -1) {
			but_name = "Rejected"
			className = frist + "Rejected";
		}

		if (item.status == 0 || item.status == 1) {
			className = frist + "Reviewing";
			but_name = "Reviewing"
		}

		if (item.status == 2) {
			className = frist + "Passed";
			but_name = "Passed"
		}

		if (item.status == 3) {
			className = frist + "Finished";
			but_name = "Finished"
		}

		if (item.status == 4) {
			className = frist + "Removed";
			but_name = "Removed"
		}

		return <div className={className}  >
			{but_name}
		</div>
	}



	function getButM(item: any) {
		let frist = "mmy_post_status_";
		let but_name = "";
		let className = "";
		if (item.status == -1) {
			but_name = "Rejected"
			className = frist + "Rejected";
		}

		if (item.status == 0 || item.status == 1) {
			className = frist + "Reviewing";
			but_name = "Reviewing"
		}

		if (item.status == 2) {
			className = frist + "Passed";
			but_name = "Passed"
		}

		if (item.status == 3) {
			className = frist + "Finished";
			but_name = "Finished"
		}

		if (item.status == 4) {
			className = frist + "Removed";
			but_name = "Removed"
		}

		return <div className={className}  >
			{but_name}
		</div>
	}


	const [isModalOpen, setIsModalOpen] = useState(false);
	const [id, setId] = useState();
	
	const handleCancel = () =>{
		setIsModalOpen(false)
	}

	const [but, setBut] = useState("Project");
	let Web = <div className="my_post_body" >
		<Modal width={640}  visible={isModalOpen} closable={false} footer={null} maskClosable={true} onCancel={handleCancel}>
			<div style={{height:'320px',padding:"60px 0"}} >
				<div style={{textAlign:'center'}}>
					<img src={require("../../assets/j.png")} style={{width:"48px",height:'48px'}} alt=""    />
					<div style={{margin:"20px 0 80px"}}>Are you sure you want to delist your channel?</div>
				</div>
				<div
				onClick={() => {
					delChannel(id);
				}} 
				style={{
					width: "320px",
					height: "48px",
					background: "#279D8E",
					borderRadius: "16px",
					textAlign:"center",
					lineHeight:'48px',
					color:'#fff',
					margin:"auto"
				}}>Confirmation</div>
			</div>
       
      </Modal>
		<div className="my_post_body_box" >
			<div className="person_nft_badge_name" >My application</div>
			{myApprovePage.length > 0 ?
				myApprovePage.map((item: any, idx: any) => {
					return <div key={idx} className="my_post_item" >
						<Link to={"/Channel?channelId=" + item.id}  >
							<div className="my_post_item_1">
								<div className="my_post_item_1_name" >{item.name}</div>
								<div className="my_post_item_1_date" >

									<Formatter dateTime={item.createDateTime} ></Formatter>
								</div>
								{getBut(item)}
							</div>
						</Link>
						<div className="my_post_item_end"  >
							{item.status == -1 ? <div className="my_post_item_end_1">REASON: <span className="my_post_item_end_text" >{item.reasonRejection}</span></div> : ""}
							{item.status != -1 ?
								<div style={{ display: "flex", alignItems: "center" }} >
									<div className="flex_width100 donate_list_item_cld_num " style={{ width: "32.4688vw", borderRight: "1px solid rgba(245, 245, 245, 1)" }}  >
									</div>
									<div className="project_remove" onClick={() => {
										setIsModalOpen(true)
										setId(item.id)
										// delChannel(item.id);
									}} >
										<img src={require("../../assets/v2/xj.png")} className="project_remove_img" alt="" />
										Delist
									</div>

									<div className="project_remove" onClick={() => {
										Revisechannel(item.id);
									}} >
										<img src={require("../../assets/v2/Rewrite@2x.png")} className="project_remove_img" alt="" />
										Revise
									</div>
								</div>
								: ""}
						</div>

					</div>
				}) : 
				<div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="no_list" >You don’t have any channel yet!</div></div>
			}
		</div>

		<div className="my_channel_box" >
			<div className="person_nft_badge_name" >Joined Channels</div>
			<div style={{ width: "100%", display: "flex",flexWrap:"wrap" }} >
				{myJoinPageList.length > 0 ?
					myJoinPageList.map((item: any, idx: any) => {
						return <div key={idx} className="com_channel_1" style={{ marginTop: " 1.0833vw" }}  >
							<div className="my_live_1">
								<img src={item.headerImgArr[0]} className="my_live_header" alt="" />
								<div className="my_live_0" >
									<div className="my_live_1_1" >
										<div className="com_live_1_1_name" >{item.name}</div>
										<img src={require("../../assets/v2/member@2x.png")} className="my_live_member" alt="" />
									</div>
									<div className="my_live_1_2" >
										<div className="my_flex" >
											<img src={require("../../assets/v2/renshu@2x.png")} className="my_live_renshu" alt="" />
											{item.memberNum}
										</div>
										<div className="my_flex" >
											<img src={require("../../assets/v2/person@2x.png")} className="my_live_renshu" alt="" />
											{item.commentNum}
										</div>
									</div>
								</div>
							</div>
						</div>

					}) : 
					<div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="no_list" >You don’t have any channel yet!</div></div>
				}
			</div>
		</div>
	</div>

	let Mobile = <div>
		<Modal width={"100%"}  visible={isModalOpen} closable={false} footer={null} maskClosable={true} onCancel={handleCancel}>
				<div style={{height:'320px',padding:"60px 0"}} >
					<div style={{textAlign:'center'}}>
						<img src={require("../../assets/j.png")} style={{width:"48px",height:'48px'}} alt=""    />
						<div style={{margin:"20px 0 80px",	fontSize:"12px"}}>Are you sure you want to delist your channel?</div>
					</div>
					<div
					onClick={() => {
						delChannel(id);
					}} 
					style={{
						width: "320px",
						height: "48px",
						background: "#279D8E",
						borderRadius: "16px",
						textAlign:"center",
						lineHeight:'48px',
						color:'#fff',
						margin:"auto",
						fontSize:"23px"
					}}>Confirmation</div>
				</div>
		</Modal>
		{but == 'Project' ? <div className="mmy_post_body" >
			{myApprovePage.length > 0 ?
				myApprovePage.map((item: any, idx: any) => {
					return <div key={idx} className="mmy_post_item" >
						<div className="mmy_post_item_1_name" onClick={() => {
							navigate("/Channel?channelId=" + item.id)
						}} >{item.name}</div>
						<div style={{ marginTop: "2.6667vw" }} >
							{getButM(item)}
						</div>
						{item.status == -1 ? <div className="mmy_post_item_REASON">REASON: <span className="mmy_post_item_end_text" >{item.reasonRejection}</span></div> : ""}

						<div className="mmy_post_but" >
							<Formatter dateTime={item.createDateTime} ></Formatter>
							<img src={require("../../assets/mobile/Delist.png")} alt="" 
							onClick={() => {
								setIsModalOpen(true)
								setId(item.id)
							}}
							className="mmy_post_delist" />
							<img src={require("../../assets/mobile/left.png")} alt="" className="mmy_post_left_but" />
						</div>
					</div>
				}) : 
				<div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You don’t have any channel yet!</div></div>
			}
		</div> :
			<div className="mproject_box" >
				{myJoinPageList.length > 0 ? myJoinPageList.map((ite: any, inx: any) => {
					return <div key={inx} className="mchannel_box_item" onClick={() => {
						navigate("/Channel?channelId=" + ite.id);
					}}    >
						<Image src={ite.headerImgArr[0]} alt="" className="mchannel_box_item_img" />
						<div className="mchannel_box_item_1"  >
							<div className="mmchannel_name" >{ite.name}</div>
							<img src={require("../../assets/v2/member@2x.png")} className="mchannel_member_img" alt="" />						</div>
					</div>

				}):
				<div style={{width:"100%",justifyContent:"center",display:"flex"}} > <div  className="mno_list" >You don’t have any channel yet!</div></div>
				}
			</div>
		}
	</div>
	return <div>
		{!Common.isMobile ? "" : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%",marginTop:"10px" }}  >
			<div style={{ marginRight: 20 }} className={but == 'Project' ? 'mmy_post_project_but_active' : 'mmy_post_project_but'} onClick={() => {
				setBut("Project")
			}} >
				My Channel
			</div>
			<div style={{ marginLeft: 20 }} className={but == 'Thoughts' ? 'mmy_post_project_but_active' : 'mmy_post_project_but'} onClick={() => {
				setBut("Thoughts")
			}}  >
				Thoughts
			</div>
		</div>}
		{Common.isMobile ? Mobile : Web}
	</div>


}
