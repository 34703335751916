import { useEffect, useState } from "react";
import { Input,Dropdown,message} from 'antd';
import "./User_but.css";
import { httpGet, httpPost } from '../../utils/httpUtils';

type IProps = {
    handleClick: () => void,
	item:any
}
export function Forum_opention(props:IProps) {

	let item=props.item;

	function remove(){
		httpGet("/forum/remove/"+item.id).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("The post was successfully deleted");
				props.handleClick();
			}
		})
	}

	function top(){
		httpGet("/forum/top/"+item.id).then((e:any)=>{
			if(e.data.code == 0 ){
				message.success("The post was successfully top");
				props.handleClick();
			}
		})
	}
	

	const items = [
		{
		  key: '1',
		  label: (
				<div className="user_menu" onClick={remove} >
					<img src={require("../../assets/v2/del@2x.png")}   className="dis_list_im_set"   alt="" />
					Delete
				</div>
		  ),
		},
		{
			key: '2',
			label: (
				  <div className="user_menu" onClick={top} >
					  <img src={require("../../assets/v2/Top@2x.png")}   className="dis_list_im_set"   alt="" />
					  Top
				  </div>
			),
		  }
	  ];


	return <Dropdown
	menu={{
	items,
	}}
	placement="bottomRight"
	arrow
	>
	<img src={require("../../assets/v2/set-0@2x.png")}   className="dis_list_im_set"   alt="" />
	</Dropdown>

}
