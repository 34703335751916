import { useEffect, useState } from "react";
import { Link, useNavigate  } from 'react-router-dom'
import { Input,Dropdown,Popover,message} from 'antd';
import {httpGet,httpPost} from '../../utils/httpUtils';
import "./UserHeader.css";
import { Common } from "../../app/Common";
import { web3 } from '../../app/Config';

export function UserHeader(param:any) {
	const navigate = useNavigate();
	let member=param.member;
	let w=param.w;
	let h=param.h;
	let marginRight=param.marginRight
	function windowOpen(url:any) {
		if(url){
			window.open(url, '_block');
		}else{
			
		}
	}
	const [memberVo,setMemberVo] =  useState({} as any);
	useEffect(()=>{
		setMemberVo(member);
	},[member]) 

	function memberAttention(){
		httpGet("/attention/memberAttention/"+member.id).then((e:any)=>{
			if(e.data.code==0){
				message.success("follow member successful.");	
				memberVo.isFollow=1;
				setMemberVo({...memberVo});
			}
		})
	}
	const [myMemberNft,setMyMemberNft] =  useState({ }as any);

	function addMsg(){
		httpGet("/privateletter/create/"+member.id).then((e:any)=>{
			if(e.data.code==0){
				navigate("/chat?privateLetterId="+e.data.data);
			}
		})
	}
	function cancelAttention(item:any){
		httpGet("/attention/cancelAttention/"+item.id+"/2").then((e:any)=>{
			if(e.data.code==0){
				message.success("unfollow member successful.");
				memberVo.isFollow=0;
				setMemberVo({...memberVo});

			}
		})
	}
	
	
	useEffect(()=>{
		if(member && member.nftList){
			setMyMemberNft(member.nftList);
		}
	},[]) 


	const [userPostList,setUserPostList] =  useState({} as any);

	function openUser(){
		httpGet("/forum/getLatestByMemberId/"+member.id).then((e:any)=>{
			if(e.data.code==0){
				console.log(e.data.data);
				setUserPostList(e.data.data);
			}
		})
	}

	
	function getTags(item:any){
		let color_conf={
			a:"#274554",b:"#279D8E",c:"#E9C468",d:"#F5A261",e:"#E77150",f:"#A56698",g:"#65B8BA",h:"#279D75",i:"#3D6799",j:"#71BB63",k:"#457B9D",l:"#D67370",m:"#FFB4A2",
			n:"#E5979A",o:"#B4838D",p:"#6D6674",q:"#F8A091",r:"#5DC572",s:"#C9CA7B",t:"#E088A9",u:"#FFC79A",v:"#9D5FB4",w:"#5A87AD",x:"#DAAF7E",y:"#6CBFC2",z:"#6F74BC"
		}

		let tags=item.tages;
		let tagshtml=<div></div>
		if(tags){
			tags =tags.split(",");
			if(tags.length > 0 ){
				let color="";
				if(tags[0]){
					color=color_conf[tags[0].substring(0,1).toLowerCase()]?color_conf[tags[0].substring(0,1).toLowerCase()]:"#FFB4A2";
				}

				tagshtml=	<div className="forum_flex_one" >
				 <div  className="forum_but but-shadow"  style={{backgroundColor:color}} >{tags[0]}</div> 
				</div>
			}
		}
		return  tagshtml;
	}

	const content = (
		<div style={{minWidth:"20.625vw"}} >
			<div className="p_user_detail_1" >
				<Link to={"/Person_show?ref="+member.id}  >
					<img  src={member &&member.headImg?member.headImg:require("../../assets/v2/logo.png")} style={{width:'2.5vw',height:'2.5vw',borderRadius:"100%",marginRight:"0.5208vw",objectFit:"cover"}}   alt="" />
				</Link>
				<div >
					<div className="p_user_name" >{member ? member.userName:""}</div>
					<div>
						{myMemberNft.length > 0 ? 
						myMemberNft.map((item:any,inx:any)=>{
							return   item.image?<img key={inx}   src={JSON.parse(item.image)[0].url}  className="u_nft_badge_img" alt="" />:""
						})
						:<div></div>
						 }
					</div>
				</div>
				<div style={{display:"flex",alignItems:"center"}} >
					
					{ !memberVo.isFollow ? <div  onClick={memberAttention} className="p_user_detail_Follow  but-shadow" >
						<img src={require("../../assets/v2/add.png")}   alt="" className="p_u_add_img" />
						Follow
					</div>:""}
					{ memberVo.isFollow==1 ? <div onClick={()=>{cancelAttention(memberVo)}}  className="p_user_detail_unfollow but-shadow">
								Unfollow
							</div>:""}

					<div  onClick={addMsg}  className="p_user_detail_chat  but-shadow"  >
						<img src={require("../../assets/v2/chat-1.png")}   alt="" className="p_u_add_img" />
					</div>
				</div>
			</div>
			<div className="p_user_detail_2"  >
				{member.bio}
			</div>

			<div className="p_user_detail_2"  >
				{userPostList && userPostList.id  ? 	<div style={{display:"flex"}} className="dis_cover_name"  onClick={()=>{navigate("/Forumlist?forumId="+userPostList.id);}}  >
						{getTags(userPostList)}
						<div>{userPostList.title}</div>
						</div>:""}
			</div>
			<div>
				Social media:
				<img src={require("../../assets/v2/net_bk.png")}  onClick={()=>{windowOpen(member.officialWebsite)}}   alt="" className="project_share_img" />
			</div>
		</div>
	  );


	let Web=  <div>
	  {member && member.id > 0 ? <Popover placement="bottomLeft"  content={content} >
		  <Link to={"/Person_show?ref="+member.id}  >
			  <img  onMouseEnter={openUser} src={member && member.headImg?member.headImg:require("../../assets/v2/logo.png")} style={{width: w?w:'2.1875vw',height: h?h:'2.1875vw',borderRadius:"100%",marginRight:marginRight?marginRight:"0.5208vw",objectFit:"cover"}}   alt="" />
		  </Link>
	  </Popover>:<div></div>}
  </div>


let Mobile=  <div>
	 {member && member.id > 0 ?
		   <Link  to={"/Person_show?ref="+member.id}  >
			  <img  src={member && member.headImg?member.headImg:require("../../assets/v2/logo.png")} style={{width: w?w:'32px',height: h?h:'32px',borderRadius:"100%",marginRight:marginRight?marginRight:"0.5208vw"}}   alt="" />
		  </Link>:""}
  </div>


	return Common.isMobile ? Mobile : Web

	
	
	
	

}
