import { useEffect, useState } from "react";
import { Input, Dropdown, message,Tabs } from 'antd';
import "./User_but.css";
import { Link } from 'react-router-dom'
import { httpGet, httpPost, host_url } from '../../utils/httpUtils';
import { UserHeader } from './UserHeader'
import { Common } from "../../app/Common";
import { CheckOutline, AddOutline } from 'antd-mobile-icons'

export function People() {
	const [followName, setFollowName] = useState('Following')
	const [activeKey,setActiveKey] =  useState("Thoughts");


	useEffect(() => {
		getAttentionMemberList();
	}, [])

	function changTabs(e:any) {
		setActiveKey(e);
		selFollowName(e);
		if(e =='Following'){
			getAttentionMyList();
		}else{
			getAttentionMemberList();
		}
	}

	const [attentionMemberList, setAttentionMemberList] = useState({} as any);

	function getAttentionMemberList() {
		httpGet("/attention/getAttentionMemberList").then((e: any) => {
			if (e.data.code == 0) {
				setAttentionMemberList(e.data.data);
			}
		})
	}

	function getAttentionMyList() {
		httpGet("/attention/getAttentionMyList").then((e: any) => {
			if (e.data.code == 0) {
				setAttentionMemberList(e.data.data);
			}
		})
	}



	const selFollowName = (name: string) => {
		setFollowName(name)
		if (name == 'Following') {
			getAttentionMemberList();
		} else {
			getAttentionMyList()
		}

	}
	function windowOpen(url: any) {
		window.open(url, '_block');
	}

	function memberAttention(item: any) {
		httpGet("/attention/memberAttention/" + item.id).then((e: any) => {
			if (e.data.code == 0) {
				message.success("follow member successful.");
				changTabs(activeKey);
			}
		})
	}


	function cancelAttention(item: any) {
		httpGet("/attention/cancelAttention/" + item.id + "/2").then((e: any) => {
			if (e.data.code == 0) {
				message.success("unfollow member successful.");
				changTabs(activeKey);
			}
		})
	}




	let Web = <div className="user_body" >
		`	<Tabs onChange={changTabs}   defaultActiveKey={activeKey} >
				<Tabs.TabPane tab="Following" key="Following">
							{attentionMemberList.length > 0 ? attentionMemberList.map((item: any, index: any) => {
						return <div key={index} className="person_people_box">
							<div>
								<UserHeader member={item} w={"2.5vw"} h={"2.5vw"} ></UserHeader>
							</div>
							<div style={{ width: "100%" }}>
								<div className="person_people_name" >
									{item.userName}
								</div>
								<div className="person_people_text" >
									<div style={{ width: "80%" }} >
										{item.bio}
									</div>

									<div style={{ width: "20%", justifyContent: "center", display: "flex" }} >
										{item.isFollow == 0 ? <div onClick={() => { memberAttention(item) }} className="person_people_follow but-shadow ">
											√ Follow
										</div> : ""}
										{item.isFollow == 1 ? <div onClick={() => { cancelAttention(item) }} className="person_people_Unfollow but-shadow">
											Unfollow
										</div> : ""}
									</div>
								</div>
								<div className="person_people_Social" >
									<div>Social media：</div>
									<img src={require("../../assets/v2/net_bk.png")} onClick={() => { windowOpen(item.personalWebsite) }} className="person_people_img" alt="" />
								</div>
							</div>
						</div>
					}) : <div style={{width:"100%",justifyContent:"center",display:"flex"}} > 
							<div  className="no_list" >You don’t have any following yet!</div>
					</div>}
					
				</Tabs.TabPane>

				<Tabs.TabPane tab="Follower" key="Follower">
				{attentionMemberList.length > 0 ? attentionMemberList.map((item: any, index: any) => {
					return <div key={index} className="person_people_box">
						<div>
							{/* <img src={require("../../assets/v2/gold.png")} alt="" className="person_people_logo" /> */}
							<UserHeader member={item} w={"2.5vw"} h={"2.5vw"} ></UserHeader>
						</div>
						<div style={{ width: "100%" }}>
							<div className="person_people_name" >
								{item.userName}
							</div>
							<div className="person_people_text" >
								<div style={{ width: "80%" }} >
									{item.bio}
								</div>

								<div style={{ width: "20%", justifyContent: "center", display: "flex" }} >
									{item.isFollow == 0 ? <div onClick={() => { memberAttention(item) }} className="person_people_follow but-shadow ">
										√ Follow
									</div> : ""}
									{item.isFollow == 1 ? <div onClick={() => { cancelAttention(item) }} className="person_people_Unfollow but-shadow">
										Unfollow
									</div> : ""}
								</div>
							</div>
							<div className="person_people_Social" >
								<div>Social media：</div>
								<img src={require("../../assets/v2/net_bk.png")} onClick={() => { windowOpen(item.personalWebsite) }} className="person_people_img" alt="" />
							</div>
						</div>
					</div>
				}) : <div style={{width:"100%",justifyContent:"center",display:"flex"}} > 
						<div  className="no_list" >You don’t have any follower yet!</div>
				</div>}



				</Tabs.TabPane>
			</Tabs>`

		{/* <div className="disflex" style={{ alignItems: "center" }} >
			<div className="disflex dis_tab"  >
				<div className="dis_status dis_status_active" onClick={changTabs} >
					{tabs[0]}
				</div>
				<div className="dis_status" onClick={changTabs} style={{ marginLeft: "3.9583vw", marginBottom: "10px" }} >
					{tabs[1]}
				</div>
			</div>
		</div> */}

		
	</div>

	let Mobile = <div>
		<div className='followBut'>
			<div className={followName === 'Following' ? 'followsel' : 'followNoSel'} onClick={() => selFollowName('Following')} >Following</div>
			<div className={followName === 'Follower' ? 'followsel' : 'followNoSel'} onClick={() => selFollowName('Follower')}  >Follower</div>
		</div>
		<div className='followList'>
			{attentionMemberList.length > 0 ? attentionMemberList.map((item: any, index: any) => {
				return <div className='followList_item' key={index}>
				<div className='followList_item_imgAndContent'>
					<div className='followList_item_img'>
						<UserHeader member={item} w={"8.2667vw"} h={"8.2667vw"} ></UserHeader>
					</div>
					<div className='followList_item_center'>
						<div className='followList_item_title'>{item.userName}</div>
						<div className='followList_item_content'>{item.email}</div>
					</div>
				</div>
				{item.isFollow == 0 ? <div className={item.isFollow == 0?"followList_item_but_OK":''}  onClick={() => { memberAttention(item) }}><CheckOutline />Follow</div> : ""}
				{item.isFollow == 1 ? <div className={item.isFollow == 0?"followList_item_but":''}  onClick={() => { cancelAttention(item) }}><AddOutline />follow</div> : ""}
			</div>
			}) :
			<div style={{width:"100%",justifyContent:"center",display:"flex"}} > 
				{followName == 'Following' ?<div  className="mno_list" >You don’t have any following yet!</div>:<div  className="mno_list" >You don’t have any follower yet!</div>}
			</div>
			}
		</div >
	</div >



	return Common.isMobile ? Mobile : Web

}
