import { useEffect, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';

type IProps = {
    handleClick: (e:any) => void,
	value?:any,
    w?:any,
    h?:any,
    placeholder?:any
}
export function Quill_Input(props:IProps) {
    Quill.register("modules/emoji", quillEmoji);
	let modules = {
        toolbar: [
            ['bold'],        // toggled buttons
            // ['blockquote', 'code-block'],
            // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            // [{ 'direction': 'rtl' }],                         // text direction
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            // [{ 'align': [] }],
            ['emoji'], //emoji表情，设置了才能显示
            ['clean']                                         // remove formatting button
        ],
        "emoji-toolbar": true,
        // "emoji-textarea": true,
        // "emoji-shortname": true,
    }
	const [value, setValue] = useState("");
    useEffect(()=>{
        setValue(props.value);
	},[props.value]);
	 const onValueChange = (value:any) => {
        setValue(value);
        props.handleClick(value);
    }
	
	return  <ReactQuill
	value={value}
	theme="snow"
    placeholder={props.placeholder}
	modules={modules}
	style={{width:props.w? props.w:"47.7917vw",height: props.h? props.h:"7.3892vw"}}
	onChange={onValueChange} />
}
