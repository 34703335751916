import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { httpGet, httpPost } from '../../utils/httpUtils'
import './Ctrending_star.css'
import { Create_project_but } from './Create_project_but'
import { Common } from '../../app/Common'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



export function Ctrending_star(param: any) {
  const [recommendList, setRecommendList] = useState([] as Array<any>)
  useEffect(() => {
    getRecommendList()
  }, [])

  function getRecommendList() {
    httpGet('/tidings/getRecommendList').then((e: any) => {
      if (e.data.code == 0) {
        setRecommendList(e.data.data)
      }
    })
  }
  let Web = (
    <div className="trendingContent">
      <div
        className="trendingTrending"
        style={{
          paddingLeft: param.paddingLeft_,
          paddingTop: param.paddingTop_ ? param.paddingTop_ : '1.5625vw',
        }}>
        <div className="trendingflex" style={{ alignItems: 'center' }}>
          <div
            className="trendingflex"
            style={{ alignItems: 'center' }}>
            <div className="trending_status trending_status_active">
              {param.title ? param.title : 'Trending'}
            </div>
          </div>
          {/* {param.isCreate ? <Create_project_but></Create_project_but> : ''} */}
        </div>

        {recommendList.length>0 ? 
        <div>
          {recommendList.map((item, index) => {
                    return (
                      <div key={index} style={{ marginTop: 30 }}>
                        <div
                          style={{ width: '20.625vw' }}
                          className="trending_tren_title">
                          {item.title}
                        </div>
                        <div
                          style={{ width: '20.625vw', marginTop: 10 }}
                          className="trending_tren_text">
                          {item.outline}
                        </div>
                        <div
                          className="trendingflex"
                          style={{
                            marginTop: 10,
                            width: '20.625vw',
                            justifyContent: 'flex-end',
                          }}>
                          <Link to={'/News?tidingId=' + item.id}>
                            <div className="trending_Reading">Continue Reading→</div>
                          </Link>
                        </div>
                      </div>
                    )
                  })}
        </div>
        
        :<Skeleton   count={3}   style={{ width: '20.625vw', marginTop: 10 }}   className="trending_tren_title" ></Skeleton>

        }

       




      </div>
    </div>
  	)

		let Mobile = <div>
				{recommendList.map((item, index) => {
          return (
            <div key={index} style={{ marginBottom: 20 }}>
              <div
                className="trending_tren_titlem">
                {item.title}
              </div>
              <div >
                {item.outline}
              </div>
              {/* <div
                className="trendingflex"
                style={{
                  marginTop: 10,
                  justifyContent: 'flex-end',
                }}>
                <Link to={'/News?tidingId=' + item.id}>
                  <div className="trending_Reading">Continue Reading→</div>
                </Link>
              </div> */}
            </div>
          )
        })}
				
		</div>
		return Common.isMobile ? Mobile : Web
}
