/* eslint-disable @typescript-eslint/no-unused-vars */
import "./LivePage.css";
import { Common } from "../../app/Common";
import { useEffect, useState } from 'react'
import { MMChannel } from '../components/MMChannel';
import {httpGet,httpPost} from '../../utils/httpUtils';
import { useNavigate } from "react-router-dom";
export function LivePage() {


  const navigate = useNavigate();
  const [liveList,setLiveList] =  useState([]as Array<any>);
  const [keyWorld,setKeyWorld] =  useState("");



  useEffect(()=>{
        getLivePageList(keyWorld);
	},[])

  const  getLivePageList=(keyWorld:any)=>{
		httpPost("/live/getPageList",{page:1,limit:100,keyWorld:keyWorld}).then((e:any)=>{
			if(e.data.code==0){
				setLiveList(e.data.data.list);
			}
		})
	}

  // @ts-ignore
  const Web = <div id="LivePage" mode="web"></div>
  // @ts-ignore
  const Mobile = <div id="LivePage" mode="mobile">

            <div  className="LiveTitle">Live </div>
            <div>
                {liveList.length > 0 ? liveList.map((item:any,index:any)=>{
                return  <div className="mcom_live_box"   onClick={()=>{ navigate("/ChLive?liveId="+item.id)}}  >
                                <img src={JSON.parse(item.image)[0]['url']}  className="mcom_live_img"   alt="" />
                <div  className="mcom_live_box_name">
                        <img src={item && item.channelEntity &&  item.channelEntity.headerImgArr ? item.channelEntity.headerImgArr[0]:require("../../assets/v2/member@2x.png")} className="mcom_live_header"   alt="" />
                        <div  className="mcom_live_0" >
                                <div className="mcom_live_1_1" >{item.name}</div>
                                <div  className="mcom_live_1_2" >
                                        <div  >{item && item.channelEntity ? item.channelEntity.plateName:""}</div>
                                </div>
                        </div>
                </div>
                </div>
        }):""}
        </div>
  </div>
  return Common.isMobile ? Mobile : Web
}