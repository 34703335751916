import {BasePage, page} from "./BasePage";
import "../css/common.css"
import "./Nft_m.css"
import { NftMain } from "../components/NftMain";
import { NftMain_m } from "../components/NftMain_m";
@page("Nft")
export class Nft extends BasePage {
	protected webContent() {
		function changTab(tpye:any){
		}
		return <NftMain></NftMain>
	}

	protected mobileContent(): JSX.Element {
		return <NftMain_m></NftMain_m>
	}
}
