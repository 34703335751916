import {BasePage, page} from "./BasePage";
import "./Donate.css"
import "../css/common.css";
import { CDonate_view} from '../components/CDonate_view';
import { Navigation } from "../components/Navigation";

@page("Donate_view")
export class Donate_view extends BasePage {
	protected webContent() {
		return <div  style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}}  >
			<CDonate_view></CDonate_view>
		</div>
	}


	

	protected mobileContent(): JSX.Element {
		return <div>
			



		</div>;
	}
}
