import {BasePage, page} from "./BasePage";
import "./BadgeDetail.css";
import { CBadgeDetail} from '../components/CBadgeDetail';
import { Navigation } from "../components/Navigation";

@page("BadgeDetail")
export class BadgeDetail extends BasePage {
	protected webContent() {
		return <div style={{height:"86vh",overflowY:"scroll",marginTop:"7vh"}} >
		<CBadgeDetail></CBadgeDetail>
	</div>
	}

	protected mobileContent(): JSX.Element {
		return <div   >
			<CBadgeDetail></CBadgeDetail>
		</div>
	}
}
